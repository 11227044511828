import React, { useMemo, useState } from 'react'
import { withStyles } from '@material-ui/core'

import Dialog from '../../../../../../../../components/Dialog/Dialog'
import CloseIcon from '../../../../../../../../components/Icon/CloseIcon'
import ItemGrid from '../../../../../../../../components/Modal/ItemGrid'
import InputForm from '../../../../../../../../components/Input/InputForm'
import { TextFieldSearch } from '../../../../../../../../components/TextField'

import { findAllProcedimentosAtendimento } from '../../../../../../../../services/ProcedimentoService'

import { Button } from '../../../../../../../../components/ui/Buttons'
import {
  createControleSessao,
  findAllAgendamentoTipo,
  calculaQuantidadeAgendamentoPeridoControleSessao,
} from '../../../../../../../../services/ControleSessaoService'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import string from '../../../../../../../../utils/string'
import { styles } from './styles'
import { InputDateForm } from '../../../../../../../../components/Modal/Input'
import moment from 'moment'
import { ReactSelect } from '../../../../../../../../components/Select/SelectSearch'
import { periodos } from '../../utils/constants'

const getCurrentField = isProcedure => {
  const field = isProcedure ? 'controleSessaoProcedimentos' : 'controleSessaoAgendamentoTipos'
  const subField = isProcedure ? 'procedimento' : 'agendamentoTipo'
  return { field, subField }
}

const ModalCreateSession = observer(({ open, onClose, classes, type, controleSessaoStore }) => {
  const [pacoteSessao, setPacoteSessao] = useState({})
  const [quantidadeSessoes, setQuantidadeSessoes] = useState(1)
  const [isLoading, setIsLoading] = useState(false)
  const [periodo, setPeriodo] = useState(null);

  const isProcedure = type === 'PROCEDIMENTO'
  const idSujeitoAtencao = window.location.href.split('/')[6]

  const handleLoadMore = async ({ search, data }) => {
    let pageableDTO = {
      sortDir: 'ASC',
      sortField: isProcedure ? 'nome' : 'descricao',
      pageNumber: data.page,
      pageSize: 20,
    }
    const procedimento =
      isProcedure &&
      (await findAllProcedimentosAtendimento({
        nome: search,
        ativo: true,
        salaId: null,
        pageableDTO,
      }))

    const consulta =
      !isProcedure &&
      (await findAllAgendamentoTipo({
        search,
        ativo: true,
        pageableDTO,
      }))
    const { content, last } = procedimento || consulta
    if (content || consulta) {
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: data.page + 1,
        },
      }
    }
  }

  const loadProcedimentosConsultas = async (search, loadedOptions, { page }) => {
    return handleLoadMore({
      search,
      data: { page },
    })
  }

  const handleChange = (value, field) => {
    setPacoteSessao({ ...pacoteSessao, [field]: value })
  }

  const handleChangeProcedimentoConsultas = value => {
    const field = getCurrentField(isProcedure).field
    const subField = getCurrentField(isProcedure).subField
    setPacoteSessao(prevState => {
      return {
        ...prevState,
        [field]: [
          {
            [subField]: value,
          },
        ],
      }
    })
  }

  const findProcedimentoConsultaSelected = () => {
    const field = getCurrentField(isProcedure).field
    return (
      pacoteSessao[field] &&
      pacoteSessao[field].map(item => item[getCurrentField(isProcedure).subField])
    )
  }

  const handleChangeQuantidadeSessoes = value => {
    if (value < 0) {
      return setQuantidadeSessoes(0)
    }
    if (value > 365) return;
    setQuantidadeSessoes(value)
  }

  const handleSubmit = async () => {
    setIsLoading(true)
    const field = getCurrentField(isProcedure).field
    const fieldEmpty =
      field === 'controleSessaoProcedimentos'
        ? 'controleSessaoAgendamentoTipos'
        : 'controleSessaoProcedimentos'
    try {
      const newSessoes = Array.from({ length: quantidadeSessoes }, () => ({
        ...pacoteSessao[field][0],
      }))
      const variables = {
        controleSessao: {
          ...pacoteSessao,
          [field]: newSessoes,
          [fieldEmpty]: [],
          sujeitoAtencao: {
            id: idSujeitoAtencao,
          },
          ...(pacoteSessao.dataVencimento && {dataVencimento: moment(pacoteSessao.dataVencimento).format("YYYY-MM-DD")}),
        },
      }
      await createControleSessao(variables)
      await controleSessaoStore.resetParamsPacote()
      await controleSessaoStore.loadAllPacotesSessoes({ idSujeitoAtencao }, true)
      onClose()
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const calculaQuantidadeSessoes = async() => {
    try {
      const quantidade = await calculaQuantidadeAgendamentoPeridoControleSessao({
        dataLimite: moment(pacoteSessao.dataVencimento).format('YYYY-MM-DD'),
        periodo: periodo.value,
      });
      setQuantidadeSessoes(quantidade);

    } catch (error) {
      showAlertMessage({
        isOpen: true,
        message: 'Erro ao calcular a quantidade de sessões.',
        variant: 'error',
      })
    }
  };

  const disableButton = useMemo(() => {
    const field = string.currentFieldControleSessao(pacoteSessao)?.field
    const subField = string.currentFieldControleSessao(pacoteSessao)?.subField
    const procedimento = pacoteSessao?.[field]?.[0]?.[subField]
    return !procedimento || quantidadeSessoes <= 0 || !pacoteSessao.nomeSessao
  }, [pacoteSessao, quantidadeSessoes])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={'sm'}
      fullWidth={true}
      classes={{ paper: classes.paper }}
    >
      <div className={classes.content}>
        <div className={classes.header}>
          <h1>Criar sessão</h1>
          <CloseIcon onClick={onClose} size='20' />
        </div>
        <div>
          <div className={classes.inlineFields}>
            <ItemGrid size={12} label={'Nome da sessão:'} bold>
              <InputForm
                name={'nomeSessao'}
                placeholder={'Nome da sessão'}
                className={classes.inputContainer}
                value={pacoteSessao.nomeSessao}
                onChange={e => handleChange(e.target.value, 'nomeSessao')}
              />
            </ItemGrid>
          </div>
          <div className={classes.inlineFields}>
            <ItemGrid classes={{ item: classes.removePadding }} size={4} label={'Período:'} bold>
              <ReactSelect
                className={classes.reactSelect}
                placeholder={'Selecione'}
                value={periodo}
                options={periodos}
                onChange={(e) => setPeriodo(e)}
                menuPlacement={'auto'}
              />
            </ItemGrid>
            <ItemGrid classes={{ item: classes.removePadding }} size={4} label={'Vencimento:'} bold>
              <InputDateForm
                name={'dataVencimento'}
                iconposition="end"
                classes={{
                  date: classes.inputDate,
                  input: classes.colorInput,
                }}
                invalidDateMessage=""
                value={pacoteSessao.dataVencimento || null}
                onChange={e => handleChange(e?.target?.value || e, 'dataVencimento')}
              />
            </ItemGrid>
            <ItemGrid classes={{ item: classes.removePadding }} size={4} label={''} bold>
              <Button
                onClick={calculaQuantidadeSessoes}
                kind='primary'
                bgColor="rgb(112, 124, 151)"
                disabled={!periodo || !pacoteSessao.dataVencimento}
              >
                Calcular quantidade
              </Button>
            </ItemGrid>
          </div>
          <div className={classes.inlineFields}>
            <ItemGrid classes={{ item: classes.removePadding }} size={8} label={isProcedure ? 'Procedimento*:' : 'Consulta*:'} bold>
              <TextFieldSearch
                classNotched={classes.notchedOutline}
                classInput={classes.inputTextField}
                classIcons={classes.classIcons}
                placeholder={'Selecione'}
                value={findProcedimentoConsultaSelected()}
                onChange={e => handleChangeProcedimentoConsultas(e)}
                menuPlacement={'auto'}
                getOptionLabel={option => option.descricao || option.nome}
                getOptionValue={option => option.id}
                withPaginate
                loadOptions={loadProcedimentosConsultas}
                debounceTimeout={300}
                additional={{
                  page: 0,
                }}
              />
            </ItemGrid>
            <ItemGrid size={4} label={'QTD. de sessões:'} bold>
              <InputForm
                value={quantidadeSessoes}
                onChange={e => handleChangeQuantidadeSessoes(e.target.value)}
                placeholder={'Digite a quantidade'}
                type={'number'}
                className={classes.inputContainer}
              />
            </ItemGrid>
          </div>
          <ItemGrid size={12} label={'Observações:'} bold>
            <InputForm
              className={classes.inputObservacao}
              rowsMax={20}
              rows={5}
              multiline
              value={pacoteSessao?.observacao}
              onChange={e => handleChange(e.target.value, 'observacao')}
            />
          </ItemGrid>
        </div>
        <div className={classes.footerButtons}>
          <Button
            onClick={handleSubmit}
            kind='primary'
            disabled={disableButton || isLoading}
            isLoading={isLoading}
          >
            Salvar
          </Button>
        </div>
      </div>
    </Dialog>
  )
})

const ModalCreateWithStyles = withStyles(styles)(ModalCreateSession)
export default inject('controleSessaoStore')(ModalCreateWithStyles)
