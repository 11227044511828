import Api from "../config/api";

export const linkLoginAzulControle = async() => {
    try{
        const response = await Api.post("", {
            query:  `{
                linkLoginAzulControle
            }
        `});

        return response.data.data.linkLoginAzulControle;
     } catch (error) {
        console.error(error);
     }
};

export const linkFaturamentoAgendamento = async({agendamentoId}) => {
    const response = await Api.post("", {
        query:  `{
            linkFaturamentoAgendamento(agendamentoId: ${agendamentoId})
        }
    `});

    if(response?.data?.data) {
        return response.data.data.linkFaturamentoAgendamento;
    } else {
        throw(new Error(response.data.errors[0]))
    }
};

export const linkNotaAgendamento = async({agendamentoId}) => {
    try{
        const response = await Api.post("", {
            query:  `
                query ($agendamentoId: Long) {
                    linkNotaAgendamento(agendamentoId: $agendamentoId)
                }
        `,
        variables: { agendamentoId }
    });

        return response.data.data.linkNotaAgendamento;
     } catch (error) {
        throw error;
     }
};

export const ativarCentroCusto = async id => {
    const response = await Api.post("", {
        query: `
            mutation($id: Long){
                ativarCentroCusto(id: $id){
                    ativo
                }
        }`,
        variables: { id }
    });

    return response.data.data.ativarCentroCusto;
};

export const inativarCentroCusto = async id => {
    const response = await Api.post("", {
        query: `
            mutation($id: Long){
                inativarCentroCusto(id: $id){
                    ativo
                }
        }`,
        variables: { id }
    });

    return response.data.data.inativarCentroCusto;
};

export const getSaldoAtual = async id => {
    const response = await Api.post("", {
        query: `
            {
                findSaldoAtualContaBancariaById(id: ${id})
            }
        `
    });
    return response.data.data.findSaldoAtualContaBancariaById;
};

export const relatorioTituloParcelaCsv = async ({nomeQuery, variables}) => {
    let profissionalSaude = {
        profissionalTipo: "",
        profissionalSaudeId: "",
    }

    if (nomeQuery === "relatorioTituloParcelaOutrousProfissionaisCsv") {
        profissionalSaude = {
            profissionalTipo: `, $profissionalSaudeId: Long`,
            profissionalSaudeId: `, profissionalSaudeId: $profissionalSaudeId`,
        }
    }

    const response = await Api.post('',
    {
        query: `
            query($search: String, $tipo: TituloTipo, $convenioId: Long, $formasPagamento: [FormaPagamentoInput], $sujeitoAtencaoId: UUID, $contaBancariaId: Long, $dataInicial: LocalDate, $dataFinal: LocalDate, $status: [StatusTituloParcela], $categoriaFinanceiraId: Long, $centroCustoId: Long${profissionalSaude.profissionalTipo}){
            ${nomeQuery}(search: $search, tipo: $tipo, convenioId: $convenioId, formasPagamento: $formasPagamento, sujeitoAtencaoId: $sujeitoAtencaoId, contaBancariaId: $contaBancariaId, dataInicial: $dataInicial, dataFinal: $dataFinal, status: $status, categoriaFinanceiraId: $categoriaFinanceiraId, centroCustoId: $centroCustoId${profissionalSaude.profissionalSaudeId})
            }`,
        variables: variables
    });

    const relatorioAgendamentoCsv = response.data.data[nomeQuery];

    return relatorioAgendamentoCsv
};

export const findAllTitulo = async (variables) => {
    const response = await Api.post('',
    {
        query: `
        query ($search: String, $profissionalSaudeId: Long, $possuiAgendamento: Boolean, $sujeitoAtencaoId: UUID, $pageableDTO: PageableDTOInput) {
            findAllTitulo(search: $search, profissionalSaudeId: $profissionalSaudeId, possuiAgendamento: $possuiAgendamento, sujeitoAtencaoId: $sujeitoAtencaoId, pageableDTO: $pageableDTO) {
              last
              content {
                id
                nome
                dataVencimento
              }
            }
          }
        `,
        variables: variables
    });

    return response.data.data.findAllTitulo;
};


export const createTitulo = async (variables) => {
    const response = await Api.post('',
    {
        query: `
        mutation ($titulo: TituloInput) {
          createTitulo(titulo: $titulo) {
            id
            tipoRepeticao
            valorEntrada
            codigoTransicao
            parcelas {
              tipo
              numeroParcela
              valor
              formaPagamento {
                id
              }
              desconto
              tipoDesconto
              dataVencimento
              dataPagamento
              contaBancaria {
                id
              }
              nome
            }
            tituloProcedimentos {
                quantidade
                procedimento {
                    id
                    nome
                }
            }  
          }
        }
        `,
        variables,
    });
    return response.data.data.createTitulo;
};

export const findAllCategoriaFinanceira = async (variables) => {
    const response = await Api.post("", {
        query: `
          query ($searchDTO: SearchFinanceiroDTOInput) {
            findAllCategoriaFinanceira(searchDTO: $searchDTO) {
              last
              content {
                id
                ativo
                nome
                tipo
                categoriasFinanceiras {
                  id
                  nome
                  ativo
                }
              }
            }
          }
                `,
        variables: variables
    });

    if(response?.data?.data) {
        return response.data.data.findAllCategoriaFinanceira;
    } else {
        throw(new Error(response.data.errors[0]))
    }
};

export const alteraStatusCategoriaFinanceira = async(categoriaFinanceiraId, action) => {
    const response = await Api.post("", {
        query: `
            mutation{
            ${action}CategoriaFinanceira(categoriaFinanceiraId: ${categoriaFinanceiraId}){
                ativo
            }
        }
        `,
    });
    return response;
};

export const alteraStatusContaBancaria = async(contaBancariaId, action) => {
    const response = await Api.post("", {
        query: `
            mutation{
            ${action}ContaBancaria(contaBancariaId: ${contaBancariaId}){
                ativo
            }
        }
        `,
    });
    return response;
};

export const faturarLoteTituloParcela = async (variables) => {
    const response = await Api.post('',
    {
    query: `
        mutation ($tituloIds: [Long], $dataPagamento: LocalDate) {
            faturaLoteTituloParcela(tituloIds: $tituloIds, dataPagamento: $dataPagamento) {
                id
                nome
                dataVencimento
            }
        }
    `,
        variables,
    });
    return response.data.data.faturaLoteTituloParcela;
}

export const pesquisaVoucherAssociado = async (variables) => {
	const response = await Api.post("", {
		query: `
			query($sujeitoAtencaoId: UUID, $status: VoucherStatus){
				pesquisaVoucherAssociado(sujeitoAtencaoId: $sujeitoAtencaoId, status: $status) {
					voucherOrdenado {
						id
						status
					}
				}
			}
		`,
		variables,
	});
	const { pesquisaVoucherAssociado } = response?.data?.data;
	if (!pesquisaVoucherAssociado) {
		throw Error('Falha ao validar o voucher.');
	}
	return pesquisaVoucherAssociado;
}

export const vincularVoucherAgendamento = async (variables) => {
    const response = await Api.post("", {
        query: `
            mutation vincularVoucherAgendamento($agendamentoId: Long, $codigoExterno: String) {
                vincularVoucherAgendamento(agendamentoId: $agendamentoId, codigoExterno: $codigoExterno)
            }
        `,
        variables,
    });
    if (!response?.data?.data?.vincularVoucherAgendamento) {
        throw Error('Falha ao vincular o voucher.');
    }
    return response.data.data.vincularVoucherAgendamento;

}

export const FindRelatorioTicketMedioByUnidade= async (searchDTO)=>{
    const response = await Api.post("",{
        query:`query {
            findRelatorioTicketMedioByUnidade{
                ticketMedio
                totalPagamentos
            }
        }`,
        searchDTO ,// corrigir assim que o back conseguir aceitar os valores  
    });
    return response.data.data.findRelatorioTicketMedioByUnidade.ticketMedio;
}

export const linkGuiaFaturamento = async (variables) => {
  const response = await Api.post("", {
    query: `
        query ($agendamentoId: Long, $internacaoId: UUID) {
            linkGuiaFaturamento(agendamentoId: $agendamentoId, internacaoId: $internacaoId)
        }
    `,
    variables: variables,
  });

  if (response?.data?.data) {
    return response.data.data.linkGuiaFaturamento;
  } else {
    throw new Error(response.data.errors[0]);
  }
};
export const linkGuiaFaturamentoSessao = async (variables) => {
    const response = await Api.post("", {
      query: `
          query ($profissionalId: Long, $controleSessaoId: UUID) {
              linkGuiaFaturamentoSessao(profissionalId:$profissionalId, controleSessaoId: $controleSessaoId)
          }
      `,
      variables: variables,
    });
  
    if (response?.data?.data) {
      return response.data.data.linkGuiaFaturamentoSessao;
    } else {
      throw new Error(response.data.errors[0]);
    }
  };
