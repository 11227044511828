import React from "react";
import Route from "react-router-dom/Route";
import { matchPath } from "react-router";
import { observer, inject } from "mobx-react";
import classnames from "classnames";
import { Grid } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/pages/configuracoesStyle";
import PanelLeft from "../../components/PanelLeft/PanelLeft";
import Footer from "../../components/Footer/Footer";
import Header from "../../template/Header/Header";
import SubMenu from "./Menu/SubMenu";
import ListarDocumentos from "./ModelosDocumento/ListarDocumentos";
import ModeloDocumento from "./ModelosDocumento/ModeloDocumento";
import ListarHorarioAtendimento from "./HorarioAtendimento/ListarHorarioAtendimento";
import HorarioAtendimento from "./HorarioAtendimento/HorarioAtendimento";
import ConfiguracaoReceita from "./ConfiguracaoReceita/ConfiguracaoReceita";
import ConfiguracaoConsulta from "./ConfiguracaoConsulta/ConfiguracaoConsulta";
import MedicamentosPersonalizados from "./MedicamentosPersonalizados/MedicamentosPersonalizados";
import Convenios from "./Convenio/Convenios";
import Procedimentos from "./Procedimento/Procedimentos";
import Salas from "./Sala/Salas";
import ModeloSADT from "./ModelosDocumento/SADT/ModeloSADT";
import Produtos from "./Produto/Produtos";
import ModelosAnamnese from "./ModelosDocumento/Anamnese/ModelosAnamnese";
import ListaConfiguracaoReceita from "./ConfiguracaoReceita/ListaConfiguracaoReceita";
import ConfiguracaoUnidade from './ConfiguracaoUnidade/ConfiguracaoUnidade';
import TransferenciaAgendamento from "./TransferenciaAgendamento";
import ConfiguracaoSecretaria from "./ConfiguracaoSecretaria";
import ModeloReceituario from "./ModelosDocumento/Receituario/ModeloReceituario";
import { LockedFallback, RoleProtection } from "../../components/RoleProtection";
import AUTHORITIES from "../../config/authorities";

import configuracoesBlur from "../../assets/img/blur-screens/configuracoes.png";
import ModelosGuiaInternacao from './ModelosDocumento/GuiaInternacao/ModelosGuiaInternacao';
import ModeloProcedimento from "./ModelosDocumento/Procedimento";
import AparelhosConectados from "./AparelhosConectados/AparelhosConectados";
import ProfissionaisExternos from "./ProfissionalExternos/ProfissionaisExternos";
import Mensagem from "./MensagemSistema/mensagemSistema";
import Leitos from "./Leito/Leitos";

const getRoutes = ({
    canViewTransferenciaAgendamento,
    canViewUpdateSecretaria,
    showAparelhoConectado,
    showLeitos,
}) => [
        {
            title: "Agendamentos",
            children: [
                {
                    uri: "/horario-atendimento",
                    title: "Config. horário atendimento",
                    component: ListarHorarioAtendimento,
                    menu: true,
                    roles: [AUTHORITIES.ROLE_CONFIGURACAO_HORARIO_ATENDIMENTO_READ]
                },
                {
                    uri: "/horario-atendimento/criar",
                    title: "Config. horário atendimento - Criar",
                    component: HorarioAtendimento,
                    menu: false,
                    roles: [AUTHORITIES.ROLE_CONFIGURACAO_HORARIO_ATENDIMENTO_CREATE]
                },
                {
                    uri: "/horario-atendimento/:id/editar",
                    title: "Config. horário atendimento - Editar",
                    component: HorarioAtendimento,
                    menu: false,
                    roles: [AUTHORITIES.ROLE_CONFIGURACAO_HORARIO_ATENDIMENTO_UPDATE]
                },
                {
                    uri: "/horario-salas",
                    title: "Config. horário das salas",
                    component: ListarHorarioAtendimento,
                    menu: true,
                    roles: [AUTHORITIES.ROLE_CONFIGURACAO_HORARIO_ATENDIMENTO_READ]
                },
                {
                    uri: "/horario-salas/criar",
                    title: "Config. horário salas - Criar",
                    component: HorarioAtendimento,
                    menu: false,
                    roles: [AUTHORITIES.ROLE_CONFIGURACAO_HORARIO_ATENDIMENTO_CREATE]
                },
                {
                    uri: "/horario-salas/:id/editar",
                    title: "Config. horário salas - Editar",
                    component: HorarioAtendimento,
                    menu: false,
                    roles: [AUTHORITIES.ROLE_CONFIGURACAO_HORARIO_ATENDIMENTO_UPDATE]
                },
                (canViewTransferenciaAgendamento && {
                    uri: "/transferencia-agendamento",
                    title: "Transf. de agendamento",
                    component: TransferenciaAgendamento,
                    menu: true,
                    hidden: !canViewTransferenciaAgendamento,
                    roles: [AUTHORITIES.ROLE_AGENDAMENTO_READ_OUTROS_PROFISSIONAIS]
                }),
                {
                    uri: "/mensagem-sistema",
                    title: "Mensagens do sistema",
                    component: Mensagem,
                    menu: true,
                },  
            ]
        },
        {
            title: "Modelos de documentos",
            children: [
                {
                    uri: "/modelos-documento",
                    title: "Modelos de documento",
                    component: ListarDocumentos,
                    menu: true,
                    roles: [AUTHORITIES.ROLE_DOCUMENTO_MODELO_READ]
                },
                {
                    uri: "/modelos-documento/criar",
                    title: "Modelos de documento - Criar",
                    component: ModeloDocumento,
                    menu: false,
                    roles: [AUTHORITIES.ROLE_ANAMNESE_MODELO_CREATE]
                },
                {
                    uri: "/modelos-documento/:id/editar",
                    title: "Modelos de documento - Editar",
                    component: ModeloDocumento,
                    menu: false,
                    roles: [AUTHORITIES.ROLE_ANAMNESE_MODELO_UPDATE]
                },
                {
                    uri: "/anamneses",
                    title: "Modelos de anamnese",
                    component: ModelosAnamnese,
                    menu: true,
                    roles: [AUTHORITIES.ROLE_ANAMNESE_MODELO_READ]
                },
                {
                    uri: "/SADT",
                    title: "Modelos SP/SADT",
                    component: ModeloSADT,
                    menu: true,
                    roles: [AUTHORITIES.ROLE_GUIA_SERVICO_MODELO_READ]
                },
                {
                    uri: "/guias-interncao",
                    title: "Guia de internação",
                    component: ModelosGuiaInternacao,
                    menu: true,
                    roles: [AUTHORITIES.ROLE_GUIA_INTERNACAO_MODELO_CREATE]
                },
                {
                    uri: "/modelos-documento-receituario",
                    title: "Modelos de receituário",
                    component: ModeloReceituario,
                    menu: true,
                    roles: [AUTHORITIES.ROLE_RECEITA_MODELO_READ]
                },
                {
                    uri: "/modelos-procedimento",
                    title: "Modelos de procedimentos",
                    component: ModeloProcedimento,
                    menu: true,
                    roles: []
                },
            ]
        },
        {
            title: "Geral",
            children: [
                {
                    uri: "/convenios",
                    title: "Convênios",
                    component: Convenios,
                    menu: true,
                    roles: [AUTHORITIES.ROLE_CONVENIO_READ]
                },
                {
                    uri: "/consulta",
                    title: "Tipos de consulta",
                    component: ConfiguracaoConsulta,
                    menu: true,
                    roles: [AUTHORITIES.ROLE_AGENDAMENTO_TIPO_READ]
                },
                {
                    uri: "/medicamentos-personalizados",
                    title: "Medicamentos personaliza...",
                    component: MedicamentosPersonalizados,
                    menu: true,
                    roles: [AUTHORITIES.ROLE_MEDICAMENTO_READ]
                },
                {
                    uri: "/procedimentos",
                    title: "Procedimentos",
                    component: Procedimentos,
                    menu: true,
                    roles: [AUTHORITIES.ROLE_PROCEDIMENTO_READ]
                },
                {
                    uri: "/produtos",
                    title: "Produtos",
                    component: Produtos,
                    menu: true,
                    roles: [AUTHORITIES.ROLE_PRODUTO_READ]
                },
                {
                    uri: "/salas",
                    title: "Salas",
                    component: Salas,
                    menu: true,
                    roles: [AUTHORITIES.ROLE_SALA_READ]
                },
                {
                    uri: "/profissionais-externos",
                    title: "Profissionais externos",
                    component: ProfissionaisExternos,
                    menu: true,
                    roles: []
                },
                (showLeitos && 
                    {
                        uri: "/leitos",
                        title: "Leitos",
                        component: Leitos,
                        menu: true,
                        roles: []
                    }
                ),
                (showAparelhoConectado
                    && {
                    uri: "/aparelhos-conectados",
                    title: "Aparelhos conectados",
                    component: AparelhosConectados,
                    menu: true,
                    roles: []
                }),
                {
                    uri: "/configuracao-unidade",
                    title: "Configuração da unidade",
                    component: ConfiguracaoUnidade,
                    menu: true,
                    roles: [AUTHORITIES.ROLE_UNIDADE_UPDATE]

                },
                (canViewUpdateSecretaria && {
                    uri: "/configuracao-usuarios",
                    title: "Configuração de usuários",
                    component: ConfiguracaoSecretaria,
                    menu: true,
                    roles: [AUTHORITIES.ROLE_PROFISSIONAL_SAUDE_CREATE, AUTHORITIES.ROLE_PROFISSIONAL_SAUDE_UPDATE_OUTROS]
                }),
                {
                    uri: "/receita",
                    title: "Configuração de Impressão",
                    component: ListaConfiguracaoReceita,
                    menu: true,
                    roles: [AUTHORITIES.ROLE_CONFIGURACAO_IMPRESSAO_READ]
                },
                {
                    uri: "/receita/:tipo/editar",
                    title: "Configuração de Impressão - Editar",
                    component: ConfiguracaoReceita,
                    menu: false,
                    roles: [AUTHORITIES.ROLE_CONFIGURACAO_IMPRESSAO_UPDATE]
                },
            ]
        },
    ];

@inject("configuracaoImpressaoStore", "unidadeStore")
@observer
class Configuracoes extends React.Component {
    state = {
        subMenuItens: [],
        showSidebar: true,
        savingConfiguracaoImpressao: false,
        routes: [],
        showAparelhoConectado: false,

    };

    async componentDidMount() {
        const { history, match, location, unidadeStore } = this.props;

        if (!unidadeStore?.unidade?.plugzapiClientToken) {
            await unidadeStore.getUnidadeLogada();

        }
        if (!unidadeStore?.configuracaoUnidade) {
            await unidadeStore.inicializaConfiguracaoUnidade()
        }
        const profissional = JSON.parse(localStorage.getItem("_immortal|usuarioLogado"));
        const canViewTransferenciaAgendamento = await profissional?.authorities?.some(authority => {
            return authority?.authority === 'ROLE_AGENDAMENTO_READ_OUTROS_PROFISSIONAIS';
        });

        const aparelho = await this.verifyAparelhoConectadoCondition()
        const canViewUpdateSecretaria = await profissional?.authorities?.some(authority => {
            return authority?.authority === 'ROLE_PROFISSIONAL_SAUDE_UPDATE_OUTROS';
        });

        const routes = getRoutes({
            canViewTransferenciaAgendamento,
            canViewUpdateSecretaria,
            showAparelhoConectado: aparelho,
            showLeitos: unidadeStore?.configuracaoUnidade?.realizaInternacao
        });

        const subMenuItens = routes.map(route => {
            return {
                ...route,
                children: route.children?.filter(item => item?.menu)
            }
        });

        let showSidebar = true;
        routes.forEach((route) => {
            return route?.children.forEach(item => {
                const isMatchPath = matchPath(location.pathname, {
                    path: `${match.path}${item?.uri}`,
                    exact: true,
                })

                if (!isMatchPath) return
                showSidebar = item?.menu
            });
        })

        this.setState({ subMenuItens, showSidebar: showSidebar, routes }, () => {
            const item = this.state.subMenuItens[0].children[0] || null;
            if (item && match.isExact) {
                history.push(`${match.path}${item.uri}`);
            }
        });
    }
    verifyAparelhoConectadoCondition = async () => {
        const { unidadeStore } = this.props;

        const { plugzapiClientToken, plugzapiUrl } = unidadeStore.unidade;
        const { utilizaPlugzapi } = unidadeStore.configuracaoUnidade || {}
        const conditionToken = plugzapiClientToken?.length > 0;
        const conditionURl = plugzapiUrl?.length > 0;
        if (conditionToken && conditionURl) {
            return utilizaPlugzapi
        }
        return false
    };
    handleSave = () => {
        const { configuracaoImpressaoStore } = this.props;
        this.setState({ savingConfiguracaoImpressao: true }, () =>
            configuracaoImpressaoStore.save().then(() => {
                this.setState({ savingConfiguracaoImpressao: false });
            })
        );

    };

    render() {
        const { classes, match } = this.props;
        const { subMenuItens, showSidebar, routes } = this.state;
        return (
            <div className={classnames(classes.root, !showSidebar && classes.rootWithoutSidebar)}>
                {showSidebar && (
                    <PanelLeft className={classes.panelLeft}>
                        <Grid item>
                            <Header>
                                <Grid
                                    item
                                    container
                                    alignItems={"center"}
                                    justify={"center"}
                                    xs={12}
                                >
                                    <h3 className={classes.titleHeader}>
                                        Configurações
                                    </h3>
                                </Grid>
                            </Header>
                        </Grid>
                        <div className={classes.submenuItens}>
                            <SubMenu items={subMenuItens} />
                        </div>
                    </PanelLeft>
                )}

                {routes.map((route) => {
                    const routeChildren = route.children.filter(item => item?.uri);
                    return routeChildren.map(({ uri , roles, component: Component }) => (
                        <Route
                            exact
                            key={uri}
                            path={`${match.path}${uri}`}
                            component={(props) => (
                                <RoleProtection roles={roles} fallback={(props) => (
                                    <LockedFallback blurImageSrc={configuracoesBlur} {...props} />
                                )}>
                                    <Component {...props} />
                                </RoleProtection>
                            )}
                        />
                    ));
                })}


                <Footer />
            </div>
        );
    }
}

export default withStyles(styles)(Configuracoes);
