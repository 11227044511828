import Api from "../../config/api";
import string from "../../utils/string";
import { getProfissionalSaudeLogado, getUnidadeLogado } from "../UsuarioService";
export const configuracaoMultiplosHorariosAtendimento = async ({ agendas, date, permiteVerOutrosProfissionais }) => {
    try {
        if (!agendas || agendas.length < 1) return;
        let metodoProfissionais = 'configuracaoHorarioAtendimentoVigente';
        if (permiteVerOutrosProfissionais) {
            metodoProfissionais = 'configuracaoHorarioAtendimentoVigenteOutrosProfissionaisSaude';
        }

        const metodoSalas = 'configuracaoHorarioAtendimentoVigenteSala'
        const unidadeAtual = await getUnidadeLogado();

        let queryMultipla = '';
        const dateFormated = date.format("YYYY-MM-DD")

        const getArgumentosQuery = (agenda) => {
            if (agenda.tipo === 'PROFISSIONAL') {
                return {
                    id: agenda.id,
                    metodo: metodoProfissionais,
                    agendaId: `profissionalSaudeId: ${agenda.id}`,
                }
            } else if (agenda.tipo === 'SALA') {
                return {
                    id: agenda.id.replaceAll("-", ""),
                    metodo: metodoSalas,
                    agendaId: `salaId: "${agenda.id}"`,
                }
            }
        }

        agendas.forEach(agenda => {
            const argumentos = getArgumentosQuery(agenda);
            queryMultipla = queryMultipla + `config${argumentos.id}: ${argumentos.metodo}(
                searchDTO: {
                    dataInicial: "${dateFormated}", 
                    dataFinal: "${dateFormated}", 
                    unidadeId: ${unidadeAtual.id},
                    ${argumentos.agendaId} 
                }
            ) {
                id
                horaInicioPeriodoAtendimento
                horaFimPeriodoAtendimento
                duracao
                ...dias
            }`
        })

        const result = await Api.post('', {
            query:
                `
                fragment dadosDia on HorarioAtendimento {
                  horaInicio
                  horaFim
                  permiteAgendamento
                }
                fragment dias on ConfiguracaoHorarioAtendimento {
                    domingo {
                      ...dadosDia
                    }
                    segunda: segundaFeira {
                      ...dadosDia
                    }
                    terca: tercaFeira {
                      ...dadosDia
                    }
                    quarta: quartaFeira {
                      ...dadosDia
                    }
                    quinta: quintaFeira {
                      ...dadosDia
                    }
                    sexta: sextaFeira {
                      ...dadosDia
                    }
                    sabado {
                      ...dadosDia
                    }
                }
                query{ ${queryMultipla} }`

        });

        return result.data.data;
    } catch (error) {
        console.log(error);
    }
}

export const findAllMultiplosAgendamentos = async ({ agendas, date }) => {
    if (!agendas) return;

    const profissionalSaudeAtual = await getProfissionalSaudeLogado();
    const unidadeAtual = await getUnidadeLogado();

    const metodoSalas = 'findAllAgendamentoSala';

    const getArgumentosQuery = (agenda) => {
        if (agenda.tipo === 'PROFISSIONAL') {
            const metodoProfissionais = profissionalSaudeAtual.id === agenda.id ? 'findAllAgendamento' : 'findAllAgendamentoOutrosProfissionaisSaude';

            return {
                id: agenda.id,
                metodo: metodoProfissionais,
                agendaId: `profissionalSaudeId: ${agenda.id}`,
            }
        } else if (agenda.tipo === 'SALA') {
            return {
                id: string.converteUUIDeID(agenda.id),
                metodo: metodoSalas,
                agendaId: `salaId: "${agenda.id}"`,
            }
        }
    }

    let queryMultipla = '';
    const dateFormated = date.format('YYYY-MM-DD')

    agendas.forEach(agenda => {
        const argumentos = getArgumentosQuery(agenda);
        queryMultipla = queryMultipla + `agendamentos${argumentos.id}: ${argumentos.metodo}(
                searchDTO: {
                    dataInicial:  "${dateFormated}",
                    dataFinal:  "${dateFormated}",
                    unidadeId: ${unidadeAtual.id},
                    ${argumentos.agendaId} 
                },
                pageableDTO: {
                    pageSize: 2000,
                    pageNumber: 0
                }
            ) {
                content {
                  id
                  nome
                  data
                  situacao
                  horaFim
                  horaInicio
                  possuiPaciente
                  dataNascimento
                  tipo {
                      descricao
                      destaque
                  }
                  procedimentos {
                    procedimento {
                        id
                        nome
                    }
                  }
                  sujeitoAtencao{
                      id
                      nome:nomeSocialOuNome
                  }
                }
            }
        `
    })

    const result = await Api.post('', {
        query: `query{${queryMultipla}}`
    });

    const multiAgendamentos = result.data.data

    return multiAgendamentos;
}

export const findByConfiguracaoMultiAgenda = async () => {
    const response = await Api.post('', {
        query: `{
            findByConfiguracaoMultiAgenda {
              id
               hideBlocked
               hideEmpty
              configuracaoAgendaOrder {
                id
                ativo
                h
                i
                maxH
                maxW
                minH
                minW
                moved
                statitc
                w
                x
                y
                profissionalSaude {
                  id
                  nome
                  especialidades {
                    especialidade
                    id
                  }
                }
                sala {
                  id
                  nome
                }
              }
            }
          }`
    });

    if(response?.data?.errors) {
        throw(new Error(response.data.errors[0].message))
    }
    return response.data.data.findByConfiguracaoMultiAgenda;
}

export const saveConfiguracaoMultiAgenda = async(configuracaoAgenda,query,hideItems) => {
    const response = await Api.post('', {
        query: `
          mutation ($configuracaoAgenda: [ConfiguracaoAgendaInput], $hideEmpty: Boolean, $hideBlocked: Boolean) {
            ${query}ConfiguracaoMultiAgenda(configuracaoAgenda: $configuracaoAgenda, hideEmpty: $hideEmpty, hideBlocked: $hideBlocked) {
              id
              configuracaoAgendaOrder {
                id
                ativo
                h
                i
                maxH
                maxW
                minH
                minW
                moved
                statitc
                w
                x
                y
                profissionalSaude {
                  id
                  nome
                }
                sala {
                  id
                  nome
                }
              }
            }
          }
        `,
        variables: {
          configuracaoAgenda,
          hideBlocked: hideItems.hideBlocked,
          hideEmpty: hideItems.hideEmpty
        },
    });

    if (response.data.errors) {
        throw new Error(response.data.errors[0].message)
    }
    return response.data.data[`${query}ConfiguracaoMultiAgenda`]

};