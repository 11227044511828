import colors from '../../../../../../../../template/Colors'

export const styles = {
  paper: {
    height: 'fit-content',
    padding: '24px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h1': {
      fontSize: '1.125rem',
      fontWeight: 'bold',
    },

    '& svg': {
      cursor: 'pointer',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  inputContainer: {
    width: '100%',
    background: '#F2F2F2',
    color: '#505050',
    borderRadius: '8px',
    height: '32px',
    padding: '4px 8px',

    '& > div': {
      '& > input': {
        border: 'none',
        '&::placeholder': {
          fontSize: '14px',
        },
      },
      '& > p': {
        overflow: 'hidden',
        fontSize: '14px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
  inputTextField: {
    fontSize: '14px',
    border: '0',
    height: '32px',
    padding: '4px 8px',
    color: colors.commons.gray9,
    backgroundColor: colors.commons.gray2,
    margin: '0 0 0 0',
    borderRadius: '8px',
    fontFamily: 'Poppins',
  },
  notchedOutline: {
    border: '0',
  },
  inlineFields: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  },
  inputObservacao: {
    width: '100%',
    maxHeight: '100px',
    background: '#F2F2F2',
    border: ' 1px solid rgba(220, 220, 220, 0.2)',
    borderRadius: 10,
    padding: '8px',

    '& textarea': {
      maxHeight: '85px',
      border: 'none',
    },
  },
  footerButtons: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& button': {
      minWidth: '150px',
    },
  },
  removePadding: {
    padding: '0 !important',
  },
  inputDate: {
    borderRadius: '8px',
    height: '40px',
  },
  colorInput: {
    color: "#333333",
  },
  reactSelect: {
    width: '100%',
    "& > div > div": {
      height: '40px',
    },
  }
}
