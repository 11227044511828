import React from 'react'
import { normalizeProps } from './normalizeProps'

const TimerIcon = props => {
  return (
    <svg
      width="19"
      height="21"
      viewBox="0 0 19 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...normalizeProps(props)}
    >
      <path d="M6.25 1.5V0H12.25V1.5H6.25ZM8.5 12.675H10V6.925H8.5V12.675ZM9.25 20.975C8.01667 20.975 6.85417 20.7375 5.7625 20.2625C4.67083 19.7875 3.71667 19.1417 2.9 18.325C2.08333 17.5083 1.4375 16.5542 0.9625 15.4625C0.4875 14.3708 0.25 13.2083 0.25 11.975C0.25 10.7417 0.4875 9.57917 0.9625 8.4875C1.4375 7.39583 2.08333 6.44167 2.9 5.625C3.71667 4.80833 4.67083 4.1625 5.7625 3.6875C6.85417 3.2125 8.01667 2.975 9.25 2.975C10.3667 2.975 11.4167 3.1625 12.4 3.5375C13.3833 3.9125 14.2583 4.43333 15.025 5.1L16.3 3.825L17.35 4.875L16.075 6.15C16.675 6.81667 17.1875 7.625 17.6125 8.575C18.0375 9.525 18.25 10.6583 18.25 11.975C18.25 13.2083 18.0125 14.3708 17.5375 15.4625C17.0625 16.5542 16.4167 17.5083 15.6 18.325C14.7833 19.1417 13.8292 19.7875 12.7375 20.2625C11.6458 20.7375 10.4833 20.975 9.25 20.975ZM9.25 19.475C11.3333 19.475 13.1042 18.7458 14.5625 17.2875C16.0208 15.8292 16.75 14.0583 16.75 11.975C16.75 9.89167 16.0208 8.12083 14.5625 6.6625C13.1042 5.20417 11.3333 4.475 9.25 4.475C7.16667 4.475 5.39583 5.20417 3.9375 6.6625C2.47917 8.12083 1.75 9.89167 1.75 11.975C1.75 14.0583 2.47917 15.8292 3.9375 17.2875C5.39583 18.7458 7.16667 19.475 9.25 19.475Z"
        fill="currentColor" />

    </svg>
  )
}

export default TimerIcon

