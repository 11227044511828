import { action, observable } from 'mobx';

import { 
  getContactsListByProfissionalSaude, 
  getMessagesByContact,
  findContatoByProfissionalSaudeId, 
  enviarMensagem,
  fixarContato,
  chamarPaciente,
  atualizarMensagensNaoLidas
} from '../services/ChatService';
import { getProfissionalSaudeLogado, getUnidadeLogado } from '../services/UsuarioService';

import BaseStore from './Base.store';

import localStorageService, {ACCESS_TOKEN_KEY} from '../services/storage';

import { createChatContactObject, getChatContactObjectUpdated } from '../utils/createChatContactObject';

import audioUrl from '../assets/sounds/alarm.mp3';


const contactsPaginationSettingsDefault = {
  pageableDTO: {
    pageSize: 20,
    pageNumber: 0,
    sortFields: [
      {
        sortField: 'fixado',
        sortDir: 'ASC',
      },
      {
        sortField: 'ultimaMensagem.dataHoraCriacao',
        sortDir: 'DESC',
      }
    ]
  },
  ativo: true,
  lastPage: false,
};

const messagesPaginationSettingsDefault = {
  pageableDTO: {
    pageSize: 30,
    pageNumber: 0,
    sortField: 'dataHoraCriacao',
    sortDir: 'DESC',
  },
  lastPage: false,
};

const maxFixedContacts = 3;
const maxPopUpContacts = 3;

export default class ChatStore extends BaseStore {
  socketStore = null;
  loginStore = null;
  usuarioStore = null;

  @observable unidadeLogadaId = null;
  @observable loggedUser = null;
  @observable accessToken = null;
  @observable contactsPaginationSettings = contactsPaginationSettingsDefault;
  @observable messagesPaginationSettings = messagesPaginationSettingsDefault;
  @observable contactInputSearch = '';
  @observable lastSearchedName = '';
  @observable selectedContact = null;
  @observable contacts = [];
  @observable messages = [];
  @observable scrollContactListTop = false;
  @observable contactsToPopup = [];
  @observable selectedContactData = null;
  @observable willScrollToBottom = false;
  @observable isDisconnected = false;
  @observable stopReconnect = false;
  @observable callSound = null;

  constructor(rootStore) {
    super();
    this.socketStore = rootStore.socketStore;
    this.loginStore = rootStore.loginStore;
    this.usuarioStore = rootStore.usuarioStore;
    this.audio = new Audio(audioUrl);
  }
  @action  selectTypeMensagem = (dataMensagem, remetente) =>{
    switch (dataMensagem.tipoMensagem) {
      case "IMAGE":
        return `${remetente} enviou uma imagem!`
        case "AUDIO":
        return `${remetente} enviou um audio!`
        case "FILE":
        return `${remetente} enviou um arquivo!`
      case "TEXT":
         return `${remetente}: ${dataMensagem.mensagem}`
      default:
        return `Você tem uma nova mensagem de ${remetente}`
    }
  
  }

  @action notifyChatMessage = (dataMensagem, remetente) =>{
   
    const chatOptions = {
      body: this.selectTypeMensagem(dataMensagem, remetente),
      icon:'/web-push-icon.png'
    };
  
    if (!("Notification" in window)) {
      return
    } else if (Notification.permission === "granted") {
      new Notification("Chat App Health", chatOptions);
    } else if (Notification.permission !== "denied") {
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          new Notification("Chat App Health", chatOptions);
        }
      });
    }
   
  }

  @action enableChatListenners = () => {
    const { socket } = this.socketStore;
    if (socket) {
      socket.onmessage = async ({ data }) => {
        const  parsedData = JSON.parse(data);
        const { id, mensagem, remetente, dataHoraCriacao, tipoMensagem, urlDownload } = parsedData;
        const newMessage = {
          id,
          mensagem: mensagem,
          remetente: {
            id: remetente.id,
          },
          tipoMensagem,
          urlDownload,
          dataHoraCriacao,
        };

        if (tipoMensagem === 'TEXT' && mensagem?.toLowerCase()?.includes('chamar paciente')) {
          this.audio.play();
        }

        if (location.hash !== '#/chat') {
          if (this.contactsToPopup.length >= maxPopUpContacts) return;
          const contactIndex = this.contactsToPopup.findIndex(contact => contact.contact.profissionalSaudeContato.id === remetente.id);
          if (contactIndex > -1) {
            const params = {
              contact: this.contactsToPopup[contactIndex].contact, 
              message: { ...newMessage, lido: true }, 
              isPopup:true, 
              popupContactIndex: contactIndex
            }
            this.willScrollToBottom = true;
            this.insertNewMessage(params);
            await this.atualizarMensagensStatus({ 
              lastMessageId: newMessage.id, 
              popupContactIndex: contactIndex,
              notifyWindowFunction: ()=>this.notifyChatMessage(newMessage, remetente.nome) });
            return;
          }

          const contact = {
            profissionalSaudeContato: {
              ...remetente
            }
          }
          const newContactPopup = createChatContactObject(contact);
          this.contactsToPopup = this.contactsToPopup.concat(newContactPopup);
          return;
        }

        const { contact } = this.selectedContact || {};
        const senderIsSelectedContact = remetente.id === contact?.profissionalSaudeContato?.id;
        if (senderIsSelectedContact) {
          this.insertNewMessage({
            contact: this.selectedContact.concat, 
            message: { ...newMessage, lido: true }, 
            isPopup: false 
          });
          await this.atualizarMensagensStatus({ lastMessageId: newMessage.id});
        }

        const senderIndex = this.contacts.findIndex(contact => contact.profissionalSaudeContato.id === remetente.id);
        let sender = this.contacts[senderIndex];

        if (!sender) {
          sender = await this.getContatoByProfissionalSaude(remetente.id);
        }

        this.ascendToTopWithNewMessage(sender, parsedData);
      };

      socket.addEventListener('close', async (event) => {
        const { code } = event;
        const { isAuthenticated } = this.usuarioStore;

        if (code !== 1000 && isAuthenticated) {
          this.selectedContact = null;
          this.socketStore.resetSettings();

          if (this.stopReconnect) {
            this.socketStore.isConnectionOpen = false;
            return;
          }

          await this.chatReconnect();
        } 
      });

      socket.onerror = () => {
        this.stopReconnect = true;
      }

      socket.addEventListener('message', () => ({}));
    }
  }

  @action chatLocalConnection = async () => {
    const { isAuthenticated } = this.usuarioStore;
    if (!isAuthenticated)return;

    await this.loginStore.authenticateChat();
    await this.socketStore.connect();
    this.enableChatListenners();
    
    const { isApiInitialized, isConnectionOpen } = this.socketStore;
    if (!isApiInitialized || !isConnectionOpen) {
      await this.chatReconnect();
    }
  }

  @action chatReconnect = async () => {
    const { isAuthenticated } = this.usuarioStore;
    this.isDisconnected = true;

    if (!isAuthenticated) {
      return;
    }

    let interval = setInterval(async() => {
      await this.loginStore.authenticateChat();
      const willConnectOnSocket = isAuthenticated && this.socketStore.isApiInitialized && !this.socketStore.isConnectionOpen;

      if (willConnectOnSocket) {
        await this.socketStore.connect();
        this.enableChatListenners();
      }
      
      const { isApiInitialized, isConnectionOpen } = this.socketStore;
      if (isApiInitialized && isConnectionOpen) {
        this.isDisconnected = false;
        clearInterval(interval);
      }
    }, 10000);
  };

  @action getContatoByProfissionalSaude = async (profissionalSaudeContatoId) => {
    try {
      const contato = await findContatoByProfissionalSaudeId({
        profissionalSaudeId: this.loggedUser.id, 
        profissionalSaudeContatoId,
        ChatApi: this.socketStore.ChatApi
      });
      return contato;
    } catch(error) {
      this.openNotification(
        error.message,
        'error',
      );
    }
  }

  @action getUnidadeLogadaId = async () => {
    const { id: unidadeId } = await getUnidadeLogado() || {};
    return unidadeId;
  }

  @action getProfissionalSaudeAtual = async () => {
    const profissionalSaude = await getProfissionalSaudeLogado();
    this.loggedUser = profissionalSaude;
  }
  @action getContacts = async () => {
    try {
      const sameNameSearched = this.lastSearchedName === this.contactInputSearch;
      const pageableDTO = sameNameSearched ? this.contactsPaginationSettings.pageableDTO : contactsPaginationSettingsDefault.pageableDTO;

      if (!this.loggedUser) {
        this.contactsPaginationSettings.lastPage = true;
        throw new Error('Falha ao carregar os contatos');
      }

      const { content, last } = await getContactsListByProfissionalSaude({
        profissionalSaudeId: this.loggedUser.id, 
        pageableDTO,
        search: this.contactInputSearch,
        ChatApi: this.socketStore.ChatApi,
        ativo: this.contactsPaginationSettings.ativo
      });

      this.contacts = pageableDTO.pageNumber === 0 ? content : [...this.contacts, ...content];
      this.contactsPaginationSettings = {
        pageableDTO: {
          pageSize: pageableDTO.pageSize,
          pageNumber: last ? pageableDTO.pageNumber : pageableDTO.pageNumber + 1,
          sortFields: pageableDTO.sortFields
        },
        lastPage: last,
      }
    } catch(error) {
      this.contactsPaginationSettings.lastPage = true;
      this.openNotification(
        error.message,
        'error',
      );
    }
  };

  @action setSelectedContact = (contact) => {
    this.selectedContact = createChatContactObject(contact);
  }
  
  @action loadMessagesByContact = async (contact, paginationOptions, messages, isPopup) => {
    try {
      if (!contact) {
        throw new Error('Nenhum contato selecionado');
      }

      const { id: remetenteId} = await getProfissionalSaudeLogado();
      const { id: destinatarioId, nome: nomeDestinatario } = contact.contact.profissionalSaudeContato;
      const { pageableDTO } = paginationOptions;
      
      const { content, last } = await getMessagesByContact({
        destinatarioId, 
        remetenteId, 
        pageableDTO,
        ChatApi: this.socketStore.ChatApi
      });
      
      let loadedMessages = pageableDTO.pageNumber === 0 ? content : [...messages, ...content];
      const newestMessage = loadedMessages[0]
      const willUpdateMessagesStatus = pageableDTO.pageNumber === 0 && loadedMessages.length > 0 && newestMessage?.remetente?.id !== remetenteId;
      if (willUpdateMessagesStatus) {
       
        
      
        await this.atualizarMensagensStatus({
          lastMessageId: loadedMessages[0]?.id,
          ...(isPopup ? { popupContactIndex: 
            this.contactsToPopup.findIndex(contact => contact.contact.profissionalSaudeContato.id === remetenteId) } : {}),
            notifyWindowFunction: ()=> this.notifyChatMessage(newestMessage, nomeDestinatario)
        }, loadedMessages)
      }

      const contactDataUpdated = getChatContactObjectUpdated(contact.contact, paginationOptions, loadedMessages, last);

      if (isPopup) {
        const contactIndex = this.contactsToPopup.findIndex(contact => {
          return contact.contact.profissionalSaudeContato.id === contactDataUpdated.contact.profissionalSaudeContato.id
        });
        return this.contactsToPopup[contactIndex] = { ...contactDataUpdated };
      }
      
      this.selectedContact = { ...contactDataUpdated };
    } catch(error) {
      this.openNotification(
        error.message,
        'error',
      );
    }
  };

  @action getMessageSenderById = (id) => {
    if (this.selectedContact.profissionalSaudeId === id) {
      return this.selectedContact;
    } else {
      return this.loggedUser;
    }
  };

  @action sendMessage = async ({ message, tipoMensagem, file, contact, isPopup }) => {
    const { id: destinatarioId } = contact.profissionalSaudeContato;

    try {
      const newMessage = {
        mensagem: {
          mensagem: message,
          tipoMensagem,
          remetente: {
            id: this.loggedUser.id,
          },
          destinatario: {
            id: destinatarioId,
          }
        },
        ...(file && {
          objetoAmazonS3DTO: {
            ...file,
          }
        }),
      };

      const createdMessage = await enviarMensagem({ ...newMessage, ChatApi: this.socketStore.ChatApi });
      this.insertNewMessage({ contact, message: createdMessage, isPopup });
      !isPopup && this.ascendToTopWithNewMessage(contact, createdMessage);
    } catch (error) {
      console.error(error);
    }
  }

  insertNewMessage = ({ contact, message, isPopup, popupContactIndex }) => {
    if (isPopup) {
      const contactIndex = popupContactIndex || this.contactsToPopup.findIndex(c => {
        return c.contact.profissionalSaudeContato?.id === contact.profissionalSaudeContato?.id
      });
      const popupContact = this.contactsToPopup[contactIndex];
      const { messages } = popupContact;
      const updatedMessages = [message, ...messages];

      this.contactsToPopup[contactIndex] = {
        ...popupContact,
        messages: updatedMessages,
      }
    } else {
      this.selectedContact = {
        ...this.selectedContact,
        messages: [message, ...this.selectedContact.messages],
      }
    }
    this.willScrollToBottom = true;
  }

  @action ascendToTopWithNewMessage = (contact, message) => {
    const contactIndex = this.contacts.findIndex(c => c.id === contact.id);
    if (contactIndex > -1) {
      this.contacts.splice(contactIndex, 1);
    }
    const newPosition = this.countFixedContacts();
    const lido = this.selectedContact && (this.selectedContact.contact.profissionalSaudeContato.id === message.remetente.id);
    const contactWithUpdatedMessage = {
      ...contact,
      ultimaMensagem: {
        ...message,
        ...({ lido: !!lido }),
      },
    }

    this.contacts.splice(newPosition, 0, contactWithUpdatedMessage);
    this.scrollContactListTop = true;
  }

  countFixedContacts = () => {
    const subArray = this.contacts.slice(0, 3);
    let totalFixed = 0;

    subArray.forEach(contact => {
      if (contact.fixado > 0) {
        totalFixed++;
      }
    });

    return totalFixed;
  }

  @action callPaciente = async (profissionalId, pacienteNome) => {
    try {
      await chamarPaciente({ 
        profissionalSaudeId: profissionalId, 
        pacienteNome,
        ChatApi: this.socketStore.ChatApi
      });
    } catch(error) {
      this.openNotification(
        error.message,
        'error',
      );
    }
  }

  @action atualizarMensagensStatus = async ({ lastMessageId, popupContactIndex, notifyWindowFunction}, messages ) => {
    try {
      if ((!lastMessageId || !popupContactIndex < 0) && !this.selectedContact) {
        return;
      }
      
       await atualizarMensagensNaoLidas({
        ChatApi: this.socketStore.ChatApi,
        lastMessageId: lastMessageId || this.selectedContact.ultimaMensagem.id,
      });
     
      if(!!notifyWindowFunction && popupContactIndex !== undefined){
        notifyWindowFunction();
      }
      
      if (popupContactIndex && this.contactsToPopup[popupContactIndex]) {
        this.contactsToPopup[popupContactIndex].ultimaMensagem.lido = true; 
      }

      if (this.selectedContact) {
        this.selectedContact.contact.ultimaMensagem.lido = true;
      }
      messages?.length > 0 && this.checkMessagesAsRead(messages);
    } catch(error) {
      this.openNotification(
        error.message,
        'error',
      );
    }
  }

  @action checkMessagesAsRead = (messagesArray) => {
    const firstReadedMessageIndex = messagesArray.findIndex(message => message.lido);

    for (let i = 0; i < firstReadedMessageIndex; i++) {
      if (!messagesArray[i].lido) {
        messagesArray[i].lido = true;
      }
    }
  };
  @action checkMessagesNotRead = (messagesArray) => {
    if(messagesArray.length === 0){
      return false
    }
    for (let i = 0; i < messagesArray.length; i++) {
      if (!messagesArray[i].lido) {
        return true;
      }
    }
    return false
  };

  @action getAccessToken = async () => {
    this.accessToken = await localStorageService.get(ACCESS_TOKEN_KEY);
  }

  @action closePopup = (contactId) => {
    const contactIndex = this.contactsToPopup.findIndex(contact => contact.id === contactId);
    if (contactIndex > -1) {
      this.contactsToPopup.splice(contactIndex, 1);
    }
  }

  @action handleToggleFixarContato = async (contact) => {
    const { id, fixado } = contact;
    const totalFixed = this.countFixedContacts();
    const isFixed = typeof fixado === 'number';

    if (!isFixed && totalFixed >= maxFixedContacts) {
      this.openNotification(
        `Limite de contatos fixados atingido (${maxFixedContacts})`,
        'error',
      );
      return;
    }
    
    let ordem = null;

    if (!isFixed) {
      ordem = this.getOrdemFixacao();
    }
    try {
      await fixarContato({
        contatoId: id, 
        ordem,
        ChatApi: this.socketStore.ChatApi
      });
      this.resetContacts();
      await this.getContacts();
    } catch (error) {
      console.error(error);
    }
  }

  countFixedContacts = () => {
    const subArray = this.contacts.slice(0, 3);
    let totalFixed = 0;
    subArray.forEach(contact => {
      if (contact?.fixado !== null) {
        totalFixed++;
      }
    });
    return totalFixed;
  };

  getOrdemFixacao = () => {
    const subArray = this.contacts.slice(0, 3);
    let ordem = 0;
    subArray.forEach(contact => {
      if (typeof contact.fixado === 'number') {
        ordem++;
      }
    });
    return ordem;
  }

  resetContacts = (ativo = true) => {
    this.contactsPaginationSettings = {
      ...contactsPaginationSettingsDefault,
      ativo
    }
    this.contacts = [];
  }

  @action logout = () => {
    this.selectedContact = null;
    this.contactsToPopup = [];
  }
}
