import Api from "../config/api";

export const findAllOrcamento = async (variables) => {
  const response = await Api.post("", {
    query: `
      query(
        $profissionalSaudeId: Long
        $sujeitoAtencaoId: UUID
        $search: String
        $procedimentoId: UUID
        $dataHoraLancamentoInicio: LocalDateTime
        $dataHoraLancamentoFim: LocalDateTime
        $pageableDTO: PageableDTOInput
        $status: [OrcamentoStatus]
        $tipo: OrcamentoTipo
      ) {
        findAllOrcamento(
          profissionalSaudeId: $profissionalSaudeId
          sujeitoAtencaoId: $sujeitoAtencaoId
          search: $search
          procedimentoId: $procedimentoId
          dataHoraLancamentoInicio: $dataHoraLancamentoInicio
          dataHoraLancamentoFim: $dataHoraLancamentoFim
          pageableDTO: $pageableDTO
          status: $status
          tipo: $tipo
        ) {
          last
          content {
            id
            dataHoraLancamento
            nome
            profissionalSaude {
              id
              nome
            }
            valorTotalLiquido
            status
            tipo
            faturado
          }
        }
      }    
    `,
    variables: variables,
  });

  if (!response.data.errors) {
    return response.data.data.findAllOrcamento;
  } else {
    throw response.data.errors[0];
  }
};

export const getProcedimentoOdontologico = async (variables) => {
  const response = await Api.post("", {
    query: `
    query ($search: String, $localOdontograma: [Long], $pageableDTO: PageableDTOInput, $tipoProcedimentoDTO: TipoProcedimentoDTOInput) {
      findAllProcedimentos(search: $search, localOdontograma: $localOdontograma, pageableDTO: $pageableDTO, tipoProcedimentoDTO: $tipoProcedimentoDTO) {
        last
        content {
          value:id
          label:codigoTermoDescricao
        }
      }
    }
    `,
    variables: {
      tipoProcedimentoDTO: {
        tipo: 'ODONTOLOGICO',
      },
      ...variables,
    },
  });

  if (!response.data.errors) {
    return response.data.data.findAllProcedimentos;
  } else {
    throw response.data.errors[0];
  }
};

export const findProfissionalSaudeByUnidadeComAgendaPage = async (variables) => {
  const response = await Api.post("", {
    query: `
    query($unidadeId: Long, $search: String, $pageableDTO: PageableDTOInput, $especialidadeMedica: Long) {
      findProfissionalSaudeByUnidadeComAgendaPage(unidadeId: $unidadeId, search: $search, pageableDTO: $pageableDTO, especialidadeMedica: $especialidadeMedica) {
        last
        content{
          value:id
          label:nome
          id
          nome
        }
      }
    }
    `,
    variables: variables,
  });

  if (!response.data.errors) {
    return response.data.data.findProfissionalSaudeByUnidadeComAgendaPage;
  } else {
    throw response.data.errors[0];
  }
};

export const updateStatusOrcamento = async (variables) => {
  const response = await Api.post("", {
    query: `
    mutation($orcamentoId: UUID, $status: OrcamentoStatus) {
      updateStatusOrcamento(orcamentoId: $orcamentoId, status: $status) {
        id
      }
    }
    `,
    variables: variables,
  });

  if (!response.data.errors) {
    return response.data.data.updateStatusOrcamento;
  } else {
    throw response.data.errors[0];
  }
};

export const createOrcamento = async (orcamento) => {
  const response = await Api.post("", {
    query: `
    mutation($orcamento: OrcamentoInput) {
      createOrcamento(orcamento: $orcamento) {
        id
      }
    }
    `,
    variables: {
      orcamento,
    },
  });

  if (!response.data.errors) {
    return response.data.data.createOrcamento;
  } else {
    throw response.data.errors[0];
  }
}

export const updateOrcamento = async (orcamento) => {
  const response = await Api.post("", {
    query: `
    mutation($orcamento: OrcamentoInput) {
      updateOrcamento(orcamento: $orcamento) {
        id
      }
    }
    `,
    variables: {
      orcamento,
    },
  });

  if (!response.data.errors) {
    return response.data.data.updateOrcamento;
  } else {
    throw response.data.errors[0];
  }
}

export const findOrcamentoById = async ({ id }) => {
  const response = await Api.post("", {
    query: `
      query($orcamentoId: UUID) {
        findOrcamentoById(orcamentoId: $orcamentoId) {
          convenio {
            id
            descricao
          }
          dataVencimento
          desconto {
            valorDesconto
            tipoDesconto
          }
          descontoAprovado
          faturado
          nome
          observacao
          orcamentoProcedimentos {
            id
            procedimento {
              id
              nome
              descricao
              convenioProcedimentos {
                valor
                convenio {
                  id
                }
              }
            }
           procedimentoOdontologico {
              id
              dente {
                id
              }
              procedimento {
                id
                nome
                descricao
                convenioProcedimentos {
                  convenio {
                    id
                  }
                  valor
                }
              }
            }
            valorUnitario
            quantidade
          }
          profissionalSaude {
            id
            nome
          }
          tipo
          status
          tipoPagamento
          valor
          valorEntrada
          faturado
        }
      }
    `,
    variables: { orcamentoId: id },
  });

  if (!response.data.errors) {
    return response.data.data.findOrcamentoById;
  } else {
    throw response.data.errors[0];
  }
};

export const getLinkFaturamentoOrcamento = async ({ orcamentoId }) => {
  const response = await Api.post("", {
    query: `
      query($orcamentoId: UUID) {
        linkFaturamentoOrcamento(orcamentoId: $orcamentoId)
      }
    `,
    variables: { orcamentoId },
  });

  if (!response.data.errors) {
    return response.data.data.linkFaturamentoOrcamento;
  } else {
    throw response.data.errors[0];
  }

};
  
export const findAllProcedimentos = async (variables) => {
  const response = await Api.post("", {
    query: `
    query FindAllProcedimentos($ativo: Boolean, $tiposProcedimentos: [TipoProcedimento], $nome: String, $pageableDTO: PageableDTOInput) {
      findAllProcedimentos(ativo: $ativo, tiposProcedimentos: $tiposProcedimentos, nome: $nome, pageableDTO: $pageableDTO) {
        last
        content {
          convenioProcedimentos {
            valor
            convenio {
              id
            }
          }
          nome
          id
          tiposProcedimentos
        }
      }
    }
    `,
    variables: variables
  });

  if (!response.data.errors) {
    return response.data.data.findAllProcedimentos;
  } else {
    throw response.data.errors[0];
  }
}

export const findAllLocalPalmilha = async () => {
  const response = await Api.post("", {
    query: `
      query {
        findAllLocalPalmilha {
          id
          nome
          codigo
        }
      }
    `,
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  };

  return response.data.data.findAllLocalPalmilha;
};

export const findOrcamentoOrtopedicoById = async(id) =>{
  const response = await Api.post("", {
    query: `
    query($orcamentoId: UUID) {
      findOrcamentoById(orcamentoId: $orcamentoId) {
        id
        nome
        observacao
        status
        numero
        orcamentoProcedimentos {
            id
            procedimento {
              id
              nome
              descricao
              tiposProcedimentos
              convenioProcedimentos {
                valor
                convenio {
                  id
                }
              }
            }
            valorUnitario
            quantidade
          }
        sujeitoAtencao {
          id
          nome
        }
        profissionalSaude {
          id
          nome
        }
        locaisPalmilhaPeDireito {
          espessura
          id
          codigo
          nome:nomeLocal
        }
        locaisPalmilhaPeEsquerdo {
          id
          codigo
          espessura
          nome:nomeLocal
        }
        tipo
        tipoPagamento
        totalParcelas
        valor
        valorEntrada
      }
    }    
    `, variables: {orcamentoId: id},
  });

  if (!response.data.errors) {
    return response.data.data.findOrcamentoById;
  } else {
    throw response.data.errors[0];
  }
}

export const findAllConvenioList = async () => {
  const response = await Api.post("", {
    query: `
      query {
        findAllConvenioList(searchDTO: {
          ativo: true,
          sortDir: "ASC",
          sortField: "descricao"
        }) {
          value: id
          label: descricao
          convenioProcedimentos {
            valor
            convenio {
              id
            }
          }
        }
      }
    `,
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  };

  return response.data.data.findAllConvenioList;
};

export const urlPainel3dpe = async () => {
  const response = await Api.post("", {
    query: `
      {
        urlPainel3dpe
      }
    `,
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }

  return response.data.data.urlPainel3dpe;
}