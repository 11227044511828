import React, { useState } from "react";
import classNames from "classnames";
import { Fab, withStyles } from "@material-ui/core";
import { CircularProgress, Checkbox } from "@material-ui/core";
import Scroll from "../../../../../components/InfiniteScroll/Scroll";
import InputMaskCurrency from "../../../../../components/Input/InputMaskCurrency";
import ConfiguracoesIcon from "../../../../../components/Icon/Configuracoes";
import ModalConfigVigencia from "../../../../../components/Modal/ModalConfigVigencia/ModalConfigVigencia";
import moment from "moment";

const ConvenioList = ({
  classes,
  listaModal,
  handleChangeCheckbox,
  handleChange,
  loadMore,
  lastScroll,
  loadingModal,
  procedimentoSelected,
  loadConvenios,
}) => {
  const [openModal,setOpenModal]=useState(false)
  const [convenio,setConvenio]=useState(null)

  const getVigencia = (convenio) => {
    const { vigencia } = convenio || {};
    const {dataInicioVigencia,dataFimVigencia}=vigencia || {}

    const dataInicioFormatada= dataInicioVigencia && moment(dataInicioVigencia).format("DD/MM/YYYY")
    const dataFimFormatada= dataFimVigencia && moment(dataFimVigencia).format("DD/MM/YYYY")

     return `${dataInicioFormatada || ""} - ${dataFimFormatada || ''}`;
  }

  const handleClick = (e) => {
    setOpenModal(true)
    const convenio = {
     ...e.convenio,
     procedimentoSelected
    }
    setConvenio(convenio)
  }
  
  const handleClose =()=>{
    setOpenModal(false)
  }

  return (
    <div className={classes.content}>
      <Scroll
        loadMore={loadMore}
        hasMore={!lastScroll}
        pageStart={0}
        initialLoad={false}
        className={classes.scrollContainerModelos}
      >
        <div className={classes.titles}>
          <div className={classes.title}> Selecione o convênio </div>
          <div className={classes.titleValorVigencia}>
            <div className={classNames([classes.title, procedimentoSelected?.id ? classes.vigencia : classes.titleSemValorVigencia])}>
              Valor
            </div>
            {procedimentoSelected?.id &&
              <>
                <div className={classNames(classes.title, classes.vigencia)}>
                  Vigência
                </div>
                <div className={classNames(classes.title, classes.vigencia)}>
                  Ações
                </div>
              </>}
          </div>
        </div>
        {listaModal.length > 0 &&
          listaModal.map((itemLista, index) => (
            <div className={classes.linha} key={index}>
              <div className={classes.checkboxDescricao}>
                <Checkbox
                  color={"primary"}
                  onChange={() => handleChangeCheckbox(itemLista, index)}
                  checked={itemLista.convenio?.checked || itemLista.convenios?.checked}
                />
                <div className={classes.convenio}>
                  {itemLista.convenio?.descricao ?? itemLista.convenios.descricao}
                </div>
              </div>
              <div className={classes.contentValorVigencia}>
                <InputMaskCurrency
                  name={"valor"}
                  value={itemLista?.valor === 0 ? "" : itemLista?.valor}
                  className={classNames(
                    classes.inputValor,
                    !(itemLista.convenio?.checked ?? itemLista.convenios.checked) && classes.inputValorDesabilitado
                  )}
                  onChange={(e) => handleChange(e, itemLista, index)}
                  disabled={procedimentoSelected?.id}
                />
                {procedimentoSelected?.id &&
                  <>
                    <div className={classes.dataVigencia}>
                      {getVigencia(itemLista)}
                    </div>
                    <Fab
                      size={"small"}
                      className={classes.config}
                      onClick={() => handleClick(itemLista)}
                    >
                      <ConfiguracoesIcon color={"#868686"} />
                    </Fab>
                  </>
                } 
              </div>
            </div>
          ))}
        {loadingModal && (
          <div className={classes.contentLoading}>
            <CircularProgress />
          </div>
        )}
      </Scroll>
      {openModal &&
        <ModalConfigVigencia
        title={`${convenio.descricao}${procedimentoSelected?.nome ?` - ${procedimentoSelected?.nome}` : ""}`} 
          open={openModal}
          convenio={convenio}
          handleClose={handleClose}
          procedimentoSelected={procedimentoSelected}
          findAllDados={()=> loadConvenios()}
        />}
    </div>
  );
};

const styles = {
  content: {
    height: "calc(100% - 105px)",
    overflow: "auto",
  },
  titles: {
    display: "flex",
    justifyContent: "space-between",
    position: "sticky",
    top: 0,
    zIndex: 1,
    background: "#fff",
    width:"100%",
  },
  title: {
    fontSize: "10px",
    color: "#868686",
    marginRight: '7px'
  },
  convenio: {
    color: "#868686",
  },
  linha: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "4px",
  },
  inputValor: {
    background: "#F2F2F2",
    padding: "4px 4px 4px 8px",
    width: "90px",
    height: "30px",
    border: "1px solid rgba(220, 220, 220, 0.2)",
    borderRadius: "8px",
  },
  checkboxDescricao: {
    display: "flex",
    "&>span": {
      color: "#868686",
    },
  },
  contentLoading: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  scrollContainerModelos: {
    height: "100%",
  },
  inputValorDesabilitado: {
    color: "#E5E5E5",
  },
  contentValorVigencia: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  dataVigencia:{
    width: '165px',
    height: '40px',
    borderRadius: '8px',
    background: '#f2f2f2',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textWrap: 'nowrap',
    fontSize: '12px',
    color: '#505050',
    fontWeight: '500'
  },
  titleValorVigencia: {
    width: '50%',
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '100px'
  },
  titleSemValorVigencia: {
    gap: '100px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    paddingRight: '30px',
  },
  config:{
    cursor: 'pointer',
    height: '14px',
    padding: '0',
    background: '#ffffff',
    border: 'none',
    boxShadow: 'none'
  },
};

export default withStyles(styles)(ConvenioList);
