import Api from "../config/api";

export const findAllMunicipio = async (searchDTO = {}) => {
  const defaultSearchDTO = {
    pageSize: 30,
    pageNumber: 0,
    sortDir: "ASC",
  };

  const response = await Api.post("", {
    query: `
        query ($searchDTO: SearchDTOInput) {
        findAllMunicipio(searchDTO: $searchDTO) {
          last
          content {
            id
            nome
            uf
          }
        }
      }
    `,
    variables: {
      searchDTO: {
        ...defaultSearchDTO,
        ...searchDTO,
      },
    },
  });
  const { last, content } = response.data.data.findAllMunicipio;

  const customContent = content.map((municipio) => {
    const municipioComUf = `${municipio.nome} - ${municipio.uf}`;
    return {
      ...municipio,
      value: municipio.id,
      label: municipioComUf,
    };
  });

  return {
    last,
    content: customContent,
  };
};

export const findAllSujeitoAtencaoAniversariante = async (variables) => {
  const response = await Api.post("", {
    query: `
    query ($pageable: PageableDTOInput, $ativo: Boolean, $search: String, $dataInicio: LocalDate, $municipioId: Long, $dataFinal: LocalDate) {
      findAllSujeitoAtencaoAniversariante(pageable: $pageable, ativo: $ativo, search: $search, dataInicio: $dataInicio, municipioId: $municipioId, dataFinal: $dataFinal) {
        last
        totalElements
        content {
          nome
          ativo
          dataNascimento
          contato {
            email
            telefonePrincipal
            telefonePrincipalDDI
            telefoneSecundario
            telefoneSecundarioDDI
          }
          endereco {
            municipio {
              nome
              uf
            }
          }
          espanhaMunicipio {
            id
            descricao
          }
        }
      }
    }`,
    variables: variables,
  });

  return response.data.data.findAllSujeitoAtencaoAniversariante;
};

export const findAllSujeitoAtencaoQuemIndicou = async (variables) => {
  const response = await Api.post("", {
    query: `
    query ($pageable: PageableDTOInput, $ativo: Boolean, $search: String, $municipioId: Long) {
      findAllSujeitoAtencaoQuemIndicou(pageable: $pageable, ativo: $ativo, search: $search, municipioId: $municipioId) {
        last
        totalElements
        content {
          nome
          ativo
          contato {
            telefonePrincipal
            telefoneSecundario
            telefonePrincipalDDI
            telefoneSecundarioDDI
          }
          endereco {
            municipio {
              nome
              uf
            }
          }
          espanhaMunicipio {
            descricao
            id
          }
          quemIndicou
        }
      }
    }`,
    variables: variables,
  });

  return response.data.data.findAllSujeitoAtencaoQuemIndicou;
};

export const relatorioSujeitoAtencaoQuemIndicouData = async (variables) => {
  const response = await Api.post("", {
    query: `
    query ($ativo: Boolean, $search: String, $pageable: PageableDTOInput, $dataInicio: LocalDate, $dataFinal: LocalDate) {
      relatorioSujeitoAtencaoQuemIndicouData(ativo: $ativo, search: $search, dataInicio: $dataInicio, dataFinal: $dataFinal, pageable: $pageable) {
        last
        totalElements
        content {
          quantidade
          quemIndicou
        }
      }
    }`,
    variables: variables,
  });

  return response.data.data.relatorioSujeitoAtencaoQuemIndicouData;
};

export const relatorioProcedimento = async (variables) => {
  const response = await Api.post("", {
    query: `
    query ($pageableDTO: PageableDTOInput,$dataInicio: LocalDate, $dataFim: LocalDate) {
      relatorioProcedimento(pageableDTO: $pageableDTO, , dataInicio: $dataInicio, dataFim: $dataFim) {
        last
        totalElements
        content {
          procedimento
          quantidade
          valor
        }
      }
    }
      `,
    variables: variables,
  });

  return response.data.data.relatorioProcedimento;
};

export const relatorioProcedimentoTotal = async (variables) => {
  const response = await Api.post("", {
    query: `
    query ($dataInicio: LocalDate, $dataFim: LocalDate) {
      relatorioProcedimentoTotal(dataInicio: $dataInicio, dataFim: $dataFim) {
        quantidade
        valor
      }
    }
      `,
    variables: variables,
  });

  return response.data.data.relatorioProcedimentoTotal;
};

export const relatorioProcedimentoLista = async (variables) => {
  const response = await Api.post("", {
    query: `
      query ($dataInicio: LocalDate, $dataFim: LocalDate) {
        relatorioProcedimentoLista(dataInicio: $dataInicio, dataFim: $dataFim) {
          procedimento
          quantidade
          valor
        }
      }
      `,
    variables: variables,
  });

  return response.data.data.relatorioProcedimentoLista;
};

export const relatorioAgendamentosSituacao = async (variables) => {
  const response = await Api.post("", {
    query: `
        query ($search: SearchAgendamentoDTOInput, $pageableDTO: PageableDTOInput, $criacao: Long) {
          relatorioAgendamentosSituacao(search: $search, pageableDTO: $pageableDTO, criacao: $criacao) {
            totalElements
            last
            content {
              id
              nome
              horaInicio
              horaFim
              telefone
              telefoneDDI
              data
              situacao
              modoConfirmacao
              tipo {
                id
                descricao
              }
              sujeitoAtencao {
                id
                nome
              }
              convenio {
                  id
                descricao
                valorConsulta
              }
              
              profissionalSaude {
                nome
              }
              procedimento {
                procedimentos 
                valorProcedimentos
              }
            }
          }
        }`,
    variables: variables,
  });

  return response.data.data.relatorioAgendamentosSituacao;
};

export const relatorioAgendamentosRepasse = async (variables) => {
  const response = await Api.post("", {
    query: `
      query ($search: SearchAgendamentoDTOInput, $pageableDTO: PageableDTOInput) {
        relatorioAgendamentosRepasse(search: $search, pageableDTO: $pageableDTO) {
            totalElements
            last
            content {
              id
              nome
              horaInicio
              horaFim
              data
              situacao
              valorRepasseAgendamento
              tipo {
                id
                descricao
              }
              profissionalSaude {
                nome
              }
            }
          }
        }`,
    variables: variables,
  });

  if (response?.data?.data) {
    return response.data.data.relatorioAgendamentosRepasse;
  } else {
    throw new Error(response.data.errors[0]);
  }
};

export const findAllProfissionalSaude = async (searchDTO = {}) => {
  try {
    const defaultSearchDTO = {
      ativo: true,
      pageSize: 30,
      pageNumber: 0,
      sortDir: "ASC",
      sortField: "nome",
    };

    const response = await Api.post("", {
      query: `
        query ($searchDTO: SearchProfissionalSaudeDTOInput) {
          findAllProfissionalSaude(searchDTO: $searchDTO) {
            last
            totalElements
            numberOfElements
            content {
              id
              nome
              value:id
              label:nome
            }
          }
        }
      `,
      variables: {
        searchDTO: {
          ...defaultSearchDTO,
          ...searchDTO,
        },
      },
    });

    const { last, content } = response.data.data.findAllProfissionalSaude;

    return {
      last,
      content,
    };
  } catch (error) {
    throw error;
  }
};

export const relatorioAgendamentosRepasseCsv = async (search) => {
  const response = await Api.post("", {
    query: `
      query ($search: SearchAgendamentoDTOInput) {
        relatorioAgendamentosRepasseCsv(search: $search)
      }
      `,
    variables: search,
  });

  if (response?.data?.data) {
    return response?.data?.data?.relatorioAgendamentosRepasseCsv;
  } else {
    throw new Error(response.data.errors[0]);
  }
};

export const valorConsultaAndProcedimento = async (search) => {
  const response = await Api.post("", {
    query: `
      query ($search: SearchAgendamentoDTOInput) {
        valorConsultaAndProcedimento(search: $search) {
          valorTotalConsultas
          valorTotalProcedimentos
        }
      }
      `,
    variables: search,
  });

  if (response?.data?.data) {
    return response?.data?.data?.valorConsultaAndProcedimento;
  } else {
    throw new Error(response.data.errors[0]);
  }
};

export const relatorioAgendamentosRepasseValorTotal = async (search) => {
  const response = await Api.post("", {
    query: `
      query ($search: SearchAgendamentoDTOInput) {
        relatorioAgendamentosRepasseValorTotal(search: $search) 
      }
      `,
    variables: { search },
  });

  if (response?.data?.data) {
    return response.data.data.relatorioAgendamentosRepasseValorTotal;
  } else {
    throw new Error(response.data.errors[0]);
  }
};

export const findAllProcedimentoSolicitado = async (variables) => {
  const response = await Api.post("", {
    query: `
      query ($profissionalSaudeId: Long, $procedimentoId: UUID, $agendado: Boolean, $pageableDTO: PageableDTOInput, $dataHoraInicio: LocalDateTime, $dataHoraFim: LocalDateTime) {
        relatorioProcedimentosSolicitados(profissionalSaudeId: $profissionalSaudeId, procedimentoId: $procedimentoId, agendado: $agendado, pageableDTO: $pageableDTO, dataHoraInicio: $dataHoraInicio, dataHoraFim: $dataHoraFim) {
          last
          totalElements
          content {
            profissionalSaude {
              id
              nome
            }
            agendado
            procedimento {
              id
              nome
            }
            dataHoraLancamento
          }
        }
      }
    `,
    variables,
  });

  const { last, content, totalElements } = response?.data?.data?.relatorioProcedimentosSolicitados;


  return {
    last,
    content,
    totalElements,
  };
}

export const totalProcedimentosSolicitados = async (variables) => {
  const response = await Api.post("", {
    query: `
      query ($profissionalSaudeId: Long, $procedimentoId: UUID, $agendado: Boolean, $pageableDTO: PageableDTOInput, $dataHoraInicio: LocalDateTime, $dataHoraFim: LocalDateTime) {
        relatorioProcedimentosSolicitados(profissionalSaudeId: $profissionalSaudeId, procedimentoId: $procedimentoId, agendado: $agendado, pageableDTO: $pageableDTO, dataHoraInicio: $dataHoraInicio, dataHoraFim: $dataHoraFim) {
          totalElements
        }
      }
    `,
    variables,
  });

  if (response.data.errors) {
    throw new Error(response.data.errors[0].message)
  }

  const { totalElements } = response?.data?.data?.relatorioProcedimentosSolicitados;

  return {
    totalElements,
  };
}

export const relatorioProcedimentosSolicitadosCsv = async (variables) => {
  const response = await Api.post("", {
    query: `
      query ($profissionalSaudeId: Long, $procedimentoId: UUID, $agendado: Boolean, $dataHoraInicio: LocalDateTime, $dataHoraFim: LocalDateTime) {
        relatorioProcedimentosSolicitadosCsv(profissionalSaudeId: $profissionalSaudeId, procedimentoId: $procedimentoId, agendado: $agendado, dataHoraInicio: $dataHoraInicio, dataHoraFim: $dataHoraFim) 
      }
    `,
    variables,
  });
  if (response.data.errors) {
    throw new Error(response.data.errors[0].message)
  }
  return response?.data?.data?.relatorioProcedimentosSolicitadosCsv;
}

export const countProcedimentosSolicitados = async (variables) => {
  const response = await Api.post("", {
    query: `
      query ($profissionalSaudeId: Long, $procedimentoId: UUID, $agendado: Boolean, $dataHoraInicio: LocalDateTime, $dataHoraFim: LocalDateTime) {
        countProcedimentosSolicitados(profissionalSaudeId: $profissionalSaudeId, procedimentoId: $procedimentoId, agendado: $agendado, dataHoraInicio: $dataHoraInicio, dataHoraFim: $dataHoraFim) 
      }
    `,
    variables,
  });
  if (response.data.errors) {
    throw new Error(response.data.errors[0].message)
  }
  return response?.data?.data?.countProcedimentosSolicitados;
}

export const findAllRelatorioCID = async (variables) => {
  const response = await Api.post("", {
    query: `
      query RelatorioAgendamentosByCid10Subcategoria($profissionalSaudeId: Long, $dataFim: LocalDate, $cid10SubcategoriaId: Long, $dataInicio: LocalDate, $situacoes: [AgendamentoSituacao]) {
        relatorioAgendamentosByCid10Subcategoria(profissionalSaudeId: $profissionalSaudeId, dataFim: $dataFim, cid10SubcategoriaId: $cid10SubcategoriaId, dataInicio: $dataInicio, situacoes: $situacoes) {
          quantidade
          descricao
        }
      }
      `,
    variables,
  });
  if (response.data.errors) {
    throw new Error(response.data.errors[0].message)
  }
  return response.data.data.relatorioAgendamentosByCid10Subcategoria;
}

export const findAllCid10Subcategoria = async (variables) => {
  const response = await Api.post("", {
    query: `
    query FindAllCid10Subcategoria($searchDto: SearchDTOInput) {
      findAllCid10Subcategoria(searchDTO: $searchDto) {
        last
        totalElements
        content {
          descricao
          id
          codigo
          descricaoAbreviada
        }
      }
    }
      `,
    variables,
  });

  if (response.data.errors) {
    throw new Error(response.data.errors[0].message)
  }

  return response.data.data.findAllCid10Subcategoria;
}

export const relatorioUltimasConsultas = async (variables) => {

  const response = await Api.post("", {
    query: `
    query RelatorioUltimasConsultas(
    $profissionalId: Long, 
    $periodo: Long, 
    $convenioId: Long,
    $search: String,
    $municipioId: Long,
    $pageableDTO: PageableDTOInput
     ) {
       relatorioUltimasConsultas(
      profissionalId: $profissionalId, 
      periodo: $periodo,
      convenioId:  $convenioId,
      search: $search,
      municipioId: $municipioId,
      pageableDTO: $pageableDTO) {
        content{
        dataUltimoAtendimento
        nome
        idade
        profissional
        contatoEmail
        contatoTelefonePrincipal
        contatoTelefonePrincipalDdi
        dataNascimento
        nomeMunicipio
        descricaoConvenio
  }
        last
        totalElements
      }
    }
      `,
    variables,
  });

  if (response.data.errors) {
    throw new Error(response.data.errors[0].message)
  }

  return response.data.data.relatorioUltimasConsultas;
}

export const relatorioUltimasConsultasCsv = async (variables) => {

  const response = await Api.post("", {
    query: `
    query (
   $profissionalId: Long, 
    $periodo: Long, 
    $convenioId: Long,
    $search: String,
    $municipioId: Long,
    ) {
      relatorioUltimasConsultasCsv(
      profissionalId: $profissionalId, 
      periodo: $periodo,
      convenioId:  $convenioId,
      search: $search,
      municipioId: $municipioId,
      )
    }
    `,
    variables,
  });

  if (response.data.errors) {
    throw new Error(response.data.errors[0].message)
  }

  return response.data.data.relatorioUltimasConsultasCsv;
}

export const relatorioAgendamentoProducaoPorProfissional = async (variables) => {

  const response = await Api.post("", {
    query: `
    query(
    $dataInicio: LocalDate, 
    $dataFim: LocalDate, 
    $pageableDto: PageableDTOInput, 
    $situacoes: [AgendamentoSituacao], 
    $criacao: Boolean, 
    $profissionalSaudeId: Long){
      relatorioAgendamentoProducaoPorProfissional(
      pageableDTO: $pageableDto,
      dataInicio: $dataInicio, 
      dataFim: $dataFim, 
      situacoes: $situacoes, 
      criacao: $criacao, 
      profissionalSaudeId: $profissionalSaudeId){
        last
        totalElements
        content {
          profissionalSaude
          situacao
          quantidade
        }
      }
    }
    `,
    variables,
  });

  if (response.data.errors) {
    throw new Error(response.data.errors[0].message)
  }
  return response.data.data.relatorioAgendamentoProducaoPorProfissional;
}

export const relatorioAgendamentoProducaoPorProfissionalCsv = async (variables) => {

  const response = await Api.post("", {
    query: `
  query($pageableDto:PageableDTOInput,
   $dataInicio: LocalDate, 
   $dataFim: LocalDate,  
   $situacoes: [AgendamentoSituacao], 
   $criacao: Boolean, 
   $profissionalSaudeId: Long){
  relatorioAgendamentoProducaoPorProfissionalCsv(
  pageableDTO: $pageableDto,
  dataInicio: $dataInicio, 
  dataFim: $dataFim,  
  situacoes: $situacoes, 
  criacao: $criacao, 
  profissionalSaudeId: $profissionalSaudeId)
}
    `,
    variables,
  });

  if (response.data.errors) {
    throw new Error(response.data.errors[0].message)
  }

  return response.data.data.relatorioAgendamentoProducaoPorProfissionalCsv;
}

export const findByUnidadeComAgenda = async({unidadeId, pageableDTO, search, ativo}) => {
  try {
    const response = await Api.post("", {
      query: `
        query FindProfissionalSaudeByUnidadeComAgendaPage($search: String, $unidadeId: Long, $pageableDTO: PageableDTOInput) {
          findProfissionalSaudeByUnidadeComAgendaPage(search: $search, unidadeId: $unidadeId, pageableDTO: $pageableDTO) {
            last
            content {
              id
              nome
            }
          }
        }
      `, 				
      variables: {
        unidadeId,
        pageableDTO,
        search,
        ...(ativo && { ativo }),
      }
    });

    if (response.data.errors) {
      throw response.data.errors[0];
    }

    return response.data.data.findProfissionalSaudeByUnidadeComAgendaPage;
  } catch (error) {
    throw error;
  }
}

export const relatorioProcedimentosAgendados = async(variables) => {
  const response = await Api.post("", {
    query: `
      query($dataInicio: LocalDate, $dataFim: LocalDate, $faturado: Boolean, $pageableDTO: PageableDTOInput, $profissionalSaudeId: Long, $convenio: ConvenioInput, $procedimento: ProcedimentoInput, $situacoes: [AgendamentoSituacao]){
        relatorioProcedimentosAgendados(dataInicio: $dataInicio, dataFim: $dataFim, faturado: $faturado, pageableDTO: $pageableDTO, profissionalSaudeId: $profissionalSaudeId, convenio: $convenio, procedimento: $procedimento, situacoes: $situacoes){
          last
          totalElements
          content {
            procedimento
            quantidade
            situacao
          }
        }
      }
    `, 				
    variables: variables
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }

  return response.data.data.relatorioProcedimentosAgendados;
}

export const relatorioProcedimentosAgendadosCsv = async(variables) => {
  const response = await Api.post("", {
    query: `
      query(
        $dataInicio: LocalDate,
        $dataFim: LocalDate,
        $profissionalSaudeId: Long,
        $situacoes: [AgendamentoSituacao],
        $faturado: Boolean
      ){
        relatorioProcedimentosAgendadosCsv(
          dataInicio: $dataInicio,
          dataFim: $dataFim,
          profissionalSaudeId: $profissionalSaudeId,
          situacoes: $situacoes,
          faturado: $faturado
        )
      }
    `, 				
    variables: variables
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }

  return response.data.data.relatorioProcedimentosAgendadosCsv;
}

export const findAllConvenio = async(variables) => {
  const response = await Api.post("", {
    query: `
      query ($searchDTO: SearchDTOInput) {
        findAllConvenio(searchDTO: $searchDTO) {
          last
          content {
            id
            descricao
          }
        }
      }
    `,
    variables: variables,
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }
  return response.data.data.findAllConvenio;
}

export const findAllProcedimentos = async(variables) => {
  const response = await Api.post("", {
    query: `
      query ($ativo: Boolean, $nome: String, $pageableDTO: PageableDTOInput) {
      findAllProcedimentos(ativo: $ativo, nome: $nome, pageableDTO: $pageableDTO) {
        last
        content {
          nome
          id
        }
      }
    }
    `,
    variables: variables,
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }
  return response.data.data.findAllProcedimentos;
}

export const relatorioProcedimentosRealizados = async(variables) => {
  const response = await Api.post("", {
    query: `
      query($dataInicio: LocalDate, $dataFim: LocalDate, $faturado: Boolean, $pageableDTO: PageableDTOInput, $profissionalSaudeId: Long, $convenio: ConvenioInput, $procedimento: ProcedimentoInput){
        relatorioProcedimentosRealizados(dataInicio: $dataInicio, dataFim: $dataFim, faturado: $faturado, pageableDTO: $pageableDTO, profissionalSaudeId: $profissionalSaudeId, convenio: $convenio, procedimento: $procedimento){
          last
          totalElements
          content {
            procedimento
            quantidade
          }
        }
      }
    `, 				
    variables: variables
  });

  if (response.data.errors) {
    throw data.errors[0];
  }

  return response.data.data.relatorioProcedimentosRealizados;
}

export const relatorioProcedimentosRealizadosCsv = async(variables) => {
  const response = await Api.post("", {
    query: `
      query(
        $dataInicio: LocalDate,
        $dataFim: LocalDate,
        $profissionalSaudeId: Long,
        $faturado: Boolean
      ){
        relatorioProcedimentosRealizadosCsv(
          dataInicio: $dataInicio,
          dataFim: $dataFim,
          profissionalSaudeId: $profissionalSaudeId,
          faturado: $faturado
        )
      }
    `, 				
    variables: variables
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }

  return response.data.data.relatorioProcedimentosRealizadosCsv;
}

export const findAllSala = async(variables) => {
  const response = await Api.post("", {
    query: `
      query ($pageableDto: PageableDTOInput, $search: String, $ativo: Boolean) {
      findAllSala(pageableDTO: $pageableDto, search: $search, ativo: $ativo) {
        last
        content {
          id
          nome
        }
      }
    }
  `, 				
    variables: variables
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }

  return response.data.data.findAllSala;
}

export const findAllAgendamentoTipo = async(variables) => {
  const response = await Api.post("", {
    query: `
     query ($pageableDto: PageableDTOInput, $ativo: Boolean) {
      findAllAgendamentoTipo(pageableDTO: $pageableDto, ativo: $ativo) {
        id
        descricao
        label: descricao
        value: id
      }
    }
  `, 				
    variables: variables
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }

  return response.data.data.findAllAgendamentoTipo;
}

export const findByUnidadeLogadaConfiguracaoUnidade = async() => {
  const response = await Api.post("", {
    query: `
     query {
      findByUnidadeLogadaConfiguracaoUnidade {
        mostrarApenasProfissionaisAtivo
        utilizaTelefoneInternacional
        confirmaPresencaAgendamento
      }
    }
  ` 				
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }

  return response.data.data.findByUnidadeLogadaConfiguracaoUnidade;
}

export const relatorioTipoConsulta = async(variables) => {
  const response = await Api.post("", {
    query: `
      query($dataInicio: LocalDate, $dataFim: LocalDate, $pageableDTO: PageableDTOInput, $profissionalId: Long, $tipoId: UUID, $search: String,){
        relatorioTipoConsulta(dataInicio: $dataInicio, dataFim: $dataFim, pageableDTO: $pageableDTO, profissionalId: $profissionalId, tipoId: $tipoId, search: $search){
          last
          totalElements
          content {
            profissionalSaudeNome
            sujeitoAtencaoNome
            tipo
            quantidade
          }
        }
      }
    `, 				
    variables: variables
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }

  return response.data.data.relatorioTipoConsulta;
};

export const findAllAgendamentoTipoPage = async(variables) => {
  const response = await Api.post("", {
    query: `
     query ($pageableDTO: PageableDTOInput, $ativo: Boolean) {
      findAllAgendamentoTipoPage(pageableDTO: $pageableDTO, ativo: $ativo) {
        last
        content {
          id
          descricao
        }
      }
    }
  `, 				
    variables: variables
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }

  return response.data.data.findAllAgendamentoTipoPage;
}

export const relatorioTipoConsultaCsv = async(variables) => {
  const response = await Api.post("", {
    query: `
      query(
        $dataInicio: LocalDate,
        $dataFim: LocalDate,
        $profissionalId: Long,
        $tipoId: UUID,
        $search: String,
        $pageableDTO: PageableDTOInput
      ){
        relatorioTipoConsultaCsv(
          dataInicio: $dataInicio,
          dataFim: $dataFim,
          profissionalId: $profissionalId,
          tipoId: $tipoId,
          search: $search,
          pageableDTO: $pageableDTO
        )
      }
    `, 				
    variables: variables
  });

  if (response.data.errors) {
    throw response.data.errors[0];
  }

  return response.data.data.relatorioTipoConsultaCsv;
}