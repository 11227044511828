import React, { useEffect, useState } from "react";
import moment from "moment";
import { CircularProgress, Grid, withStyles } from "@material-ui/core";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import HeaderRelatorio from "../../HeaderRelatorio";
import Table from "../../../../components/Table/Table";
import {
  findAllAgendamentoTipoPage,
  relatorioTipoConsulta,
  relatorioTipoConsultaCsv,
} from "../../../../services/RelatorioService";
import { columns, filtersDefault } from "./constants";
import { TextFieldSearch } from "../../../../components/TextField";
import { findAllProfissionalSaude } from "../../../../services/ProfissionalSaudeService";
import PrintIcon from "../../../../components/Icon/Print";
import { Button } from "../../../../components/ui/Buttons";
import ImpressaoHtml from "../../../../components/Impressao/ImpressaoHtml";
import Notification from "../../../../components/Notification";
import ArrowDownloadIcon from "../../../../components/Icon/ArrowDownload";
import { base64Convert } from "../../../../utils/base64ToCsv";
import { InputDateForm } from "../../../../components/Modal/Input";
import { inject } from "mobx-react";
import AgendamentosPorTipoConsulta from "../../../../template/pdf/relatorio/AgendamentosPorTipoConsulta";
import Scroll from "../../../../components/InfiniteScroll/Scroll";
import MensagemListaVazia from "../../../../components/Mensagem/MensagemListaVazia";

const RelatorioAgendamentosTipoConsulta = ({
  classes,
  configuracaoImpressaoStore,
  relatorioStore,
}) => {
  const [
    agendamentosTipoAgendamento,
    setAgendamentosTipoAgendamento,
  ] = useState([]);
  const [filters, setFilters] = useState(filtersDefault);
  const [isLoading, setIsLoading] = useState(false);
  const [pageableDTO, setPageableDTO] = useState({
    pageNumber: 0,
    pageSize: 30,
    sortField: "descricao",
    sortDir: "ASC",
  });
  const [last, setLast] = useState(false);
  const [search, setSearch] = useState("");
  const [totalElements, setTotalElements] = useState(0);
  const [isPrintMustache, setIsPrintMustache] = useState(false);
  const [isLoadingPrint, setIsLoadingPrint] = useState(false);
  const [
    agendamentosTipoAgendamentoPrint,
    setAgendamentosTipoAgendamentoPrint,
  ] = useState([]);
  const [notification, setNotification] = useState({
    isOpen: false,
    message: "",
  });

  const {
    profissionalSaude,
    dataInicio,
    dataFim,
    tipoAgendamento,
  } = filters || {};
  const { sortField, sortDir } = pageableDTO;

  const possuiDatas = dataInicio && dataFim;

  useEffect(() => {
    loadagendamentosTipoConsulta({ isClearable: true });
  }, []);

  const loadagendamentosTipoConsulta = async (options) => {
    try {
      const { dataInicio, dataFim } = filters;

      if (!relatorioStore.verificaLimiteTrintaDias(dataInicio, dataFim)) {
        showAlertMessage({
          isOpen: true,
          message: "O limite de sessenta dias foi excedido",
        });
        return;
      }

      setIsLoading(true);
      const response = await relatorioTipoConsulta({
        pageableDTO: {
          ...pageableDTO,
          pageNumber: options?.isClearable ? 0 : pageableDTO.pageNumber,
        },
        profissionalId: profissionalSaude?.id || null,
        dataInicio: moment(dataInicio).format("YYYY-MM-DD"),
        dataFim: moment(dataFim).format("YYYY-MM-DD"),
        search: options?.isClearSearch ? "" : search,
        tipoId: tipoAgendamento?.id || null
      });

      const { content, last, totalElements } = response;
      const list = options?.isClearable
        ? content
        : [...agendamentosTipoAgendamento, ...content];

      setTotalElements(totalElements);
      setLast(last);
      setAgendamentosTipoAgendamento(list);
    } catch (error) {
      showAlertMessage({
        isOpen: true,
        variant: "error",
        message:
          "Erro ao carregar o relatório de agendamentos por tipo consulta",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilters = (value, field) => {
    setFilters({ ...filters, [field]: value });
  };

  const loadProfissionaisSaude = async (search, loadedOptions, { page }) => {
    try {
      const { mostrarApenasProfissionaisAtivo } =
        relatorioStore.configuracaoUnidade || {};
      const { content, last } = (
        await findAllProfissionalSaude({
          pageNumber: page,
          search,
          ...(mostrarApenasProfissionaisAtivo && {
            ativo: mostrarApenasProfissionaisAtivo,
          }),
        })
      ).data.data.findAllProfissionalSaude;
      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error(error);
    }
  };

  const loadTiposAgendamentos = async (search, loadedOptions, { page }) => {
    try {
      const response = await findAllAgendamentoTipoPage({
        pageableDTO: {
          sortDir: "ASC",
          sortField: "descricao",
          pageSize: 30,
          pageNumber: page,
        },
        search,
        ativo: true,
      });
      const { content, last } = response;

      return {
        options: content,
        hasMore: !last,
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error(error);
    }
  };

  const handleDownloadCSV = async () => {
    const { dataInicio, dataFim } = filters;

    if (!relatorioStore.verificaLimiteTrintaDias(dataInicio, dataFim)) {
      showAlertMessage({
        isOpen: true,
        message: "O limite de sessenta dias foi excedido",
      });
      return;
    }

    const base64 = await relatorioTipoConsultaCsv({
      pageableDTO: {
        sortField,
        sortDir,
      },
      profissionalId: profissionalSaude?.id || null,
      dataInicio: moment(dataInicio).format("YYYY-MM-DD"),
      dataFim: moment(dataFim).format("YYYY-MM-DD"),
      search,
      tipoId: tipoAgendamento?.id || null
    });
    if (base64 === "error") return;
    const blob = base64Convert(base64);
    const url = window.URL.createObjectURL(blob);
    let link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", "relatorioAgendamentosTipoConsulta.csv");
    document.body.appendChild(link);
    link.click();
  };

  const handlePrint = async () => {
    try {
      const { dataInicio, dataFim } = filters;

      if (!relatorioStore.verificaLimiteTrintaDias(dataInicio, dataFim)) {
        showAlertMessage({
          isOpen: true,
          message: "O limite de sessenta dias foi excedido",
        });
        return;
      }
      setIsLoadingPrint(true);
      await configuracaoImpressaoStore.getConfig("RELATORIO");

      const response = await relatorioTipoConsulta({
        pageableDTO: {
          ...pageableDTO,
          pageSize: totalElements,
        },
        profissionalId: profissionalSaude?.id || null,
        dataInicio: moment(dataInicio).format("YYYY-MM-DD"),
        dataFim: moment(dataFim).format("YYYY-MM-DD"),
        search,
        tipoId: tipoAgendamento?.id || null
      });

      const { content } = response;
      setAgendamentosTipoAgendamentoPrint(content);

      setIsPrintMustache(true);
    } catch (error) {
      showAlertMessage({
        isOpen: true,
        variant: "error",
        message:
          "Erro ao imprimir o relatório de agendamentos por tipo consulta",
      });
    } finally {
      setIsLoadingPrint(false);
    }
  };

  const showAlertMessage = (notification) => {
    setNotification(notification);

    const timeoutId = setTimeout(() => {
      closeAlertMessage();
      clearTimeout(timeoutId);
    }, 3000);
  };

  const closeAlertMessage = () => {
    const notification = {
      isOpen: false,
      message: "",
    };
    setNotification(notification);
  };

  const handleClickOrdenar = async (value) => {
    const sortDir = pageableDTO.sortDir === "ASC" ? "DESC" : "ASC";
    setPageableDTO({
      ...pageableDTO,
      sortDir,
      sortField: value,
    });

    await loadagendamentosTipoConsulta({ isClearable: true });
  };

  const onClickPesquisar = async () => {
    await loadagendamentosTipoConsulta({ isClearable: true });
  };

  const handleClickClearPesquisar = async () => {
    setSearch("");
    loadagendamentosTipoConsulta({ isClearable: true, isCelarSearch: true });
  };

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const onKeypressSearch = async (e) => {
    if (e.key === "Enter") {
      await loadagendamentosTipoConsulta({ isClearable: true });
    }
  };

  return (
    <div className={classes.content}>
      <PageTitle title="Relatório - Agendamentos por tipo consulta" />
      <HeaderRelatorio
        title="Agendamentos por tipo consulta"
        search={onClickPesquisar}
        clearSearch={handleClickClearPesquisar}
        value={search}
        onChange={handleSearchChange}
        onKeyPress={onKeypressSearch}
        hiddenFilter
        hiddenButtons
        hiddenTotal
      />
      <div className={classes.contentFiltros}>
        <div className={classes.filtros}>
          <div className={classes.campoFiltro}>
            <span className={classes.tituloFiltros}> Data início: </span>
            <InputDateForm
              iconposition="end"
              openTo="day"
              views={["year", "month"]}
              value={filters.dataInicio || ""}
              onChange={(e) => handleFilters(e, "dataInicio")}
              classes={{
                input: classes.inputData,
              }}
            />
          </div>
          <div className={classes.campoFiltro}>
            <span className={classes.tituloFiltros}> Data Fim: </span>
            <InputDateForm
              iconposition="end"
              openTo="day"
              views={["year", "month"]}
              value={filters.dataFim || ""}
              onChange={(e) => handleFilters(e, "dataFim")}
              classes={{
                input: classes.inputData,
              }}
            />
          </div>
          <div className={classes.campoFiltro}>
            <span className={classes.tituloFiltros}> Profissional: </span>
            <TextFieldSearch
              placeholder="Selecione"
              classNotched={classes.notchedOutline}
              loadOptions={loadProfissionaisSaude}
              getOptionLabel={(option) => option?.nome}
              getOptionValue={(option) => option?.id}
              value={profissionalSaude}
              onChange={(e) => handleFilters(e, "profissionalSaude")}
              withPaginate
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
              menuPosition="fixed"
            />
          </div>
          <div className={classes.campoFiltro}>
            <span className={classes.tituloFiltros}> Tipo consulta: </span>
            <TextFieldSearch
              placeholder="Selecione"
              classNotched={classes.notchedOutline}
              loadOptions={loadTiposAgendamentos}
              getOptionLabel={(option) => option?.descricao}
              getOptionValue={(option) => option?.id}
              value={tipoAgendamento}
              onChange={(e) => handleFilters(e, "tipoAgendamento")}
              withPaginate
              debounceTimeout={300}
              additional={{
                page: 0,
              }}
              menuPosition="fixed"
            />
          </div>
        </div>
        <Button
          bgColor="#707C97"
          onClick={() => loadagendamentosTipoConsulta({ isClearable: true })}
          disabled={isLoading || !possuiDatas}
        >
          Filtrar
        </Button>
      </div>
      <div className={classes.tableContainer}>
        <Scroll
          loadMore={loadagendamentosTipoConsulta}
          hasMore={!last}
          pageStart={0}
          initialLoad={false}
          // className={classes.scrollContainerModelos}
        >
          {agendamentosTipoAgendamento.length === 0 && !isLoading && (
            <div className={classes.notFoundContainer}>
              <MensagemListaVazia />
            </div>
          )}
          {agendamentosTipoAgendamento?.length > 0 && (
            <div>
              <Table
                dados={agendamentosTipoAgendamento}
                columns={columns}
                comOrdenacao
                ordenarTabela={pageableDTO}
                handleClickOrdenar={handleClickOrdenar}
              />
            </div>
          )}
          {isLoading && (
            <Grid
              container
              justify="center"
              alignItems="center"
            >
              <CircularProgress size={30} />
            </Grid>
          )}
        </Scroll>
      </div>
      <div className={classes.buttonsDownloadPrint}>
        <Button
          id="exportCsv"
          shape="circle"
          bgColor="#F9BE73"
          onClick={handleDownloadCSV}
          disabled={agendamentosTipoAgendamento.length === 0 || isLoadingPrint}
        >
          <ArrowDownloadIcon />
        </Button>
        <Button
          shape="circle"
          bgColor="#F9BE73"
          onClick={handlePrint}
          disabled={agendamentosTipoAgendamento.length === 0 || isLoadingPrint}
        >
          <PrintIcon />
        </Button>
      </div>
      {isPrintMustache && (
        <ImpressaoHtml
          isPrintMustache={isPrintMustache}
          handlePrintMustache={() => setIsPrintMustache(false)}
          htmlStringComponent={
            <AgendamentosPorTipoConsulta
              dadosRelatorio={agendamentosTipoAgendamentoPrint || []}
              filters={filters}
            />
          }
        />
      )}

      <Notification
        close={closeAlertMessage}
        reset={closeAlertMessage}
        isOpen={notification.isOpen}
        variant={"error"}
        message={notification.message}
      />
    </div>
  );
};

const styles = {
  content: {
    display: "grid",
    gridTemplateRows: "80px 71px 1fr",
    position: "relative",
    overflow: "hidden",
    background: "#f5f5f5",

    "& thead th": {
      textAlign: "left",
      position: "sticky",
      top: 0,
    },
  },
  contentFiltros: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    padding: "13px 16px 8px 16px",
    boxShadow:
      "10px 10px 25px rgb(112 124 151 / 5%), 15px 15px 35px rgb(112 124 151 / 5%)",
    background: "#fff",
    marginTop: 1,
  },
  filtros: {
    gap: "8px",
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  tableContainer: {
    overflow: "auto",
    margin: "16px",
    borderRadius: "16px",
    border: "1px solid rgba(0, 0, 0, 0.1)",
    background: "#fff",
    "&> div": {
      height: "100%",
      "&> div": {
        height: "100%",
      },
    },
  },
  tituloFiltros: {
    color: "#868686",
    fontSize: "12px !important",
  },
  campoFiltro: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "188px",
  },
  notchedOutline: {
    border: "0",
  },
  buttonsDownloadPrint: {
    display: "flex",
    position: "absolute",
    bottom: "20px",
    right: "14px",
    width: "88px",
    justifyContent: "space-between",
  },
  inputContainer: {
    display: "flex",
    background: "#F2F2F2",
    color: "#505050",
    borderRadius: "8px",
    border: "none",
    "& > div": {
      "& > p": {
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      },
    },
  },
  select: {
    width: "165px",
  },
  selectMenu: {
    marginTop: "4px",
    width: "165px",
    zIndex: 1000,
  },
  notFoundContainer: {
    height: "100%",
  },
};

const stores = ["relatorioStore", "configuracaoImpressaoStore"];
const RelatorioAgendamentosTipoConsultaWithStyles = withStyles(styles)(
  RelatorioAgendamentosTipoConsulta
);
export default inject(...stores)(RelatorioAgendamentosTipoConsultaWithStyles);
