import React from "react";
import {
  ContainerTab,
  FormControlLabelStyled,
  Row,
  TextFieldSearchStyled,
} from "../../styles";
import Field from "../../components/Field";
import {
  InputDateForm,
  InputForm,
} from "../../../../../components/Modal/Input";
import {
  findAllConvenio,
  findAllProfissionalSaudeComAgenda,
  findProfissionalSaudeByUnidadeSemAgenda,
} from "../../../../../services/InternacaoService";
import { ReactSelect } from "../../../../../components/Select/SelectSearch";
import { tiposEncaminhamentos } from "../../constants";
import { Checkbox } from "@material-ui/core";

const DadosComplementares = ({ internacao, handleField }) => {
  const { dadosComplementares } = internacao;
  const {
    ambulante,
    convenio,
    dataGuia,
    encaminhamento,
    numeroGuia,
    observacoesGerais,
    observacoesMedico,
    profissionalAtendente,
    profissionalResponsavel,
    profissionalSolicitante,
    transferido,
  } = dadosComplementares || {};

  const unidadeLogada = JSON.parse(localStorage["_immortal|unidadeLogada"]);

  const handleFieldDadosComplementares = (value, field) => {
    const dadosComplementares = internacao.dadosComplementares;
    dadosComplementares[field] = value;

    handleField("dadosComplementares", dadosComplementares);
  };

  const loadAllConvenio = async (search, loadedOptions, { page }) => {
    const searchDTO = {
      search,
      pageNumber: page,
      pageSize: 20,
      sortDir: "ASC",
      sortField: "descricao",
    };

    const response = await findAllConvenio({ searchDTO });
    const { content, last } = response;

    return {
      options: content,
      hasMore: !last,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadAllProfissionaisSemAgenda = async (
    search,
    loadedOptions,
    { page }
  ) => {
    const variables = {
      pageableDTO: {
        pageNumber: page,
        pageSize: 20,
        sortDir: "ASC",
        sortField: "nome",
      },
      unidadeId: unidadeLogada.id,
    };

    const response = await findProfissionalSaudeByUnidadeSemAgenda(variables);
    const { content, last } = response;

    return {
      options: content,
      hasMore: !last,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadAllProfissionalComAgenda = async (
    search,
    loadedOptions,
    { page }
  ) => {
    const variables = {
      search,
      pageableDTO: {
        pageNumber: page,
        pageSize: 20,
        sortDir: "ASC",
        sortField: "nome",
      },
      unidadeId: unidadeLogada.id,
      ativo: true,
    };

    const response = await findAllProfissionalSaudeComAgenda(variables);
    const { content, last } = response;

    return {
      options: content,
      hasMore: !last,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadAllProfissionalExternoComAgenda = async (
    search,
    loadedOptions,
    { page }
  ) => {
    const variables = {
      search,
      pageableDTO: {
        pageNumber: page,
        pageSize: 20,
        sortDir: "ASC",
        sortField: "nome",
      },
      unidadeId: unidadeLogada.id,
      ativo: true,
      externos: true,
    };

    const response = await findAllProfissionalSaudeComAgenda(variables);
    const { content, last } = response;

    return {
      options: content,
      hasMore: !last,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <ContainerTab>
      <Row>
        <Field label="Convênio" width="80%">
          <TextFieldSearchStyled
            placeholder="Selecione"
            loadOptions={loadAllConvenio}
            getOptionLabel={(option) => option.descricao}
            getOptionValue={(option) => option.id}
            value={convenio}
            onChange={(e) => handleFieldDadosComplementares(e, "convenio")}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          />
        </Field>
      </Row>
      <Row>
        <Field label="Guia">
          <InputForm
            value={numeroGuia}
            type="number"
            onChange={(e) =>
              handleFieldDadosComplementares(e.target.value, "numeroGuia")
            }
          />
        </Field>
        <Field label="Data guia">
          <InputDateForm
            value={dataGuia}
            onChange={(e) => handleFieldDadosComplementares(e, "dataGuia")}
          />
        </Field>
      </Row>
      <Row>
        <Field label="Profissional Responsável">
          <TextFieldSearchStyled
            placeholder="Selecione"
            loadOptions={loadAllProfissionalComAgenda}
            getOptionLabel={(option) => option.nome}
            getOptionValue={(option) => option.id}
            value={profissionalResponsavel}
            onChange={(e) =>
              handleFieldDadosComplementares(e, "profissionalResponsavel")
            }
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          />
        </Field>
        <Field label="Profissional Solicitante">
          <TextFieldSearchStyled
            placeholder="Selecione"
            loadOptions={loadAllProfissionalExternoComAgenda}
            getOptionLabel={(option) => option.nome}
            getOptionValue={(option) => option.id}
            value={profissionalSolicitante}
            onChange={(e) =>
              handleFieldDadosComplementares(e, "profissionalSolicitante")
            }
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          />
        </Field>
      </Row>
      <Row>
        <Field label="Transferido">
          <InputForm
            value={transferido}
            onChange={(e) =>
              handleFieldDadosComplementares(e.target.value, "transferido")
            }
          />
        </Field>
      </Row>
      <Row>
        <Field label="Obs geral">
          <InputForm
            value={observacoesGerais}
            onChange={(e) =>
              handleFieldDadosComplementares(
                e.target.value,
                "observacoesGerais"
              )
            }
          />
        </Field>
      </Row>
      <Row>
        <Field label="Obs médico">
          <InputForm
            value={observacoesMedico}
            onChange={(e) =>
              handleFieldDadosComplementares(
                e.target.value,
                "observacoesMedico"
              )
            }
          />
        </Field>
      </Row>
      <Row>
        <Field label="Funcionária Responsável">
          <TextFieldSearchStyled
            placeholder="Selecione"
            loadOptions={loadAllProfissionaisSemAgenda}
            getOptionLabel={(option) => option.nome}
            getOptionValue={(option) => option.id}
            value={profissionalAtendente}
            onChange={(e) =>
              handleFieldDadosComplementares(e, "profissionalAtendente")
            }
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          />
        </Field>
      </Row>
      <Row>
        <FormControlLabelStyled
          control={
            <Checkbox
              checked={ambulante}
              color="primary"
              onChange={() =>
                handleFieldDadosComplementares(!ambulante, "ambulante")
              }
            />
          }
          label="Ambulante"
        />
        <Field label="Encaminhado para" width="20%">
          <ReactSelect
            value={encaminhamento}
            onChange={(e) =>
              handleFieldDadosComplementares(e, "encaminhamento")
            }
            options={tiposEncaminhamentos}
            menuPlacement="auto"
          />
        </Field>
      </Row>
    </ContainerTab>
  );
};

export default DadosComplementares;
