import React, { useEffect, useState } from "react";
import {inject} from "mobx-react";
import { withStyles } from "@material-ui/core/styles/index";
import { ReactSelect } from "../Select/SelectSearch";
import ButtonPrimary from "../Button/ButtonPrimary"
import { TextFieldSearch } from "../TextField";
import Colors from "../../template/Colors";
import MultipleSelectCheckbox from "../Select/MultipleSelectCheckbox";
import { checkUserRole } from "../../utils/checkUserRole";
import { findAgendamentoTipoByAtivo } from "../../services/AgendamentoTipoService";

const styleInputSearch = () => ({
    title: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "#FFF",
        "& > h3": {
            color: "#5F6368",
            fontSize: "16px",
        },
        "& > span": {
            color: "#626060",
            fontSize: "14px",
            textDecorationLine: "underline",
            "&:hover": {
                cursor: "pointer"
            }
        }
    },
    selectSearch: {
        width: "100%"
    },
    searchStatus: {
        width: "100%",
        marginLeft: '5px'
    },
    selects: {
        display: 'flex',
    },
    searchButton: {
        borderRadius: 85,
        width: "50%",
        boxShadow: "0px 4px 16px rgba(0, 0, 0, 0.08)",
        "& > span": {
            fontSize: "14px"
        }
    },
    buttons: {
        marginTop: "20px"
    },
    notchedOutline: {
        border: '0',
    },
    inputTextField: {
        fontSize: '14px',
        border: '0',
        minHeight: '6px',
        height: 32,
        color: Colors.commons.fontColor,
        backgroundColor: Colors.commons.gray2,
        margin: '0 0 0 0',
        borderRadius: '100px',
    },
    classIcons: {
        fontSize: 24,
    },
    listaFiltro: {
        maxHeight: '50vh',
        overflow: 'auto',
        gap: '8px',
        display: 'flex',
        flexDirection: 'column',
    }
});

const FiltrosLancamentoFinanceiro = (props) => {
    const {
        classes,
        handleChangeInput,
        searchLancamento,
        extratoStore,
        redefinirFiltros,
        handleSujeitoAtencaoLoadOptions,
        filterLancamento,
        quantidadeChecked,
        changeCheckbox,
        formaPagamentoList,
        quantidadeCheckedStatus,
        statusList,
        changeCheckboxStatus,
    } = props;

    const [showProfissionalSaude, setShowProfissionalSaude] = useState(true);
    const [optionsTipoConsulta, setOptionsTipoConsulta] = useState([]);

    useEffect(() => {
        verificaProfissionalSaude();
        getTiposConsulta();
    }, []);

    const verificaProfissionalSaude = async() => {
        const role = await checkUserRole('ROLE_TITULO_PARCELA_READ_OUTROS_PROFISSIONAIS');
        setShowProfissionalSaude(role);
    };

    const getTiposConsulta = async() => {
        const response = await findAgendamentoTipoByAtivo();
        setOptionsTipoConsulta(response?.data?.data.findAllAgendamentoTipo || []);
    };

    const handleProfissionalSaudeLoadOptions = async (
        search,
        loadedOptions,
        { page }
        ) => {
        return handleLoadMoreOptions({
          search,
          loadedOptions,
          data: { page },
          method: "findAllProfissionalSaude",
        });
    };

    const handleProcedimentoLoadOptions = async (
        search,
        loadedOptions,
        { page }
        ) => {
        return handleLoadMoreOptions({
          search,
          loadedOptions,
          data: { page },
          method: "findAllProcedimentos",
        });
    };
    
    const handleConvenioLoadOptions = async (
        search,
        loadedOptions,
        { page }
        ) => {
        return handleLoadMoreOptions({
          search,
          loadedOptions,
          data: { page },
          method: "findAllConvenios",
        });
    };

    const handleLoadMoreOptions = async ({ search, data, method }) => {
        let searchDTO = {};
    
        if (data.searchDTO) {
          searchDTO = {
            ...data.searchDTO,
          };
        }
    
        const response = await extratoStore[method]({
          pageNumber: data.page,
          ativo: null,
          search,
          ...searchDTO,
        });
    
        return {
          options: response.content,
          hasMore: !response.last,
          additional: {
            page: data.page + 1,
          },
        };
    };

    return (
        <div className={classes.content}>
            <div className={classes.title}>
                <h3>Filtrar</h3>
                <span onClick={() => redefinirFiltros()}>
                    Redefinir filtros
                </span>
            </div>
            <div className={classes.listaFiltro}>
                <div className={classes.selectSearch}>
                    <span>Status </span>
                    <MultipleSelectCheckbox
                        quantidadeChecked={quantidadeCheckedStatus}
                        listaOpcoes={statusList || []}
                        changeCheckbox={changeCheckboxStatus}
                    />
                </div>
                <div className={classes.selectSearch}>
                    <span>Paciente </span>
                    <TextFieldSearch
                        placeholder="Paciente"
                        classNotched={classes.notchedOutline}
                        classInput={classes.inputTextField}
                        classIcons={classes.classIcons}
                        loadOptions={handleSujeitoAtencaoLoadOptions}
                        withPaginate
                        value={filterLancamento.sujeitoAtencao}
                        onChange={(e) => handleChangeInput(e, 'sujeitoAtencao', 'sujeitoAtencaoId')}
                        debounceTimeout={300}
                        additional={{
                            page: 0
                        }}
                    />
                </div>
                {showProfissionalSaude && <div>
                    <span>Profissional</span>
                    <TextFieldSearch
                        placeholder="Profissional"
                        classNotched={classes.notchedOutline}
                        classInput={classes.inputTextField}
                        classIcons={classes.classIcons}
                        loadOptions={handleProfissionalSaudeLoadOptions}
                        withPaginate
                        value={filterLancamento.profissionalSaude}
                        onChange={(e) => handleChangeInput(e, 'profissionalSaude', 'profissionalSaudeId')}
                        debounceTimeout={300}
                        additional={{
                            page: 0
                        }}
                    />
                </div>}
                <div className={classes.selectSearch}>
                    <span>Meio pagamento </span>
                    <MultipleSelectCheckbox
                        quantidadeChecked={quantidadeChecked}
                        listaOpcoes={formaPagamentoList || []}
                        changeCheckbox={changeCheckbox}
                    />
                </div>
                <div>
                    <span className={classes.tituloFiltros}> Centro de custo: </span>
                    <ReactSelect
                        isClearable
                        className={classes.selectSearch}
                        placeholder="Centro de custo"
                        value={filterLancamento.centroCusto}
                        options={extratoStore.centroCustoList || []}
                        getOptionLabel={(option) => option.nome}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => handleChangeInput(e, 'centroCusto',"centroCustoId")}
                    />
                </div>
                <div>
                    <span className={classes.tituloFiltros}> Categoria: </span>
                    <ReactSelect
                        isClearable
                        className={classes.selectSearch}
                        placeholder="Categoria"
                        value={filterLancamento.categoria}
                        options={extratoStore.categoriaList || []}
                        getOptionLabel={(option) => option.nome}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => handleChangeInput(e, 'categoria', "categoriaFinanceiroId")}
                    />
                </div>
                <div>
                    <span className={classes.tituloFiltros}> Convênio: </span>
                    <TextFieldSearch
                        placeholder="Convênio"
                        classNotched={classes.notchedOutline}
                        classInput={classes.inputTextField}
                        classIcons={classes.classIcons}
                        loadOptions={handleConvenioLoadOptions}
                        getOptionLabel={(option) => option.descricao}
                        getOptionValue={(option) => option.id}
                        withPaginate
                        value={filterLancamento.convenio}
                        onChange={(e) => handleChangeInput(e, "convenio", "convenioId")}
                        debounceTimeout={300}
                        menuPlacement={'auto'}
                        additional={{
                            page: 0
                        }}
                    />
                </div>
                <div>
                    <span className={classes.tituloFiltros}> Conta bancária: </span>
                    <ReactSelect
                        isClearable
                        className={classes.selectSearch}
                        placeholder="Conta bancária"
                        value={filterLancamento.contaBancaria}
                        options={extratoStore.contaBancariaList || []}
                        getOptionLabel={(option) => option.nome}
                        getOptionValue={(option) => option.id}
                        onChange={(e) => handleChangeInput(e, "contaBancaria", "contaBancariaId")}
                    />
                </div>
                <div>
                    <span className={classes.tituloFiltros}> Tipo: </span>
                    <ReactSelect
                        isClearable
                        className={classes.selectSearch}
                        placeholder="Tipo"
                        value={filterLancamento.tipo}
                        options={extratoStore.tiposFinanceiro || []}
                        getOptionLabel={(option) => option.tipo}
                        getOptionValue={(option) => option.tipo}
                        onChange={(e) => handleChangeInput(e, "tipo", "tipo")}
                    />
                </div>
                <div className={classes.selectSearch}>
                    <span> Procedimento </span>
                    <TextFieldSearch
                        placeholder="Procedimento"
                        classNotched={classes.notchedOutline}
                        classInput={classes.inputTextField}
                        classIcons={classes.classIcons}
                        loadOptions={handleProcedimentoLoadOptions}
                        getOptionLabel={(option) => option.nome}
                        getOptionValue={(option) => option.id}
                        withPaginate
                        value={filterLancamento.procedimento}
                        onChange={(e) => handleChangeInput(e, 'procedimento', 'procedimentoId')}
                        debounceTimeout={300}
                        menuPlacement={'auto'}
                        additional={{
                            page: 0
                        }}
                    />
                </div>
                <div className={classes.selectSearch}>
                    <span> Tipo consulta </span>
                    <ReactSelect
                        isClearable
                        className={classes.selectSearch}
                        placeholder="Tipo consulta"
                        value={filterLancamento.tipoConsulta}
                        options={optionsTipoConsulta}
                        getOptionLabel={(option) => option.descricao}
                        getOptionValue={(option) => option.id}
                        menuPlacement={'auto'}
                        onChange={(e) => handleChangeInput(e, "tipoConsulta", "tipoConsultaId")}
                    />
                </div>
            </div>

            <div className={classes.buttons}>
                <ButtonPrimary
                    onClick={() => searchLancamento()}
                    className={classes.searchButton}
                >
                    Aplicar filtro
                </ButtonPrimary>
            </div>
        </div>
    );
};

const FiltrosLancamentoFinanceiroWithStyles = withStyles(styleInputSearch)(FiltrosLancamentoFinanceiro);
export default inject("extratoStore")(FiltrosLancamentoFinanceiroWithStyles);
