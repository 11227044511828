import {
    background,
    fontColor,
    primaryColor,
    fontColorDark,
    primaryColorDark
} from "../appHealth.style";
import colors from '../../../template/Colors';

const prontuarioAtendimentoStyle = (theme) => ({
    dialogPaper: {
        minWidth: "400px",
        minHeight: "70px",
        zIndex: 999,
        padding: 24,
        top: 0,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: '15px',
    },
    headerModalProcedimentos:{
        display: 'flex',
        justifyContent: 'space-between',
        width: "100%",
        color: primaryColor,
        fontWeight: "bold",
        textAlign: "center",
        "& h3":{
            fontSize: 24,
            marginTop: 0
        }
    },
    closeIcon:{
        cursor: "pointer",
        marginTop: '-5px'
    },

    contentModalProcedimentos:{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
    },
    procedimento:{
        color: fontColor,
        margin: "2px 0px 2px 0px",
        fontWeight: 500,
        fontSize: 16,
    },

    procedimentoEmpty:{
        fontSize: 12,
        color: fontColor,
        width: '100%',
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    gridContainer:{
        display: 'grid',
        height: "100dvh",
        gridTemplateColumns: `240px 1fr`,
        gridTemplateRows: '1fr 64px',
        gridTemplateAreas: `
            "sidebar-left content"
            "footer footer"
            `,
        flex: 1,
        [theme.breakpoints.down('sm')]:{
            gridTemplateColumns: `80px 1fr`,
        },
    },

    gridContainerBarraMinimizado: {
        display: 'grid',
        height: "100dvh",
        gridTemplateColumns: `107px 1fr`,
        gridTemplateRows: '1fr 64px',
        gridTemplateAreas: `
            "sidebar-left content"
            "footer footer"
            `,
        flex: 1
    },

    gridContainerTelaAmpliada: {
        gridTemplateColumns: '1fr',
    },

    sidebarLeft: {
        gridArea: 'sidebar-left',
        display: "grid",
        backgroundColor: background,
        alignContent: "start",
        overflow: 'auto',
        gridTemplateRows: '1fr 1fr 0.5fr 5fr 0.5fr'
    },

    wrapperHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '1rem',

        fontSize: '1.125rem',
        color: primaryColor,
        fontFamily: 'Poppins',
        fontWeight: 700,
    },

    bold: {
        fontWeight: 'bold',
    },

    subtitleLeft: {
        fontSize: '1.4rem',
        fontWeight: 'bold',
        color: fontColor,
        textAlign: "center",
        margin: 0
    },

    searchPacienteListLeft: {
        padding: '20px 40px',
    },

    searchPaciente: {
        position: "relative",
        display: "grid",
        width: '100%',
        gridColumnGap: "40px",
        alignItems: "end",
    },

    searchPacienteImg: {
        height: "75px",
        borderRadius: "37px"
    },

    btnAddEditMini: {
		minHeight: 24,
		height: 24,
		width: 24,
        backgroundColor: 'transparent',
		boxShadow: "none",
		color: "#333333",
        margin: ' 0 2px',
        '&:hover': {
            backgroundColor: 'transparent',
            boxShadow: "none",
        },
        '& > svg':{
            width: 16,
            height: 16,
        }
    },
    
    headerRowName: {
        display: 'flex'
    },
    listaPacientesBotaoMinimizar:{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        overflow: 'auto',
    },

    drawerMinimizar: {
        width: '20%',
        flexShrink: 0,
        marginLeft: 80,
        display: 'grid',
    },

    drawerPaper: {
        width: '20%',
    },

    semPerfil: {
        width: 'auto',
        height: 100,
    },

    drawerOpen: {
        left: 72,
        width: 240,
        maxHeight:'calc(100% - 64px)',
        overflowY: 'hidden',
        background: '#F9F9F9',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        left: 72,
        maxHeight:'calc(100% - 64px)',
        background: '#F9F9F9',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden !important',
        overflowY: 'hidden',
        width: 107,
    },
    menuButton: {
        justifyContent: 'center',
        borderRadius: 0,
        zIndex: 10,
        fontWeight: 400,
        color: '##868686',
    },

    conteudoConsulta:{
        flexGrow: 1,
        background: '#E5E5E5',
        overflowY: 'scroll',
    },

    inputMessageSujeitoAtencaoRoot: {
        border: 'none',
        backgroundColor: "#fff",
        display: 'flex',
        alignItems: 'flex-end',
    },

    divider:{
        marginTop: '10px',
        width: '99%',
    },

    inputMessageAdornment: {
        height: 40,
        maxHeight: 'none',
        alignItems: 'flex-start'
    },

    dataFilterContainer: {
        flexDirection: "row",
        alignItems: "center",
        display: "flex",
        justifyContent: "center",
        padding: '0 16px',
        color: "#333333",
    },

    dataFilterContainerMinimizado: {
        padding: 0
    },

    dataFilterInnerContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: '100%',
    },
    dataFilterArrows: {
        color: "#333333",
        cursor: "pointer",
        display: 'flex',
    },

    dataFilterArrowsMinimizado:{
        padding: '6px 0',
        color: "#333333",
        cursor: "pointer"
    },
    searchPacienteDataFilter: {
        color: theme.palette.primary.dark,
        fontWeight: "bold",
        cursor: "pointer",
        fontSize: 15,

    },
    dataFilter: {
        padding: '0px 7px 3px 7px',
        color: "#333333",
        fontWeight: "bold",
        cursor: "pointer",
        fontSize: 16,
    },

    formInput: {
        marginTop: 12,
        marginBottom: 20,
    },

    inputMessageSujeito: {
        padding: '15px 0',
        maxHeight: "190px",
        fontSize: 'medium',
        color: fontColorDark
    },

    listaPacientes: {
        display: "grid",
        gridRowGap: "15px",
        gridTemplateRows: "inherit",
        marginLeft: "-3px",
        overflow: 'auto',
        height: '100%',
        alignContent: 'start',
    },
    
    listaPacientesDrawerAberto: {
        justifyContent: 'center',
        paddingLeft: '24px',
    },

    checkboxOptions: {
        display: "grid",
        gridRowGap: 0,
        [theme.breakpoints.down('lg')]: {
            padding: '0 25px',
            gridTemplateColumns: "repeat(auto-fill, minmax(100px, auto))",

        },
        [theme.breakpoints.up('lg')]: {
            gridTemplateColumns: "repeat(auto-fill, minmax(100px, auto))",
            padding: '0 15px',
        },
        [theme.breakpoints.up('xl')]: {
            gridTemplateColumns: "repeat(auto-fill, minmax(125px, auto))",
            padding: '0 25px',
        },
    },

    checkboxOptionsBarraMinimizado: {
        display: 'grid',
        gridRowGap: 0,
        gridTemplateColumns: 'auto',
        justifyContent: 'center',
        gridTemplateRows: '1fr 1fr 0.5fr 3fr 0.5fr',
    },

    drawer: {

    },

    minimizarBarraLateral:{
        justifySelf: 'center',
        margin: 20,
        display: 'flex',
        alignItems: 'center',
    },

    minimizarBarraLateralLabel:{
        paddingLeft: 20,
        fontFamily: 'Nexa Bold',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: '14px',
        lineHeight: '14px',
        color: colors.commons.gray7,
    },



    content: {
        gridArea: 'content',
        display: "grid",
        gridTemplateRows: "109px 1fr",
        overflowY: "hidden",
        height: '100%',
    },

    contentLoadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        width: "100%",
        height: "100%",
    },

    contentNotFoundContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    },
    headerDrawer:{
        height: 48,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        padding: '4px 0px',
    },

    headerContainer: {
        display: "flex",
        flex: 1,
        flexWrap: "nowrap",
        justifyContent: "space-between",
        margin: 0

    },
    header:{
        padding: "0 16px 0 2% !important",
        boxShadow: "0px 5px 25px rgba(38, 172, 169, 0.07), 0px 7px 25px rgba(38, 172, 169, 0.08), 0px 10px 15px rgba(0, 0, 0, 0.03)",
        background: "#fff",
    },
    
    headerOpenDrawer: {
        "@media (min-width: 960px)": {
            width: 'calc(100vw - 352px)',
        },
    },

    headerPacienteBarraInfo:{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },

    infoPacienteProfile:{
        flexFlow: 'nowrap',
        height: 64,
    },
    headerPacienteButtons:{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        gap: '1rem',
    },

    image:{
        [theme.breakpoints.down('lg')]:{
            display: 'none',
        }
    },
    headerPaciente:{
        display: 'flex',
        flexDirection: 'column',
    },
    imageMini:{
        borderRadius: '30px',
        minWidth: 40,
        height: 40,
    },
    headerPacienteFoto:{
      background: 'black',
    },
    headerPacienteNome:{
        fontWeight: 700,
        color: colors.commons.black,
        fontSize: 16,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis' 
    },
    headerDivPacienteInfo:{
        display: 'flex',
        alignItems: 'center',
        gap: '0.5rem',
        height: '20px',
    },
    headerPacienteInfo:{
        fontWeight: 'bold',
        fontSize: '0.875rem',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis' 
    },
    infoAdicionais:{
        display: 'flex',
        flexDirection: 'row',
        height: 44,
        alignItems: 'center',
        padding: '0 16px',
        width: "75vw",
        overflowX: 'scroll',
        overflowY: 'hidden',
    },

    infoAdicionaisDrawerAberto: {
        "@media(max-width: 1800px)": {
            width: "70vw"
        },
        "@media(max-width: 1500px)": {
            width: "65vw"
        },
        "@media(max-width: 1200px)": {
            width: "58vw"
        },
    },

    infoAdicionaisAlturaPeso:{
        paddingRight: '0 !important',
    },

    infoAdicional:{
        paddingRight: 7,
    },
    alertaConsultaTelemedicina:{
        display: 'flex',
        flexDirection: 'row',
        background: colors.commons.purple,
        borderRadius: 100,
        height: 16,
        fontSize: 12,
        color: 'white',
        paddingLeft: 5,
        paddingTop: 1,
        alignItems: 'center',
        '& svg':{
            width: 14,
            height: 14,
            marginRight: 10,
            paddingBottom: 5,
        }
    },
    semAlertaConsultaTelemedicina:{
        height: 16,
    },
    chatInfo: {
        display: "grid",
        gridTemplateColumns: "auto 25% auto auto auto",
        gridColumnGap: "10px",
        borderRadius: "50px",
        backgroundColor: colors.commons.gray8,
        alignContent: "center",
        alignItems: "center",
        padding: "10px 25px",
        color: colors.commons.gray7,
        fontWeight:'bold',
        height: 35
    },

    chatInfoText: {},

    textAutoSize: {
        overflow: 'hidden',
        position: 'relative',
        lineHeight: '1.3em',
        maxHeight: '2.5em',
        margin: '0 -1em 0 0',
        width: '94%',
        minWidth: 100,
        whiteSpace: 'nowrap',
        "&::before": {
            content: '"..."',
            position: 'absolute',
            right: 0,
            bottom: 0,
        },

        "&::after": {
            content: '""',
            position: 'absolute',
            right: 0,
            width: '1em',
            height: '1em',
            marginTop: '0.2em',
            background: colors.commons.gray8,
        },

        "&:hover": {
            width: 'max-content',
            backgroundColor: colors.commons.gray8,
            zIndex: 1,
            padding: '15px 15px 15px 0',

            "&::before": {
                content: '""',
            }
        },

    },

    inputBlock: {
        display: "grid",
        gridTemplateColumns: "auto auto",
        gridColumnGap: '10px',
        justifyContent: "space-around",
    },

    inputRootBlockField: {
        paddingLeft: 0,
        border: 'none',
        fontSize: 14,
    },

    inputAlturaPeso:{
        backgroundColor: 'white',
        borderRadius: 100,
        justifyContent: 'center',
        minWidth: 60,
        border: 'none',
        maxHeight: 22,
        maxWidth:80,
    },

    inputBlockField: {
        fontFamily: "Poppins !important",
        padding: "5px 0",
        textAlign: "center",
        fontWeight: "normal !important",
        "::-webkit-input-placeholder": {
            color: "pink"
        },
    },

    documentoFileInput: {
        display: 'none'
    },

    buttonIniciarConsulta:{
        width: "40px",
        height: "40px",
        background: primaryColor,
        boxShadow: 'none',
        "&:hover": {
            background: primaryColorDark,
        },
    },
    buttonPlay:{
        color: '#fff',
        svg:{
            width: '1em',
            height: '1em',
        },
    },

    playStopIcon: {
        width: '1em',
        height: '1em',
        color: 'white',
    },

    tuneIcon: {
        width: '1em',
        height: '1em',
        color: primaryColor,
    },

    buttonEditarCamposProntuario: {
        minWidth: '40px',
        width: '40px',
        height: '40px',
        boxShadow: 'none',
        background: '#F5F5F5'
    },

    buttonCall: {
        width: "40px",
        height: "40px",
        background: colors.commons.yellow,
        boxShadow: 'none',
        "&:hover": {
            background: '#e0a048',
        },
    },

    buttonCallIcon: {
        width: "1em",
        height: "1em",
    },

    buttonProcedimentosIcon:{
        width: "0.7em",
        height: "0.7em"
    },

    buttonAddInput: {
        width: "45px",
        height: "45px",
        minHeight: "1px",
        position: 'relative',
        top: '-8px',
        boxShadow: "none !important",
        marginRight: 10,
    },

    buttonSendInput: {
        width: "45px",
        height: "45px",
        minHeight: "1px",
        position: 'relative',
        top: '-8px',
        boxShadow: "none !important",
    },

    buttonSendIcon: {
        color: 'white',
        width: 20,
        height: 20,
    },

    chatInfoIcon: {
        justifySelf: 'end'
    },

    contentMensagensProntuarioSubMenu: {
        display: "grid",
        gridTemplateColumns: "1fr 70px",
        gridTemplateRows: "1fr auto",
        gridColumnGap: '10px',
        padding: "16px 0 0",
        overflowY: "auto",
        maxHeight: '-webkit-fill-available',
        minHeight: '200px',
    },

    mensagensBlock: {
        overflowY: "auto",
        width: '100%'
    },

    optionsDocuments:{
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('md')]:{
           display: 'none',
        },
    },

    buttonGreen: {
        margin: '4px 0',
        minWidth: "45px",
        width: "45px !important",
        minHeight: "45px",
        height: '45px !important',
        background: primaryColor,
        boxShadow: 'none',
        "&:hover": {
            background: primaryColorDark,
        },
    },
    medicineIcon: {
        width: "25px",
        cursor: "pointer"
    },

    documentoIcon: {
        width: "19px",
        cursor: "pointer",
        color: 'fff',
    },

    iconButton: {
        cursor: "pointer",
        color: 'white',
    },
    floatingModal: {
        position: "absolute",
    
        height: "550px",
        display: "flex",
        right: "85px",
        bottom: "160px",
        "@media (min-width: 1920px)": {
          right: "130px",
        },
        "@media (max-height: 710px)": {
          bottom: "72px",
        },
        "@media (max-height: 620px)": {
          height: "80%",
        },
    },

    toolTipPaciente:{
        marginLeft: '-26px',
        marginRight: '0',
        marginTop: '38px'
    },
    toolTipPacienteMinimizado: {
        marginLeft: '26px',
        marginTop: '31px',
    },
    attentionButton: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "#FB7676",
        fontSize: "14px",
        borderRadius: "50%",
        color: "#FFF",
        width: "16px",
        height: "16px",
        minHeight: "1px",
        position: 'absolute',
        
    },
    procedimentoButton: {
        position: 'absolute',
        top: '2px'
    },
    procedimentoButtonMinimizado: {
        marginLeft: '26px',
        marginTop: '0',
    },
    agendamentoInfoButton: {
        position: 'absolute',
        top: '42px',
        right: '1px'
    },
    agendamentoInfoButtonMinimizado: {
        top: '25px',
        right: '15px',
    }, 
    originLabel: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center'
    },
    originLabelText: {
        fontSize: '12px !important',
        fontWeight: '500 !important',
        color: '#505050',
        width: "max-content",
    }, 
    endOfHeaderItems: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: '16px',
    },
    buttonSolicitacoes: {
        background: '#75809A',
        border: '2px solid rgba(220, 220, 220, 0.2)',
        padding: '0.5rem 1rem',
        boxShadow: 'none',
        display: 'flex',
        textTransform: 'none',
        alignItems: 'center',
        '&:hover': {
        background: '#2D4884',
        opacity: '0.96',
        },
        '&>span': {
        fontSize: '0.875rem',
        fontWeight: '700',
        color: '#FFF',
        lineHeight: '14px',
        },
    },
    quantitySolicitacoes: {
        background: '#717FFC',
        width: '16px',
        height: '16px',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        borderRadius: '16px',
        display: 'flex',
        gap: '10px', 
        alignItems: 'center',
        justifyContent: 'center',
        padding: '2px',
        marginLeft: '6px',
        color: '#FFF',
        fontSize: '10px',
        fontWeight: '700',
    },
    contentTab: {
        display: 'grid',
        background: '#fff',
        margin: '16px 110px 16px 16px',
        borderRadius: '16px',
        padding: '14px',
        overflow: 'auto',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        gridTemplateRows: '1fr auto',
    },
    contentTabFiltrado: {
        width: 'calc(100% - 465px)',
    },
    contentTabOdonto: {
        gridTemplateRows: '32px 1fr auto',
    },
    contentTabSelectedOdontograma: {
        margin: '16px 62px 16px 16px',
    },
    buttonsTab: {
        position: 'sticky',
        top: 0,
        maxHeight: '32px',
    },
    switchWithLabel: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 14px',
        margin: '10px 0 24px 0',
    },

    switchLabel: {
        fontSize: '12px !important',
        color: '#868686'
    }

});

export default prontuarioAtendimentoStyle;
