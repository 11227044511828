import React from "react";
import { inject, observer } from "mobx-react";
import { Grid, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles/index";
import moment from "moment";
import styles from "./RelatorioAgendamentosSituacaoStyle";
import ErrorCollector from "../../../utils/ErrorCollector";
import Table from "../../../components/Table/Table";
import Api from "../../../config/api";
import Scroll from "../../../components/InfiniteScroll/Scroll";
import { DownloadIcon } from "../../../assets/img/svg";
import { base64Convert } from "../../../utils/base64ToCsv";
import {
  getProfissionalSaudeLogado,
  permiteVerAgendaOutrosProfissionais,
  acessoFinanceiro
} from "../../../services/UsuarioService";
import HeaderRelatorio, { periodos } from "../HeaderRelatorio";
import ButtonYellow from "../../../components/Button/ButtonYellow";
import { formattedPhone } from "../../../components/Input/TelefoneFormatado";
import PageTitle from "../../../components/PageTitle/PageTitle";
import PopperCheck from "../../../components/Popper/PopperCheck";
import { situacoes } from "../../../utils/constants/situacoes";
import { findByUnidadeLogadaConfiguracaoUnidadeConfirmaPresencaAgendamento } from "../../../services/UnidadeService";
import { relatorioAgendamentosSituacao, valorConsultaAndProcedimento } from "../../../services/RelatorioService";
import { InputDateForm } from '../../../components/Modal/Input';
import { Button } from "../../../components/ui/Buttons";
import ImpressaoHtml from "../../../components/Impressao/ImpressaoHtml";
import PrintIcon from "../../../components/Icon/Print";
import AgendamentosPorSituacao from "../../../template/pdf/relatorio/AgendamentosPorSituacao";
import ModalAuditoria from "./ModalAuditoria";
import { applyCurrencyMask } from "../../../utils/CurrencyMask";
import Notification from "../../../components/Notification";

const modosConfirmacao = {
  DOCUMENTO: "Confirmado",
  EMAIL: "Confirmado",
  NAO_COMPARECI: "Não compareceu",
  TELEFONE: "Confirmado",
};

const color = {
  Confirmado: {
    backgroundColor: "#E1F3F4",
    borderColor: "#007F79",
  },
  Aguardando: {
    backgroundColor: "#E5F3FC",
    borderColor: "#3B7ECE",
  },
};

const columns = ({ utilizaConfirmaPresenca, permissaoFinanceiro, utilizaTelefoneInternacional }) => [
  utilizaConfirmaPresenca && {
    Header: "Confirmação de Presença",
    getValue: (agendamento) => {
      const label =
        modosConfirmacao[agendamento.modoConfirmacao] || "Aguardando";
      const colorLabel = color[label];
      const { borderColor, backgroundColor } = colorLabel || {};

      const styleContent = {
        display: "flex",
        alignItems: "center",
        border: `1px solid ${borderColor || "#BF3028"}`,
        backgroundColor: backgroundColor || "#FCEDEF",
        borderRadius: "8px",
        width: "130px",
        height: "29px",
        justifyContent: "center",
      };

      return <div style={styleContent}> {label} </div>;
    },
  },
  {
    Header: "Nome",
    field: "nome",
    getValue: (agendamento) => {
      return agendamento?.sujeitoAtencao?.nome || agendamento?.nome;
    },
  },
  {
    Header: "Data",
    field: "data",
    getValue: (agendamento) => {
      return moment(agendamento.data).format("DD/MM/YYYY");
    },
  },
  {
    Header: "Horário",
    field: "horaInicio",
    getValue: (agendamento) => {
      return `${agendamento.horaInicio} - ${agendamento.horaFim}`;
    },
  },
  {
    Header: "telefone",
    getValue: (agendamento) => {
      const { telefone, telefoneDDI } = agendamento;

      return formattedPhone({telefone, telefoneDDI, utilizaTelefoneInternacional})
    },
  },
  {
    Header: "Profissional da saúde",
    field: "profissionalSaude.nome",
    getValue: (agendamento) => {
      return agendamento.profissionalSaude?.nome;
    },
  },
  {
    Header: "Convênio",
    field: "convenio.descricao",
    getValue: (agendamento) => {
      return agendamento.convenio?.descricao;
    },
    field: "convenio",
  },
  {
    Header: "Tipo",
    getValue: (agendamento) => {
      return agendamento?.tipo?.descricao;
    },
  },
  permissaoFinanceiro && 
  {
    Header: "Valor",
    getValue: (agendamento) => {
      const { convenio } = agendamento || {};
      const valor = convenio?.valorConsulta || 0;

      return valor.toLocaleString("pt-br", { style: "currency", currency: "BRL" });    },
  },
  {
    Header: "Procedimento(s)",
    getValue: (agendamento) => {
      return agendamento.procedimento?.procedimentos || '';
    },
  },
  permissaoFinanceiro && 
  {
    Header: "Valor procedimentos",
    getValue: (agendamento) => {
      const { procedimmento } = agendamento || {};
      const valor = procedimmento?.valorProcedimentos || 0;

      return valor.toLocaleString("pt-br", { style: "currency", currency: "BRL" })
    },
  },
];

const firstDayOfMonth = moment()
  .startOf("month")
  .format("YYYY-MM-DD");
const lastDayOfMonth = moment()
  .endOf("month")
  .format("YYYY-MM-DD");

@inject(
  "atendimentoStore",
  "googleAnalyticsStore",
  "profissionalSaudeStore",
  "unidadeStore",
  "relatorioStore"
)
@observer
class RelatorioAgendamentosSituacao extends React.Component {
  constructor() {
    super();
    this.state = {
      loadingCsv: false,
      agendamentos: [],
      dataInicial: firstDayOfMonth,
      dataFinal: lastDayOfMonth,
      nome: "",
      convenio: "",
      procedimento: null,
      profissionalSaude: null,
      tipos: [],
      sala: null,
      statusFinanceiro: null,
      filtroProfissionais: false,
      criacao: null,
      situacoes: situacoes.map((item) => {
        return item.value === "ATENDIDO"
          ? { ...item, checked: true }
          : { ...item, checked: false };
      }),
      lastScroll: false,
      pageNumberScroll: 0,
      isLoading: false,
      periodoSelecionado: periodos[2],
      anchorEl: null,
      totalAgendamentos: 0,
      dadosRelatorio: [],
      isPrintMustache: false,
      agendamentoSelected: null,
      openModal: false,
      sortFields: [
        {campo: "data", direcao: "DESC"},
        {campo: "horaInicio", direcao: "ASC"}
      ],
      isDefaultSort: true,
      permissaoFinanceiro: false,
    };
  }

  utilizaConfirmaPresenca = false;
  status = [];

  valoresHeader = [
    {
      title: "Valor tipo de consulta",
      titlePrint: "Tipo de consulta",
      label: "R$ 0,00",
    },
    {
      title: "Valor procedimentos",
      titlePrint: "Procedimentos",
      label: "R$ 0,00",
    },
  ];

  async componentDidMount() {
    try {
      this.status = this.state.situacoes
        .filter((item) => !item.checked)
        .map((item) => item.value);
      await this.verificaPermissoes();
      await this.consultaAgendamento();
      this.utilizaConfirmaPresenca = await this.verificaConfirmacaoPresenca();
      const permissaoFinanceiro = await acessoFinanceiro();
      this.setState({ permissaoFinanceiro });
    } catch (error) {
      throw error;
    }

    const paginaVisualizada = {
      page: window.location.hash,
      title: "Relatórios - Agendamentos por situação",
    };
    this.props.googleAnalyticsStore.pageView(paginaVisualizada);
  }

  verificaConfirmacaoPresenca = async () => {
    return findByUnidadeLogadaConfiguracaoUnidadeConfirmaPresencaAgendamento();
  };

  verificaPermissoes = async () => {
    if (await permiteVerAgendaOutrosProfissionais()) {
      this.setState({ filtroProfissionais: true });
    } else {
      const profissionalSaudeAtual = await getProfissionalSaudeLogado();
      this.setState({
        filtroProfissionais: false,
        profissionalSaude: {
          id: profissionalSaudeAtual.id,
          nome: profissionalSaudeAtual.nome,
        },
      });
    }
  };

  handleDateChange = (e, field) => {
    const dataFormatada = e && moment(e).format("YYYY-MM-DD");

		this.setState({
			[field]: dataFormatada
		});
  };

  handleChangeTipo = (e) => {
    this.setState({ tipos: e });
  };

  handleChangeProcedimento = (e) => {
    this.setState({ procedimento: e });
  };

  handleNomeChange = (e) => {
    this.setState({ nome: e.target.value });
  };

  onKeypressSearch = (e) => {
    if (e.key === "Enter") {
      this.search();
    }
  };

  onClickSearch = () => {
    this.search();
  };

  handleClickClearPesquisar = () => {
    this.setState({ nome: "" });
    this.search();
  };

  handleChangeProfissionalSaude = (profissionalSaude) => {
    this.setState({ profissionalSaude });
  };

  handleChangeSalas = (e) => {
    this.setState({ sala: e });
  };

  handleChangeStatusFinanceiro = (e) => {
    this.setState({ statusFinanceiro: e });
  };

  handleConvenioChange = (e) => {
    this.setState({ convenio: e });
  };

  handleChangeCriacao = (e) => {
    this.setState({ criacao: e });
  };

  changeCheckbox = (e, index) => {
    let situacoes = this.state.situacoes;
    let actualState = situacoes[index].checked;
    situacoes[index].checked = !actualState;
    this.setState({ situacoes });
    this.status = this.state.situacoes
      .filter((value) => !value.checked)
      .map(({ value }) => value);
  };

  consultaAgendamento = async () => {
    try {
      this.setState({ isLoading: true });

      const { tipos, statusFinanceiro, criacao } = this.state;

      const { sortFields } = this.state;
      const tiposId = tipos.length > 0 ? tipos.map((item) => item.id) : null;

      const dataInicial = this.state.dataInicial;
      const dataFinal = this.state.dataFinal;
      const nome = this.state.nome ? this.state.nome : null;
      const convenioId = this.state.convenio ? this.state.convenio.id : null;
      const situacoes = this.status ? this.status : null;
      const profissionalId = this.state.profissionalSaude?.id;
      const salaId = this.state.sala ? this.state.sala.id : null;

      const variables = {
        search: {
          situacoes: situacoes,
          dataInicial: dataInicial,
          dataFinal: dataFinal,
          search: nome,
          convenioId: convenioId,
          profissionalSaudeId: profissionalId,
          salaId: salaId,
          tiposId,
          procedimentoId: this.state.procedimento?.id || null,
          ...( statusFinanceiro && { statusFinanceiro: statusFinanceiro.value }),
        },
        ...(criacao?.id && { criacao: criacao.id }),
        pageableDTO: {
          pageSize: 30,
          pageNumber: this.state.pageNumberScroll,
          sortFields,
        },
      };

      const response = await relatorioAgendamentosSituacao(variables);

      const { content, last, totalElements } = response;
      this.setState({
        lastScroll: last,
        agendamentos: [...this.state.agendamentos, ...content],
        totalAgendamentos: totalElements,
      });

      const dadosHeader = await valorConsultaAndProcedimento(variables);
      this.valoresHeader = [
        {
          title: "Valor tipo de consulta",
          titlePrint: "Tipo de consulta",
          label: applyCurrencyMask(dadosHeader.valorTotalConsultas),
        },
        {
          title: "Valor procedimentos",
          titlePrint: "Procedimentos",
          label: applyCurrencyMask(dadosHeader.valorTotalProcedimentos),
        },
      ]

    } catch (error) {
      return [];
    } finally {
      this.setState({ isLoading: false });
    }
  };

  consultaAgendamentoCsv = async () => {
    if (!this.state.loadingCsv) {
      try {
        this.setState({ loadingCsv: true });
        const { sortFields, statusFinanceiro, procedimento, criacao } = this.state;

        const dataInicial = this.state.dataInicial;
        const dataFinal = this.state.dataFinal;
        const nome = this.state.nome ? this.state.nome : null;
        const convenioId = this.state.convenio ? this.state.convenio.id : null;
        const situacoes = this.status ? this.status : null;
        const profissionalId = this.state.profissionalSaude?.id;
        const salaId = this.state.sala ? this.state.sala.id : null;
        const tiposId = this.state.tipos.map((item) => item.id);

        const query = this.state.permissaoFinanceiro ? "relatorioAgendamentosSituacaoCsv" : "relatorioAgendamentosSituacaoSimplesCsv";

        const response = await Api.post("", {
          query: `
					query($search: SearchAgendamentoDTOInput, $pageableDTO: PageableDTOInput, $criacao: Long) {
						relatorioAgendamentosSituacaoCsv: ${query}(search: $search, pageableDTO: $pageableDTO, criacao: $criacao)
					}`,
          variables: {
            search: {
              situacoes: situacoes,
              dataInicial: dataInicial,
              dataFinal: dataFinal,
              search: nome,
              convenioId: convenioId,
              profissionalSaudeId: profissionalId,
              salaId: salaId,
              ...(procedimento?.id && { procedimentoId: procedimento?.id }),
              ...( statusFinanceiro && { statusFinanceiro: statusFinanceiro.value }),
              ...(tiposId.length > 0 && { tiposId }),
            },
            ...(criacao?.id && { criacao: criacao.id }),
            pageableDTO: {
              sortFields
            },
          },
        });

        const relatorioAgendamentoCsv =
          response.data.data.relatorioAgendamentosSituacaoCsv;
        this.setState({ loadingCsv: false });
        return relatorioAgendamentoCsv;
      } catch (error) {
        this.setState({ loadingCsv: false });
        return [];
      }
    } else {
      return "error";
    }
  };

  getCsv = async () => {
    const { relatorioStore } = this.props;
    const { dataInicial, dataFinal } = this.state;

    if(!relatorioStore.verificaLimiteTrintaDias(dataInicial, dataFinal)){
      relatorioStore.openNotification("O limite de sessenta dias foi excedido", "error");
      return;
    }

    const base64 = await this.consultaAgendamentoCsv();
    if (base64 !== "error") {
      const blob = base64Convert(base64);
      const url = window.URL.createObjectURL(blob);
      var link = document.createElement("a");
      link.setAttribute("href", url);
      link.setAttribute("download", "relatorioAgendamentosSituacao.csv");
      document.body.appendChild(link);
      link.click();
    }
  };

  print = async () => {
    const { relatorioStore } = this.props;
    try {
      this.setState({isLoading: true})
      
      const {
        dataInicial,
        dataFinal,
        nome,
        convenio,
        profissionalSaude,
        sala,
        tipos,
        totalAgendamentos,
        sortFields,
        statusFinanceiro,
        procedimento,
        criacao,
      } = this.state;

      if(!relatorioStore.verificaLimiteTrintaDias(dataInicial, dataFinal)){
        relatorioStore.openNotification("O limite de sessenta dias foi excedido", "error");
        return;
      }
  
      const tiposId = tipos.map(item => item.id);
  
      const variables = {
        search: {
          situacoes: this.status || null,
          dataInicial: dataInicial,
          dataFinal: dataFinal,
          search: nome || null,
          convenioId: convenio?.id || null,
          profissionalSaudeId: profissionalSaude?.id,
          salaId: sala?.id || null,
          ...(procedimento?.id && { procedimentoId: procedimento?.id }),
          ...(statusFinanceiro && { statusFinanceiro: statusFinanceiro.value }),
          ...(tiposId.length > 0 && { tiposId }), 
        },
        ...(criacao?.id && { criacao: criacao.id }),
        pageableDTO: {
          pageSize: totalAgendamentos,
          pageNumber: 0,
          sortFields,
        },
      };
  
      const dadosRelatorio = await relatorioAgendamentosSituacao(variables);
    
      this.setState({ dadosRelatorio: dadosRelatorio?.content }, () => {
          this.handlePrintRelatorio();
      });

    } catch (error) {
      relatorioStore.openNotification("Erro ao imprimir o relatório", "error");

    } finally {
      this.setState({ isLoading: false });
    }
  };

  handlePrintRelatorio = () => {
    this.setState({ isPrintMustache: true, isLoading: false });
  }

  search = async () => {
    const { relatorioStore } = this.props;
    const { dataInicial, dataFinal } = this.state;

    if(!relatorioStore.verificaLimiteTrintaDias(dataInicial, dataFinal)){
      relatorioStore.openNotification("O limite de sessenta dias foi excedido", "error");
      return;
    }

    this.setState({
      agendamentos: [],
      pageNumberScroll: 0,
    });

    await this.consultaAgendamento();
  };

  redefinirFiltros = () => {
    this.setState({
      dataInicial: firstDayOfMonth,
      dataFinal: lastDayOfMonth,
      convenio: null,
      profissionailSaude: null,
      tipo: "",
      sala: null,
    });
  };

  loadMore = async () => {
    const { isLoading, agendamentos, pageNumberScroll } = this.state;

    if (isLoading) {
      return;
    }

    const pageNumber = agendamentos.length > 0 ? pageNumberScroll + 1 : 0;

    this.setState({
      pageNumberScroll: pageNumber,
    });

    await this.consultaAgendamento();
  };

  handlePeriodoSelecionado = (value) => {
    const { dataInicial, dataFinal } = value;

    this.setState(
      {
        periodoSelecionado: value,
        dataInicial: dataInicial,
        dataFinal: dataFinal,
      },
    );
  };

  handleClickSituacao = (event) => {
    const { currentTarget } = event;
    this.setState((state) => ({
      anchorEl: state.anchorEl ? null : currentTarget,
    }));
  };

  quantidadeChecked = () => {
    const { situacoes } = this.state;

    const situacoesChecked = situacoes.filter((item) => item.checked);
    const quantidadeChecked = situacoesChecked.length;

    const quantidade = {
      [situacoes.length]: "Todos",
      0: "Selecione",
    };

    return quantidade[quantidadeChecked] || `${quantidadeChecked} selecionados`;
  };

  handleClickOpen = (agendamento) => {
    this.setState({ 
      openModal: true,
      agendamentoSelected: agendamento,
    });
  };

  handleClickOrdenar = (value) => {
    const { sortFields } = this.state;
    const sortFieldsIndex = sortFields.findIndex(({campo})=> campo === value)
    if(value === "data" || value === "horaInicio"){
      const direcaoAtual = sortFields[sortFieldsIndex].direcao
      sortFields[sortFieldsIndex].direcao = direcaoAtual === "ASC"? "DESC" : "ASC"
      sortFields.length>2 && sortFields.splice(0, 1)
      this.search()
      return
    }
    if(sortFieldsIndex + 1 !== 0){
      const direcaoAtual = sortFields[sortFieldsIndex].direcao
       direcaoAtual === "ASC"? 
       sortFields[sortFieldsIndex].direcao = "DESC" :  
       sortFields.splice(sortFieldsIndex, 1)
    }
    else{
      sortFields.length > 2 && sortFields.splice(0, 1)
      sortFields.unshift(
        {
          campo: value,
          direcao: "ASC"
        }
      )
    }
    this.search()
   
  };

  render() {
    const { classes, relatorioStore } = this.props;
    const {
      nome,
      convenio,
      sala,
      tipos,
      periodoSelecionado,
      isLoading,
      agendamentos,
      procedimento,
      situacoes,
      statusFinanceiro,
      criacao,
      profissionalSaude,
      totalAgendamentos,
      dadosRelatorio,
      agendamentoSelected,
      openModal,
      dataInicial,
      dataFinal,
    } = this.state;
  
    const functionsFilters = {
      handleDateChange: this.handleDateChange,
      handleConvenioChange: this.handleConvenioChange,
      handleChangeProfissionalSaude: this.handleChangeProfissionalSaude,
      handleChangeTipo: this.handleChangeTipo,
      handleChangeProcedimento: this.handleChangeProcedimento,
      handleChangeSalas: this.handleChangeSalas,
      handleChangeStatusFinanceiro: this.handleChangeStatusFinanceiro,
      handleSearch: this.search,
      redefinirFiltros: this.redefinirFiltros,
      handleChangeCriacao: this.handleChangeCriacao,
    };
    const valueFilters = {
      convenio,
      profissionalSaude,
      tipos,
      sala,
      procedimento,
      statusFinanceiro,
      criacao,
    };

    const filtersPrint = {
      dataInicial,
      dataFinal,
      nome,
      convenio: convenio?.descricao || "",
      profissionalSaude: profissionalSaude?.nome || "",
      sala: sala?.nome || "",
      tipos: tipos || [],
      ...( statusFinanceiro && { statusFinanceiro: statusFinanceiro.value }),
    };

    const possuiDatas = dataInicial && dataFinal;

    return (
      <div className={classes.content}>
        <PageTitle title="Relatórios - Agendamentos por situação" />
        <HeaderRelatorio
          handlePeriodo={this.handlePeriodoSelecionado}
          search={this.onClickSearch}
          clearSearch={this.handleClickClearPesquisar}
          value={nome}
          onChange={this.handleNomeChange}
          onKeyPress={this.onKeypressSearch}
          periodoSelecionado={periodoSelecionado}
          title="Agendamento por situação"
          screen="AgendamentoPorSituacao"
          totalTitle="agendamentos"
          searchRelatorioAgendamentoPorSituacao={this.search}
          classes={{
            paper: classes.paper,
          }}
          functionsFilters={functionsFilters}
          valueFilters={valueFilters}
          filtroProfissionais={this.state.filtroProfissionais}
          totalAgendamentos={totalAgendamentos}
          infosHeader={this.state.permissaoFinanceiro && this.valoresHeader}
        >
        </HeaderRelatorio>
          <div className={classes.contentFiltros}>
            <div className={classes.filtros}>
              <Grid item xs={3} className={classes.spacingConvenio}>
                <span className={classes.tituloFiltros}> Data início: </span>
                <InputDateForm
                  iconposition="end"
                  openTo="day"
                  views={["year", "month"]}
                  value={this.state.dataInicial}
                  classes={{
                    input: classes.inputData,
                  }}
                  onChange={(e) => this.handleDateChange(e, "dataInicial")}
                />
              </Grid>
              <Grid item xs={3} className={classes.spacingConvenio}>
                <span className={classes.tituloFiltros}> Data fim: </span>
                <InputDateForm
                  iconposition="end"
                  openTo="day"
                  views={["year", "month"]}
                  value={this.state.dataFinal}
                  classes={{
                    input: classes.inputData,
                  }}
                  onChange={(e) => this.handleDateChange(e, "dataFinal")}
                  minDate={this.state.dataInicial}
                />
              </Grid>
              <div className={classes.contentSituacao}>
                <span className={classes.tituloFiltros}> Situação: </span>
                <PopperCheck
                  options={situacoes}
                  changeCheckbox={this.changeCheckbox}
                  label={this.quantidadeChecked()}
                />
              </div>
            </div>
            <Button
              onClick={this.search}
              bgColor='#707C97'
              disabled={isLoading || !possuiDatas}
            >
              Filtrar
            </Button>
        </div>
        <ErrorCollector mensagem={"Não foi possível carregar o relatório!"}>
          <div className={classes.listaAgendamentoSituacao}>
            <Scroll
              loadMore={this.loadMore}
              hasMore={!this.state.lastScroll}
              pageStart={0}
              initialLoad={false}
              className={classes.scrollContainerModelos}
            >
              {agendamentos.length === 0 && !isLoading && (
                <div className={classes.notFoundContainer}>
                  <h3>Nenhum item encontrado</h3>
                </div>
              )}

              {agendamentos.length > 0 && (
                <Table
                  className={classes.table}
                  dados={this.state.agendamentos}
                  columns={columns({
                    utilizaConfirmaPresenca: this.utilizaConfirmaPresenca,
                    permissaoFinanceiro: this.state.permissaoFinanceiro,
                    utilizaTelefoneInternacional: relatorioStore.configuracaoUnidade?.utilizaTelefoneInternacional
                  })}
                  sortFields={this.state.sortFields}
                  comOrdenacao
                  ordenarTabela={this.state.ordenarTabela}
                  handleClickOrdenar={this.handleClickOrdenar}
                  clickable={true}
                  getDadosRow={this.handleClickOpen}
                />
              )}

              {isLoading && (
                <Grid
                  container
                  justify="center"
                  alignItems="center"
                  style={{ height: "100%", marginTop: 20 }}
                >
                  <CircularProgress size={30} />
                </Grid>
              )}

              {openModal && (
                <ModalAuditoria
                  open={openModal}
                  onClose={() => this.setState({ openModal: false })}
                  agendamentoId={agendamentoSelected.id}
                  nomePaciente={agendamentoSelected.paciente?.nome || agendamentoSelected.sujeitoAtencao?.nome || agendamentoSelected.nome}
                />
              )}
            </Scroll>
          </div>
        </ErrorCollector>
        <Notification
          close={relatorioStore.closeNotification}
          reset={relatorioStore.closeNotification}
          isOpen={relatorioStore.notification.isOpen}
          variant={relatorioStore.notification.variant}
          message={relatorioStore.notification.message}
        />
        <div className={classes.buttonsDownloadPrint}>
          <Button
            shape='circle'
            bgColor='#F9BE73'
            onClick={() => this.print()}
            disabled={isLoading}
          >
            <PrintIcon />
          </Button>
          <ButtonYellow
            id="exportCsv"
            className={classes.buttonImprimir}
            onClick={() => this.getCsv()}
            disabled={isLoading}
          >
            <img src={DownloadIcon} alt="Download" />
          </ButtonYellow>
        </div>
        { this.state.isPrintMustache && <ImpressaoHtml
          isPrintMustache={this.state.isPrintMustache}
          handlePrintMustache={() => {this.setState({ isPrintMustache: false })}}
          htmlStringComponent={
              <AgendamentosPorSituacao 
                permissaoFinanceiro={this.state.permissaoFinanceiro}
                dadosRelatorio={dadosRelatorio || []}
                filters={filtersPrint}
                totalAgendamentos={totalAgendamentos}
                valores={this.valoresHeader}
              />
          }
        />}
      </div>
    );
  }
}

export default withStyles(styles)(RelatorioAgendamentosSituacao);
