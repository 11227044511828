import Colors from '../../../template/Colors'

export const styles = {
  root: {
    position: 'relative',
    overflow: 'none',
    zIndex: 1,
  },
  paper: {
    borderRadius: 15,
    overflow: 'unset',
    height: 630,
    display: 'flex',
    flexDirection: 'row',
  },
  paperFullWidth: {
    borderRadius: 15,
    overflow: 'unset',
    height: 630,
    display: 'flex',
    flexDirection: 'row',
    maxWidth: '1280px',
    backgroundColor: 'transparent',
  },
  dialogHeader: {
    background: 'white',
    padding: '24px 20px',
    borderTopLeftRadius: '15px',
  },
  dialogHeaderContent: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  HeaderborderRadius: {
    borderRadius: '15px',
  },
  headerButtons: {
    display: 'flex',
    flexDirection: 'row',
  },
  dialogTitle: {
    display: 'flex',
    flexDirection: 'row',
    '&>h1': {
      color: '#26ACA9',
      fontSize: '24px',
      marginTop: '6px',
      marginRight: '20px',
    },
  },
  dialogContentBorder: {
    backgroundColor: '#E0E0E0',
    borderRadius: '0 0 0 15px',
    padding: '0 15px 15px 15px',
  },
  semLateralSugestoes: {
    backgroundColor: '#E0E0E0',
    borderRadius: '0 0 15px 15px',
    padding: '0 15px 15px 15px',
  },
  dialogContent: {
    overflowY: 'scroll',
    margin: 0,
    backgroundColor: 'white',
    boxShadow: 'inset 0px 11px 38px -15px rgba(0,0,0,0.07);',
    height: '100%',
    borderRadius: '0 0 15px 15px',
    width: '100%',
  },
  dialogActions: {
    justifyContent: 'flex-start',
    padding: '20px 24px 24px',
    margin: 0,
    zIndex: 1,
  },
  buttonActionClose: {
    backgroundColor: Colors.commons.red,
    width: '40px',
    height: '40px',
    color: 'white',
    marginLeft: 10,
    '&:hover': {
      background: Colors.commons.darkRed,
    },
  },
  buttonActionMinimize: {
    backgroundColor: Colors.commons.gray10,
    width: '40px',
    height: '40px',
    color: 'white',
    marginLeft: 10,
    '&:hover': {
      background: Colors.commons.gray10Dark,
    },
  },
  buttonSave: {
    backgroundColor: Colors.commons.secondary,
    width: '40px',
    height: '40px',
    color: 'white',
    marginLeft: 10,
    '&:hover': {
      background: Colors.primary.dark,
    },
  },
  buttonCopy: {
    width: '40px',
    height: '40px',
    color: 'white',
    marginLeft: 10,
  },
  spanSeparator: {
    margin: '0px 60px',
    borderBottom: '1px solid #E0E0E0',
    width: 'auto',
    display: 'block',
  },
  receita: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  lateral: {
    display: 'flex',
    flexDirection: 'column',
    background: '#26ACA9',
    width: '332px',
    borderRadius: '0 15px 15px 0',
    alignItems: 'center',
  },
  botoesSugestoesModelosProntos: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: 'auto',
    fontWeight: 'normal !important',
    background: 'rgba(0, 0, 0, 0.1)',
    '&.active': {
      background: 'rgba(0, 0, 0, 0.5)',
    },
  },
  itemList: {
    fontSize: '12px !important',
    fontFamily: 'Poppins !important',
  },
  radioButton: {
    margin: 24,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '26vw',
    maxWidth: '284px',
  },
  tituloSugestao: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: 'auto',
    fontWeight: 'normal !important',
    background: 'rgba(0, 0, 0, 0.5)',
    borderRadius: 87,
    color: '#fff',
    width: 130,
    height: 32,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '4px 12px',
  },
  lista: {
    textAlign: '-webkit-center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
  },
  labelSaveIcon: {
    textTransform: 'capitalize',
  },
  rowModelo: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    height: 58,
    marginTop: 10,
  },
  label: {
    fontFamily: 'Poppins !important',
    fontWeight: 'bold',
  },
  inputModelo: {
    fontFamily: 'Poppins!important',
    border: '0',
    fontSize: '14px',
    minHeight: '6px',
    height: '18px',
    color: Colors.commons.fontColor,
    backgroundColor: Colors.commons.gray2,
    margin: '0 0 0 0',
    padding: '9px 0px 5px 10px',
    borderRadius: '100px',
    width: '100%',
    marginRight: 10,
  },
  inputNomeModelo: {
    width: '100%',
  },
  oftalmologicaForm: {
    padding: '30px',
  },
  downloadIconLink: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fieldObservacao: {
    color: '#868686',
    fontSize: '12px !important',
    fontWeight: '400',
     margin: '16px 0 0 16px',
  },
  input: {
    border: '1px solid rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    alignItems: 'start',
    padding: '6px',
    width: 'calc(100% - 40px)',
    marginLeft: '16px',

    "&::before": {
      content:'none',
    },
  },
  containerRecipes: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  listagemRecipes: {
    height: 'calc(100% - 100px)',
  },
  listagemRecipesDynamic: {
    height: 'auto',
  },
  containerObservacao: {
    position: 'absolute',
    bottom: 32,
    width: 'calc(100% - 312px)',
  },
  containerObservacaoDynamic: {
    position: 'relative',
    width: '100%',
  },
  containerObservacaoWithDadosImportantes: {
    width: 'calc(100% - 622px)',
  },
  containerObservacaoReadOnly: {
    width: 'calc(100% - 38px)',
  },
}
