import React, { useEffect, useState } from "react"
import Dialog from "../../Dialog/Dialog";
import { CircularProgress, Fab, withStyles } from "@material-ui/core";
import Button from "../../Button/Button";
import Table from "../../../components/Table/Table";
import { getColumnsVigenciaProcedimento } from "./utils/getColumnsVigenciaProcedimento";
import HeaderModal from "../../../pages/Atendimento/Tabs/Odontograma/Components/HeaderModal";
import InputDateForm from "../../Input/InputDateForm";
import InputMaskCurrency from "../../Input/InputMaskCurrency";
import AddIcon from "../../Icon/AddIcon";
import moment from "moment";
import Notification from "../../Notification";
import { findValorByConvenioAndProcedimento, modifyValorByConvenioAndProcedimento } from "../../../services/ConvenioService";
import string from "../../../utils/string";

const ModalConfigVigencia = ({ classes, open, convenio, handleClose, procedimentoSelected, title , findAllDados}) => {
  const novaVigenciaDefault = {
    disabled: false,
     id: null,
     dataFimVigencia: null,
    dataInicioVigencia: null 
  }

  const [novaVigencia, setNovaVigencia] = useState({...novaVigenciaDefault})
  const [notification, setNotification] = useState({ isOpen: false, variant: "", message: "" })
  const [dadosVigencia, setDadosVigencia] = useState([])
  const [variablesQuery, setVariables] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [loadingVigencias,setloadingVigencias]=useState(true)
  const [excluidos,setExcluidos]=useState([])

  useEffect(() => {
    findAllValorByConvenioAndProcedimento()
    setVariables({ 
      procedimento: { 
        id: procedimentoSelected.id, 
      }, 
      convenio: { 
        id: convenio.id 
      } 
    })

  }, [])


  const findAllValorByConvenioAndProcedimento = async () => {
    try{
      setloadingVigencias(true)
      const variables = {
        procedimento: {
          id: procedimentoSelected.id
        },
        convenio: {
          id: convenio.id
        }
      }
      const response = await findValorByConvenioAndProcedimento(variables)
      const { valores } = response
      const vigencias = valores.map(item => ({ ...item, disabled: true }))
      setDadosVigencia(vigencias)
    }catch(e){
      console.error(e)
    }finally{
      setloadingVigencias(false)
    }
  }

  const handleClickEditar = (item, index) => {
    const IsEditavel = dadosVigencia.map((obj, i) => (
      {...obj,
        disabled: i !== index 
      }
    ))
    setDadosVigencia(IsEditavel)
  }

  const verificarSobreposicoes = (intervaloDatasVigencias, vigencia) => {
    try {
      const validacaoIntervalo = intervaloDatasVigencias.some(element => {

        const dataFimElement = element.dataFim ? moment(element.dataFim) : moment(element.dataInicio).add(1, "years");
        const dataFimNovaVigencia = vigencia.dataFimVigencia ? moment(vigencia.dataFimVigencia) : moment(vigencia.dataInicioVigencia).add(1, "years");

        if (!moment(element.dataInicio).isValid() || !moment(vigencia.dataInicioVigencia).isValid()) {
          console.error("Datas inválidas encontradas", element.dataInicio, vigencia.dataInicioVigencia);
          throw new Error("Data inválida encontrada.");
        }

        const sobrepoe = moment(element.dataInicio).isSameOrBefore(dataFimNovaVigencia) &&
          moment(vigencia.dataInicioVigencia).isSameOrBefore(dataFimElement);

        return sobrepoe;
      });

      return validacaoIntervalo;

    } catch (e) {
      console.error("Erro ao verificar sobreposições:", e);
      return false;  
    }

  };

  const validar = (intervaloDatasVigencias) => {

    for (let i = 0; i < intervaloDatasVigencias.length; i++) {
      for (let j = i + 1; j < intervaloDatasVigencias.length; j++) {

        const vigencia = {
          dataInicioVigencia: intervaloDatasVigencias[j].dataInicio,
          dataFimVigencia: intervaloDatasVigencias[j].dataFim
        };

        if (verificarSobreposicoes([intervaloDatasVigencias[i]], vigencia)) {
          return true;  
        }
      }
    }

    return false;
  }

  const handleClickSalvar = async () => {
    setIsLoading(true)
    const vigencias = [...dadosVigencia]
    const intervaloDatasVigencias = vigencias.map((item) => ({ dataInicio: (item.dataInicioVigencia), dataFim: (item.dataFimVigencia) }))

    if (validar(intervaloDatasVigencias)) {
      setNotification({ isOpen: true, message: `O intervalo de datas se sobrepõe,verifique as datas e tente novamente!`, variant: "error" })
      setIsLoading(false)
      return
    } else {

      try {
        const variables = {
          convenioProcedimento: {
            valores: vigencias.map(({ disabled, index, valor, ...rest }) => {
              const valorComoString = typeof valor === 'number' ? String(valor) : valor;
              const valorSemPontos = valorComoString.includes(".") ? valorComoString.replace(/\./g, ",") : valorComoString;
      
              return {
                ...rest,
                valor: valorSemPontos.includes(",")
                  ? parseFloat(valorSemPontos.replace(",", ".")).toFixed(2) 
                  : parseFloat(valorSemPontos).toFixed(2), 
              };
            }),
            ...variablesQuery,
          }
        };


        await modifyValorByConvenioAndProcedimento({ variables })
        setNotification({ isOpen: true, message: "Vigencia salva com sucesso!", variant: "success" })
      } catch (e) {
        setNotification({ isOpen: true, message: "Erro ao salvar a vigencia!", variant: "error" })
      } finally {
        setIsLoading(false)
        findAllDados()
      }
    }
  }

  const onChange = (value, field, index) => {
    let dados = [...dadosVigencia]
    let valueFormated = value

    if (field === "dataInicioVigencia" || field === "dataFimVigencia") {

      if (!valueFormated) {

        valueFormated = "";
      } else {
        valueFormated = moment(valueFormated).format("YYYY-MM-DD");
      }
    }
    if (field === "valor") {
      valueFormated = valueFormated.replace(/[R$.\s]/g, "");

    }

    const dadosAlterados = {
      ...dadosVigencia[index],
      [field]: valueFormated,
      index: index,
    }

    dados[index] = dadosAlterados

    setDadosVigencia(dados)
  }

  const handleClickExcluir = (index, data) => {
    const dados = [...dadosVigencia]
    const novoItemExcluido = dados.filter((item, i) => i === index)
    setExcluidos([...excluidos, novoItemExcluido])
    const vigencias = dados.filter((_, i) => i !== index);
    setDadosVigencia(vigencias)
  }

  const handleClickAdicionar = () => {
    const vigencias = [...dadosVigencia]
    const intervaloDatasVigencias = vigencias.map((item) => ({ dataInicio: (item.dataInicioVigencia), dataFim: (item.dataFimVigencia) }))

    const dataFimMenor = moment(novaVigencia.dataFimVigencia) < moment(novaVigencia.dataInicioVigencia)
    
    if(dataFimMenor){
      
      setNotification({ isOpen: true, message: `A data final da vigencia deve ser maior que a data inicial!`, variant: "error" })
      return
    }

    if (verificarSobreposicoes(intervaloDatasVigencias,novaVigencia)) {
      setNotification({ isOpen: true, message: `O intervalo de datas se sobrepõe,verifique as datas e tente novamente!`, variant: "error" })
      return
    } else {
    

      setDadosVigencia([
        ...dadosVigencia,
        novaVigencia,
      ])

      setNovaVigencia({ disabled: false, id: null })
    }

  }

  const onChangeNovaVigencia = (value, field) => {
    let valueFormated = value


    if (field === "dataInicioVigencia" || field === "dataFimVigencia") {

      if (!valueFormated ) {
        valueFormated = "";
      } else {
        valueFormated = moment(valueFormated).format("YYYY-MM-DD");
       
      }
    }
    if (field === "valor") {
      const value =  valueFormated.replace("R$", "")
      valueFormated = value
    }

    setNovaVigencia({
      ...novaVigencia,
      [field]: valueFormated,
      disabled: true,
    })
  }

  const closeAlertMessage = () => {
    const notification = {
      isOpen: false,
      message: "",
    };
    setNotification({ notification: notification });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault()
    }
  }

  const handleClickDesfazer = () => {
    if (excluidos.length === 0) return
    const ultimoItem = excluidos[excluidos.length - 1]
    const listaSemItemRestaurado = excluidos.filter((item) => item !== ultimoItem)

    const itemReestabelecido = {
      disabled: true,
      id: ultimoItem[0].id || null,
      dataFimVigencia: ultimoItem[0].dataFimVigencia,
      dataInicioVigencia: ultimoItem[0].dataInicioVigencia,
      valor: ultimoItem[0].valor
    }

    setDadosVigencia([...dadosVigencia, itemReestabelecido])
    setExcluidos(listaSemItemRestaurado)
  }

  const renderAddNovaVigencia = () => {
    const disabledAdd = !novaVigencia.dataInicioVigencia || !novaVigencia.valor
    return (
      <div className={classes.contentInputs} onKeyDown={handleKeyDown} >
        <InputDateForm
          iconposition="end"
          invalidDateMessage=""
          value={novaVigencia?.dataInicioVigencia || ""}
          onChange={(e) => onChangeNovaVigencia(e, "dataInicioVigencia")}
          className={classes.inputDateForm}
        />

        <InputDateForm
          iconposition="end"
          value={novaVigencia?.dataFimVigencia || ""}
          invalidDateMessage=""
          className={classes.inputDateForm}
          onChange={(e) => onChangeNovaVigencia(e, "dataFimVigencia")}
        />

        <InputMaskCurrency
          placeholder={" R$"}
          className={classes.inputValor || ""}
          value={novaVigencia?.valor || ""}
          onChange={(e) => onChangeNovaVigencia(e.target.value, "valor")}
        />
        <Fab size={"small"} color={"primary"} onClick={handleClickAdicionar} disabled={disabledAdd}>
          <AddIcon />
        </Fab>
        <Button className={classes.buttonDesfazer} onClick={handleClickDesfazer} disabled={!excluidos}>
          Desfazer exclusão
        </Button>
      </div>)
  }

  return (
    <Dialog open={open} classes={{ paper: classes.dialog }}>
      <div className={classes.wraper}>

        <div>
          <HeaderModal
            title={string.truncate(title, 40)}
            tooltip={title}
            onClose={handleClose}
            className={classes.header}></HeaderModal>
        </div>
        <div className={classes.contentTable}>
          <div>
            <div className={classes.wraperInputs}>
              <div className={classes.headerInputs}>
                <span className={classes.span}>Inicio Vigencia</span>
                <span className={classes.span}>Fim Vigencia</span>
                <span className={classes.span}>Valor</span>
              </div>
              {renderAddNovaVigencia()}
            </div>
          </div>
          <div className={classes.wraperTable} onKeyDown={handleKeyDown}>
            <Table
              dados={dadosVigencia}
              columns={getColumnsVigenciaProcedimento({
                functions: {
                  onChange: onChange,
                  handleClickEditar: handleClickEditar,
                  handleClickExcluir: handleClickExcluir,
                },
              })}
              classes={{
                table: classes.table,
                tableHead: classes.tableHeader,
              }}
            />
             { loadingVigencias &&
            <div className={classes.loading}>
              <CircularProgress ></CircularProgress>
            </div>
             }
          </div>
        </div>

      </div>
      <div className={classes.contenButton}>
        <Button disabled={isLoading} className={classes.buttonSalvar} onClick={handleClickSalvar}>
          Salvar
        </Button>
      </div>
      <Notification
        close={closeAlertMessage}
        reset={closeAlertMessage}
        isOpen={notification.isOpen}
        variant={notification.variant}
        message={notification.message}
      />
    </Dialog>


  )
}

const styles = {
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: "10px"
  },
  dialog: {
    display: "flex",
    minHeight: "640px",
    minWidth: "742px",
    justifyContent: 'space-between',
  },
  buttonSalvar: {
    width: "150px",
    borderRadius: '25px'
  },
  contentTable: {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'center',
    justifyContent: 'center',
    height: '100%',
    width: "100%",
  },
  wraperTable: {
    height: "calc(460px - 60px)",
    overflowY: "scroll"
  },
  table: {
    "& tbody> tr> td> div >div": {
      width: "125px",
    }, "& tbody> tr> td> div": {
      width: "70px",
    }
  },
  tableHeader: {
    background: "blue"
  },
  wraper: {
    gap: '20px',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '15px',
  },
  contenButton: {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: '5px'
  },
  inputDateForm: {
    width: "150px"
  },
  inputValor: {
    width: "115px",
    background: "#f2f2f2",
    height: '31px',
    borderRadius: '8px',
    padding: '2px 0px 0px 8px'
  },
  contentInputs: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  span: {
    width: "150px"
  },
  headerInputs: {
    display: "flex",
    gap: '20px',
    color: '#505050',
    fontWeight: '500'
  },
  wraperInputs: {
    padding: "10px"
  },
  buttonDesfazer:{
    color: '#FFFFFF',
    border: '0',
    height: '37px',
    padding: '0 9px',
    background: '#F9BE73',
    borderRadius: '23px'
  },
  loading:{
    display:"flex", 
    flexDirection: "row",
    alignItems:"center",
    justifyContent: "center",
    height:"100%",
  }
}

export default withStyles(styles)(ModalConfigVigencia);