import Api from "../config/api";

export const findAllLeito = async (variables) => {
  const response = await Api.post("", {
    query: `
      query ($tipo: TipoLeito, $ativo: Boolean, $search: String, $pageableDTO: PageableDTOInput) {
        findAllLeito(tipo: $tipo, ativo: $ativo, search: $search, pageableDTO: $pageableDTO) {
          last
          content {
            ativo
            id
            nome
            tipoLeito
          }
        }
      }
    `,
    variables: variables,
  });

  if (response?.data?.errors) {
    throw Error(response.data.errors[0].message);
  }

  return response.data.data.findAllLeito;
};

export const saveLeito = async (variables) => {
  const nomeMutation = variables.leito.id ? "updateLeito" : "createLeito";

  const response = await Api.post("", {
    query: `
      mutation ($leito: LeitoInput) {
        ${nomeMutation}(leito: $leito) {
          id
        }
      }
    `,
    variables: variables,
  });

  if (response.data.errors) {
    throw response.data.errors;
  }
  return response.data.data[nomeMutation];
};

export const findByIdLeito = async (variables) => {
  const response = await Api.post("", {
    query: `
      query ($id: UUID) {
        findByIdLeito(id: $id) {
          ativo
          id
          nome
          tipoLeito
        }
      }
    `,
    variables: variables,
  });

  if (response.data.errors) {
    throw response.data.errors;
  }
  return response.data.data.findByIdLeito;
};

export const inativarLeito  = async (id) => {
  const response = await Api.post("", {
    query: `
    mutation($id: UUID){
      inativarLeito(id: $id) {
        id
      }
    }
    `,
    variables: { id },
  });

  if (response.data.errors) {
    throw response.data.errors;
  }
  return response.data.data.inativarLeito;
};

export const ativarLeito  = async (id) => {
  const response = await Api.post("", {
    query: `
    mutation($id: UUID){
      ativarLeito(id: $id) {
        id
      }
    }
    `,
    variables: { id },
  });

  if (response.data.errors) {
    throw response.data.errors;
  }
  return response.data.data.ativarLeito;
};