import React, { useState } from "react";
import {
  Row,
  TextFieldSearchStyled,
  ContainerProcedimentos,
  InputMaskCurrencyStyled,
  ContentButtons,
} from "../../styles";
import Field from "../../components/Field";
import { InputForm } from "../../../../../components/Modal/Input";
import {
  findAllProcedimentosValor,
  findAllProfissionalSaudeComAgenda,
} from "../../../../../services/InternacaoService";
import { Button } from "../../../../../components/ui/Buttons";
import {
  columnsProcedimentosSolicitados,
  procedimentosExecutadosDefault,
} from "../../constants";
import Table from "../../../../../components/Table/Table";
import InputDateForm from "../../../../../components/Input/InputDateForm";

const ProcedimentosExecutados = ({ internacao, handleField }) => {
  const { procedimentosExecutados, sujeitoAtencao } = internacao;

  const [procedimentoNovo, setProcedimentoNovo] = useState(
    procedimentosExecutadosDefault
  );
  const [isEdit, setIsEdit] = useState(false);

  const {
    procedimento,
    codigoAMB,
    profissionalSaude,
    sequencia,
    valor,
    quantidade,
    dataRealizacao,
    prestacaoServico,
    resultado,
  } = procedimentoNovo || {};

  const unidadeLogada = JSON.parse(localStorage["_immortal|unidadeLogada"]);

  const handleProcedimentoSelected = (value) => {
    const idConvenio = sujeitoAtencao?.dadosConvenio?.convenio?.id;
    let valor = 0;

    if (idConvenio && value?.convenioProcedimentos?.length > 0) {
      const convenioProcedimento = value.convenioProcedimentos.find(
        (convenioProcedimento) => {
          return convenioProcedimento?.convenio?.id === idConvenio;
        }
      );

      valor = convenioProcedimento?.valor || 0;
    }

    setProcedimentoNovo((prevState) => ({
      ...prevState,
      procedimento: value,
      valor,
    }));
  };

  const handleProcedimento = (value, field) => {
    setProcedimentoNovo((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleAdicionaProcedimento = () => {
    const dadosProcedimentos = internacao.procedimentosExecutados;
    dadosProcedimentos.push(procedimentoNovo);

    setProcedimentoNovo({
      ...procedimentosExecutadosDefault,
      sequencia: procedimentosExecutados.length + 1,
    });

    handleField("procedimentosExecutados", dadosProcedimentos);
  };

  const handleDelete = (data) => {
    const dadosProcedimentos = internacao.procedimentosExecutados;
    const index = dadosProcedimentos.findIndex(
      (procedimento) => procedimento.id === data.id
    );

    dadosProcedimentos.splice(index, 1);
    const procedimentosExecutadosSequencial = alteraSequencia(dadosProcedimentos);
    
    handleField("procedimentosExecutados", procedimentosExecutadosSequencial);
  };

  const alteraSequencia = (procedimentos) => {
    setProcedimentoNovo((prevState) => ({
      ...prevState,
      sequencia: procedimentosExecutados.length + 1,
    }));

    const procedimentosExecutadosSequencial = procedimentos.map((procedimento, index) => {
      return {
        ...procedimento,
        sequencia: index + 1
      }
    });

    return procedimentosExecutadosSequencial;
  };

  const handleEdit = (data) => {
    setProcedimentoNovo(data);
    setIsEdit(true);
  };

  const resetProcedimentoNovo = () => {
    setProcedimentoNovo({
      ...procedimentosExecutadosDefault,
      sequencia: procedimentosExecutados.length + 1,
    });
  };

  const handleCancelarProcedimentoEdit = () => {
    resetProcedimentoNovo();
    setIsEdit(false);
  };

  const handleSalvarProcedimentoEdit = () => {
    const dadosProcedimentos = internacao.procedimentosExecutados;
    const index = dadosProcedimentos.findIndex(
      (procedimento) => procedimento.id === procedimentoNovo.id
    );

    dadosProcedimentos[index] = procedimentoNovo;

    handleField("procedimentosExecutados", dadosProcedimentos);
    setIsEdit(false);
    resetProcedimentoNovo();
  };

  const loadAllProcedimentos = async (search, loadedOptions, { page }) => {
    const variables = {
      nome: search,
      pageableDTO: {
        pageNumber: page,
        pageSize: 20,
        sortDir: "ASC",
        sortField: "nome",
      },
      ativo: true,
    };

    const response = await findAllProcedimentosValor(variables);
    const { content, last } = response;

    return {
      options: content,
      hasMore: !last,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadAllProfissional = async (search, loadedOptions, { page }) => {
    const variables = {
      search,
      pageableDTO: {
        pageNumber: page,
        pageSize: 20,
        sortDir: "ASC",
        sortField: "nome",
      },
      ativo: true,
      unidadeId: unidadeLogada.id,
    };

    const response = await findAllProfissionalSaudeComAgenda(variables);
    const { content, last } = response;

    return {
      options: content,
      hasMore: !last,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <ContainerProcedimentos>
      <Row>
        <Field label="Sequencia" width="10%">
          <InputForm
            value={sequencia}
            onChange={(e) => handleProcedimento(e.target.value, "sequencia")}
            disabled={true}
          />
        </Field>
        <Field label="Procedimento" width="25%">
          <TextFieldSearchStyled
            placeholder="Selecione"
            loadOptions={loadAllProcedimentos}
            getOptionLabel={(option) => option.nome}
            getOptionValue={(option) => option.id}
            value={procedimento}
            onChange={handleProcedimentoSelected}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          />
        </Field>
        <Field label="Profissional" width="25%">
          <TextFieldSearchStyled
            placeholder="Selecione"
            loadOptions={loadAllProfissional}
            getOptionLabel={(option) => option.nome}
            getOptionValue={(option) => option.id}
            value={profissionalSaude}
            onChange={(e) => handleProcedimento(e, "profissionalSaude")}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          />
        </Field>
        <Field label="Código ambulatorial" width="15%">
          <InputForm
            value={codigoAMB}
            onChange={(e) => handleProcedimento(e.target.value, "codigoAMB")}
          />
        </Field>
        <Field label="Valor" width="15%">
          <InputMaskCurrencyStyled
            value={valor}
            onChange={(e) => handleProcedimento(e.target.value, "valor")}
          />
        </Field>
      </Row>
      <Row>
        <Field label="Data exame" width="20%">
          <InputDateForm
            iconposition="end"
            value={dataRealizacao}
            onChange={(e) => handleProcedimento(e, "dataRealizacao")}
          />
        </Field>
        <Field label="Quantidade" width="20%">
          <InputForm
            type="number"
            value={quantidade}
            onChange={(e) => handleProcedimento(e.target.value, "quantidade")}
          />
        </Field>
        <Field label="Prestação Serviço" width="30%">
          <InputForm
            value={prestacaoServico}
            onChange={(e) =>
              handleProcedimento(e.target.value, "prestacaoServico")
            }
          />
        </Field>
        <Field label="Resultado" width="30%">
          <InputForm
            value={resultado}
            onChange={(e) => handleProcedimento(e.target.value, "resultado")}
          />
        </Field>
        {isEdit ? (
          <ContentButtons>
            <Button
              bgColor="#FB7676"
              style={{ height: 40 }}
              padding={0}
              onClick={handleCancelarProcedimentoEdit}
            >
              Cancelar
            </Button>
            <Button
              bgColor={"#00B0AE"}
              style={{ height: 40 }}
              padding={0}
              onClick={handleSalvarProcedimentoEdit}
              disabled={!procedimento?.id || !profissionalSaude?.id}
            >
              Salvar
            </Button>
          </ContentButtons>
        ) : (
          <Button
            bgColor={"#707C97"}
            style={{ height: 40 }}
            padding={0}
            onClick={handleAdicionaProcedimento}
            disabled={!procedimento?.id || !profissionalSaude?.id}
          >
            Adicionar
          </Button>
        )}
      </Row>
      <Table
        columns={columnsProcedimentosSolicitados({ handleDelete, handleEdit })}
        dados={procedimentosExecutados}
      />
    </ContainerProcedimentos>
  );
};

export default ProcedimentosExecutados;
