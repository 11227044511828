export const periodos = [
  {
    value: 'DIARIO',
    label: 'Diário',
  },
  {
    value: 'SEMANAL',
    label: 'Semanal',
  },
  {
    value: 'MENSAL',
    label: 'Mensal',
  },
  {
    value: 'QUINZENAL',
    label: 'Quinzenal',
  },
];