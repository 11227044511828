import { action, observable } from 'mobx';
import Moment from "moment";
import Api from '../config/api';
import string from "../utils/string";
import { Dates } from "../utils";
import { createMutation } from "../pages/Querys";
import localStorageService, { ACCESS_TOKEN_KEY, CONVENIOS_KEY, TIPO_NOVO_KEY } from '../services/storage';
import { findByUnidadeLogadaConfiguracaoUnidade } from "../services/ConfiguracoesUnidadeService";
import { findConveniosByAtivo } from '../services/ConvenioService';
import { findAllProcedimentos } from '../services/ProcedimentoService';
import { findAgendamentoTipoByAtivo } from '../services/AgendamentoTipoService';
import { buildUrlFotoPerfil, db, getViziWebUrl } from '../config/config';
import { getProfissionalSaudeLogado } from '../services/UsuarioService';
import moment from "moment";
import verificaDocumento from '../utils/verificaDocumento';
import { findAllConvenios, updateWhatsappMensagemManual } from '../services/AgendamentoService';
import BaseStore from './Base.store';
import { createAgendamentoAndControleSessao, vincularAgendamentoByControleSessaoAgendamentoTipo } from '../services/ControleSessaoService';
import { defaultErrors, defaultTitulo } from './Financeiro/Extrato.store';
import { calcTotalTitulo } from '../pages/Atendimento/ModalTabs/utils';
import { findByUnidadeComAgendaEspecialidade } from '../services/ProfissionalSaudeService';

const situacoesDefault = [
    { value: 'AGENDADO', name: "Agendado", cor: "blue" },
    { value: 'CONFIRMADO', name: "Confirmado", cor: "green" },
    { value: 'AGUARDANDO', name: "Aguardando", cor: "yellow" },
    { value: 'ATENDENDO', name: "Atendendo", cor: "waikawaGrey" },
    { value: 'ATENDIDO', name: "Atendido", cor: "purple" },
    { value: 'CANCELADO', name: "Cancelado", cor: "red" },
    { value: 'BLOQUEADO', name: "Bloqueado", cor: "darkGray" },
    { value: 'FALTOU', name: 'Faltou', cor: 'pink' }
];

const tiposConsultaDefault = [
    { id: 'PRESENCIAL', name: "Presencial", value: false },
    { id: 'REMOTA', name: "Remota", value: true }
];

const sujeitoAtencaoPageableDefault = {
    pageNumber: 0,
    pageSize: 10,
    sortDir: 'ASC',
    sortField: 'nome'
};

export const modalConfirmacaoDefault = {
    open: false,
    title: "",
    color: "",
    icon: null,
    buttons: null,
    children: null
};

const controleSessaoDataDefault = {
    idPacote: null,
    idSessao: null,
    idSujeitoAtencao: null,
    isProcedure: null,
}

class AtendimentoStore extends BaseStore {
    prontuarioStore = null;
    filtroHeaderStore = null;
    odontogramaStore = null;
    extratoStore = null;
    @observable accessToken = null;
    @observable sujeitosAtencao = [];
    @observable sujeitosAtencaoLastContent = false;
    @observable sujeitosAtencaoLoading = false;
    @observable sujeitosAtencaoCurrentPage = 0;
    @observable convenios = [{ value: '', name: '' }];
    @observable tipos = [{ value: '', name: '' }];
    @observable open = false;
    @observable openCompletarDadosModal = false;
    @observable openModalRealizarProcedimentos = false;
    @observable modalRecorrenciaIsOpen = false;
    @observable openInfosAgendamentoModal = false;
    @observable infosConsultaSelecionada = {};
    @observable enableCadastrarNovo = false;
    @observable idOpened;
    @observable timeRange;
    @observable eventos = [];
    @observable eventosSala = [];
    @observable eventosFiltrados = [];
    @observable eventosEncaixes = [];
    @observable eventosFiltradosEncaixes = [];
    @observable configVigente = null;
    @observable calendarConfig = {};
    @observable selectedDate = Moment();
    @observable status = [];
    @observable periodosAtendimento = [];

    @observable loadingCalendar = false;
    @observable loadingCalendarSala = false;
    @observable finishedInitialLoad = false;

    @observable isModalHorarioDisponivel = false;
    @observable calendarErrorMessage = null;
    @observable calendarErrorMessageSala = null;
    @observable cancelandoAgendamento = false;
    @observable disableModalButtons = false;
    @observable isProntuarioPage = false;
    @observable typeCalendarViewSelected = 'dayView';
    @observable isEncaixeSelected = false;
    @observable isMultiAgendaSelected = false;
    @observable isSalaSelected = false;
    @observable horariosAgendamento = [];
    @observable stepperHoraInicio = 5;
    @observable mesCalendario = "";
    @observable isModalSala = '';
    @observable idSalaSelecionada = '';
    @observable tipoProfissionalLogado = '';
    @observable procedimentoSelecionado = [];
    @observable procedimentosDisponiveis = [];
    @observable tipoModalAtendimento = '';
    @observable listaProfissionalSaudeComAgenda = {};
    @observable loadingFindAllAgendamentoEEncaixe = false;
    @observable permiteVerAgendaOutrosProfissionais = false;
    @observable excluirAgendamentoAlertData = {
        agendamento: null,
        openAlert: false,
        title: '',
        description: ''
    };
    @observable loadingHorariosDisponiveis = false;
    @observable controleSessaoData = controleSessaoDataDefault;
    @observable agendamentoRetornoData = null;
    @observable objView = {
        documento: '',
        dataNascimento: '',
        id: '',
        sujeitoId: '',
        nome: '',
        motivo: '',
        situacao: '',
        telefone: '',
        telefoneDDI: '55',
        email: '',
        tipo: { id: '' },
        convenio: { id: '' },
        isConsultaRemota: false,
        observacao: '',
        data: '',
        horaInicio: '',
        horaFim: '',
        duracao: 0,
        linkTelemedicina: '',
        remoto: '',
        chavePublica: '',
        mensagemWhatsappEnviada: false,
        utilizaEnderecoMensagemAgendamento: false,
        horasPermiteAgendamento: 0,
        documentoTipo: '',
        financeiroGuiaConsulta: '',
        procedimentos: [],
        urgente: false,
        listaDeEspera: false,
        titulo: null,
        validadeCarteira: '',
        numeroCarteira: '',
        whatsappMensagemQuantidade: 0,
        especialidadeMedica: {
            especialidade: '',
            id: 0
        },
        documentosAssinadosCliente:{}
    };

    @observable listaFilter = {
        last: false,
        content: [],
        pageable: { pageNumber: 0 }
    };

    @observable horariosDisponiveis = {
        last: false,
        content: [],
        pageable: { pageNumber: 0 }
    };

    @observable errors = {
        documento: false,
        nome: false,
        telefone: false,
        email: false,
        sala: false,
        duracao: false,
        procedimentos: false,
        convenio: false,
    };

    @observable duracoesDisponives = [];

    @observable situacoes = situacoesDefault;

    @observable filtroSituacoes = situacoesDefault.filter(s => s.value !== 'BLOQUEADO');

    @observable situacoesAlteracaoStatus = situacoesDefault.filter(s => {
        return s.value === 'CONFIRMADO' || s.value === 'AGUARDANDO' || s.value === 'ATENDIDO' || s.value === 'CANCELADO';
    });

    tipo = [];

    @observable tiposConsulta = tiposConsultaDefault;

    @observable idSolicitanteDoProcedimento = null;

    @observable urlVizi = getViziWebUrl();

    @observable agendamentoRecorrencia = null;

    @observable dataAgendamentoTemp = '';

    @observable utilizaTelefoneInternacional = false;

    @observable documentosTipo = [];

    @observable openModalVincularPaciente = false;

    @observable modalVinculoSujeitoAlertTitle = "";

    @observable modalVinculoSujeitoAlertDescription = "";

    @observable waitingSelectSujeitoToLink = false;

    @observable notification = {
        isOpen: false,
        message: "",
    };

    @observable convenioPagamento = null;

    @observable dadosModalConfirmacao = modalConfirmacaoDefault;

    @observable isConvenioObrigatorio = false;

    @observable updatingRecorrencia = false;

    @observable isOdontograma = false;

    @observable utilizaControleSessao = false;

    @observable dataSelecionadaBloqueioAgenda = {
        dataInicial: null,
        dataFinal: null
    };
    @observable profissionaisAgendaBloqueadaOptions = [];

    @observable horariosOptions = [];

    @observable agendamentoRecorrencia = {
        dataCriacao: null,
        diasSemanas: {
            diaSemana: []
        },
        intervalo: null,
        quantidade: null,
        tipo: ''
    };
    @observable isLastAgendamentoOfRecurrence = false;

    @observable canViewFincanceiro = false;

    @observable procedimentosModal = [];

    @observable showTitulo = false;

    @observable isTelaSessoes = false;
    @observable dataSelecionadaCancelaAgenda = {
        dataInicial: null,
        dataFinal: null
    };

    @observable openModalListaEspera = false;
    @observable agendamentoListaEspera = null;
    @observable procedimentosPedidosSelecionados = {
        id: null,
        procedimentosSolicitados: []
    };
    @observable hasAuthorityToEditRecorrencia = false;
    @observable utilizaContratoAzulControle = false;
    @observable isAgendarProcedimentoSolicitado = false;
    @observable openModalListaEsperaItem = false;
    @observable isAgendamentoModalListaEspera= false;
    @observable listaProfissionaisSaudeListaEspera= [];

    @observable isLoadingUnidadeConfig = false;

    constructor(rootStore) {
        super();
        this.extratoStore = rootStore?.extratoStore;
        this.controleSessaoStore = rootStore?.controleSessaoStore;
    }

    tipoPadrao = () => {
        if(this.tipo.length === 1) {
            const { descricao, id } = this.tipo[0] || {};

            return {
                label: descricao,
                value: id
            };
        }

        const tipoConsulta = this.tipo?.find?.(item => item.codigo === "CONSULTA");

        return tipoConsulta ? {
            label: tipoConsulta?.descricao,
            value: tipoConsulta?.id
        } : null
    }

    @action carregaDocumentosTipo = async () => {
        const result = await Api.post('', {
            query: `{
                findAllDocumentoTipo(pageableDTO:{sortField: "descricao", sortDir: "ASC"}){
                    id
                    descricao
                }
            }`,

        });

        this.documentosTipo = result.data.data.findAllDocumentoTipo;
    };

    getSelectValuesFromFiltroStore = () => {
        const { unidade, profissionalSaude, sala } = this.filtroHeaderStore;
        const { id: unidadeId } = unidade || {};
        const { id: profissionalSaudeId } = profissionalSaude || {};
        const { id: salaId } = sala || {};

        return { unidadeId, profissionalSaudeId, salaId };
    }

    @action async changeOpen({
        id,
        horaInicio,
        horaFim,
        status,
        isModalSala,
        tipoModalAtendimento,
        agendaSelecionada,
        profissionalSolicitanteConfig,
        procedimentosConfig
    }, cbFinally) {
        if (isModalSala) {
            this.loadProcedimentos(this.idSalaSelecionada);
        } else {
            await this.loadAgendamentoTipo();
        }

        this.idOpened = id;
        this.enableCadastrarNovo = !id;
        this.objView.data = Moment(horaInicio);
        this.objView.situacao = status || "AGENDADO";
        this.objView.dataNascimento = this.objView.dataNascimento || '';
        this.objView.convenio = this.objView.convenio || { id: '' };
        this.objView.tipo = this.objView.tipo?.id ? { label: this.objView.tipo?.descricao, value: this.objView.tipo?.id } : this.tipoPadrao();
        this.objView.horaInicio = horaInicio && Moment(horaInicio).format('HH:mm');
        this.objView.horaFim = horaFim && Moment(horaFim).format('HH:mm');
        this.objView.duracao = {
            label: `${Moment(horaFim).diff(horaInicio, 'minute')} min.`,
            value: Moment(horaFim).diff(horaInicio, 'minute')
        };
        this.objView.sala = this.idSalaSelecionada && isModalSala ? { id: this.idSalaSelecionada } : null
        this.isModalSala = isModalSala;
        this.tipoModalAtendimento = tipoModalAtendimento;
        this.dataAgendamentoTemp = Moment(horaInicio).format('YYYY-MM-DD');
        this.sujeitosAtencaoLastContent = false;

        if ((!id || !this.objView?.id) && isModalSala) {
            const procedimentos = procedimentosConfig?.length ? procedimentosConfig.map((procedimento) => {
                return {
                    procedimento: {
                        id: procedimento?.id,
                        nome: procedimento?.nome
                    },
                    nomeProcedimento: procedimento.nome,
                }
            }) : [];
            this.objView.procedimentos = [...procedimentos];
            this.objView.solicitante = profissionalSolicitanteConfig || null;
        }

        await this.getConfiguracaoUnidadeLogada();


        if (id) {
            this.loadAgendamento(id, cbFinally);
        } else {
            this.agendamentoRecorrencia = null;
        }

        if (agendaSelecionada) {
            if (agendaSelecionada.tipo === 'PROFISSIONAL') {
                this.objView.profissionalSaude = agendaSelecionada;
            } else {
                this.objView.sala = { id: agendaSelecionada.id }
                this.isModalSala = 'isModalSala';
                this.loadProcedimentos(this.idSalaSelecionada);
            }
        }

        this.open = true;
    }

    @action async changeOpenHorarioDisponivel(id, horaInicio, horaFim, data, profissionalSaudeId) {
        this.idOpened = id;
        this.enableCadastrarNovo = !id;
        this.objView.profissionalSaudeId = profissionalSaudeId;
        this.isModalHorarioDisponivel = true;
        this.objView.data = Moment(data);
        this.objView.situacao = status || "AGENDADO";
        this.objView.dataNascimento = this.objView.dataNascimento || '';
        this.objView.convenio = this.objView.convenio || { id: '' };
        this.objView.tipo = this.objView.tipo?.id ? { label: this.objView.tipo?.descricao, value: this.objView.tipo?.id } : this.tipoPadrao();
        this.objView.horaInicio = horaInicio;
        this.objView.horaFim = horaFim;
        this.objView.duracao = {
            label: `${Moment(horaFim, 'HH:mm').diff(Moment(horaInicio, 'HH:mm'), 'minute')} min.`,
            value: Moment(horaFim, 'HH:mm').diff(Moment(horaInicio, 'HH:mm'), 'minute')
        };

        this.objView.sala = { id: this.idSalaSelecionada }
        this.sujeitosAtencaoLastContent = false;
        await this.loadAgendamentoTipo(profissionalSaudeId);
        await this.getConfiguracaoUnidadeLogada();
        this.open = true;
    }

    @action changeTypeModal(type) {
        this.isModalSala = type;
    }

    @action clearIdOpened() {
        this.idOpened = null;
    }

    @action setTipoProfissionalLogado(tipoProfissional) {
        this.tipoProfissionalLogado = tipoProfissional;
    }

    @action
    async initObjectView(date, loadingCalendar = true) {
        this.accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
        switch (this.typeCalendarViewSelected) {
            case 'weekView':
                await this.initObjectWeekView(date, loadingCalendar = true);
                break;
            case 'encaixesView':
                await this.initObjectEncaixesView(date, loadingCalendar = true);
                break;
            default:
                await this.initObjectDayView(date, loadingCalendar = true);
                break;
        }
    }

    @action
    async initObjectViewSala(date, loadingCalendarSala = true) {
        this.accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
        switch (this.typeCalendarViewSelected) {
            case 'weekView':
                await this.initObjectWeekViewSala(date, loadingCalendarSala = true);
                break;
            default:
                await this.initObjectDayViewSala(date, loadingCalendarSala = true);
                break;
        }
    }


    async initObjectWeekView(date, loadingCalendar = true) {
        let dateAux = Moment(date);
        try {
            this.loadingCalendar = loadingCalendar;
            this.calendarErrorMessage = null;
            const { unidadeId, profissionalSaudeId } = await this.filtroHeaderStore.getFiltroAtual();
            const { showFiltroProfissionalSaude } = await this.filtroHeaderStore.checkRoles('AGENDAMENTO');

            if (profissionalSaudeId === 0 && showFiltroProfissionalSaude) {
                throw new Error('É necessário selecionar um profissional de saúde.');
            }

            let metodoConfiguracaoVigente = 'configuracaoHorarioAtendimentoVigente';
            if (profissionalSaudeId && showFiltroProfissionalSaude) {
                metodoConfiguracaoVigente = 'configuracaoHorarioAtendimentoVigenteOutrosProfissionaisSaude';
            }

            const result = await Api.post('', {
                query:
                    `
                    fragment dadosDia on HorarioAtendimento {
                        horaInicio
                        horaFim
                        permiteAgendamento
                    }
                    fragment dias on ConfiguracaoHorarioAtendimento {
                        domingo {
                            ...dadosDia
                        }
                        segunda: segundaFeira {
                            ...dadosDia
                        }
                        terca: tercaFeira {
                            ...dadosDia
                        }
                        quarta: quartaFeira {
                            ...dadosDia
                        }
                        quinta: quintaFeira {
                            ...dadosDia
                        }
                        sexta: sextaFeira {
                            ...dadosDia
                        }
                        sabado {
                            ...dadosDia
                        }
                    }
                    query($filter: SearchDTOInput){
                        configuracaoHorarioAtendimentoVigente: ${metodoConfiguracaoVigente}(searchDTO: $filter) {
                            id
                            horaInicioPeriodoAtendimento
                            horaFimPeriodoAtendimento
                            duracao
                            ...dias
                        }
                    }`,
                variables: {
                    filter: {
                        dataInicial: dateAux.days(0).format('YYYY-MM-DD'),
                        dataFinal: dateAux.days(6).format('YYYY-MM-DD'),
                        unidadeId,
                        profissionalSaudeId
                    }
                }
            });
            const { configuracaoHorarioAtendimentoVigente } = result.data?.data;

            const config = configuracaoHorarioAtendimentoVigente ? configuracaoHorarioAtendimentoVigente[0] : null;

            this.configVigente = config;
            if (!config) {
                if (profissionalSaudeId === 0 && showFiltroProfissionalSaude) {
                    throw new Error('É necessário selecionar um profissional de saúde.');
                } else {
                    throw new Error('O Horário do Atendimento não foi configurado.');
                }
            }

            this.horariosAgendamento = config.domingo.map(item => {
                return Moment(item.horaInicio, 'HH:mm').format('x')
            });

            this.periodosAtendimento = config.periodosAtendimento;
            let horaInicioPeriodoAtendimento = config.horaInicioPeriodoAtendimento;
            let horaFimPeriodoAtendimento = config.horaFimPeriodoAtendimento;
            let duracao = config.duracao;

            this.objView.duracao = { label: config.duracao + ' min.', value: config.duracao };

            let opcoesDeDuracao = [1, 2, 3, 4, 5, 6, 7, 8];
            this.duracoesDisponives = opcoesDeDuracao.map(element => {
                let value = element * duracao;
                return { label: value + ' min.', value }
            });

            this.calendarConfig = {
                min: Moment(horaInicioPeriodoAtendimento, 'HH:mm').day(0).toDate(),
                max: Moment(horaFimPeriodoAtendimento, 'HH:mm').add(duracao, 'minutes').day(6).toDate(),
                duracao: duracao
            };

            this.atualizaAgendamentos(this.selectedDate);

        } catch (error) {
            this.calendarErrorMessage = error.message;
        } finally {
            this.loadingCalendar = false;
        }
    }

    async initObjectDayView(date) {
        if (this.loadingCalendar) {
            return; 
        }

        try {
            this.calendarErrorMessage = null;
            this.loadingCalendar = true;
            const { unidadeId, profissionalSaudeId } = await this.filtroHeaderStore.getFiltroAtual();
            const { showFiltroProfissionalSaude } = await this.filtroHeaderStore.checkRoles('AGENDAMENTO');

            if (profissionalSaudeId === 0 && showFiltroProfissionalSaude) {
                throw new Error('É necessário selecionar um profissional de saúde.');
            }

            let metodoConfiguracaoVigente = 'configuracaoHorarioAtendimentoVigente';
            if (profissionalSaudeId && showFiltroProfissionalSaude) {
                metodoConfiguracaoVigente = 'configuracaoHorarioAtendimentoVigenteOutrosProfissionaisSaude';
            }

            const result = await Api.post('', {
                query:
                    `
                    fragment dadosDia on HorarioAtendimento {
                        horaInicio
                        horaFim
                        permiteAgendamento
                    }
                    fragment dias on ConfiguracaoHorarioAtendimento {
                        domingo {
                            ...dadosDia
                        }
                        segunda: segundaFeira {
                            ...dadosDia
                        }
                        terca: tercaFeira {
                            ...dadosDia
                        }
                        quarta: quartaFeira {
                            ...dadosDia
                        }
                        quinta: quintaFeira {
                            ...dadosDia
                        }
                        sexta: sextaFeira {
                            ...dadosDia
                        }
                        sabado {
                            ...dadosDia
                        }
                    }
                    query($filter: SearchDTOInput){
                        configuracaoHorarioAtendimentoVigente: ${metodoConfiguracaoVigente}(searchDTO: $filter) {
                            id
                            horaInicioPeriodoAtendimento
                            horaFimPeriodoAtendimento
                            duracao
                            ...dias
                        }
                    }`,
                variables: {
                    filter: {
                        dataInicial: date.format('YYYY-MM-DD'),
                        dataFinal: date.format('YYYY-MM-DD'),
                        unidadeId,
                        profissionalSaudeId
                    }
                }
            });

            const { configuracaoHorarioAtendimentoVigente } = result.data.data;

            const config = configuracaoHorarioAtendimentoVigente ? configuracaoHorarioAtendimentoVigente[0] : null;

            this.configVigente = config;
            if (!config) {
                if (profissionalSaudeId === 0 && showFiltroProfissionalSaude) {
                    throw new Error('É necessário selecionar um profissional de saúde.');
                } else {
                    throw new Error('O Horário do Atendimento não foi configurado.');
                }
            }

            this.horariosAgendamento = config.domingo.map(item => {
                return Moment(item.horaInicio, 'HH:mm').format('x')
            });

            this.periodosAtendimento = config.periodosAtendimento;
            let horaInicioPeriodoAtendimento = config.horaInicioPeriodoAtendimento;
            let horaFimPeriodoAtendimento = config.horaFimPeriodoAtendimento;
            let duracao = config.duracao;
            this.objView.duracao = { label: config.duracao + ' min.', value: config.duracao };

            this.getListaDuracao(duracao);

            this.calendarConfig = {
                min: Moment(horaInicioPeriodoAtendimento, 'HH:mm').toDate(),
                max: Moment(horaFimPeriodoAtendimento, 'HH:mm').add(duracao, 'minutes').toDate(),
                duracao: duracao
            };

            this.atualizaAgendamentos(this.selectedDate);
        } catch (error) {
            this.calendarErrorMessage = error.message;
            console.log(error);
        } finally {
            this.loadingCalendar = false;
        }
    }

    getListaDuracao = (duracao) => {
        let opcoesDeDuracao = [];

        let opcoesDeDuracaoFixo = [
            { label: 5 + ' min.', value: 5 },
            { label: 10 + ' min.', value: 10 },
            { label: 15 + ' min.', value: 15 },
            { label: 20 + ' min.', value: 20 },
        ];

        for (let x = 1; x <= (360 / duracao); x++) {
            opcoesDeDuracao.push(x);
        }

        const opcoesDeDuracaoDisponiveis = opcoesDeDuracao.map(element => {
            let value = element * duracao;
            return { label: value + ' min.', value }
        })

        this.duracoesDisponives = [...opcoesDeDuracaoFixo, ...opcoesDeDuracaoDisponiveis];

    };

    async initObjectDayViewSala(date, loadingCalendarSala = true) {
        try {
            this.loadingCalendarSala = loadingCalendarSala;
            this.calendarErrorMessageSala = null;
            const { unidadeId, salaId } = await this.filtroHeaderStore.getFiltroAtual();
            const { showFiltroSalas } = await this.filtroHeaderStore.checkRoles('AGENDAMENTO_SALA');

            this.idSalaSelecionada = salaId;

            if (salaId === '0' && showFiltroSalas) {
                throw new Error('É necessário selecionar uma sala.');
            }

            let metodoConfiguracaoVigente = 'configuracaoHorarioAtendimentoVigenteSala';

            const result = await Api.post('', {
                query:
                    `
                    fragment dadosDia on HorarioAtendimento {
                        horaInicio
                        horaFim
                        permiteAgendamento
                        profissionalSolicitanteConfig: solicitante {
                            id
                            nome
                        }
                        procedimentosConfig: procedimentos {
                            id
                            nome
                        }
                    }
                    fragment dias on ConfiguracaoHorarioAtendimento {
                        domingo {
                            ...dadosDia
                        }
                        segunda: segundaFeira {
                            ...dadosDia
                        }
                        terca: tercaFeira {
                            ...dadosDia
                        }
                        quarta: quartaFeira {
                            ...dadosDia
                        }
                        quinta: quintaFeira {
                            ...dadosDia
                        }
                        sexta: sextaFeira {
                            ...dadosDia
                        }
                        sabado {
                            ...dadosDia
                        }
                    }
                    query($filter: SearchConfiguracaoHorarioAtendimentoDTOInput){
                        configuracaoHorarioAtendimentoVigente: ${metodoConfiguracaoVigente}(searchDTO: $filter) {
                            id
                            horaInicioPeriodoAtendimento
                            horaFimPeriodoAtendimento
                            duracao
                            ...dias
                        }
                    }`,
                variables: {
                    filter: {
                        dataInicial: date.format('YYYY-MM-DD'),
                        dataFinal: date.format('YYYY-MM-DD'),
                        unidadeId,
                        salaId: salaId
                    }
                }
            });

            const { configuracaoHorarioAtendimentoVigente } = result.data.data;

            const config = configuracaoHorarioAtendimentoVigente ? configuracaoHorarioAtendimentoVigente[0] : null;

            this.configVigenteSala = config;

            if ((salaId === '0' || !salaId) && !this.configVigenteSala) {
                throw new Error('É necessário selecionar uma sala.');
            }

            this.periodosAtendimentoSala = config.periodosAtendimento;
            let horaInicioPeriodoAtendimentoSala = config.horaInicioPeriodoAtendimento;
            let horaFimPeriodoAtendimentoSala = config.horaFimPeriodoAtendimento;
            let duracaoSala = config.duracao;

            let opcoesDeDuracao = [];

            for (let x = 1; x <= (360 / duracaoSala); x++) {
                opcoesDeDuracao.push(x);
            }

            this.duracoesDisponivesSalas = opcoesDeDuracao.map(element => {
                let value = element * duracaoSala;
                return { name: value + ' min.', value: value }
            });

            this.calendarConfigSala = {
                min: Moment(horaInicioPeriodoAtendimentoSala, 'HH:mm').toDate(),
                max: Moment(horaFimPeriodoAtendimentoSala, 'HH:mm').add(duracaoSala, 'minutes').toDate(),
                duracao: duracaoSala
            };

            await this.atualizaAgendamentosSala(this.selectedDate);
        } catch (error) {
            this.calendarErrorMessageSala = error.message;
            console.log(error);
        } finally {
            this.loadingCalendarSala = false;
        }
    }

    async initObjectWeekViewSala(date, loadingCalendarSala = true) {
        let dateAux = Moment(date);
        try {
            this.loadingCalendarSala = loadingCalendarSala;
            this.calendarErrorMessageSala = null;
            const { unidadeId, salaId } = await this.filtroHeaderStore.getFiltroAtual();
            const { showFiltroSalas } = await this.filtroHeaderStore.checkRoles('AGENDAMENTO_SALA');

            this.idSalaSelecionada = salaId;

            let metodoConfiguracaoVigente = 'configuracaoHorarioAtendimentoVigenteSala';

            const result = await Api.post('', {
                query:
                    `
                    fragment dadosDia on HorarioAtendimento {
                        horaInicio
                        horaFim
                        permiteAgendamento
                    }
                    fragment dias on ConfiguracaoHorarioAtendimento {
                        domingo {
                            ...dadosDia
                        }
                        segunda: segundaFeira {
                            ...dadosDia
                        }
                        terca: tercaFeira {
                            ...dadosDia
                        }
                        quarta: quartaFeira {
                            ...dadosDia
                        }
                        quinta: quintaFeira {
                            ...dadosDia
                        }
                        sexta: sextaFeira {
                            ...dadosDia
                        }
                        sabado {
                            ...dadosDia
                        }
                    }
                    query($filter: SearchConfiguracaoHorarioAtendimentoDTOInput){
                        configuracaoHorarioAtendimentoVigente: ${metodoConfiguracaoVigente}(searchDTO: $filter) {
                            id
                            horaInicioPeriodoAtendimento
                            horaFimPeriodoAtendimento
                            duracao
                            ...dias
                        }
                    }`,
                variables: {
                    filter: {
                        dataInicial: dateAux.days(0).format('YYYY-MM-DD'),
                        dataFinal: dateAux.days(6).format('YYYY-MM-DD'),
                        unidadeId,
                        salaId
                    }
                }
            });

            const { configuracaoHorarioAtendimentoVigente } = result.data.data;

            const config = configuracaoHorarioAtendimentoVigente ? configuracaoHorarioAtendimentoVigente[0] : null;

            this.configVigenteSala = config;
            if (!config) {
                if (salaId === '0' && showFiltroSalas) {
                    throw new Error('É necessário selecionar uma Sala.');
                }
            }

            this.horariosAgendamento = config.domingo.map(item => {
                return Moment(item.horaInicio, 'HH:mm').format('x')
            });

            this.periodosAtendimento = config.periodosAtendimento;
            let horaInicioPeriodoAtendimento = config.horaInicioPeriodoAtendimento;
            let horaFimPeriodoAtendimento = config.horaFimPeriodoAtendimento;
            let duracao = config.duracao;

            let opcoesDeDuracao = [1, 2, 3, 4, 5, 6, 7, 8];
            this.duracoesDisponivesSalas = opcoesDeDuracao.map(element => {
                let value = element * duracao;
                return { name: value + ' min.', value: value }
            });

            this.calendarConfigSala = {
                min: Moment(horaInicioPeriodoAtendimento, 'HH:mm').day(0).toDate(),
                max: Moment(horaFimPeriodoAtendimento, 'HH:mm').add(duracao, 'minutes').day(6).toDate(),
                duracao: duracao
            };

            await this.atualizaAgendamentosSala(this.selectedDate);

        } catch (error) {
            this.calendarErrorMessageSala = error.message;
        } finally {
            this.loadingCalendarSala = false;
        }
    }

    async initObjectEncaixesView() { 
        try {
            this.calendarErrorMessage = null;
            const { profissionalSaudeId } = await this.filtroHeaderStore.getFiltroAtual();
            const { showFiltroProfissionalSaude } = await this.filtroHeaderStore.checkRoles('AGENDAMENTO');

            if (profissionalSaudeId === 0 && showFiltroProfissionalSaude) {
                throw new Error('É necessário selecionar um profissional de saúde.');
            }

            this.atualizaAgendamentosEncaixes(this.selectedDate);

        } catch (error) {
            this.calendarErrorMessage = error.message;
        }
    }

    @action
    async atualizaAgendamentos(date = Moment(), dateFinal, isExcluir = false) {
        switch (this.typeCalendarViewSelected) {
            case 'weekView':
                await this.atualizaAgendamentosWeek(date);
                break;
            case 'encaixesView':
                await this.atualizaAgendamentosEncaixes(date);
                break;
            case 'filterPacientView':
                if (isExcluir) {
                    await this.atualizaAgendamentosFilter(true);
                }
                break;
            case 'threeDaysView':
                await this.atualizaAgendamentosThreeDays(date, dateFinal);
                break;
            default:
                await this.atualizaAgendamentosDay(date);
                break;
        }
    }

    @action
    async atualizaAgendamentosSala(date = Moment()) {
        switch (this.typeCalendarViewSelected) {
            case 'weekView':
                await this.atualizaAgendamentosWeekSala(date);
                break;
            default:
                await this.atualizaAgendamentosDaySala(date);
                break;
        }
    }

    getConfiguracaoUnidadeLogada = async () => {
        if (this.isLoadingUnidadeConfig) return;
        try {
            this.isLoadingUnidadeConfig = true;
            const response = await findByUnidadeLogadaConfiguracaoUnidade();
            const {
                horasPermiteConfirmarAgendamento,
                utilizaEnderecoMensagemAgendamento,
                utilizaConvenioAgendamento,
                utilizaContratoAzulControle,
                utilizaControleSessao
            } = response.data.data.findByUnidadeLogadaConfiguracaoUnidade;
            this.objView.horasPermiteAgendamento = horasPermiteConfirmarAgendamento;
            this.objView.utilizaEnderecoMensagemAgendamento = utilizaEnderecoMensagemAgendamento;
            this.isConvenioObrigatorio = utilizaConvenioAgendamento;
            this.utilizaContratoAzulControle = utilizaContratoAzulControle;
            this.utilizaControleSessao = utilizaControleSessao;
            return response.data.data.findByUnidadeLogadaConfiguracaoUnidade;
        } catch (error) {
            console.error(error);
        } finally {
            this.isLoadingUnidadeConfig = false;
        }
    }

    async atualizaAgendamentosWeek(date = Moment()) {
        let dateAux = Moment(date);
        const { unidadeId, profissionalSaudeId } = this.getSelectValuesFromFiltroStore();
        const profissionalSaudeAtual = await getProfissionalSaudeLogado();

        const metodoAgendamento = profissionalSaudeAtual.id !== profissionalSaudeId ? 'findAllAgendamentoOutrosProfissionaisSaude' : 'findAllAgendamento';

        const result = await Api.post('', {
            query:
                `   query($filterAgendamento: SearchAgendamentoDTOInput){
                        agendamentos: ${metodoAgendamento}(searchDTO: $filterAgendamento) {
                            content {
                                id
                                nome
                                documento
                                chavePublica
                                mensagemWhatsappEnviada
                                whatsappMensagemQuantidade
                               documentosAssinadosCliente {
                                    id
                                    tipo
                                    urlDownload
                                    chaveAssinatura
                                    nome
                                    situacao
                                }
                               documentoTipo {
                                    id
                                    descricao
                                }
                                dataNascimento
                                data
                                telefone
                                telefoneDDI  
                                situacao
                                horaFim
                                horaInicio
                                remoto
                                possuiPaciente
                                observacao
                                sala{
                                    id
                                    nome
                                }
                                convenio {
                                    descricao
                                }
                                tipo{
                                    descricao
                                }
                                origemHealth
                                sujeitoAtencao{
                                    id
                                    nome
                                    nomeSocialOuNome
                                    contato{
                                        telefonePrincipal
                                        telefonePrincipalDDI
                                    }
                                    dadosConvenio{
                                        convenio{
                                            descricao
                                        }
                                    }
                                }
                                procedimentos{
                                    id
                                    procedimento{
                                        nome
                                    }
                                }
                            }
                        }
                    }`,
            variables: {
                filterAgendamento: {
                    dataInicial: dateAux.days(0).format('YYYY-MM-DD'),
                    dataFinal: dateAux.days(6).format('YYYY-MM-DD'),
                    unidadeId,
                    profissionalSaudeId,
                    pageSize: 2000,
                    pageNumber: 0
                }
            }
        });

        const { agendamentos } = result.data.data;
        if (agendamentos) {
            this.eventos = agendamentos.content.map(value => {
                const min = Moment(this.calendarConfig.min).format('HH:mm');
                if (Moment(value.horaInicio, 'HH:mm').isBefore(Moment(min, 'HH:mm'))) {
                    return null;
                }
                const max = Moment(this.calendarConfig.max).format('HH:mm');
                if (Moment(value.horaFim, 'HH:mm').isAfter(Moment(max, 'HH:mm'))) {
                    return null;
                }
                return {
                    id: value.id,
                    nome: value.nome,
                    status: value.situacao,
                    telefone: value.telefone,
                    telefoneDDI: value.telefoneDDI,
                    convenio: value.convenio?.descricao || '',
                    data: value.data,
                    start: Moment(`${value.data} ${value.horaInicio}`, 'YYYY-MM-DD HH:mm'),
                    end: Moment(`${value.data} ${value.horaFim}`, 'YYYY-MM-DD HH:mm'),
                    permiteAgendamento: true,
                    tipoConsulta: value.tipo?.descricao,
                    origemHealth: value.origemHealth,
                    sujeitoAtencaoTelefone: value.sujeitoAtencao?.contato?.telefonePrincipal || '',
                    sujeitoAtencaoTelefoneDDI: value.sujeitoAtencao?.contato?.telefonePrincipalDDI || '',
                    sujeitoAtencaoNome: value.sujeitoAtencao?.nomeSocialOuNome || '',
                    sujeitoAtencaoId: value.sujeitoAtencao?.id || '',
                    sujeitoAtencaoConvenio: value.sujeitoAtencao?.dadosConvenio?.convenio?.descricao || '',
                    procedimentos: value?.procedimentos || [],
                    remoto: value.remoto,
                    observacao: value?.observacao || '',
                    salaNome: value?.sala?.nome || '',
                    chavePublica: value.chavePublica,
                    mensagemWhatsappEnviada: value.mensagemWhatsappEnviada,
                    whatsappMensagemQuantidade: value?.whatsappMensagemQuantidade || 0,
                }
            }
            ).filter(Boolean);
        }

        if (this?.configVigente)
            this.eventos.push(...this.filterAgendamentos(this.configVigente));

        this.filterEvents();
    }

    async atualizaAgendamentosDay(date = Moment()) {
        const { unidadeId, profissionalSaudeId } = this.getSelectValuesFromFiltroStore();
        const profissionalLogado = await getProfissionalSaudeLogado();

        if (profissionalSaudeId === 0) return;
        let metodoAgendamento = 'findAllAgendamento';
        if (profissionalLogado.id !== profissionalSaudeId) {
            metodoAgendamento = 'findAllAgendamentoOutrosProfissionaisSaude';
        }

        const result = await Api.post('', {
            query:
                `   query($filterAgendamento: SearchAgendamentoDTOInput){
                        agendamentos: ${metodoAgendamento}(searchDTO: $filterAgendamento) {
                            content {
                                id
                                nome
                                cpf
                                documento
                                chavePublica
                                mensagemWhatsappEnviada
                                whatsappMensagemQuantidade
                                 documentosAssinadosCliente {
                                    id
                                    tipo
                                    urlDownload
                                    chaveAssinatura
                                    nome
                                    situacao
                                }
                                remoto
                                linkTelemedicina
                                possuiVoucher
                                titulos {
                                    id
                                    ativo
                                }
                                documentoTipo {
                                    id
                                    descricao
                                }
                                dataNascimento
                                observacao
                                procedimentoPedido {
                                    procedimentosSolicitados  {
                                        procedimento {
                                            nome
                                        }
                                    }
                                }
                                data
                                telefone
                                telefoneDDI  
                                situacao
                                horaFim
                                horaInicio
                                possuiPaciente
                                procedimentos{
                                    id
                                    procedimento{
                                        nome
                                    }
                                    procedimentoSolicitado {
                                        id    
                                        possuiNota
                                        titulo {
                                            id
                                            ativo
                                        }
                                    }
                                }
                                dataChegadaSujeitoAtencao
                                dataInicioAtendimento 
                                unidade {
                                    id
                                    nomeFantasia
                                    endereco {
                                        nomeLogradouro
                                        numero
                                        cep
                                        bairro
                                        estado {
                                            id
                                            nome
                                            uf
                                        }
                                        municipio {
                                            id
                                            nome
                                            uf
                                        }
                                    }
                                }
                                profissionalSaude{
                                    id
                                    nome
                                }
                                paciente {
                                    id
                                }
                                origemHealth
                                tipo {
                                    descricao
                                    destaque
                                }
                                convenio {
                                    descricao
                                }
                                sujeitoAtencao{
                                    id
                                    dataNascimento,
                                    fotoPerfil
                                    nome
                                    nomeSocialOuNome
                                    dataNascimento
                                    contato{
                                        telefonePrincipal
                                        telefonePrincipalDDI
                                    }
                                    dadosConvenio{
                                        convenio{
                                            descricao
                                        }
                                    }
                                }
                            }
                        }
                    }`,
            variables: {
                filterAgendamento: {
                    dataInicial: date.format('YYYY-MM-DD'),
                    dataFinal: date.format('YYYY-MM-DD'),
                    unidadeId,
                    profissionalSaudeId,
                    pageSize: 2000,
                    pageNumber: 0
                }
            }
        });

        const { agendamentos } = result?.data?.data;
        this.eventos = agendamentos?.content.map(value => {
            return {
                id: value.id,
                nome: value.nome,
                status: value.situacao,
                telefone: value.telefone,
                telefoneDDI: value.telefoneDDI,
                data: value.data,
                start: Moment(`${value.data} ${value.horaInicio}`, 'YYYY-MM-DD HH:mm'),
                end: Moment(`${value.data} ${value.horaFim}`, 'YYYY-MM-DD HH:mm'),
                permiteAgendamento: true,
                convenio: value.convenio?.descricao,
                tipo: value.tipo?.descricao,
                destaqueTipo: value.tipo?.destaque,
                origemHealth: value.origemHealth,
                idade: value.sujeitoAtencao?.dataNascimento ? Dates.calculaIdade(value.sujeitoAtencao?.dataNascimento) : '',
                dataNascimento: value.sujeitoAtencao?.dataNascimento || '',
                fotoPerfil: value.sujeitoAtencao?.fotoPerfil ? buildUrlFotoPerfil(value.sujeitoAtencao?.fotoPerfil, this.accessToken) : null,
                sujeitoAtencaoTelefone: value.sujeitoAtencao?.contato?.telefonePrincipal || '',
                sujeitoAtencaoTelefoneDDI: value.sujeitoAtencao?.contato?.telefonePrincipalDDI || '',
                sujeitoAtencaoNome: value.sujeitoAtencao?.nomeSocialOuNome || '',
                sujeitoAtencaoConvenio: value.sujeitoAtencao?.dadosConvenio?.convenio?.descricao || '',
                sujeitoAtencaoId: value.sujeitoAtencao?.id || '',
                chavePublica: value.chavePublica,
                mensagemWhatsappEnviada: value.mensagemWhatsappEnviada,
                whatsappMensagemQuantidade: value?.whatsappMensagemQuantidade || 0,
                remoto: value?.remoto,
                linkTelemedicina: value?.linkTelemedicina,
                observacao: value?.observacao || '',
                procedimentos: value?.procedimentos || [],
                procedimentoPedido: value?.procedimentoPedido || [],
                possuiVoucher: value?.possuiVoucher || false,
                titulos: value?.titulos || [],
                dataChegadaSujeitoAtencao : value?.dataChegadaSujeitoAtencao,
                dataInicioAtendimento : value?.dataInicioAtendimento,
                documentosAssinadosCliente:value?.documentosAssinadosCliente,
            }
        }
        ).filter(Boolean);

        if (this.configVigente && Array.isArray(this.eventos))
            this.eventos.push(...this.filterAgendamentosDay(this.configVigente));

        this.filterEvents();
    }

    async atualizaAgendamentosDaySala(date = Moment()) {
        const { unidadeId, salaId } = this.getSelectValuesFromFiltroStore();

        if (salaId === '0') return;
        const result = await Api.post('', {
            query:
                `   query($filterAgendamento: SearchAgendamentoDTOInput, $pageableDTO: PageableDTOInput){
                        agendamentos: findAllAgendamentoSala(searchDTO: $filterAgendamento, pageableDTO: $pageableDTO) {
                            content {
                                id
                                nome
                                documento
                                dataNascimento
                                data
                                telefone 
                                telefoneDDI 
                                situacao
                                horaFim
                                horaInicio
                                chavePublica
                                whatsappMensagemQuantidade
                                observacao
                                titulos {
                                    id
                                    ativo
                                }
                                procedimentoPedido {
                                    procedimentosSolicitados  {
                                        procedimento {
                                            nome
                                        }
                                    }
                                }
                                tipo {
                                    descricao
                                }
                                convenio {
                                    descricao
                                }
                                profissionalSaude {
                                    nome
                                }
                                solicitante {
                                    nome
                                }
                                procedimentos {
                                    id
                                    procedimento {
                                        id
                                        nome
                                    }
                                    procedimentoSolicitado {
                                        id
                                        possuiNota
                                        titulo {
                                            id
                                            ativo
                                        }
                                    }
                                }
                                sujeitoAtencao{
                                    id
                                    dataNascimento,
                                    fotoPerfil
                                    nome
                                    nomeSocialOuNome
                                    dataNascimento
                                    contato{
                                        telefonePrincipal
                                        telefonePrincipalDDI
                                    }
                                    dadosConvenio{
                                        convenio{
                                            descricao
                                        }
                                    }
                                }
                                unidade {
                                    nomeFantasia
                                }
                                dataChegadaSujeitoAtencao
                                dataInicioAtendimento
                                origemHealth
                            }
                        }
                    }`,
            variables: {
                filterAgendamento: {
                    dataInicial: date.format('YYYY-MM-DD'),
                    dataFinal: date.format('YYYY-MM-DD'),
                    unidadeId,
                    salaId

                },
                pageableDTO: {
                    pageSize: 2000,
                    pageNumber: 0
                }
            }
        });

        const { agendamentos } = result?.data?.data;
        if (!agendamentos) return;

        this.eventosSala = agendamentos.content.map(value => {
            return {
                id: value.id,
                nome: value.nome,
                status: value.situacao,
                telefone: value.sujeitoAtencao?.contato?.telefonePrincipal || value?.telefone,
                telefoneDDI: value.sujeitoAtencao?.contato?.telefonePrincipalDDI || value?.telefoneDDI,
                chavePublica: value?.chavePublica,
                whatsappMensagemQuantidade: value?.whatsappMensagemQuantidade || 0,
                start: Moment(`${value.data} ${value.horaInicio}`, 'YYYY-MM-DD HH:mm'),
                end: Moment(`${value.data} ${value.horaFim}`, 'YYYY-MM-DD HH:mm'),
                permiteAgendamento: true,
                convenio: value.convenio?.descricao,
                tipo: value.tipo?.descricao,
                idade: value.sujeitoAtencao?.dataNascimento ? Dates.calculaIdade(value.sujeitoAtencao?.dataNascimento) : '',
                dataNascimento: value.sujeitoAtencao?.dataNascimento || '',
                fotoPerfil: value.sujeitoAtencao?.fotoPerfil ? buildUrlFotoPerfil(value.sujeitoAtencao?.fotoPerfil, this.accessToken) : null,
                sujeitoAtencaoTelefone: value.sujeitoAtencao?.contato?.telefonePrincipal || '',
                sujeitoAtencaoNome: value.sujeitoAtencao?.nomeSocialOuNome || '',
                sujeitoAtencaoConvenio: value.sujeitoAtencao?.dadosConvenio?.convenio?.descricao || '',
                solicitante: value?.solicitante?.nome || '',
                profissionalSaude: value?.profissionalSaude?.nome || '',
                procedimentos: value?.procedimentos || [],
                procedimentosString: string.concatenarArrayProcedimentosEmString(value?.procedimentos),
                nomeFantasiaUnidade: value?.unidade?.nomeFantasia || '',
                observacao: value?.observacao || '',
                procedimentoPedido: value?.procedimentoPedido || [],
                titulos: value?.titulos || [],
                dataChegadaSujeitoAtencao:value?.dataChegadaSujeitoAtencao,
                dataInicioAtendimento: value?.dataInicioAtendimento,
                origemHealth:value?.origemHealth,
            }
        }
        ).filter(Boolean);

        if (this.configVigenteSala && Array.isArray(this.eventosSala))
            this.eventosSala.push(...this.filterAgendamentosDay(this.configVigenteSala));

        this.filterEventsSala();
    }

    async atualizaAgendamentosThreeDays(date = Moment(), dateFinal = Moment().add(3, 'days')) {
        const { unidadeId, profissionalSaudeId } = this.getSelectValuesFromFiltroStore();
        const profissionalLogado = await getProfissionalSaudeLogado();

        if (profissionalSaudeId === 0) return;
        let metodoAgendamento = 'findAllAgendamento';
        if (profissionalLogado.id !== profissionalSaudeId) {
            metodoAgendamento = 'findAllAgendamentoOutrosProfissionaisSaude';
        }

        const result = await Api.post('', {
            query:
                `   query($filterAgendamento: SearchAgendamentoDTOInput){
                        agendamentos: ${metodoAgendamento}(searchDTO: $filterAgendamento) {
                            content {
                                id
                                nome
                                cpf
                                documento
                                documentoTipo {
                                    id
                                    descricao
                                }
                                dataNascimento
                                data
                                telefone
                                telefoneDDI  
                                situacao
                                horaFim
                                horaInicio
                                possuiPaciente
                                observacao
                                procedimentoPedido {
                                    procedimentosSolicitados  {
                                        procedimento {
                                            nome
                                        }
                                    }
                                }
                                tipo {
                                    descricao
                                }
                                convenio {
                                    descricao
                                }
                                paciente {
                                id
                                }
                                sujeitoAtencao{
                                    id
                                    dataNascimento,
                                    fotoPerfil
                                    nome
                                    nomeSocialOuNome
                                    dataNascimento
                                    contato{
                                    telefonePrincipal
                                    telefonePrincipalDDI
                                    }
                                    dadosConvenio{
                                    convenio{
                                        descricao
                                    }
                                }
                            }
                        }
                    }
                }`,
            variables: {
                filterAgendamento: {
                    dataInicial: date.format('YYYY-MM-DD'),
                    dataFinal: Moment(date).add(3, 'days').format('YYYY-MM-DD'),
                    unidadeId,
                    profissionalSaudeId,
                    pageSize: 2000,
                    pageNumber: 0
                }
            }
        });

        const { agendamentos } = result.data.data;
        const agendamentosEventos = agendamentos.content.map(value => {
            return {
                id: value.id,
                nome: value.nome,
                status: value.situacao,
                telefone: value.telefone,
                telefoneDDI: value.telefoneDDI,
                start: Moment(`${value.data} ${value.horaInicio}`, 'YYYY-MM-DD HH:mm'),
                end: Moment(`${value.data} ${value.horaFim}`, 'YYYY-MM-DD HH:mm'),
                permiteAgendamento: true,
                convenio: value.convenio?.descricao,
                tipo: value.tipo?.descricao,
                idade: value.sujeitoAtencao?.dataNascimento ? Dates.calculaIdade(value.sujeitoAtencao?.dataNascimento) : '',
                dataNascimento: value.sujeitoAtencao?.dataNascimento || '',
                fotoPerfil: value.sujeitoAtencao?.fotoPerfil ? buildUrlFotoPerfil(value.sujeitoAtencao?.fotoPerfil, this.accessToken) : null,
                sujeitoAtencaoTelefone: value.sujeitoAtencao?.contato?.telefonePrincipal || '',
                sujeitoAtencaoTelefoneDDI: value.sujeitoAtencao?.contato?.telefonePrincipalDDI || '',
                sujeitoAtencaoNome: value.sujeitoAtencao?.nomeSocialOuNome || '',
                sujeitoAtencaoConvenio: value.sujeitoAtencao?.dadosConvenio?.convenio?.descricao || '',
                observacao: value?.observacao || '',
                procedimentoPedido: value?.procedimentoPedido || [],
            }
        }
        ).filter(Boolean);

        if (this.configVigente)
            this.eventos = [...agendamentosEventos, ...this.filterAgendamentosThreeDays(this.configVigente)]
        this.filterEvents();
    }


    async atualizaAgendamentosWeekSala(date = Moment()) {
        let dateAux = Moment(date);
        const { unidadeId, salaId } = this.getSelectValuesFromFiltroStore();

        const result = await Api.post('', {
            query:
                `   query($filterAgendamento: SearchAgendamentoDTOInput, $pageableDTO: PageableDTOInput){
                    agendamentos: findAllAgendamentoSala(searchDTO: $filterAgendamento, pageableDTO: $pageableDTO) {
                            content {
                                id
                                nome
                                documento
                                documentoTipo {
                                    id
                                    descricao
                                }
                                dataNascimento
                                data
                                telefone  
                                telefoneDDI  
                                situacao
                                horaFim
                                horaInicio
                                remoto
                                convenio {
                                    descricao
                                }
                                tipo{
                                    descricao
                                }
                                procedimentos {
                                    id
                                    procedimento {
                                        id
                                        nome
                                    }
                                }
                                sujeitoAtencao{
                                    id
                                    nome
                                    nomeSocialOuNome
                                    contato{
                                        telefonePrincipal
                                        telefonePrincipalDDI
                                    }
                                    dadosConvenio{
                                        convenio{
                                            descricao
                                        }
                                    }
                                }
                            }
                        }
                    }`,
            variables: {
                filterAgendamento: {
                    dataInicial: dateAux.days(0).format('YYYY-MM-DD'),
                    dataFinal: dateAux.days(6).format('YYYY-MM-DD'),
                    unidadeId,
                    salaId,
                },
                pageableDTO: {
                    pageSize: 2000,
                    pageNumber: 0
                }
            }
        });

        const { agendamentos } = result.data.data;

        if (!agendamentos) return;
        if (agendamentos) {
            this.eventosSala = agendamentos.content.map(value => {
                const min = Moment(this.calendarConfig.min).format('HH:mm');
                if (Moment(value.horaInicio, 'HH:mm').isBefore(Moment(min, 'HH:mm'))) {
                    return null;
                }
                const max = Moment(this.calendarConfig.max).format('HH:mm');
                if (Moment(value.horaFim, 'HH:mm').isAfter(Moment(max, 'HH:mm'))) {
                    return null;
                }
                return {
                    id: value.id,
                    nome: value.nome,
                    status: value.situacao,
                    telefone: value.telefone,
                    start: Moment(`${value.data} ${value.horaInicio}`, 'YYYY-MM-DD HH:mm'),
                    end: Moment(`${value.data} ${value.horaFim}`, 'YYYY-MM-DD HH:mm'),
                    permiteAgendamento: true,
                    tipoConsulta: value.tipo?.descricao,
                    sujeitoAtencaoTelefone: value.sujeitoAtencao?.contato?.telefonePrincipal || '',
                    sujeitoAtencaoNome: value.sujeitoAtencao?.nomeSocialOuNome || '',
                    sujeitoAtencaoConvenio: value.sujeitoAtencao?.dadosConvenio?.convenio?.descricao || '',
                    remoto: value?.remoto
                }
            }
            ).filter(Boolean);
        }

        if (this.configVigenteSala && Array.isArray(this.eventosSala))
            this.eventosSala.push(...this.filterAgendamentos(this.configVigenteSala));

        this.filterEventsSala();
    }


    async atualizaAgendamentosFilter(clear = false) {
        if (this.loadingFindAllAgendamentoEEncaixe) return

        this.loadingFindAllAgendamentoEEncaixe = true;
        try {
            const { profissionalSaudeId, ativo, municipioId } = this.listaFilter?.searchDTO;

            const dataInicio = this.listaFilter.searchDTO?.dataInicio;
            const dataFim = this.listaFilter.searchDTO?.dataFim

            const searchDTO = {
                search: this.listaFilter.search,
                ativo: ativo?.value,
                profissionalSaudeId: profissionalSaudeId?.id,
                dataInicial: dataInicio && Moment(dataInicio).format('YYYY-MM-DD'),
                dataFinal: dataFim && Moment(dataFim).format('YYYY-MM-DD'),
                municipioId: municipioId?.value
            };

            if (clear) {
                this.listaFilter.pageNumber = 0;
                this.listaFilter.content = [];
                this.listaFilter.last = false;
            };

            const pageableDTO = {
                sortFields: [{ "campo": "data", "direcao": "DESC" },
                { "campo": "horaInicio", "direcao": "ASC" }],
                sortDir: 'DESC',
                pageSize: 30,
                pageNumber: this.listaFilter.pageNumber
            }

            const response = await Api.post('', {
                query: `
                    query ($searchDTO: SearchAgendamentoDTOInput, $pageableDTO: PageableDTOInput) {
                        findAllAgendamentoEEncaixe(searchDTO: $searchDTO, pageableDTO: $pageableDTO) {
                            last
                            totalPages
                            content {
                                id
                                nome
                                cpf
                                documento
                                documentoTipo {
                                    id
                                    descricao
                                }
                                telefone
                                telefoneDDI
                                data
                                horaInicio
                                horaFim
                                observacao
                                unidade{
                                    id
                                }
                                convenio{
                                    id
                                    descricao
                                }
                                tipo{
                                    id
                                    descricao
                                }
                                situacao
                                profissionalSaude{
                                    id
                                    nome
                                }
                                origemHealth
                                sala {
                                    id
                                    nome
                                }
                                solicitante {
                                    id
                                    nome
                                }
                                sala{
                                    id
                                    nome
                                }
                                procedimentos{
                                    nomeProcedimento
                                    procedimento{
                                        id
                                        nome
                                    }
                                }
                                procedimentoPedido {
                                    procedimentosSolicitados {
                                        procedimento {
                                        nome
                                        }
                                    }
                                }
                                titulos {
                                    tituloProcedimentos  {
                                        titulo {
                                            id
                                            valor
                                            tipo
                                            tipoDesconto
                                        }
                                        quantidade

                                    }
                                    parcelas {
                                        id
                                        desconto
                                        valor
                                        tipoDesconto
                                        excluido
                                        dataVencimento
                                        dataPagamento
                                        numeroParcela
                                        totalParcelas
                                        chavePublica
                                    }
                                    ativo
                                }
                                remoto
                                sala {
                                    id
                                    nome
                                }
                                dataNascimento
                            }
                        }
                    }
                `,
                variables: {
                    searchDTO,
                    pageableDTO
                }
            });

            if (response.data.errors) {
                throw response.data.errors[0];
            }

            const agendamentos = response.data.data.findAllAgendamentoEEncaixe;
            this.listaFilter.content = [...this.listaFilter.content, ...agendamentos?.content];
            this.listaFilter.last = agendamentos?.last;
            return agendamentos;
        } catch (error) {
            console.error(error);
            this.listaFilter.last = true;
        } finally {
            this.loadingFindAllAgendamentoEEncaixe = false;
        }
    }

    async findAllHorariosLivresUnidade(variables) {
        try {
            this.horariosDisponiveis.loading = true;

            const pageableDTO = {
                pageNumber: this.horariosDisponiveis.pageable.pageNumber,
                pageSize: 30
            }

            const horaInicio = variables.periodos?.value.horaInicio
            const horaFim = variables.periodos?.value.horaFim
            const dataInicio = variables.dataInicio && Moment(variables.dataInicio).format("YYYY-MM-DD")
            const dataFim = variables.dataFim && Moment(variables.dataFim).format("YYYY-MM-DD")
            const profissionaisSaude = variables.profissionalSaudeId && [{
                id: variables.profissionalSaudeId.id
            }]
            const especialidades = variables.especialidadeMedica && [{
                id: variables.especialidadeMedica.value
            }]
            const tipoConsulta = !!variables.tipoConsulta ? variables.tipoConsulta.map(item => item.value) : null
            const response = await Api.post('', {
                query: `
                    query (
                        $horaFim: LocalTime, 
                        $dataFim: LocalDate, 
                        $profissionaisSaude: [ProfissionalSaudeInput], 
                        $dataInicio: LocalDate, 
                        $horaInicio: LocalTime, 
                        $pageableDTO: PageableDTOInput,
                        $especialidades: [EspecialidadeMedicaInput],
                        $tipoConsulta: [TipoConsulta]) {
                            findAllHorariosLivresUnidade(
                                horaFim: $horaFim, 
                                dataFim: $dataFim, 
                                profissionaisSaude: $profissionaisSaude, 
                                dataInicio: $dataInicio, 
                                horaInicio: $horaInicio, 
                                pageableDTO: $pageableDTO,
                                especialidades: $especialidades
                                tipoConsulta: $tipoConsulta) {
                                    last
                                    totalElements
                                    numberOfElements
                                    content {
                                        data
                                        horaInicio
                                        horaFim
                                        profissionalSaudeId
                                        profissionalSaudeNome
                                    }
                                }
                            }
                `,
                variables: {
                    dataInicio,
                    dataFim,
                    horaFim,
                    horaInicio,
                    pageableDTO,
                    profissionaisSaude,
                    especialidades,
                    tipoConsulta
                }
            });
            const agendamentos = response.data.data.findAllHorariosLivresUnidade;
            this.horariosDisponiveis.content = [...this.horariosDisponiveis.content, ...agendamentos?.content];
            this.horariosDisponiveis.last = agendamentos?.last;
            return this.horariosDisponiveis;
        } catch (error) {
            console.error(error);
        } finally {
            this.horariosDisponiveis.loading = false;
        }
    }

    async atualizaAgendamentosEncaixes(date = Moment()) {
        const { unidadeId, profissionalSaudeId } = this.getSelectValuesFromFiltroStore();

        const result = await Api.post('', {
            query:
                `query ($searchDTO: SearchAgendamentoDTOInput, $pageableDTO: PageableDTOInput) {
                    findAllEncaixe(searchDTO:$searchDTO, pageableDTO:$pageableDTO){
                        last
                        pageable{
                            pageNumber
                        }
                        content{
                            id
                            nome
                            cpf
                            documento
                            documentoTipo {
                                id
                                descricao
                            }
                            observacao
                            procedimentoPedido {
                                procedimentosSolicitados  {
                                    procedimento {
                                        nome
                                    }
                                }
                            }
                            telefone
                            data
                            unidade{
                                id
                            }
                            convenio{
                                id
                                descricao
                            }
                            tipo{
                                id
                                descricao
                            }
                            situacao
                            sujeitoAtencao{
                                nome
                                nomeSocialOuNome
                                dataNascimento
                                contato{
                                    telefonePrincipal
                                }
                                dadosConvenio{
                                    convenio{
                                        descricao
                                    }
                                }
                            }
                            dataChegadaSujeitoAtencao
                            dataInicioAtendimento
                            remoto
                            procedimentoPedido {
                                id
                                procedimentosSolicitados {
                                    procedimento {
                                        nome
                                        id
                                    }
                                }
                            }
                            procedimentos {
                                procedimento{

                                    id
                                    nome
                                }
                            }
                        }
                    }
                }`,
            variables: {
                searchDTO: {
                    unidadeId,
                    profissionalSaudeId,
                    dataInicial: date.format('YYYY-MM-DD'),
                    dataFinal: date.format('YYYY-MM-DD'),
                    pageSize: 2000,
                    pageNumber: 0
                },
                pageableDTO: {
                    pageSize: 2000,
                }
            }
        });

        const agendamentos = result.data.data;
        this.eventosEncaixes = agendamentos?.findAllEncaixe.content.map(value => {
            return {
                id: value.id,
                nome: value.nome,
                status: value.situacao,
                telefone: value.telefone,
                permiteAgendamento: true,
                convenio: value.convenio?.descricao,
                tipo: value.tipo?.descricao ? string.capitalize(value.tipo.descricao) : '',
                idade: value.sujeitoAtencao?.dataNascimento ? Dates.calculaIdade(value.sujeitoAtencao?.dataNascimento) : '',
                dataNascimento: value.sujeitoAtencao?.dataNascimento,
                fotoPerfil: value.sujeitoAtencao?.fotoPerfil ? buildUrlFotoPerfil(value.sujeitoAtencao?.fotoPerfil, this.accessToken) : null,
                sujeitoAtencaoTelefone: value.sujeitoAtencao?.contato?.telefonePrincipal || '',
                sujeitoAtencaoNome: value.sujeitoAtencao?.nomeSocialOuNome || '',
                sujeitoAtencaoConvenio: value.sujeitoAtencao?.dadosConvenio?.convenio?.descricao || '',
                observacao: value?.observacao || '',
                procedimentoPedido: value?.procedimentoPedido || [],
                remoto: value?.remoto,
                procedimentos: value?.procedimentos,
                dataChegadaSujeitoAtencao: value?.dataChegadaSujeitoAtencao,
                dataInicioAtendimento:value?.dataInicioAtendimento,
            }
        });

        this.filterEvents();

    }

    @action openExcluirAgendamentoAlert(agendamento) {
        const agendamentoArray = Array.isArray(agendamento) ? agendamento : [agendamento]
        let description = '';
        let titleMessage = '';

        agendamentoArray.map(item => {
            const situacao = item.situacao || item.status;
            const dia = item.start ? Moment(item.start).format('DD/MM/YYYY') : Moment(item.data).format('DD/MM/YYYY');
            const horaInicio = item.start ? Moment(item.start).format('HH:mm') : item.horaInicio;
            const horaFim = item.end ? Moment(item.end).format('HH:mm') : item.horaFim;
            titleMessage = situacao === 'BLOQUEADO' ? `Deseja excluir o bloqueio?` : `Deseja excluir a consulta de ${item?.nome?.trim() || item?.title}?`;
            description = description + `${dia} ${horaInicio && horaFim ? `${horaInicio} - ${horaFim}` : ``};`
        });

        const descriptionArray = description.split(';');
        this.excluirAgendamentoAlertData = {
            ...this.excluirAgendamentoAlertData,
            agendamento,
            openAlert: true,
            title: titleMessage,
            description: descriptionArray
        };
    }

    @action closeExcluirAgendamentoAlert = () => {
        this.excluirAgendamentoAlertData = {
            ...this.excluirAgendamentoAlertData,
            agendamento: null,
            openAlert: false,
        }
    }

    @action
    async excluirAgendamento({ id }) {
        try {
            this.disableModalButtons = true;
            this.cancelandoAgendamento = true;
            await Api.post('', {
                query:
                    `
                    mutation($id: Long){
                        excluirAgendamento(id: $id) {
                            id
                        }
                    }`,
                variables: {
                    id
                }
            });
            await db.remove("prontuarioAgendamentoSelecionado");
            const willReload = this.typeCalendarViewSelected === 'filterPacientView';
            this.atualizaAgendamentos(this.selectedDate, null, willReload);
            if (this.isSalaSelected || this.tipoProfissionalLogado === 'tecnico') {
                this.atualizaAgendamentosSala(this.selectedDate);
            }
            this.disableModalButtons = false;
            this.cancelandoAgendamento = false;
            this.onExit();
        } catch (error) {
            this.disableModalButtons = false;
            this.cancelandoAgendamento = false;
            console.error(error);
        }
    }

    @action
    async updateSituacaoAgendamento({ id, situacao }) {
        const response = await Api.post('', {
            query:
                `
                mutation($situacao: AgendamentoSituacao, $id: Long){
                    updateAgendamentoSituacao(situacao: $situacao, id: $id) {
                        id
                    }
                }`,
            variables: {
                situacao,
                id
            }
        });

        this.atualizaAgendamentos(this.selectedDate);

        if (!response?.data?.errors) {
            return response.data.data.updateAgendamentoSituacao.id;
        } else {
            throw response.data.errors[0].message;
        }
    }

    filterAgendamentos(config) {
        let dateAux = Moment(this.selectedDate);
        let days = ['domingo', 'segunda', 'terca', 'quarta', 'quinta', 'sexta', 'sabado'];
        let horarios = [];
        days.forEach((day, index) => {
            if (!config[day])
                return []

            horarios.push(...config[day].map((diaSemana) => {

                let min = Moment(this.calendarConfig.min).format('HH:mm');
                if (Moment(diaSemana.horaInicio, 'HH:mm').isBefore(Moment(min, 'HH:mm'))) {
                    this.calendarConfig.min = Moment(diaSemana.horaInicio, 'HH:mm').day(0).toDate();
                }
                let max = Moment(this.calendarConfig.max).format('HH:mm');
                if (Moment(diaSemana.horaFim, 'HH:mm').isAfter(Moment(max, 'HH:mm'))) {
                    this.calendarConfig.max = Moment(diaSemana.horaFim, 'HH:mm').day(6).toDate();
                }
                if (!diaSemana.permiteAgendamento) {
                    return {
                        permiteAgendamento: diaSemana.permiteAgendamento,
                        start: Moment(`${dateAux.day(index).format('YYYY-MM-DD')} ${diaSemana.horaInicio}`, 'YYYY-MM-DD HH:mm').toDate(),
                        end: Moment(`${dateAux.day(index).format('YYYY-MM-DD')} ${diaSemana.horaFim}`, 'YYYY-MM-DD HH:mm').toDate(),
                        status: 'BLOQUEADO'
                    };
                }

                return null;
            }).filter(value => value))
        });
        return horarios;
    }

    @action filterAgendamentosDay(config) {
        const daysOfWeek = Dates.daysOfWeekTransform();
        const days = ['domingo', 'segunda', 'terca', 'quarta', 'quinta', 'sexta', 'sabado'];
        const selectDateString = this.selectedDate.format('dddd');
        const selectedDay = daysOfWeek[string.capitalize(selectDateString)];

        let daysConfigure = [];
        for (let i = 0; i < days.length; i++) {
            if (days[i] === selectedDay) {
                daysConfigure.push(days[i]);
            }
        }

        let horarios = [];
        daysConfigure.forEach((day) => {
            if (!config[day])
                return [];

            horarios.push(...config[day].map((diaSemana) => {

                let min = Moment(this.calendarConfig.min).format('HH:mm');
                if (Moment(diaSemana.horaInicio, 'HH:mm').isBefore(Moment(min, 'HH:mm'))) {
                    this.calendarConfig.min = Moment(diaSemana.horaInicio, 'HH:mm');
                }
                let max = Moment(this.calendarConfig.max).format('HH:mm');
                if (Moment(diaSemana.horaFim, 'HH:mm').isAfter(Moment(max, 'HH:mm'))) {
                    this.calendarConfig.max = Moment(diaSemana.horaFim, 'HH:mm');
                }
                if (!diaSemana.permiteAgendamento) {
                    return {
                        permiteAgendamento: diaSemana.permiteAgendamento,
                        start: Moment(`${this.selectedDate.format('YYYY-MM-DD')} ${diaSemana.horaInicio}`, 'YYYY-MM-DD HH:mm'),
                        end: Moment(`${this.selectedDate.format('YYYY-MM-DD')} ${diaSemana.horaFim}`, 'YYYY-MM-DD HH:mm'),
                        horaInicio: diaSemana.horaInicio,
                        horaFim: diaSemana.horaFim,
                        situacao: 'BLOQUEADO',
                        status: 'BLOQUEADO'
                    };
                }

                return null;
            }).filter(value => value))
        });
        return horarios;
    }

    @action filterAgendamentosThreeDays(config) {
        const daysOfWeek = Dates.daysOfWeekTransform();

        const diaSelecionadoAux = moment(this.selectedDate)
        const diaSelecionadoAux1 = moment(this.selectedDate)
        const dia2 = diaSelecionadoAux.add(1, 'days');
        const dia3 = diaSelecionadoAux1.add(2, 'days');

        const selectDateString = this.selectedDate.format('dddd');
        const selectedDay2String = dia2.format('dddd');
        const selectedDay3String = dia3.format('dddd');

        const selectedDay = daysOfWeek[string.capitalize(selectDateString)];
        const selectedDay2 = daysOfWeek[string.capitalize(selectedDay2String)];
        const selectedDay3 = daysOfWeek[string.capitalize(selectedDay3String)];

        let daysConfigure = [];

        daysConfigure = [
            selectedDay.replace("-feira", ""),
            selectedDay2.replace("-feira", ""),
            selectedDay3.replace("-feira", "")
        ]

        const days = [this.selectedDate, dia2, dia3]

        let horarios = [];
        daysConfigure.forEach((day, index) => {
            if (!config[day])
                return [];

            const eventos = config[day].map((diaSemana) => {
                let min = Moment(this.calendarConfig.min).format('HH:mm');
                if (Moment(diaSemana.horaInicio, 'HH:mm').isBefore(Moment(min, 'HH:mm'))) {
                    this.calendarConfig.min = Moment(diaSemana.horaInicio, 'HH:mm').day(parseInt(Moment(this.selectedDate).format("d")));
                }
                let max = Moment(this.calendarConfig.max).format('HH:mm');
                if (Moment(diaSemana.horaFim, 'HH:mm').isAfter(Moment(max, 'HH:mm'))) {
                    this.calendarConfig.max = Moment(diaSemana.horaFim, 'HH:mm').day(parseInt(Moment(this.selectedDate).format("d")) + 3);
                }
                if (!diaSemana.permiteAgendamento) {
                    return {
                        permiteAgendamento: diaSemana.permiteAgendamento,
                        start: Moment(`${Moment(days[index]).format('YYYY-MM-DD')} ${diaSemana.horaInicio}`, 'YYYY-MM-DD HH:mm'),
                        end: Moment(`${Moment(days[index]).format('YYYY-MM-DD')} ${diaSemana.horaFim}`, 'YYYY-MM-DD HH:mm'),
                        horaInicio: diaSemana.horaInicio,
                        horaFim: diaSemana.horaFim,
                        situacao: 'BLOQUEADO',
                        status: 'BLOQUEADO'
                    };
                }

                return null;
            }).filter(value => value)

            horarios = [...horarios, ...eventos]
        });
        return horarios;
    }

    @action
    async loadConvenios() {
        const { unidadeId } = this.getSelectValuesFromFiltroStore();

        findConveniosByAtivo(unidadeId)
            .then(({ data: response }) => {
                if (response.data) {
                    const convenios = response.data.convenios.map(convenio => ({
                        ...convenio,
                        name: convenio.descricao,
                        value: convenio.id
                    }));
                    this.convenios = convenios;
                    if (convenios.length > 0) {
                        localStorageService.set(CONVENIOS_KEY, convenios);
                    }
                }
            })
            .catch((error) => console.error(error));
    }

    @action
    async loadAgendamentoTipo(profissionalSaudeIdParam = null) {
        try {
            const { profissionalSaudeId } = this.getSelectValuesFromFiltroStore();

            const response = await findAgendamentoTipoByAtivo(profissionalSaudeIdParam || profissionalSaudeId)
            if (response.data.data) {
                const tipo = response.data.data.findAllAgendamentoTipo.map(agendamentoTipo => ({
                    ...agendamentoTipo,
                    name: agendamentoTipo.descricao,
                    value: agendamentoTipo.id
                }));
                this.tipo = tipo;
                if (tipo.length > 0) {
                    localStorageService.set(TIPO_NOVO_KEY, tipo);
                }
            }

        } catch (e) {
            console.error(e)
        }
    }

    @action loadProcedimentos(salaId) {
        const variables = {
            nome: '',
            ativo: true,
            pageableDTO: {
                pageNumber: 0,
                sortDir: "ASC",
                sortField: "nome"
            },
            salaId
        }
        findAllProcedimentos(variables)
            .then(({ data: response }) => {
                if (response.data) {
                    const procedimentos = response.data.findAllProcedimentos.content.map(procedimento => ({
                        ...procedimento,
                        name: procedimento.nome,
                        value: procedimento.id
                    }))

                    this.procedimentosDisponiveis = procedimentos;
                }
            })
            .catch((error) => console.error(error));
    }

    @action loadAgendamento(id, cb = () => { }, cbFinally = () => { }) {
        this.disableModalButtons = true;
        Api.post('',
            {
                query: `
                        {content: findByIdAgendamento(id: ${id}) { 
                            id 
                            data 
                            situacao 
                            tipo { 
                                id 
                                descricao 
                            }
                            nome 
                            telefone
                            telefoneDDI 
                            cpf
                            documento
                            documentoTipo {
                                id
                                descricao
                            }
                            dataNascimento 
                            remoto 
                            linkTelemedicina 
                            chavePublica
                            especialidadeMedica {
                                id
                                especialidade 
                            }
                            documentosAssinadosCliente {
                            id
                            nome
                            tipo
                            profissionalSaude {
                                id
                            }
                            situacao
                            dataHoraValidade
                            }
                            controleSessaoAgendamentoTipo {
                                id
                                agendamentoTipo {
                                    id
                                    descricao
                                }
                                controleSessao {
                                    id
                                }
                            }
                            procedimentoPedido {
                                id
                                agendamento {
                                    id
                                }
                            }
                            voucherPrever {
                                id
                                dataUtilizacao
                                codigoExterno
                                agendamento {
                                    id
                                }
                            }
                            mensagemWhatsappEnviada
                            whatsappMensagemQuantidade
                            procedimentos {
                                id
                                nomeProcedimento
                                procedimento {
                                    id
                                    nome
                                    convenioProcedimentos {
                                        convenio {
                                            id
                                        }
                                        valor
                                    }
                                }
                                controleSessaoProcedimento{
                                    id
                                }
                                realizado
                                controleSessaoProcedimento {
                                    id
                                    controleSessao {
                                        id
                                    }
                                }
                                procedimentoSolicitado {
                                    id
                                    possuiNota
                                    titulo {
                                        id
                                        ativo
                                    }
                                }
                            }
                            sala {
                                id
                                nome
                            }
                            possuiPaciente
                            solicitante { 
                                id
                                nome
                            }
                            profissionalSaude {
                                id
                                nome
                            }
                            financeiroGuiaServico {
                                id
                                numeroGuiaPrestador
                            }
                            financeiroGuiaConsulta {
                                id
                                numeroGuiaPrestador
                            }
                            observacao 
                            paciente {
                                id
                                dataNascimento,
                                email,
                                endereco {
                                    bairro
                                    cep
                                    complemento
                                    nomeLogradouro
                                    numero
                                    municipio {
                                        nome
                                        id
                                    }
                                    estado {
                                        nome
                                        id
                                    }
                                }
                                fotoPerfil,
                                telefonePrincipal
                            }
                            convenio { 
                                id
                                descricao
                                convenioAgendamentoTipo {
                                    agendamentoTipo {
                                        id
                                    }
                                    valor
                                }
                                convenioProcedimentos {
                                    procedimento {
                                        id
                                    }
                                    valor
                                }
                            }
                            numeroCarteira
                            validadeCarteira
                            horaInicio 
                            horaFim 
                            numeroGuiaTissExterna
                            sujeitoAtencao {
                                id
                                nome
                                cpf
                                nomeSocialOuNome
                                documento
                                documentoTipo {
                                    id
                                    descricao
                                }
                                dataNascimento
                                fotoPerfil
                                contato {
                                    telefonePrincipal
                                    email
                                }
                                sexo {
                                    id
                                    descricao
                                }
                                dadosConvenio{
                                    convenio{
                                        id
                                        descricao
                                        convenioAgendamentoTipo {
                                            agendamentoTipo {
                                                id
                                            }
                                            valor
                                        }
                                    }
                                    numeroCarteira
                                    validadeCarteira
                                }
                                dadosConvenios {
                                    convenio {
                                      descricao
                                      id
                                    }
                                    numeroCarteira
                                    id
                                    validadeCarteira
                                }

                            }
                            agendamentoRecorrencia {
                                id
                                agendamentos {
                                    titulos {
                                        id
                                    }
                                }
                            }
                            urgente
                            listaDeEspera
                            validadeCarteira
                            numeroCarteira
                            unidade {
                                nomeFantasia
                                endereco {
                                    nomeLogradouro
                                    numero
                                    cep
                                    bairro
                                    estado {
                                        id
                                        nome
                                        uf
                                    }
                                    municipio {
                                        id
                                        nome
                                        uf
                                    }
                                }
                            }
                            ultimoAgendamentoByRecorrencia
                        }}
                    `
            }
        ).then(async (result) => {
            let entidade = result.data.data.content;
            this.objView = entidade;
            this.objView.sujeitoAtencao = entidade.sujeitoAtencao;
            this.objView.data = entidade?.data !== "1900-01-01" && entidade.data;
            this.objView.duracao = entidade.horaInicio && {
                label: `${Moment(entidade.horaFim, 'HH:mm').diff(Moment(entidade.horaInicio, 'HH:mm'), 'minute')} min.`,
                value: Moment(entidade.horaFim, 'HH:mm').diff(Moment(entidade.horaInicio, 'HH:mm'), 'minute')
            };
            this.objView.fotoPerfil = entidade.sujeitoAtencao?.fotoPerfil ? buildUrlFotoPerfil(entidade.sujeitoAtencao?.fotoPerfil, this.accessToken) : null;
            this.objView.isConsultaRemota = entidade.remoto;
            this.objView.linkTelemedicina = entidade.linkTelemedicina;
            this.objView.especialidadeMedica = entidade.especialidadeMedica;
            this.objView.email = entidade.sujeitoAtencao?.contato?.email;
            this.objView.convenio = {
                ...entidade.convenio,
                label: entidade.convenio?.descricao,
                value: entidade.convenio?.id
            };
            this.objView.validadeCarteira = entidade?.validadeCarteira;
            this.objView.numeroCarteira = entidade?.numeroCarteira;

            this.objView.tipo = entidade.tipo?.id ? { value: entidade.tipo?.id, label: entidade.tipo?.descricao } : null;
            this.objView.profissionalSaude = entidade.profissionalSaude;
            this.objView.chavePublica = entidade.chavePublica;
            this.objView.mensagemWhatsappEnviada = entidade.mensagemWhatsappEnviada;
            this.objView.whatsappMensagemQuantidade = entidade.whatsappMensagemQuantidade;
            this.objView.dataNascimento = entidade.dataNascimento;
            this.objView.financeiroGuiaServico = entidade.financeiroGuiaServico;
            this.objView.financeiroGuiaConsulta = entidade.financeiroGuiaConsulta;
            this.objView.urgente = entidade.urgente;
            this.objView.listaDeEspera = entidade.listaDeEspera;
            this.idSolicitanteDoProcedimento = entidade.solicitante;
            this.agendamentoRecorrencia = { id: entidade.agendamentoRecorrencia?.id };
            this.isAgendamentoRecorrenciaFaturado = entidade.agendamentoRecorrencia?.agendamentos && this.getAgendamentosTitulos(entidade.agendamentoRecorrencia?.agendamentos);
            const titulos = await this.extratoStore.preencherDadosPagamentoDoAgendamento();
            this.objView.titulo = titulos;
            this.objView.unidade = entidade.unidade;
            this.objView.controleSessao = entidade.controleSessao;
            this.objView.numeroGuiaTissExterna = entidade.numeroGuiaTissExterna;
            this.agendamentoRecorrencia = entidade.agendamentoRecorrencia
            this.procedimentoPedido = entidade.procedimentoPedido;
            if (entidade.convenio) {
                const jaExiste = this.convenios.some(convenio => convenio.id === entidade.convenio.id);
                if (!jaExiste) {
                    this.convenios = [
                        ...this.convenios,
                        {
                            ...entidade.convenio,
                            name: entidade.convenio.descricao,
                            value: entidade.convenio.id,
                            inativo: true
                        }
                    ];
                }
            } else {
                this.convenios = this.convenios.filter(convenio => !convenio.inativo);
            }

            this.isLastAgendamentoOfRecurrence = entidade?.ultimoAgendamentoByRecorrencia || false;

            const contemTipo = this.tipo.some(tipo => tipo.id === entidade.tipo?.id);
            if (!contemTipo) {
                this.tipo = [...this.tipo,
                {
                    ...entidade.tipo,
                    name: entidade.tipo?.descricao,
                    value: entidade.tipo?.id,
                    ativo: false
                }
                ]
            } else {
                this.tipo = this.tipo.filter(tipo => tipo.ativo);
            }

            if (entidade.procedimentos) {
                this.objView.procedimentos = entidade.procedimentos;
            }

            if (entidade.sala) {
                this.objView.sala = entidade.sala;
            }

            if (entidade.solicitante) {
                this.objView.solicitante = entidade.solicitante;
            }

            if (this.objView.situacao === "BLOQUEADO") {
                this.objView.motivo = this.objView.nome;
                this.objView.nome = "";
            }

            let sujeitos = entidade.sujeitosAtencao;
            this.sujeitosAtencao = !sujeitos ? [] : sujeitos.map(p => {
                p.idade = p.dataNascimento ? Moment().diff(p.dataNascimento, 'years') : '?';
                return p;
            });

            this.enableCadastrarNovo = !(this.objView.sujeitoAtencao && this.objView.sujeitoAtencao.id);
            this.disableModalButtons = false;
            this.isAgendamentoModalListaEspera && this.getProfissionaisSaudeEspecialidade(entidade.especialidadeMedica);

            this.loadSujeitosAtencao();
            return cb(this.objView);
        }).catch((error) => {
            console.error(error);
        }).finally(() => {
            this.disableModalButtons = false;
            cbFinally();
        });
    }

    getAgendamentosTitulos = (agendamentos) => {
        const isAgendamentoRecorrenciaFaturado = agendamentos.some(agendamento => {
            return agendamento.titulos.some(titulo => {
                return !!titulo.id
            })
        });

        return isAgendamentoRecorrenciaFaturado;
    };

    @action
    async loadSujeitosAtencao(pageable = { ...sujeitoAtencaoPageableDefault }) {
        try {
            this.sujeitosAtencaoLoading = true;

            if (pageable.pageNumber === 0) {
                this.sujeitosAtencaoCurrentPage = 0;
            }

            const { unidadeId, profissionalSaudeId } = this.getSelectValuesFromFiltroStore();

            let agendamento = {
                unidade: { id: unidadeId },
                profissionalSaude: { id: profissionalSaudeId }
            };
            if (this.objView.nome && this.objView.nome.length > 0)
                agendamento.nome = this.objView.nome;

            if (Moment(this.objView.dataNascimento, 'DD/MM/YYYY')?.isValid())
                agendamento.dataNascimento = Moment(this.objView.dataNascimento, 'DD/MM/YYYY').format('YYYY-MM-DD');

            if (this.objView.documento && this.objView.documento.length > 0)
                agendamento.documento = string.removeSpecialChars(this.objView.documento);

            if (this.objView.telefone && this.objView.telefone.length > 0)
                agendamento.telefone = string.removeSpecialChars(this.objView.telefone);

            const result = await Api.post('',
                JSON.stringify({
                    query: `query ($agendamento: AgendamentoInput, $pageable: PageableDTOInput) {
                        findSujeitoAtencaoByAgendamento(agendamento: $agendamento, pageable: $pageable) {
                            totalElements
                            last
                            content {
                                id
                                nome
                                nomeSocialOuNome
                                dataNascimento
                                cpf
                                documento
                                documentoTipo {
                                    descricao
                                    id
                                }
                                fotoPerfil
                                permiteAcessoApp {
                                    id
                                }
                                contato {
                                    telefonePrincipal
                                    telefonePrincipalDDI
                                    email
                                }
                                dadosConvenio {
                                    convenio {
                                        id
                                        descricao
                                    }
                                    numeroCarteira
                                    validadeCarteira
                                }
                                dadosConvenios {
                                    convenio {
                                      descricao
                                      id
                                    }
                                    numeroCarteira
                                    id
                                    validadeCarteira
                                }

                            }
                        }
                    }`,
                    variables: {
                        agendamento,
                        pageable: {
                            ...sujeitoAtencaoPageableDefault,
                            ...pageable
                        }
                    }
                })
            );

            const { findSujeitoAtencaoByAgendamento } = result.data.data;
            this.sujeitosAtencaoLastContent = findSujeitoAtencaoByAgendamento.last;

            const list = findSujeitoAtencaoByAgendamento.content.map(pessoa => {
                pessoa.idade = pessoa.dataNascimento ? Moment().diff(pessoa.dataNascimento, 'years') : ' ';
                pessoa.nome = pessoa.nomeSocialOuNome;
                return pessoa;
            });

            if (pageable.pageNumber > 0) {
                this.sujeitosAtencao = [...this.sujeitosAtencao, ...list];
            } else {
                this.sujeitosAtencao = [...list];
            }

            return this.sujeitosAtencao;
        } catch (error) {
            console.error(error);
        } finally {
            this.sujeitosAtencaoLoading = false;
        }
    }

    @action
    async loadAgendamentoAndCadastrarNovo(id) {
        try {
            this.idOpened = id;
            const result = await Api.post('',
                {
                    query: `
                            {content: findByIdAgendamento(id: ${id}) { 
                                id 
                                data 
                                situacao 
                                tipo { id } 
                                nome 
                                nomeSocialOuNome
                                telefone 
                                cpf
                                documento
                                documentoTipo {
                                    id
                                    descricao
                                }
                                dataNascimento 
                                observacao 
                                sujeitoAtencao { id } 
                                convenio { id } 
                                horaInicio 
                                horaFim 
                                solicitante { 
                                    id 
                                }
                                profissionalSaude {
                                    id
                                    nome
                                }
                                paciente {
                                    id
                                    dataNascimento,
                                    email,
                                    endereco {
                                        bairro
                                        cep
                                        complemento
                                        nomeLogradouro
                                        numero
                                        municipio {
                                            nome
                                        }
                                        estado {
                                            nome
                                        }
                                    }
                                    fotoPerfil,
                                    telefonePrincipal
                                }
                                sujeitoAtencao { 
                                    id 
                                    nome
                                    cpf
                                    documento
                                    documentoTipo {
                                        id
                                        descricao
                                    }
                                    dataNascimento
                                    fotoPerfil
                                    contato{
                                        telefonePrincipal
                                        email
                                    }
                                    dadosConvenio{
                                        convenio{
                                            id
                                            descricao
                                        }
                                    }
                                    dadosConvenios {
                                        convenio {
                                          descricao
                                          id
                                        }
                                        numeroCarteira
                                        id
                                        validadeCarteira
                                    }

                                }
                            }
                        }
                        `
                }
            );
            this.objView = {
                ...result.data.data.content
            };

            return this.updateAgendamento('updateAgendamentoAndPersistSujeitoAtencao');
        } catch (error) {
            throw error;
        }
    }

    @action cadastrarNovo() {
        return this.idOpened
            ? this.updateAgendamento('updateAgendamentoAndPersistSujeitoAtencao')
            : this.createAgendamento('createAgendamentoAndPersistSujeitoAtencao');
    }

    @action agendar = () => {
        if (this?.objView?.id && !this.isAgendarProcedimentoSolicitado) {
            return this.updateAgendamento('updateAgendamento');
        } else if (!this.idOpened && this.controleSessaoData?.idPacote) {
            return this.createAgendamento('createAgendamentoControleSessao');
        } else {
            return this.createAgendamento('createAgendamento');
        }
    }

    @action async agendarRecorrencia(recorrencia, agendamento, dataVencimento) {
        return this.updatingRecorrencia
            ? this.updateAgendamentoRecorrencia(recorrencia)
            : this.createAgendamento('createAgendamento', recorrencia, dataVencimento)
    }

    @action async updateAgendamentoCadastroNovoSujeitoAtencao(agendamentoSelecionado) {
        this.disableModalButtons = true;

        const { unidadeId, profissionalSaudeId } = this.getSelectValuesFromFiltroStore();

        let agendamento = {
            id: agendamentoSelecionado.id,
            data: agendamentoSelecionado.data,
            situacao: agendamentoSelecionado.situacao,
            dataInicioAtendimento: agendamentoSelecionado.dataInicioAtendimento,
            dataFimAtendimento: agendamentoSelecionado.dataFimAtendimento,
            tipo: agendamentoSelecionado.tipo,
            nome: agendamentoSelecionado.nome,
            telefone: agendamentoSelecionado.telefone,
            cpf: agendamentoSelecionado.cpf,
            documento: agendamentoSelecionado.documento,
            documentoTipo: agendamentoSelecionado.documentoTipo,
            dataNascimento: agendamentoSelecionado.dataNascimento,
            remoto: agendamentoSelecionado.remoto,
            linkTelemedicina: agendamentoSelecionado.linkTelemedicina,
            observacao: agendamentoSelecionado.observacao,
            convenio: agendamentoSelecionado.convenio,
            horaInicio: agendamentoSelecionado.horaInicio,
            horaFim: agendamentoSelecionado.horaFim,
            paciente: agendamentoSelecionado.paciente,
            procedimentos: agendamentoSelecionado.procedimentos,
            sujeitoAtencao: agendamentoSelecionado.sujeitoAtencao,
            unidade: { id: unidadeId },
            profissionalSaude: { id: profissionalSaudeId },
            urgente: agendamentoSelecionado.urgente,
        };

        return Api.post('',
            JSON.stringify({
                query: createMutation({
                    name: 'updateAgendamentoAndPersistSujeitoAtencao',
                    objType: 'AgendamentoInput',
                    objName: 'agendamento',
                    attrsReturn: `
                        id
                        sujeitoAtencao {
                            id
                        }
                    `
                }, 'mutation'),
                variables: { agendamento: agendamento }
            })
        ).then((result) => {
            if (result.data.data && result.data.data.updateAgendamentoAndPersistSujeitoAtencao) {
                if (this.isProntuarioPage) {
                    this.onExit();
                    this.prontuarioStore.findAllAgendamento();
                    return result.data.data.updateAgendamentoAndPersistSujeitoAtencao;
                }

                this.atualizaAgendamentos(this.selectedDate);
            }
            if (result.data.errors) {
                return result.data
            }
        }).finally(() => {
            this.disableModalButtons = false;
        })
    }

    @action
    async updateAgendamento(apiName) {
        this.disableModalButtons = true;

        if (this.contemErros()) {
            this.disableModalButtons = false;
            return this.errors;
        }
        const { unidadeId, profissionalSaudeId } = this.getSelectValuesFromFiltroStore();

        if (this.objView.situacao === 'BLOQUEADO') {
            this.objView.nome = this.objView.motivo;
            this.objView.observacao = '';
            this.objView.telefone = '';
            this.objView.telefoneDDI = '';
            this.objView.documento = '';
            this.objView.documentoTipo = '';
            this.objView.convenio = null;
            this.objView.sujeitoAtencao = null;
        }

        let agendamento = {
            id: this.objView?.id || this.prontuarioStore.agendamentoSelected.id,
            data: Moment(this.objView.data).format('YYYY-MM-DD'),
            situacao: this.objView.situacao,
            nome: this.objView.nome,
            horaInicio: this.objView.horaInicio,
            horaFim: this.objView.horaFim,
            unidade: { id: unidadeId },
            remoto: this.objView.isConsultaRemota,
            linkTelemedicina: this.objView.linkTelemedicina,
            sala: this.objView.sala,
            urgente: this.objView.urgente,
            listaDeEspera: this.objView.listaDeEspera,
            numeroGuiaTissExterna: this.objView.numeroGuiaTissExterna,
        };

        agendamento.numeroCarteira = this.objView.numeroCarteira || null;
        agendamento.validadeCarteira = (this.objView.validadeCarteira && moment(this.objView.validadeCarteira).format("YYYY-MM-DD")) || null;
        agendamento.solicitante = this.objView.solicitante?.id ? { id: this.objView.solicitante?.id } : null;

        if (this.isModalSala) {
            agendamento.sala = this.objView.sala;
            agendamento.profissionalSaude = this.objView.profissionalSaude?.id ? { id: this.objView.profissionalSaude?.id } : null;
        } else if (this.isModalHorarioDisponivel) {
            agendamento.profissionalSaude = { id: this.objView.profissionalSaudeId }
        } else {
            if (this.tipoProfissionalLogado === 'tecnico') {
                agendamento.profissionalSaude = this.objView.profissionalSaude?.id ? { id: this.objView.profissionalSaude?.id } : null
            } else {
                agendamento.profissionalSaude = { id: this.objView.profissionalSaude?.id || profissionalSaudeId }
            }
        }

        if (agendamento.remoto && !this.objView.possuiPaciente) {
            if (this.objView.permiteAcessoApp) {
                const profissionaisQuePermiteAcesso = this.objView.permiteAcessoApp.map(profissional => profissional.id)
                if (!profissionaisQuePermiteAcesso.includes(profissionalSaudeId)) {
                    this.objView.permiteAcessoApp = [...this.objView.permiteAcessoApp, { id: profissionalSaudeId }]
                    await this.updateSujeitoAtencaoHabilitarRemoto();
                }
            } else {
                this.objView.permiteAcessoApp = [{ id: profissionalSaudeId }]
                await this.updateSujeitoAtencaoHabilitarRemoto();
            }
        }

        if (this.objView.procedimentos && this.objView.procedimentos.length > 0) {
            agendamento.procedimentos = this.objView.procedimentos.map(item => {
                const { id, nome } = item.procedimento;
                return {
                    ...item,
                    procedimento: { id, nome },
                }
            })
            if (this.objView.financeiroGuiaServico) {
                agendamento.financeiroGuiaServico = this.objView.financeiroGuiaServico;
            }
        }

        if (this.objView.procedimentos.length === 0 && this.objView.financeiroGuiaConsulta) {
            agendamento.financeiroGuiaConsulta = this.objView.financeiroGuiaConsulta;
        }

        if (this.objView.observacao && this.objView.observacao.length > 0)
            agendamento.observacao = this.objView.observacao;

        if (this.objView.telefone && this.objView.telefone.length > 0)
            agendamento.telefone = string.removeSpecialChars(this.objView.telefone);

        if (this.objView?.telefoneDDI?.length > 0 && this.utilizaTelefoneInternacional && this.objView?.telefone?.length > 0)
            agendamento.telefoneDDI = this.objView.telefoneDDI;

        if (this.objView.tipo?.value)
            agendamento.tipo = {
                id: this.objView.tipo.value
            };


        if (this.objView.documento && this.objView.documento.length > 0)
            agendamento.documento = string.removeSpecialChars(this.objView.documento);

        if (this.objView.documentoTipo) {
            agendamento.documentoTipo = this.objView.documentoTipo
        }

        if (Moment(this.objView.dataNascimento, 'YYYY-MM-DD')?.isValid()) {
            agendamento.dataNascimento = Moment(this.objView.dataNascimento).format('YYYY-MM-DD');
        }

        if (this.objView.convenio?.id) {
            delete this.objView.convenio?.label;
            delete this.objView.convenio?.value;
            agendamento.convenio = this.objView.convenio;
        } else {
            agendamento.convenio = null;
        }

        if (this.objView.sujeitoAtencao && this.objView.sujeitoAtencao.id)
            agendamento.sujeitoAtencao = {
                id: this.objView.sujeitoAtencao.id
            };

        if (this.objView.procedimentos) {
            const newProcedimentos = this.objView.procedimentos.map(item => {
                delete item.nomeProcedimento;
                return {
                    ...item,
                    procedimento: {
                        id: item.procedimento.id,
                        nome: item.procedimento.nome
                    },
                    ...(item?.procedimento?.idControleSessaoProcedimento && {
                        controleSessaoProcedimento: {
                            id: item?.procedimento?.idControleSessaoProcedimento
                        }
                    })
                }
            });
            agendamento.procedimentos = newProcedimentos;
        }

        if (this.objView.paciente) {
            agendamento.paciente = this.objView.paciente;
        }

        if (this.objView.especialidadeMedica) {
            agendamento.especialidadeMedica = this.objView.especialidadeMedica;
        }
        return Api.post('',
            JSON.stringify({
                query: createMutation({
                    name: apiName,
                    objType: 'AgendamentoInput',
                    objName: 'agendamento',
                    attrsReturn: `
                        id
                        sujeitoAtencao {
                            id
                        }
                        urgente
                    `
                }, 'mutation'),
                variables: { agendamento: agendamento }
            })
        ).then(async (result) => {
            if (result.data.data && result.data.data[apiName]) {
                if (this.isProntuarioPage) {
                    this.onExit();
                    this.prontuarioStore.findAllAgendamento();
                    return result.data.data[apiName];
                }
                if (this.isMultiAgendaSelected) {
                    this.onExit();
                    return result.data.data[apiName];
                }

                this.atualizaAgendamentos(this.selectedDate);
                if (this.isSalaSelected || this.tipoProfissionalLogado === 'tecnico') {
                    this.atualizaAgendamentosSala(this.selectedDate);
                }

                if (this.objView.tipo?.idControleSessaoAgendamentoTipo) {
                    const variables = {
                        agendamento: {
                            id: result.data.data[apiName].id
                        },
                        controleSessaoAgendamentoTipo: {
                            id: this.objView.tipo?.idControleSessaoAgendamentoTipo,
                        }
                    }
                    await vincularAgendamentoByControleSessaoAgendamentoTipo(variables);
                }

                if (this.controleSessaoData?.idPacote) {
                    await this.controleSessaoStore.resetParamsSessoes()
                    await this.controleSessaoStore.loadSessoes({ idPacoteSessao: this.controleSessaoData?.idPacote })
                    this.controleSessaoStore.triggerReloadFindSelectedSessao = true;
                }

                if (this.openModalListaEspera) {
                    this.onExit();
                    this.closeModalListaEspera();
                    return result.data.data[apiName];
                }
                this.onExit();
                return result.data.data[apiName];
            }

            if (result.data.errors) {
                return result.data
            }
        })
            .finally(() => {
                this.disableModalButtons = false;
            });
    };


    @action
    async createAgendamento(apiName, recorrencia = null, dataVencimento = null) {
        this.disableModalButtons = true;
        if (this.contemErros()) {
            this.disableModalButtons = false;
            return this.errors;
        }

        const { unidadeId, profissionalSaudeId } = this.getSelectValuesFromFiltroStore();
        let agendamento = {
            data: Moment(this.objView.data).format('YYYY-MM-DD'),
            horaInicio: this.objView.horaInicio,
            horaFim: this.objView.horaFim,
            situacao: this.objView.situacao,
            nome: this.objView.situacao === 'BLOQUEADO' ? this.objView.motivo : this.objView.nome,
            remoto: this.objView.isConsultaRemota,
            unidade: { id: unidadeId },
            sala: this.objView?.sala?.id ? this.objView.sala : null,
            urgente: this.objView.urgente,
            listaDeEspera: this.objView.listaDeEspera,
            numeroGuiaTissExterna: this.objView.numeroGuiaTissExterna,
            solicitante: this.objView.solicitante?.id ? { id: this.objView.solicitante?.id } : null,
        }

        if (this.objView.numeroCarteira) {
            agendamento.numeroCarteira = this.objView.numeroCarteira;
        }

        if (this.objView.validadeCarteira) {
            agendamento.validadeCarteira = moment(this.objView.validadeCarteira).format("YYYY-MM-DD");
        }

        if (this.isModalSala) {
            agendamento.sala = this.objView?.sala?.id !== "0" ? this.objView?.sala : null;
            agendamento.profissionalSaude = this.objView.profissionalSaude?.id ? { id: this.objView.profissionalSaude?.id } : null;
        } else if (this.isModalHorarioDisponivel) {
            agendamento.profissionalSaude = { id: this.objView.profissionalSaudeId }
        } else {
            this.objView.profissionalSaude?.id ? agendamento.profissionalSaude = { id: this.objView.profissionalSaude?.id } :
                agendamento.profissionalSaude = { id: profissionalSaudeId }
        }

        if (agendamento.remoto && !this.objView.possuiPaciente) {
            if (this.objView.permiteAcessoApp) {
                const profissionaisQuePermiteAcesso = this.objView.permiteAcessoApp.map(profissional => profissional.id)
                if (!profissionaisQuePermiteAcesso.includes(profissionalSaudeId)) {
                    this.objView.permiteAcessoApp = [...this.objView.permiteAcessoApp, { id: profissionalSaudeId }]
                    await this.updateSujeitoAtencaoHabilitarRemoto();
                }
            } else {
                this.objView.permiteAcessoApp = [{ id: profissionalSaudeId }]
                await this.updateSujeitoAtencaoHabilitarRemoto();
            }
        }
        if (this.objView.procedimentos && this.objView.procedimentos.length > 0) {
            if (this.isOdontograma) {
                const newProcedimentos = this.objView.procedimentos.map(item => {
                    delete item.nomeProcedimento;
                    return {
                        ...item,
                        procedimento: {
                            id: item.procedimento?.id,
                            nome: item.procedimento.nome
                        },
                        ...(item?.procedimentoOdontologico?.id && {
                            procedimentoOdontologico: {
                                id: item.procedimentoOdontologico.id
                            }
                        }),
                        ...(item?.procedimento?.idControleSessaoProcedimento && {
                            controleSessaoProcedimento: {
                                id: item?.procedimento?.idControleSessaoProcedimento
                            }
                        })
                    }
                });
                agendamento.procedimentos = newProcedimentos;
            } else {
                const newProcedimentos = this.objView.procedimentos.map(item => {
                    delete item.nomeProcedimento;
                    return {
                        ...item,
                        procedimento: {
                            id: item.procedimento.id,
                            nome: item.procedimento.nome
                        },
                        ...(item?.procedimento?.idControleSessaoProcedimento && {
                            controleSessaoProcedimento: {
                                id: item?.procedimento?.idControleSessaoProcedimento
                            }
                        })
                    }
                });

                agendamento.procedimentos = newProcedimentos;
            }
        }

        if (this.objView.financeiroGuiaServico) {
            agendamento.financeiroGuiaServico = this.objView.financeiroGuiaServico;
        }


        if (this.typeCalendarViewSelected === 'encaixesView') {
            agendamento.horaInicio = null;
            agendamento.horaFim = null;
        }

        if (this.objView.observacao && this.objView.observacao.length > 0)
            agendamento.observacao = this.objView.observacao;

        if (this.objView.telefone && this.objView.telefone.length > 0)
            agendamento.telefone = string.removeSpecialChars(this.objView.telefone);

        if (this.objView?.telefoneDDI?.length > 0 && this.utilizaTelefoneInternacional && this.objView?.telefone?.length > 0)
            agendamento.telefoneDDI = this.objView.telefoneDDI;

        if (this.objView.documento && this.objView.documento.length > 0)
            agendamento.documento = string.removeSpecialChars(this.objView.documento);

        if (this.objView.documentoTipo)
            agendamento.documentoTipo = { id: this.objView.documentoTipo.id };

        if (Moment(this.objView.dataNascimento)?.isValid())
            agendamento.dataNascimento = Moment(this.objView.dataNascimento).format('YYYY-MM-DD');

        if (this.objView.convenio?.id) {
            agendamento.convenio = {
                id: this.objView.convenio?.id,
                descricao: this.objView.convenio?.descricao
            };
        } else {
            agendamento.convenio = null;
        }

        if (this.objView.tipo?.value)
            agendamento.tipo = {
                id: this.objView.tipo.value
            };

        if (this.objView.sujeitoAtencao && this.objView.sujeitoAtencao.id)
            agendamento.sujeitoAtencao = {
                id: this.objView.sujeitoAtencao.id
            };

        if (recorrencia) {
            const agendamentoRecorrencia = {
                ...agendamento,
                id: this.objView.id
            }
            await this.createRecorrencia(recorrencia, agendamentoRecorrencia, dataVencimento);
            return
        }

        if (apiName === 'createAgendamentoControleSessao') {
            const { isProcedure, idSessao } = this.controleSessaoData;
            const variables = {
                agendamento,
                ...(isProcedure
                    ? {
                        controleSessaoProcedimento: {
                            id: idSessao
                        }
                    }
                    : {
                        controleSessaoAgendamentoTipo: {
                            id: idSessao
                        }
                    }
                ),
            };
            await createAgendamentoAndControleSessao(variables);
            await this.controleSessaoStore.resetParamsSessoes()
            await this.controleSessaoStore.loadSessoes({ idPacoteSessao: this.controleSessaoData?.idPacote })
            this.controleSessaoStore.triggerReloadFindSelectedSessao = true;
            this.disableModalButtons = false;
            this.onExit();
            return;
        }


        return Api.post('',
            JSON.stringify({
                query: createMutation({
                    name: apiName,
                    objType: 'AgendamentoInput',
                    objName: 'agendamento',
                    attrsReturn: `
                        id
                        sujeitoAtencao {
                            id
                        }
                    `
                }, 'mutation'),
                variables: { agendamento: agendamento }
            })).then(async (result) => {
                if (this.objView.tipo?.idControleSessaoAgendamentoTipo) {
                    const variables = {
                        agendamento: {
                            id: result.data.data[apiName].id
                        },
                        controleSessaoAgendamentoTipo: {
                            id: this.objView.tipo?.idControleSessaoAgendamentoTipo,
                        }
                    }
                    await vincularAgendamentoByControleSessaoAgendamentoTipo(variables);
                }

                if (result.data.data && result.data.data[apiName]) {
                    if (this.isOdontograma) {
                        this.odontogramaStore.findAllProcedimentoOdontologico();
                    }

                    if (this.isMultiAgendaSelected) {
                        this.onExit();
                        return result.data.data[apiName];
                    }

                    this.atualizaAgendamentos(this.selectedDate);
                    if (this.isSalaSelected || this.tipoProfissionalLogado === 'tecnico') {
                        this.atualizaAgendamentosSala(this.selectedDate);
                    }
                    this.onExit();
                    return result.data.data[apiName];
                }

                if (result.data.errors) {
                    return result.data
                }
            })
            .finally(() => {
                this.disableModalButtons = false;
                this.procedimentosPedidosSelecionados = {
                    id: null,
                    procedimentosSolicitados: []
                }
            });
    }

    @action updateSujeitoAtencaoHabilitarRemoto = () => {
        if (!this.objView.nome || !this.objView.telefone) return;
        let sujeitoAtencao = {
            nome: this.objView.nome,
            contato: {
                email: this.objView.email,
                receberMensagemEmail: true,
                telefonePrincipal: string.removeSpecialChars(this.objView.telefone)
            },
            id: this.objView.sujeitoAtencao?.id,
            permiteAcessoApp: this.objView.permiteAcessoApp,
        };

        if (this.objView.documento) {
            sujeitoAtencao.documento = string.removeSpecialChars(this.objView.documento)
        }

        if (!sujeitoAtencao.id) {
            Api.post("", {
                query: `
                  mutation ($sujeitoAtencao: SujeitoAtencaoInput) {
                    createSujeitoAtencao(sujeitoAtencao: $sujeitoAtencao) {
                      id
                    }
                  }
                `,
                variables: { sujeitoAtencao }
            }).then((result) => {
                this.objView.sujeitoAtencao = {
                    id: result.data.data.createSujeitoAtencao?.id
                };
            })
        }

    }

    @action
    async jaPossuiAgendamentoNesteHorario() {
        this.disableModalButtons = true;

        const { unidadeId, profissionalSaudeId } = this.getSelectValuesFromFiltroStore();

        let agendamento = {
            id: this.objView.id ? this.objView.id : null,
            data: Moment(this.objView.data).format('YYYY-MM-DD'),
            horaInicio: this.objView.horaInicio,
            horaFim: this.objView.horaFim,
            unidade: { id: unidadeId },
            sujeitoAtencao: {
                id: this.objView.sujeitoAtencao?.id
            }
        };

        if (this.isModalHorarioDisponivel) {
            agendamento.profissionalSaude = { id: this.objView.profissionalSaudeId }
        } else {
            this.objView.profissionalSaude?.id ? agendamento.profissionalSaude = { id: this.objView.profissionalSaude?.id } :
                agendamento.profissionalSaude = { id: profissionalSaudeId }
        }

        const result = await Api.post('',
            JSON.stringify({
                query: `query ($agendamento: AgendamentoInput) {
                    jaPossuiAgendamentoNesteHorario(agendamento: $agendamento) 
                }`,
                variables: {
                    agendamento,
                }
            })
        );

        this.disableModalButtons = false;
        return result?.data?.data?.jaPossuiAgendamentoNesteHorario;
    }

    contemErros() {
        this.errors = {};
        const sujeitoId = this.objView.sujeitoAtencao?.id

        if (this.objView.situacao === "BLOQUEADO") {
            return false;
        }

        if (this.isModalSala) {
            this.errors.sala = string.isEmpty(this.objView.sala?.id) || this.objView.sala?.id === "0";
        }

        if (this.typeCalendarViewSelected !== 'encaixesView' && (!this.objView?.duracao?.value || this.objView?.duracao?.value === "0")) {
            this.errors.duracao = true
            return this.errors.duracao
        }

        if (this.isConvenioObrigatorio && !this.objView.convenio?.id && this.objView.situacao !== "BLOQUEADO") {
            this.errors.convenio = true;
            return this.errors.convenio
        }

        if (!sujeitoId) {
            const telefoneErrors = this.utilizaTelefoneInternacional ? !string.validaTelefoneComDDI(this.objView.telefone, this.objView.telefoneDDI) : !string.validaTelefone(this.objView.telefone)

            this.errors.documento = !string.isEmpty(this.objView.documento) && !verificaDocumento(this.objView.documento, this.objView.documentoTipo);
            this.errors.nome = string.isEmpty(this.objView.nome);
            this.errors.telefone = telefoneErrors;
            return this.errors.nome || this.errors.documento || this.errors.telefone || this.errors.sala || this.errors.dataNascimento;; 
        } else {
            return this.errors.sala
        }
    }

    @action filterStatus() {
        this.status = (this.situacoes.filter(value => !value.disabled)).map(({ value }) => value);
    }

    @action filterEvents() {
        this.status = (this.situacoes.filter(value => !value.disabled)).map(({ value }) => value);
        this.eventosFiltrados = this.eventos.filter(value => this.status.includes(value.status));
        this.eventosFiltradosEncaixes = this.eventosEncaixes.filter(value => this.status.includes(value.status));
    }

    @action filterEventsSala() {
        this.status = (this.situacoes.filter(value => !value.disabled)).map(({ value }) => value);
        this.eventosFiltradosSala = this.eventosSala.filter(value => this.status.includes(value.status));
    }

    @action onExit() {
        this.open = false;
        this.sujeitosAtencao = [];
        this.timeRange = null;
        this.isProntuarioPage = false;
        this.isModalSala = '';
        this.isModalHorarioDisponivel = false;
        this.isOdontograma = false;
        this.procedimentosModal = [];
        this.showTitulo = false;
        this.tipo = [];

        this.objView = {
            documento: '',
            documentoTipo: '',
            dataNascimento: '',
            id: '',
            sujeitoId: '',
            nome: '',
            situacao: '',
            telefone: '',
            telefoneDDI: '55',
            email: '',
            convenio: { id: '', descricao: '' },
            tipo: { id: '', descricao: '' },
            observacao: '',
            data: '',
            horaInicio: '',
            horaFim: '',
            isConsultaRemota: false,
            permiteAcessoApp: []
        };

        this.extratoStore.errors = defaultErrors;

        this.errors = {
            documento: false,
            nome: false,
            telefone: false
        };

        this.controleSessaoData = controleSessaoDataDefault;
        this.agendamentoRetornoData = null;
        this.isTelaSessoes = false;
        this.agendamentoListaEspera = null;
        this.isAgendamentoModalListaEspera = false;
    }

    @action eraseSujeitoAtencaoAgendamento() {
        this.objView.documento = '';
        this.objView.dataNascimento = '';
        this.objView.sujeitoAtencao = null;
        this.objView.sujeitoId = '';
        this.objView.nome = '';
        this.objView.telefone = '';
        this.objView.email = '';
        this.objView.convenio = null
        this.objView.observacao = '';
        this.objView.permiteAcessoApp = [];
    };

    @action selectSujeitoAtencao(sujeitoAtencao) {

        if (sujeitoAtencao?.dadosConvenio) {
            const { convenio, numeroCarteira, validadeCarteira } = sujeitoAtencao.dadosConvenio;
            this.objView.convenio = convenio ?
                {
                    ...convenio,
                    label: convenio?.descricao,
                    value: convenio?.id
                } :
                {
                    label: '',
                    value: null
                }
            this.objView.numeroCarteira = numeroCarteira || "";
            this.objView.validadeCarteira = validadeCarteira || "";
        }

        this.objView.sujeitoAtencao = {
            id: sujeitoAtencao.id,
            observacao: sujeitoAtencao.observacao || undefined,
            dadosConvenios: sujeitoAtencao.dadosConvenios,
        };
        this.objView.dataNascimento = moment(sujeitoAtencao.dataNascimento).format('YYYY-MM-DD') || "";
        this.objView.documento = sujeitoAtencao.documento || "";
        this.objView.nome = sujeitoAtencao.nome || "";

        let telefone = string.removeSpecialChars(this.objView.telefone);

        if (telefone == null || telefone.length < 1)
            telefone = sujeitoAtencao?.contato?.telefonePrincipal || "";

        this.objView.telefone = telefone;
        this.objView.email = sujeitoAtencao?.contato?.email;
        this.objView.fotoPerfil = sujeitoAtencao.fotoPerfil ? buildUrlFotoPerfil(sujeitoAtencao.fotoPerfil, this.accessToken) : null;
        this.objView.permiteAcessoApp = sujeitoAtencao.permiteAcessoApp
    }

    @action realizarProcedimento(procedimento) {
        this.openModalRealizarProcedimentos = true;
        this.procedimentoSelecionado = procedimento;
    }


    @action async createRecorrencia(recorrencia, agendamento, dataVencimento) {
        const { procedimentos, tipo } = this.objView;
        let datasRecorrencia = [];

        if (typeof agendamento.id === 'string') {
            delete agendamento.id;
        }

        datasRecorrencia = [
            ...datasRecorrencia,
            ...recorrencia.map(item => item.data)
        ];

        const getIdsControleSessaoByProcedimentos = () => {
            if (!procedimentos) return [];
            const procedimentosComIdPacote = procedimentos
                .flatMap(item => item.procedimento.idPacote)
                .filter(idPacote => idPacote !== null && idPacote !== undefined);
            const procedimentosUnicos = procedimentosComIdPacote.reduce((uniqueItems, item) => {
                if (!uniqueItems.includes(item)) {
                    uniqueItems.push({ id: item });
                }
                return uniqueItems;
            }, []);
            return procedimentosUnicos;
        }

        let controleSessoes = getIdsControleSessaoByProcedimentos().length > 0 ? getIdsControleSessaoByProcedimentos() : [];
        controleSessoes = tipo?.idControleSessaoAgendamentoTipo ? [...controleSessoes, { id: tipo?.idPacote }] : controleSessoes;

        let agendamentoRecorrencia = {
            ...this.agendamentoRecorrencia
        }

        if (this.isLastAgendamentoOfRecurrence && agendamentoRecorrencia?.id) {
            delete agendamentoRecorrencia.id;
        }

        if (dataVencimento) {
            const dataVencimentoMoment = Moment(dataVencimento, 'YYYY-MM-DD');
            const datasRecorrenciaMoment = datasRecorrencia.map(data => Moment(data, 'YYYY-MM-DD'));
            const possuiDatasRecorrenciaMaiorQueDataVencimento = datasRecorrenciaMoment.some(data => data.isAfter(dataVencimentoMoment));
            if (possuiDatasRecorrenciaMaiorQueDataVencimento) {
                this.disableModalButtons = false;
                throw new Error('Data de vencimento não pode ser menor que a data de recorrência');
            }
        }

        const response = await Api.post("", {
            query: `
                mutation ($agendamentoRecorrencia: AgendamentoRecorrenciaInput, $datasRecorrencia: [LocalDate], $agendamento: AgendamentoInput, $controleSessoes: [ControleSessaoInput]) {
                    createAgendamentoRecorrenciaByDatasRecorrencia(agendamentoRecorrencia: $agendamentoRecorrencia, datasRecorrencia: $datasRecorrencia, agendamento: $agendamento, controleSessoes: $controleSessoes)
                }
            `,
            variables: {
                agendamentoRecorrencia,
                ...(this.recorrenciaControleSessao?.idPacote && {
                    controleSessoes: [{
                        id: this.recorrenciaControleSessao.idPacote,
                    }]
                }),
                ...(controleSessoes?.length > 0 && { controleSessoes }),
                datasRecorrencia,
                agendamento
            }
        });

        if (response.data.errors) {
            this.disableModalButtons = false;
            throw response.data.errors[0]
        }
        this.disableModalButtons = false;
        await this.controleSessaoStore.resetParamsSessoes()
        await this.controleSessaoStore.loadSessoes({ idPacoteSessao: this.controleSessaoData?.idPacote })
        this.atualizaAgendamentos(this.selectedDate);

        return response.data.data.createAgendamentoRecorrenciaByDatasRecorrencia;
    };

    @action async findByIdAgendamentoRecorrencia(id) {
        const response = await Api.post("", {
            query: `
            {
                findByIdAgendamentoRecorrencia(idAgendamentoRecorrencia: "${id}"){
                agendamentosValidos {
                  id
                  data
                  horaInicio
                  horaFim
                  nome
                }
                dataCriacao
                intervalo
                quantidade
                tipo
                diaSemanas{
                  id
                  diaSemana
                }
              }
            }`
        });

        return response.data.data.findByIdAgendamentoRecorrencia;
    };

    @action async dadosAgendamento() {
        const { unidadeId, profissionalSaudeId } = this.getSelectValuesFromFiltroStore();
        let agendamento = {
            id: this.idOpened,
            data: Moment(this.objView.data).format('YYYY-MM-DD'),
            situacao: this.objView.situacao,
            nome: this.objView.nome,
            horaInicio: this.objView.horaInicio,
            horaFim: this.objView.horaFim,
            unidade: { id: unidadeId },
            remoto: this.objView.isConsultaRemota,
            linkTelemedicina: this.objView.linkTelemedicina,
            sala: this.objView.sala
        };

        if (this.isModalSala) {
            agendamento.sala = this.objView.sala;
            if (this.objView.solicitante?.id) {
                agendamento.solicitante = { id: this.objView.solicitante?.id }
            }
            if (this.objView.profissionalSaude?.id) {
                agendamento.profissionalSaude = { id: this.objView.profissionalSaude?.id }
            }
        } else if (this.isModalHorarioDisponivel) {
            agendamento.profissionalSaude = { id: this.objView.profissionalSaudeId }
        } else {
            this.objView.profissionalSaude?.id ? agendamento.profissionalSaude = { id: this.objView.profissionalSaude?.id } :
                agendamento.profissionalSaude = { id: profissionalSaudeId }
        }

        if (this.objView.procedimentos && this.objView.procedimentos.length > 0) {
            agendamento.procedimentos = this.objView.procedimentos
            if (this.objView.financeiroGuiaServico) {
                agendamento.financeiroGuiaServico = this.objView.financeiroGuiaServico;
            }
        }

        if (this.objView.observacao && this.objView.observacao.length > 0)
            agendamento.observacao = this.objView.observacao;

        if (this.objView.telefone && this.objView.telefone.length > 0)
            agendamento.telefone = string.removeSpecialChars(this.objView.telefone);

        if (this.objView?.telefoneDDI?.length > 0)
            agendamento.telefoneDDI = this.objView.telefoneDDI;

        if (this.objView.tipo?.value)
            agendamento.tipo = {
                id: this.objView.tipo.value
            };


        if (this.objView.documento && this.objView.documento.length > 0)
            agendamento.documento = string.removeSpecialChars(this.objView.documento);

        if (Moment(this.objView.dataNascimento, 'YYYY-MM-DD')?.isValid()) {
            agendamento.dataNascimento = this.objView.dataNascimento;
        }

        if (this.objView.convenio?.id) {
            agendamento.convenio = { id: this.objView.convenio?.id, descricao: this.objView.convenio?.descricao };
        } else {
            agendamento.convenio = null;
        }

        if (this.objView.sujeitoAtencao && this.objView.sujeitoAtencao.id)
            agendamento.sujeitoAtencao = {
                id: this.objView.sujeitoAtencao.id
            };

        if (this.objView.procedimentos) {
            agendamento.procedimentos = this.objView.procedimentos.map(item => {
                item.nomeProcedimento && delete item.nomeProcedimento
                return item;
            })
        }

        if (this.objView.controleSessaoAgendamentoTipo?.id) {
            agendamento.controleSessaoAgendamentoTipo = {
                ...this.objView.controleSessaoAgendamentoTipo
            }
        }

        return agendamento;
    };

    @action async updateAgendamentoRecorrencia(recorrencias) {
        const agendamento = await this.dadosAgendamento();
        const newAgendamento = {
            ...agendamento,
            ...(this.objView.tipo?.idControleSessaoAgendamentoTipo && {
                controleSessaoAgendamentoTipo: {
                    id: this.objView.tipo?.idControleSessaoAgendamentoTipo
                }
            }),
            procedimentos: agendamento.procedimentos.map(item => {
                delete item.nomeProcedimento;
                return {
                    ...item,
                    procedimento: {
                        id: item.procedimento.id,
                        nome: item.procedimento.nome
                    },
                    ...(item?.procedimento?.idControleSessaoProcedimento && {
                        controleSessaoProcedimento: {
                            id: item?.procedimento?.idControleSessaoProcedimento
                        }
                    })
                }
            })
        }

        const agendamentos = this.agendamentoRecorrencia?.agendamentos?.map(item => {
            delete agendamento.id;
            return ({
                ...agendamento,
                procedimentos: agendamentoProcedimentoSemId,
                data: item.data,
            })
        }).filter(item => moment(this.objView.data).format('YYYY-MM-DD') !== item.data);

        const datasRecorrencia = recorrencias.map(recorrencia => recorrencia?.data);

        const agendamentoRecorrencia = {
            ...this.agendamentoRecorrencia,
            agendamentos
        };

        const response = await Api.post("", {
            query: `
            mutation ($recorrencia: AgendamentoRecorrenciaInput, $agendamento: AgendamentoInput, $datasRecorrencia: [LocalDate]) {
                updateAgendamentoRecorrencia(recorrencia: $recorrencia, agendamento: $agendamento, datasRecorrencia: $datasRecorrencia) {
                    id
                }
            }`,
            variables: {
                recorrencia: agendamentoRecorrencia,
                agendamento: newAgendamento,
                datasRecorrencia: datasRecorrencia
            }
        });

        if (response.data.errors) {
            this.updatingRecorrencia = false;
            this.disableModalButtons = false;
            throw response.data.errors[0]
        };

        this.agendamentoRecorrencia = null;
        this.updatingRecorrencia = false;
        this.disableModalButtons = false;
        this.atualizaAgendamentos(this.selectedDate);

        return response.data.data.updateAgendamentoRecorrencia;
    }

    @action async excluirAgendamentoRecorrencia() {
        try {
            this.disableModalButtons = true;
            this.cancelandoAgendamento = true;
            const agendamento = await this.dadosAgendamento();

            const response = await Api.post("", {
                query: `
                mutation ($recorrencia: AgendamentoRecorrenciaInput, $agendamento: AgendamentoInput) {
                    updateAgendamentoRecorrencia(recorrencia: $recorrencia, agendamento: $agendamento) {
                        id
                    }
                }`,
                variables: {
                    recorrencia: {
                        id: this.agendamentoRecorrencia.id,
                    },
                    agendamento: {
                        ...agendamento,
                        situacao: "EXCLUIDO"
                    }
                }
            });

            if (response.data.errors) {
                throw response.data.errors[0]
            };

            this.agendamentoRecorrencia = null;
            this.onExit();

            return response.data.data.updateAgendamentoRecorrencia;

        } finally {
            this.disableModalButtons = false;
            this.cancelandoAgendamento = false;
            this.updatingRecorrencia = false;
            this.atualizaAgendamentos(this.selectedDate);
        }
    }

    @action changeFieldsStore(values) {
        const arrayKey = Object.keys(values);

        arrayKey.forEach(item => {
            this[item] = values[item];
        })
    }

    @action loadVincularMessages = () => {
        this.modalVinculoSujeitoAlertTitle = `O agendamento ${this.objView.nome} não possui paciente.`;
        this.modalVinculoSujeitoAlertDescription = `Vincule um paciente existente ou cadastre um novo.`;
    }

    @action closeAlertMessage = () => {
        const notification = {
            isOpen: false,
            message: "",
        };
        this.notification = notification;
    };

    @action showAlertMessage = (notification) => {
        this.notification = notification;

        const timeoutId = setTimeout(() => {
            this.closeAlertMessage();
            clearTimeout(timeoutId);
        }, 3000);
    };

    @action bloquearAgendamentoData = async (variables) => {
        const response = await Api.post("", {
            query: `
            mutation ($profissionalSaude: Long, $dataInicial: LocalDate, $dataFinal: LocalDate) {
                bloquearAgendamentoData(profissionalSaude: $profissionalSaude, dataInicial: $dataInicial, dataFinal: $dataFinal)
            }`,
            variables: variables
        });

        if (response.data.errors) {
            throw response.data.errors[0]
        };

        return response.data.data.bloquearAgendamentoData;
    }

    @action increaseWhatsappMessageCounter = async (agendamentoId) => {
        try {
            const updatedWhatsappMensagemQuantidade = await updateWhatsappMensagemManual(agendamentoId);
            this.objView.whatsappMensagemQuantidade = updatedWhatsappMensagemQuantidade;
        } catch (error) {
            this.openNotification(error.message, 'error');
        }
    }

    @action handleSalvarPagamento = async (tipo) => {
        
        try {
            const procedimentos = this.objView.procedimentos.map((item)=>{
                return {
                    id:item.procedimento.id,
                    nome: item.procedimento.nome,
                }
            })
            const titulo = this.extratoStore.titulo;

            const dadosTitulo = {
                ...titulo,
                sujeitoAtencao: { id: this.objView.sujeitoAtencao?.id },
                tipo: "RECEITA",
                profissionalSaude: { id: this.filtroHeaderStore.profissionalSaude?.id },
                procedimentos:[...procedimentos],
                convenio: this.convenioPagamento
            }

            const response = await this.extratoStore.create(dadosTitulo, tipo);
            if (response?.id) {
                this.extratoStore.titulo.id = response?.id;
            }
        } catch (error) {
            console.error(error);
        }
    };

    @action updateTotal = (isModalSala) => {
        const { tipo, procedimentos, convenio } = this.objView;

        const total = calcTotalTitulo({
            convenio: convenio,
            agendamentoTipo: tipo,
            procedimentos: procedimentos,
            isModalSala,
        });
        this.extratoStore.titulo = {
            ...defaultTitulo,
            nome: this.extratoStore.titulo.nome,
            valor: total,
            subtotal: total,
        }
    };

    @action closeModalListaEspera = () => {
        this.openModalListaEspera = false;
    }

    @action closeModalListaEsperaItem = () => {
        this.openModalListaEsperaItem = false;
    }
    @action async findAllConvenio(searchDTO = {}) {
        try {
            const defaultSearchDTO = {
                pageSize: 30,
                pageNumber: 0,
                sortDir: "ASC",
                sortField: "descricao",
            };
            const response = await findAllConvenios(defaultSearchDTO, searchDTO)
            const { last, content } = response;
            const customContent = content.map(each => ({
                ...each,
                value: each.id,
                label: each.descricao
            }));

            return {
                last,
                content: customContent
            };
        } catch (error) {
            console.error(error);
        }
    }

    handleConvenioLoadOptions = (search, loadedOptions, { page }) => {
        return this.handleLoadMoreOptions({
            search,
            loadedOptions,
            data: {
                page,
                searchDTO: {
                    ativo: true,
                    profissionalSaudeId: this.filtroHeaderStore.profissionalSaude?.id,
                },
            },
            method: "findAllConvenio",
        });
    };

    handleLoadMoreOptions = async ({ search, data, method }) => {
        let searchDTO = {};

        if (data.searchDTO) {
            searchDTO = {
                ...data.searchDTO,
            };
        }

        const response = await this[method]({
            pageNumber: data.page,
            search,
            ...searchDTO,
        });

        return {
            options: response.content,
            hasMore: !response.last,
            additional: {
                page: data.page + 1,
            },
        };
    };

    @action async findaAgendamentoAuditById({ agendamentoId, pageableDTO }) {
        const response = await Api.post('', {
            query:
                ` query ($agendamentoId: Long, $pageableDTO: PageableDTOInput) {
                findAllAgendamentoAuditByAgendamentoId(agendamentoId: $agendamentoId, pageableDTO: $pageableDTO) {
                  last
                  content {
                    agendamentoRecorrenciaIdModified
                    data
                    revtype
                    revisao {
                      id
                      usuario {
                        id
                        nome
                        fotoPerfil
                      }
                      paciente {
                        id
                        nome
                        fotoPerfil
                      }
                      dataHoraModificacao
                    }
                    convenio{
                    descricao
                    }
                    convenioIdModified
                    convenioNumeroCarteiraModified
                    convenioValidadeCarteiraModified
                    documentoModified
                    documento
                    dataChegadaSujeitoAtencaoModified
                    dataChegadaSujeitoAtencao
                    dataModified
                    dataNascimentoModified
                    dataNascimento
                    horaFimModified
                    horaFim
                    horaInicioModified
                    horaInicio
                    listaDeEsperaModified
                    listaDeEspera
                    nomeModified
                    nome
                    numeroCarteira
                    numeroGuiaTissExternaModified
                    numeroGuiaTissExterna
                    observacaoModified
                    observacao
                    pacienteIdModified
                    profissionalSaudeIdModified
                    profissionalSaude {
                    nome
                    }
                    remotoModified
                    remoto
                    sala{
                    nome
                    }
                    salaIdModified
                    situacaoModified
                    situacao
                    solicitante{
                    nome
                    }
                    solicitanteIdModified
                    telefoneModified
                    telefoneDDIModified
                    telefone
                    telefoneDDI
                    tipoEntidade{
                    descricao
                    }
                    tipoModified
                    validadeCarteira
                    motivoCancelamento
                    motivoCancelamentoModified
                    urgenteModified
                    urgente
                  }
                }
              }`,
            variables: {
                agendamentoId,
                pageableDTO,
            }
        });

        if (response?.data?.data?.findAllAgendamentoAuditByAgendamentoId) {
            return response.data.data.findAllAgendamentoAuditByAgendamentoId;
        }
        return [];
    }

    getProfissionaisSaudeEspecialidade = async(especialidadeMedica) => {
        try {      
            const unidadeLogada = JSON.parse(localStorage['_immortal|unidadeLogada']);
            const response = await findByUnidadeComAgendaEspecialidade({
                unidadeId: unidadeLogada?.id,
                especialidadeMedica: especialidadeMedica?.id
            })
            this.listaProfissionaisSaudeListaEspera = response || [];
        } catch (error) {
            console.error(error);
        }
    }

    @action getDadosMensagemPersonalizada = async(id) => {
        try {
            const response = await Api.post('', {
                query:
                    ` query {
                    findByIdAgendamento(id: ${id}) {
                      profissionalSaude {
                          nome
                      }
                    }
                  }`,
            });

            if (response?.data?.errors) {
                throw response.data.errors[0];
            }
    
            return response.data.data.findByIdAgendamento;

        } catch (error) {
            console.error(error);
        }
    } 
}


export default AtendimentoStore;
