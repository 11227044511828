import { gridContainer } from "../appHealth.style"
import colors from "../../../template/Colors";

const styles = (theme) => ({
	root: {
		...gridContainer,
		height: "100dvh",
		overflow: "hidden",
	},

	headerContent: {
		background: '#FFF',
		boxShadow:
			"0px 5px 25px rgba(38, 172, 169, 0.07), 0px 7px 25px rgba(38, 172, 169, 0.08), 0px 10px 15px rgba(0, 0, 0, 0.03)",
	},

	content: {
		gridArea: "content",
		display: "grid",
		gridTemplateRows: "auto 1fr",
		position: "relative",
		overflow: "hidden",
		background: '#F5F5F5'
	},

	gridCardContainer: {
		display: "grid",
		gridGap: "24px",
		width: "90%",
		paddingBottom: "20px",
		marginLeft: "0px",
		gridTemplateColumns: "1fr 1fr",
		gridTemplateRows: 'max-content',
		gridTemplateAreas: `
		"cardStatusCadastro cardViziLink"	
		"cardDadosPessoais cardViziLink"
		"cardDadosPessoais cardAnadem"
		"cardDadosPessoais cardAnadem"
		"cardDadosPessoais cardPrintCard"
		"cardDadosConvenio cardDadosConvenio"
		"cardDadosContato cardDadosResidenciais"
		"cardDadosCadastrais cardDadosResidenciais"
		"cardDadosCadastrais cardDadosAdicionais"
		"cardDadosCadastrais cardDadosAdicionais"
		"cardFiliacao cardDadosAdicionais"
		"cardObservaoes cardDadosAdicionais"
		"cardDadosEstrangeiros cardDadosEstrangeiros"
		`,

		[theme.breakpoints.down("sm")]: {
		gridTemplateRows: "auto",
		gridTemplateColumns: "auto",
		gridTemplateAreas: `"cardDadosPessoais"
								"cardStatusCadastro"
								"cardViziLink"
								"cardAnadem"
								"cardPrintCard"
								"cardDadosConvenio"
								"cardDadosResidenciais"
								"cardDadosContato"
								"cardDadosCadastrais"
								"cardDadosEstrangeiros"
								"cardDadosAdicionais"
								"cardFiliacao"
								"cardObservaoes"
						`,
		},
		"@media (max-width: 1100px)": {
			gridTemplateColumns: "1fr",
			gridTemplateRows: "auto auto auto",
		},

		'& > div': {
			height: 'fit-content',
		},
	},

	inputError: {
		border: "1px solid " + theme.palette.commons.red,
		borderRadius: "8px",
	},

	headerPanelLeft: {
		height: "64px",
	},
	headerTitleRoot: {
		maxWidth: theme.commons.menuLeft.width,
		margin: "0 5%",
	},

	headerContainer: {
		display: "flex",
		justifyContent: "space-between",
		flexGrow: 1,
	},

	leftSpace: {
		padding: "10px",
	},

	headerProfile: {
		fontSize: "1.2rem",
		fontWeight: "bold",
		color: theme.palette.primary.main,
		textAlign: "center",
	},

	titleHeader: {
		fontSize: "1.2rem",
		color: theme.palette.primary.main,
	},

	headerSolicitacoesProntuarios: {
		display: "flex",
		justifyContent: "space-between",
	},

	headerProfileSolicitaocesProntuarios: {
		paddingRight: 16,
	},

	titleHeaderDados: {
		fontWeight: "bold",
		fontSize: 24,
		color: theme.palette.primary.main,
	},

	containerDados: {
		paddingBottom: "20px",
		display: "grid",
		width: "70%",
		gridGap: "20px",
		gridTemplateColumns: "1fr 1fr",
	},

	containerDadosEstrangeiro: {
		paddingBottom: "20px",
		display: "grid",
		width: "70%",
		gridGap: "20px",
		gridTemplateColumns: "1fr 1fr",
		gridTemplateAreas: `
														"cardDadosPessoais cardViziLink"
														"cardDadosPessoais cardStatusCadastro"
                            "cardDadosPessoais cardAnadem"
														"cardDadosPessoais cardPrintCard"
                            "cardDadosConvenio cardDadosConvenio"
                            "cardDadosResidenciais cardDadosContato"
                            "cardDadosCadastrais cardDadosAdicionais"
                            "cardDadosEstrangeiros cardDadosEstrangeiros"
                            "cardFiliacao cardObservaoes"
                            `,

		[theme.breakpoints.up("xl")]: {
			gridTemplateRows: "1fr",
			gridTemplateColumns: "1fr 1fr 1fr",
			gridTemplateAreas: `"cardDadosPessoais cardStatusCadastro cardAnadem cardPrintCard"
                                "cardDadosPessoais cardDadosConvenio cardViziLink"
                                "cardDadosContato cardDadosResidenciais cardDadosCadastrais "
                                " cardDadosAdicionais cardFiliacao cardObservaoes"
                            `,
		},
		[theme.breakpoints.down("sm")]: {
			gridTemplateRows: "auto",
			gridTemplateColumns: "auto",
			gridTemplateAreas: `"cardDadosPessoais"
																"cardViziLink"
                                "cardStatusCadastro"
                                "cardAnadem"
																"cardPrintCard"
                                "cardDadosConvenio"
                                "cardDadosResidenciais"
                                "cardDadosContato"
                                "cardDadosCadastrais"
                                "cardDadosEstrangeiros"
                                "cardDadosAdicionais"
                                "cardFiliacao"
                                "cardObservaoes"
                            `,
		},
	},
	tableContainer: {
		display: "grid",
		alignContent: "start",
		overflowY: "auto",
		gridGap: "20px",
		padding: "2% 4%",
		paddingRight: "8%",
		backgroundColor: '#F5F5F5',
	},

	tableContainerConsultas: {
		display: "grid",
		alignContent: "start",
		overflowY: "auto",
		
		"& thead th": {
			top: 0,
      		position: "sticky",
			width: '20%',

			"&:first-child": {
				width: '100px',
			},
		},

		"& tbody td": {
			width: '20%',

			"&:first-child": {
				width: '100px',
			},
		}
	},

	radioButtonGroupContainer: {
		display: "flex",
		flexDirection: "row",
		alignItems: "center",
		marginbottom: 5,
	},
	radioButtonGroupContainerAnadem: {
		display: "flex",
		flexDirection: "column",
		justifyItems: "start",
		paddingBottom: "20px !important",
	},

	radioButtonGroupLabel: {
		margin: 0,
	},

	radioButton: {
		margin: "0 0 0 10px",
		fontWeight: 600,
		fontSize: 10,
		"& label": {
			color: `${colors.commons.secondary} !important`,
			fontFamily: "Poppins !important",
		},
	},

	radioButtonVermelho: {
		fontWeight: 600,
		fontSize: 10,
		"& label": {
			color: `${colors.commons.red} !important`,
			fontFamily: "Poppins !important",
		},
		"&$checked": {
			background: `${colors.commons.red} !important`,
			color: `${colors.commons.red} !important`,
		},
	},

	radioButtonAnadem: {
		margin: "0 0 0 50px",
		fontWeight: 900,
		fontSize: 10,
		"& label": {
			color: colors.commons.secondary,
		},
	},

	inputWithCheckbox: {
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},

	formControlLabelCheckbox: {
		marginTop: 0,
		marginBottom: 5,
		paddingLeft: 15,
		"& span": {
			fontFamily: "Poppins !important",
			color: colors.commons.darkGray,
			fontSize: 13,
		},
	},

	checkBoxReceberMsg: {
		"& svg": {
			maxHeight: 14,
			maxWidth: 14,
		},
	},
	inputDadosCadastraisPaciente: {
		background: '#F2F2F2',
		height: '24px',
		borderRadius: '8px',
		padding: '4px 4px 4px 8px',
		width: 'calc(100% - 12px)',
		fontFamily: "Poppins !important",
		"& ::placeholder": {
			color: colors.commons.darkGray,
			opacity: 1,
		},
		"& input": {
			borderBottom: "none",
		},
	},
	inputDadosCadastraisPacienteValidade: {
		width: "100%",
		"& input": {
			textAlign: "start",
		},
	},
	inputDadosCadastraisPacienteSexo: {
		minWidth: "120px !important",
		width: "100%",
	},
	inputDadosCadastraisPacienteNascimento: {
		"& input": {
			textAlign: "start",
			minWidth: 87,
			width: "100%",
		},
	},

	inputDadosCadastraisPacienteObservacao: {
		background: colors.commons.gray2,
		borderRadius: 10,
		width: '100%',

		'& .MuiPrivateTextarea-root-696': {
			padding: '0 8px'
		}
	},

	inputCEP: {
		minWidth: "100%",
		"& input": {
			borderRadius: 0,
			border: "none !important",
		},
	},

	inputMenor: {
		display: "grid",
		gridTemplateRows: "1fr",
		gridTemplateColumns: "1fr 1fr",
		gap: '10px',
	},
	menor: {
		minWidth: "100%",
	},
	inputMenorEstrangeiro: {
		[theme.breakpoints.down("lg")]: {
			display: "grid",
			gridTemplateColumns: "1fr 1fr",
		},
		[theme.breakpoints.up("xl")]: {
			display: "grid",
			gridTemplateRows: "1fr 1fr",
		},
		[theme.breakpoints.down("sm")]: {
			display: "flex",
			flexDirection: "column",
		},
	},
	cardNome: {
		padding: "0 !important",
	},

	cardDadosPessoais: {
		height: "auto !important",
		gridArea: "cardDadosPessoais",
	},

	cardStatusCadastro: {
		gridArea: "cardStatusCadastro",
	},

	cardViziLink: {
		gridArea: "cardViziLink"
	},

	cardAnadem: {
		gridArea: "cardAnadem",
	},

	cardPrintCard: {
		gridArea: "cardPrintCard",
	},

	cardDadosConvenio: {
		gridArea: "cardDadosConvenio",
	},

	cardDadosResidenciais: {
		gridArea: "cardDadosResidenciais",
	},

	cardDadosContato: {
		gridArea: "cardDadosContato",
	},

	cardDadosCadastrais: {
		gridArea: "cardDadosCadastrais",
	},

	cardDadosAdicionais: {
		gridArea: "cardDadosAdicionais",
	},

	cardDadosEstrangeiros: {
		gridArea: "cardDadosEstrangeiros",
	},

	cardFiliacao: {
		gridArea: "cardFiliacao",
	},

	cardObservaoes: {
		gridArea: "cardObservaoes",
	},
	dadosConvenio: {
		display: 'flex',
		flexDirection: 'row',
		alignItems: 'end',
		padding: '8px',
		gap: '8px',
	},
	dadosConvenioSelected: {
		borderRadius: '8px',
		border: '1px solid rgba(0, 0, 0, 0.10)',
		backgroundColor: '#F9F9F9',
	},
	wrapperCardMultiConvenios: {
		display: 'flex',
		flexDirection: 'row',
		justifyContent: 'space-between',
		gap: '8px',
		marginBottom: 16,
	},
	inputDateMultiConvenios: {
		'& > div': {
			width: '110px',
			padding: '0 4px',
		}
	},
	selectConvenio: {
		flex: 1,
	},
	acoes: {
		position: "absolute",
		right: "8px",
		bottom: "35%",
		display: "flex",
		flexDirection: "column",
	},

	buttonCancelar: {
		margin: 8,
		height: 40,
		width: 40,
		background: colors.commons.red,
		color: colors.commons.white,
		"&:hover": {
			background: colors.commons.darkRed,
		},
	},

	buttonYellow: {
		background: colors.commons.yellow,
		"&:hover": {
			background: colors.commons.darkYellow,
		},
	},

	buttonSalvar: {
		background: colors.primary.main,
		"&:hover": {
			background: colors.primary.dark,
		},
	},

	buttonAcoes: {
		margin: 8,
		height: 40,
		width: 40,
		color: colors.commons.white,
	},

	footer: {
		gridArea: "footer",
		paddingLeft: 15,
		paddingRight: 160,
		display: "flex",
	},
	buttonImpressao: {
		position: "absolute",
		right: 0,
		bottom: "calc(-56px - 0.25rem)",
	},
    infoAdicionais:{
        display: 'flex',
        flexDirection: 'row',
        height: 44,
        alignItems: 'center',
        padding: '0 16px',
        width: "75vw",
        overflowX: 'scroll',
        "@media(max-width: 1800px)": {
            width: "70vw"
        },
        "@media(max-width: 1500px)": {
            width: "65vw"
        },
        "@media(max-width: 1200px)": {
            width: "58vw"
        },
	},
	
	buttonEditarCamposProntuario: {
        minWidth: '40px',
        width: '40px',
        height: '40px',
        boxShadow: 'none',
        background: '#F5F5F5'
	},
	
	tuneIcon: {
        width: '1em',
        height: '1em',
        color: theme.palette.primary.main,
	},
	inputAlturaPeso:{
        backgroundColor: 'white',
        borderRadius: 100,
        justifyContent: 'center',
        minWidth: 60,
        border: 'none',
        maxHeight: 22,
        maxWidth:80,
    },

    inputBlockField: {
        fontFamily: "Poppins !important",
        padding: "5px 0",
        textAlign: "center",
        fontWeight: "normal !important",
        "::-webkit-input-placeholder": {
            color: "pink"
        },
	},
	inputRootBlockField: {
        paddingLeft: 0,
        border: 'none',
        fontSize: 14,
	},
	infoAdicional:{
        paddingRight: 7,
    },
	anademProfissionaisLabel: {
		gridArea: "anademProfissionaisLabel",
		marginBottom: 10,
		fontSize: "14px",
		justifyItems: "center",
		color: colors.commons.darkGray,
		fontWeight: 700,
		fontFamily: "Poppins !important",
	},
	containerAcesso: {
		gridArea: "containerAcesso",
		fontSize: "14px !important",
		justifyContent: "center",
	},
	anadem: {
		display: "grid",
		grigTemplateColumns: "1fr",
		gridTemplateRows: "auto 1fr",
		gridTemplateAreas: "anademProfissionaisLabel containerAcesso",
		marginLeft: 10,
	},
	multipleAcesso: {
		marginLeft: 50,
	},
	telefoneInputContainer: {
		display: 'flex',
		flexDirection: 'row',
		columnGap: '4px !important'
	},
	telefoneInputError: {
		border: `1px solid ${theme.palette.commons.red} !important`
	},
	telefoneInput: {
		height: '32px !important',
		borderRadius: '100px !important'
	},
	cpfInputContainer: {
		height: '24px',
		borderRadius: '8px',
		padding: '4px 0px',
		width: '100%',
		fontFamily: "Poppins !important",
		"& ::placeholder": {
			color: colors.commons.darkGray,
			opacity: 1,
		},
		"& input": {
			borderBottom: "none",
			color: 'black'
		},
	},
	inputErrorCpf: {
		border: "1px solid " + theme.palette.commons.red,
		borderRadius: "8px",
		height: 'auto',
		padding: '0px',
	},
	selectSearch: {
		width: '100%',
	},
	notchedOutline: {
		border: '0',
	},
	inputTextField: {
		fontSize: '0.875rem',
		border: '0',
		minHeight: '0.375rem',
		height: 32,
		color: colors.commons.fontColor,
		backgroundColor: colors.commons.gray2,
		margin: '0',
		borderRadius: '6.25rem',
	},
	classIcons: {
		fontSize: 24,
	},
	inputDivMask: {
		background: '#F2F2F2',
		borderRadius: '8px',
		height: '32px',
		paddingLeft: '8px',
		width: 'calc(100% - 8px)',
	},
	inputMask: {
		color: '#000',
		border: 'none',
		borderRadius: 0,
		fontSize: '14px',
		padding: '8px 0 0',
	},
	inputMaskError: {
		border: 'none',
		fontFamily: 'Poppins',
		borderRadius: 0,
		fontSize: '14px',
		padding: '8px 0 0',
	},
	rowTelefone: {
		display: 'flex',
		alignItems: 'center',
	},
	selectField: {
		width: "100%",
		"&>div>div": {
			borderRadius: "8px",
		},
	},
	fieldSelectIcon: {
		"&>div": {
			borderRadius: '8px',
		},
	},
	viziWarningText: {
		fontSize: '12px !important',
		fontWeight: '400 !important',
		color: '#5F6368',
	},
	documentWarning: {
		backgroundColor: "#F9BE73",
    borderRadius: "30px",
    margin: "8px 0px 0px 0px",
    display: 'flex',
    padding: "0 10px",
	},
	documentWarningText: {
		color: colors.commons.gray9,
    fontSize: "12px !important",
    fontStyle: "normal",
    fontWeight: "normal",
    fontFamily: "Poppins",
    margin: 0,
	},
	clipboardCopy: {
		width: '150px',
	},
	cardPrintCardText: {
		fontSize: '14px !important',
		fontWeight: '600 !important',
		color: '#5F6368',
		padding: '8px 0px',
	},
});

export default styles;