import React from "react";
import { inject, observer } from "mobx-react";
import { Grid, CircularProgress } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles/index";
import moment from "moment";
import styles from "./RelatorioAtendimentosRealizadosStyle";
import { InputDateForm } from "../../../../components/Modal/Input";
import Table from "../../../../components/Table/Table";
import HeaderRelatorio, { periodos } from "../../HeaderRelatorio";
import MensagemListaVazia from "../../../../components/Mensagem/MensagemListaVazia";
import {
  relatorioProcedimento,
  relatorioProcedimentoTotal,
  relatorioProcedimentoLista,
} from "../../../../services/RelatorioService";
import { applyCurrencyMask } from "../../../../utils/CurrencyMask";
import Scroll from "../../../../components/InfiniteScroll/Scroll";
import InfoExpansiveButton from "../../../../components/Button/InfoExpansiveButton";
import { Print } from "@material-ui/icons";
import AtendimentosRealizadosContent from "../../../../template/pdf/relatorio/AtendimentosRealizados";
import ImpressaoHtml from "../../../../components/Impressao/ImpressaoHtml";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import { Button } from "../../../../components/ui/Buttons";
import Notification from "../../../../components/Notification";

const columns = [
  {
    Header: "Procedimento",
    field: "procedimento",
    getValue: (row) => {
      return row.procedimento;
    },
  },
  {
    Header: "Quantidade",
    field: "quantidade",
    getValue: (row) => {
      return row.quantidade;
    },
  },
  {
    Header: "Valor total",
    field: "valor",
    getValue: (row) => {
      return applyCurrencyMask(row.valor);
    },
  },
];

@inject(
  "atendimentoStore",
  "googleAnalyticsStore",
  "configuracaoImpressaoStore",
  "relatorioStore"
)
@observer
class RelatorioAtendimentosRealizados extends React.Component {
  constructor() {
    super();
    this.state = {
      procedimentosAgendados: [],
      dataInicio: moment().format('YYYY-MM-DD'),
      dataFim: moment().format('YYYY-MM-DD'),
      periodoSelecionado: periodos[0],
      loading: false,
      pageNumber: 0,
      lastScroll: false,
      ordenarTabela: {
        sortField: "quantidade",
        sortDir: "DESC",
      },
      openInfoTotal: false,
      infosTotal: 0,
      html: "",
      isPrintMustache: false,
      dadosRelatorio: null,
      totalAgendamentos: 0,
    };
  }

  async componentDidMount() {
    const paginaVisualizada = {
      page: window.location.hash,
      title: "Relatórios - Procedimentos por agendamento",
    };
    this.props.googleAnalyticsStore.pageView(paginaVisualizada);
  }

  getInfoTotal = async () => {
    const { dataInicio, dataFim } = this.state;
    const infosTotal = await relatorioProcedimentoTotal({
      dataInicio,
      dataFim,
    });
    this.setState({ infosTotal });
  };

  listarProcedimentosAgendados = async () => {
    try {
      this.setState({ loading: true });
      const {
        pageNumber,
        ordenarTabela,
        dataInicio,
        dataFim,
        procedimentosAgendados,
      } = this.state;

      const response = await relatorioProcedimento({
        dataInicio,
        dataFim,
        pageableDTO: {
          pageSize: 30,
          pageNumber,
          sortDir: ordenarTabela.sortDir,
          sortField: ordenarTabela.sortField,
        },
      });
      const { last, content, totalElements} = response;

      this.setState({
        lastScroll: last,
        procedimentosAgendados: [
          ...procedimentosAgendados,
          ...content,
        ],
        totalAgendamentos: totalElements, 
      });
    } catch (error) {
      console.log(error);
    } finally {
      this.setState({ loading: false });
    }
  };

  loadMore = () => {
    const { loading, procedimentosAgendados, pageNumber } = this.state;
    if (loading) {
      return;
    }

    this.setState({
      pageNumber: procedimentosAgendados.length > 0 ? pageNumber + 1 : 0,
    });

    this.listarProcedimentosAgendados();
  };

  handlePeriodo = (value) => {
    const { dataInicial, dataFinal } = value;

    this.setState({
        periodoSelecionado: value,
        dataInicio: dataInicial,
        dataFim: dataFinal,
    });
  };

  handleDateChange = (e, field) => {
    this.clearPesquisar();
    const dataFormatada = e && moment(e).format("YYYY-MM-DD");

    this.setState({ 
      [field]: dataFormatada,
      periodoSelecionado: ''
    });
  };

  clearPesquisar = () => {
    this.setState({
      pageNumber: 0,
      procedimentosAgendados: [],
      lastScroll: false,
    });
  };

  handleClickOrdenar = (value) => {
    const { ordenarTabela } = this.state;
    this.clearPesquisar();

    const sortDir =
      ordenarTabela.sortField !== value
        ? "ASC"
        : ordenarTabela.sortDir === "ASC"
        ? "DESC"
        : "ASC";

    this.setState(
      {
        ordenarTabela: {
          sortDir: sortDir,
          sortField: value,
        },
      },
      () => {
        this.listarProcedimentosAgendados();
      }
    );
  };

  handleOpenInfoTotal = () => {
    const { openInfoTotal } = this.state;
    this.setState({ openInfoTotal: !openInfoTotal });
  };

  printRelatorio = async () => {
    const { dataInicio, dataFim } = this.state;
    try {
      const dadosRelatorio = await relatorioProcedimentoLista({
        dataInicio,
        dataFim,
      });

      this.setState({ dadosRelatorio }, () => {
        this.handlePrintRelatorio();

      });
    } catch (e) {
      relatorioStore.openNotification("Erro ao imprimir relatorio!", "error")
    }
  };

  handlePrintRelatorio = () => {
    const { dadosRelatorio } = this.state
    const { relatorioStore } = this.props
    if (dadosRelatorio) {
      this.setState({ isPrintMustache: true });
    } else {
      relatorioStore.openNotification("Erro ao buscar dados para impressão!", "error")
    }
  };

  verificaDatas = () => {
    const { dataInicio, dataFim } = this.state;
    const isDataFimAntesDataInicio = moment(dataFim).isBefore(dataInicio);

    return !dataInicio || !dataFim || isDataFimAntesDataInicio
  }

  handleClickSearch = () => {
    const { dataInicio, dataFim } = this.state;
    const { relatorioStore } = this.props
    if (dataInicio && dataFim) {
      const isValid = relatorioStore.verificaLimiteTrintaDias(dataInicio, dataFim)
      if (isValid) {
        this.getInfoTotal();
        this.listarProcedimentosAgendados();
      } else {
        relatorioStore.openNotification("O limite de sessenta dias foi excedido!", "error")
      }
    }
  }

  render() {
    const { classes,relatorioStore } = this.props;
    const {
      dataInicio,
      dataFim,
      periodoSelecionado,
      loading,
      procedimentosAgendados,
      lastScroll,
      ordenarTabela,
      openInfoTotal,
      infosTotal,
      totalAgendamentos,
    } = this.state;

    const disabledButtonFilter =  !dataInicio || !dataFim || loading

    return (
      <div className={classes.content}>
        <PageTitle title="Relatórios - Procedimentos agendados"/>
        <HeaderRelatorio
          handlePeriodo={this.handlePeriodo}
          periodoSelecionado={periodoSelecionado}
          hiddenSearch
          totalTitle='agendamentos'
          totalAgendamentos={totalAgendamentos}
        />
        <div className={classes.contentFiltros}>
          <div className={classes.filtros}>
            <Grid item xs={3} className={classes.spacingConvenio}>
              <span className={classes.tituloFiltros}> Data início: </span>
              <InputDateForm
                iconposition="end"
                openTo="day"
                views={["year", "month"]}
                value={dataInicio}
                classes={{
                  input: classes.inputData,
                }}
                onChange={(e) => this.handleDateChange(e, "dataInicio")}
              />
            </Grid>
            <Grid item xs={3} className={classes.spacingConvenio}>
              <span className={classes.tituloFiltros}> Data fim: </span>
              <InputDateForm
                iconposition="end"
                openTo="day"
                views={["year", "month"]}
                value={dataFim}
                classes={{
                  input: classes.inputData,
                }}
                onChange={(e) => this.handleDateChange(e, "dataFim")}
                minDate={dataInicio}
              />
            </Grid>
          </div>
          <Button
            shape='pill'
            bgColor='#707C97'
            onClick={this.handleClickSearch}
            disabled={disabledButtonFilter}
          >
            Filtrar
          </Button>
        </div>
        {!loading && procedimentosAgendados.length === 0 && (
          <MensagemListaVazia />
        )}
        {procedimentosAgendados.length > 0 && (
          <Scroll
            loadMore={this.loadMore}
            hasMore={!lastScroll}
            pageStart={0}
            initialLoad={false}
          >
            <Table
              dados={procedimentosAgendados}
              columns={columns}
              clickable={false}
              comOrdenacao
              ordenarTabela={ordenarTabela}
              handleClickOrdenar={this.handleClickOrdenar}
            />
          </Scroll>
        )}
        {loading && (
          <Grid
            container
            justify="center"
            alignItems="center"
            style={{ height: "100%", marginTop: 20 }}
          >
            <CircularProgress size={30} />
          </Grid>
        )}
        <div>
          <InfoExpansiveButton
            openInfo={openInfoTotal}
            handleOpenInfo={this.handleOpenInfoTotal}
          >
            <div className={classes.contentInfos}>
              <div className={classes.rowInfos}>
                Quantidade total: <span> {infosTotal.quantidade} </span>
              </div>
              <div className={classes.rowInfos}>
                Valor total:
                <span>
                  {applyCurrencyMask(
                    infosTotal.valor ? infosTotal.valor : 0
                  )}
                </span>
              </div>
            </div>
          </InfoExpansiveButton>
        </div>
        <div className={classes.buttonsDownloadPrint}>
          <Button
            shape='circle'
            bgColor='#F9BE73'
            shadow
            onClick={this.printRelatorio}
            disabled={this.verificaDatas()}
          >
            <Print />
          </Button>
        </div>
        { this.state.isPrintMustache && <ImpressaoHtml
          isPrintMustache={this.state.isPrintMustache}
          handlePrintMustache={() => {this.setState({ isPrintMustache: false })}}
          htmlStringComponent={<AtendimentosRealizadosContent
            dadosRelatorio={this.state.dadosRelatorio}
            dataInicio={this.state.dataInicio}
            dataFim={this.state.dataFim}
            infosTotal={this.state.infosTotal}
          />}
        />}
        <Notification
          isOpen={relatorioStore.notification.isOpen}
          variant={relatorioStore.notification.variant}
          message={relatorioStore.notification.message}
        />
      </div>
    );
  }
}

export default withStyles(styles)(RelatorioAtendimentosRealizados);
