import React, { useState, useEffect } from 'react';
import { withStyles } from '@material-ui/core';

import Switch from '../../../../../components/ui/Switches/Switch';

import PersonIcon from '../../../../../components/Icon/PersonIcon';
import MultipleSelectCheckbox from '../../../../../components/Select/MultipleSelectCheckbox';
import BuildingHomeIcon from '../../../../../components/Icon/BuildingHomeIcon';
import { ReactSelect } from '../../../../../components/Select/SelectSearch';

import { getProfissionaisByUnidade, getUnidades } from '../utils/queries';
import { findProfissionalSaudeLogado } from '../../../../../services/UsuarioService';
import { alterarUnidadeLogada, findUnidadeLogada } from '../../../../../services/UnidadeService';

import { observer } from 'mobx-react-lite';
import { inject } from 'mobx-react';

import { ALL_CHECK_OPTION } from '../utils/constants';

import usePrevious from '../../../../../hooks/usePrevious';

const styles = {
  headerOptions: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  },
  switchContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  selectContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  select: {
    height: '32px',
    width: '116px',
    borderRadius: '8px',
  },
  unidadeSelect: {
    width: "108px",
  },
  switchRoot: {
    marginLeft: '-10px',
  },
  popper: {
    zIndex: 1300,
    width: "170px",
    height: "fit-content",
    maxHeight: "420px",
    borderRadius: "8px",
    overflowY: "auto",
    background: "#FFF",
    top: '8px !important',
    padding: 0
  },
};

const ModalAnamneseHeaderOptions = observer(({ 
  anamneseModeloSelecionado,
  profissionaisSelected, 
  unidade, 
  status,
  handleSelectProfissionais,
  handleSelectUnidade, 
  handleChangeStatus,
  userCanViewSelectors, 
  classes,
  openNotification,
  isAnamnesePadrao,
  handleChangeSwitchPadrao,
}) => {
  const [
    profissionaisOfUnidadeLogada,
    setProfissionaisOfUnidadeLogada,
  ] = useState([]);
  const [unidades, setUnidades] = useState([]);
  const [multipleSelectCheckboxText, setMultipleSelectCheckboxText] = useState("");

  const prevUnidadeSelected = usePrevious(unidade);

  const handleChangeCheckbox = (option) => {
    const { id } = option;
    let profissionaisOfUnidadeLogadaCopy = [...profissionaisOfUnidadeLogada];

    if (id === 0) {
      profissionaisOfUnidadeLogadaCopy = profissionaisOfUnidadeLogadaCopy.map((item) => {
        return {
          ...item,
          checked: !option.checked,
        }
      });
    } else {
      const optionsChecked = profissionaisOfUnidadeLogada.map((item) => {
        if (item.id === id) {
          return ({
            ...item,
            checked: !item.checked
          }
          )
        } else {
          return item
        }
      })
      profissionaisOfUnidadeLogadaCopy = optionsChecked
    }

    setProfissionaisOfUnidadeLogada(profissionaisOfUnidadeLogadaCopy);
    const { length: copyLength } = profissionaisOfUnidadeLogadaCopy;
    updateSelectedList(profissionaisOfUnidadeLogadaCopy.slice(1, copyLength));
  };

  const updateSelectedList = (profissionaisSaude) => {
    const profissionaisSelected = profissionaisSaude.reduce((acc, profissional) => {
      if (profissional.checked) {
        acc.push({ id: profissional.id });
      }
      return acc;
    }, []);
    handleSelectProfissionais(profissionaisSelected);
  }

  const onChangeStatus = (e) => {
    e.preventDefault();
    handleChangeStatus();
  }
  
  const onChangePadrao=(e)=>{
    e.preventDefault();
    handleChangeSwitchPadrao()
  }

  const loadProfissionaisByUnidade = async (profissionalSaudeLogado) => {
    const { id,profissionaisSaude } = anamneseModeloSelecionado || {};
    try {
      const profissionais = await getProfissionaisByUnidade();
      const isNew = !id;

      const profissionaisWithCheckedField = profissionais.map((profissional) => {
        if(!isNew){
          const profissionalAnamneseChecked = profissionaisSaude.some(item => profissional?.id === item?.id);
 
 
          return {
            ...profissional,
            checked: profissionalAnamneseChecked
          }
        }else{
 
 
          return {
            ...profissional,
            checked:   (profissionalSaudeLogado?.id === profissional.id )
          };
        }
      });
      setProfissionaisOfUnidadeLogada([ALL_CHECK_OPTION, ...profissionaisWithCheckedField]);
      updateSelectedList(profissionaisWithCheckedField);
    } catch (error) {
      openNotification({ isOpen: true, message: error.message });
    }
  }

  const loadInitialData = async () => {
    const unidades = getUnidades();
    const profissionalLogado = await findProfissionalSaudeLogado();
    loadDataAfterChangeUnidade(profissionalLogado, true);
    setUnidades(unidades);
  }

  const onSelectUnidade = async (unidade) => {
    try {
      if (prevUnidadeSelected?.id !== unidade?.id) {
        await alterarUnidadeLogada(unidade?.id);
        const profissionalSaudeLogado = await findProfissionalSaudeLogado(); 
        await loadDataAfterChangeUnidade(profissionalSaudeLogado);
      }
    } catch(error) {
      console.error(error);
      openNotification({ isOpen: true, message: error.message });
    }
  };

  const loadDataAfterChangeUnidade = async (profissionalSaude, initialLoad = false) => {
    const unidadeAtual = await findUnidadeLogada();
    await handleSelectUnidade(unidadeAtual, profissionalSaude, initialLoad);
    await loadProfissionaisByUnidade(profissionalSaude);
  };

  const getCheckboxSelectorText = () => {
    let text = "";
    if (profissionaisOfUnidadeLogada[0]?.checked || profissionaisSelected.length === profissionaisOfUnidadeLogada?.length - 1) {
      text = "Todos";
    } else {
      text = profissionaisSelected?.length === 0 ? 'Selecione' : `${profissionaisSelected.length} selecionados`;
    }

    setMultipleSelectCheckboxText(text);
  }

  useEffect(() => {
    loadInitialData();

    return () => {
      setProfissionaisOfUnidadeLogada([]);
    }
  }, []);

  useEffect(() => {
    getCheckboxSelectorText();
  }, [profissionaisSelected]);

  useEffect(() => {
    if (anamneseModeloSelecionado?.profissionaisSaude?.length > 0) {
      getCheckboxSelectorText();
    }
  }, [anamneseModeloSelecionado]);

  return (
    <div className={classes.headerOptions}>
      <Switch
        onClick={onChangePadrao} 
        label={'Padrão'} 
        checked={isAnamnesePadrao} 
        height={32}
        width={36}
        checkedColor={'#219A97'}
        uncheckedColor={'#E5E5E5'}
      />
      <Switch
        onClick={onChangeStatus} 
        label={'Status'} 
        checked={status} 
        height={32}
        width={36}
        checkedColor={'#219A97'}
        uncheckedColor={'#E5E5E5'}
      />
      {userCanViewSelectors && (
        <>
          <div className={classes.selectContainer}>
            <BuildingHomeIcon size={24} color='#505050' />
            <ReactSelect
              className={classes.unidadeSelect}
              options={unidades}
              value={unidade}
              onChange={onSelectUnidade}
              getOptionLabel={(option) => option.nome}
              getOptionValue={(option) => option.id}
            />
          </div>

          <div className={classes.selectContainer}>
            <PersonIcon size={24} color='#505050' />
            <MultipleSelectCheckbox
              classes={{
                select: classes.select,
                popper: classes.popper,
                paper: classes.checkboxOptions
              }}
              listaOpcoes={profissionaisOfUnidadeLogada}
              changeCheckbox={handleChangeCheckbox}
              withCustomText
              customText={multipleSelectCheckboxText}
            />
          </div>  
        </>
      )}
    </div>
  );
});

const stores = ['usuarioStore']
const ModalAnamneseHeaderOptionsStyles = withStyles(styles)(ModalAnamneseHeaderOptions);
export default inject(...stores)(ModalAnamneseHeaderOptionsStyles);
