const styles = {
  container: {
    "& thead th": {
      textAlign: "left",
      top: 0,
      position: "sticky",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    padding: "24px 24px 0 24px",
    backgroundColor: "#F5F5F5",
    gap: "16px",
    height: "calc(100% - 104px)",
  },
  tableContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    overflow: "hidden",
    borderRadius: "16px",
    border: "solid 1px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#FFF",
  },
  scrollContainer: {
    height: "100%",
  },
  notFoundContainer: {
    marginTop: "20px",
  },
  dialogPaper: {
    maxWidth: "540px",
    maxHeight: "407px",
    height: "100%",
    overflow: "hidden",
  },
  modalHeader: {
    minHeight: 64,
    padding: "0px 32px",
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
    justifyContent: "space-between",
  },
  titleModal: {
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "27px",
    color: "#26ACA9",
  },
  containerModal: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    padding: "16px 0",
    gap: "8px",
  },
  contentModal: {
    height: "100%",
  },
  toggleButtons: {
    margin: "0 32px",
  },
};

export default styles;
