import React, { useEffect, useState, lazy, Suspense } from 'react'
import { withStyles } from '@material-ui/core/styles/index'
import { Grid, CircularProgress } from '@material-ui/core'
import { Close as CloseIcon, Save as SaveIcon } from '@material-ui/icons'

import Profile from '../../../template/Header/Profile'
import Header from '../../../template/Header/Header'

import Notification from '../../../components/Notification'
import PageTitle from '../../../components/PageTitle/PageTitle'
import MultiToggleButtons from '../../../components/Button/MultiToggleButtons'
import { Button } from '../../../components/ui/Buttons'

import {
  findByUnidadeLogadaConfiguracaoUnidade,
  saveConfiguracaoUnidade,
} from '../../../services/ConfiguracoesUnidadeService'

import { pageConfiguracoes } from '../../../utils/getPageTitle'
import { tabs } from '../../../stores/Auditoria.store'
import { CAMPOS_OBRIGATORIOS } from './Tabs/Pacientes'
import unidadeStore from '../../../stores/UnidadeStore.store'
import string from '../../../utils/string'

const TABS = [
  lazy(() => import('./Tabs/Agendamento')),
  lazy(() => import('./Tabs/Pacientes')),
  lazy(() => import('./Tabs/Financeiro')),
  lazy(() => import('./Tabs/Gerais')),
  lazy(() => import('./Tabs/Auditoria')),
]

const multiSelectFields = [
  "filtroProcedimento",
  "situacoesNotificaAzulControle",
];

const ConfiguracaoUnidade = ({ classes }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [configuracaoUnidade, setConfiguracaoUnidade] = useState({})
  const [loadingSave, setLoadingSave] = useState(false)
  const [notification, setNotification] = useState({
    open: false,
    message: 'Erro',
    type: 'error',
  })
  const [tabSelected, setTabSelected] = useState(tabs.INFORMACOES)
  const [option,setOption]=useState({})

  const loadConfiguracaoUnidade = async () => {
    setIsLoading(true)
    try {
      const response = await findByUnidadeLogadaConfiguracaoUnidade()
      const { findByUnidadeLogadaConfiguracaoUnidade: content } = response.data.data
      if (content?.camposSujeitoAtencao?.length === 0) {
        content.camposSujeitoAtencao = Object.values(CAMPOS_OBRIGATORIOS).map(campo => ({
          campo,
          status: false,
        }))
      }

      multiSelectFields.forEach((value) => {
        content[value] = content[value].map(item =>({
          label: string.capitalize(item),
          value: item
        }))
      })

      unidadeStore.configuracaoUnidade = content;
      setConfiguracaoUnidade(content)
    } catch (error) {
      openNotification('Erro ao carregar configurações da unidade', error)
    } finally {
      setIsLoading(false)
    }
  }

  const handleChange = (field, event) => {
    let value = event
    const inputFields = ['numeroHorasBloqueioAgendamento', 'horasPermiteConfirmarAgendamento']

    if (inputFields.includes(field)) {
      value = event >= 0 ? event : null
    }

    setConfiguracaoUnidade(prevState => ({
      ...prevState,
      [field]: value,
    }))
  }

  const handleChangeCamposObrigatorios = (field, event) => {
    const { camposSujeitoAtencao } = configuracaoUnidade

    const newCamposSujeitoAtencao = camposSujeitoAtencao.map(campo => {
      if (campo.campo === field) {
        return {
          ...campo,
          status: event,
        }
      }
      return campo
    })

    setConfiguracaoUnidade(prevState => ({
      ...prevState,
      camposSujeitoAtencao: newCamposSujeitoAtencao,
    }))
  }

  const usuarioLogado = JSON.parse(localStorage[`_immortal|usuarioLogado`] || null)
  const isRoleUnidadeUpdate = usuarioLogado.authorities.some(
    value => value.authority === 'ROLE_UNIDADE_UPDATE'
  )

  const save = async () => {
    if (!isRoleUnidadeUpdate) {
      openNotification('Você não tem permissão para alterar as configurações da unidade')
      return
    }
    setLoadingSave(true)
    try {
      let configuracao = {
        ...configuracaoUnidade,
      }

      multiSelectFields.forEach(field => {
        const configuracaoUnidadeTypeMultiSelect = configuracaoUnidade[field].map(item => {
          return item.value
        });

        configuracao[field] = configuracaoUnidadeTypeMultiSelect
      })

      const saveConfiguracao = {
        configuracaoUnidade: {
          ...configuracao,
          unidade: {
            id: configuracaoUnidade.unidade.id,
          },
        },
      }
      await saveConfiguracaoUnidade(saveConfiguracao)
      await loadConfiguracaoUnidade()
    } catch {
      openNotification('Erro ao salvar configuração da unidade')
    } finally {
      setLoadingSave(false)
    }
  }

  const handleClickCancelar = () => {
    loadConfiguracaoUnidade()
  }

  const openNotification = (message, variant = null) => {
    setNotification(prevState => ({
      ...prevState,
      open: true,
      variant: variant,
      message: message,
    }))
  }

  const closeAlertMessage = () => {
    setNotification(prevState => ({
      ...prevState,
      open: false,
    }))
  }

  const changeTabSelected = async tabSelected => {
    setTabSelected(tabSelected)
  }

  const renderTab = () => {
    const Component = TABS[tabSelected]
    return (
      <Suspense
        fallback={
          <div className={classes.wrapperFallback}>
            <CircularProgress color='primary' size={12} />
          </div>
        }
      >
        <Component
          handleChange={handleChange}
          configuracaoUnidade={configuracaoUnidade}
          isRoleUnidadeUpdate={isRoleUnidadeUpdate}
          handleChangeCamposObrigatorios={handleChangeCamposObrigatorios}
          option={option}
        />
      </Suspense>
    )
  }

  useEffect(() => {
    loadConfiguracaoUnidade()
  }, [])

  return (
    <div className={classes.root}>
      <PageTitle title={pageConfiguracoes('Unidade')} />
      <Header className={classes.header} disableShadow borderBottom>
        <h1>Config. da unidade</h1>
        <Profile />
      </Header>

      <div className={classes.container}>
        <MultiToggleButtons
          options={['Agendamento', 'Pacientes', 'Financeiro', 'Gerais', 'Auditoria']}
          tabSelected={tabSelected}
          changeTabSelected={changeTabSelected}
          disabled={!configuracaoUnidade.azulControleAtivado}
          posicaoDesabilitada={[2]}
        />
        {isLoading && (
          <Grid container justify='center' alignItems='center' style={{ height: '100%' }}>
            <CircularProgress size={30} />
          </Grid>
        )}

        {!isLoading && renderTab()}

        <div className={classes.wrapperActions}>
          <Button kind='primary' shape='circle' onClick={save} disabled={loadingSave}>
            {loadingSave ? <CircularProgress color='inherit' size={24} /> : <SaveIcon />}
          </Button>
          <Button kind='primary' shape='circle' bgColor={'#FB7676'} onClick={handleClickCancelar}>
            <CloseIcon />
          </Button>
        </div>
      </div>

      <Notification
        close={closeAlertMessage}
        reset={closeAlertMessage}
        isOpen={notification.open}
        variant={notification.type}
        message={notification.message}
      />
    </div>
  )
}

const styles = theme => ({
  root: {
    backgroundColor: '#F5F5F5',
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    background: 'white',
    height: '64px',
    padding: '0px 16px !important',

    '& h1': {
      fontSize: '18px',
      fontWeight: 'bold',
    },
  },

  container: {
    minWidth: 'calc(100% - 80px)',
    maxWidth: 'calc(100% - 80px)',
    maxHeight: 'calc(100% - 144px)',
    margin: 'auto',
    border: '1px solid rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
    padding: '16px',
    gap: '20px',
    flex: '1',
    background: 'white',
    borderRadius: '8px',
  },

  wrapperFallback: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  wrapperActions: {
    position: 'absolute',
    right: 10,
    bottom: '35%',
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    flexDirection: 'column',
  },
})

export default withStyles(styles)(ConfiguracaoUnidade)
