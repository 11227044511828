export const enviarMensagem = async ({ mensagem, objetoAmazonS3DTO, ChatApi }) => {
  const variables = {
    mensagem: mensagem,
    ...(objetoAmazonS3DTO && { objetoAmazonS3DTO }),
  };

  const response = await ChatApi.post('', {
    query: `
      mutation ($mensagem: MensagemInput, $objetoAmazonS3DTO: ObjetoAmazonS3DTOInput) {
        enviarMensagem(mensagem: $mensagem, objetoAmazonS3DTO: $objetoAmazonS3DTO) {
          id
          dataHoraCriacao
          remetente {
            id
          }
          destinatario {
            id
          }
          mensagemAmazonS3Objeto {
            nomeComExtensao
          }
          lido
          mensagem
          tipoMensagem
          s3Key
          sucesso
          status
          urlDownload
        }
      }
    `,
    variables
  });

  if (response.data?.errors) {
    throw Error('Falha ao criar mensagem');
  }

  return response.data.data.enviarMensagem;
};

export const getContactsListByProfissionalSaude = async ({ profissionalSaudeId, pageableDTO, search, ChatApi, ativo }) => {
  const response = await ChatApi.post('', {
    query: `
      query ($profissionalSaudeId: Long, $pageableDTO: PageableDTOInput, $search: String, $ativo: Boolean) {
        findContatosOfProfissionalSaude(profissionalSaudeId: $profissionalSaudeId, pageableDTO: $pageableDTO, search: $search, ativo: $ativo) {
          content {
            id
            fixado
            profissionalSaudeContato {
              id
              ativo
              fotoPerfil
              nome
              online
              tipo
            }
            ultimaMensagem {
              id
              mensagem
              dataHoraCriacao
              lido
              tipoMensagem
              urlDownload
              remetente {
                id
              }
              mensagemAmazonS3Objeto {
                nomeComExtensao
              }
            }
          }
          last
        }
      }
    `,
    variables: {
      profissionalSaudeId,	
      pageableDTO,
      search,
      ativo
    }
  });

  if (response.data.errors) {
    throw Error('Falha ao carregar contatos do profissional de saúde'); 
  }

  if (!response) {
    return { content: [], last: true };
  }

  const { content, last } = response.data?.data?.findContatosOfProfissionalSaude;
  return { content, last };
}

export const getMessagesByContact = async ({ destinatarioId, remetenteId, pageableDTO, ChatApi }) => {
  const response = await ChatApi.post('', {
    query: `
      query ($profissionalSaudeIdRemetente: Long, $profissionalSaudeIdDestinatario: Long, $pageableDTO: PageableDTOInput){
        findMensagensByProfissionais(profissionalSaudeIdRemetente: $profissionalSaudeIdRemetente, profissionalSaudeIdDestinatario: $profissionalSaudeIdDestinatario, pageableDTO: $pageableDTO){
          content {
            id
            mensagem
            remetente {
              id
            }
            urlDownload
            tipoMensagem
            dataHoraCriacao
            lido
            mensagemAmazonS3Objeto {
              nomeComExtensao
            }
          }
          last
        }
      }
    `,
    variables: {
      profissionalSaudeIdRemetente: remetenteId,
      profissionalSaudeIdDestinatario: destinatarioId,
      pageableDTO
    }
  });

  if (response.data.errors) {
    throw Error('Falha ao carregar mensagens do profissional de saúde selecionado.');
  }

  if (!response) {
    return { content: [], last: true };
  }

  const { content, last } = response.data.data.findMensagensByProfissionais;
  return { content, last };
}

export const findContatoByProfissionalSaudeId = async ({ profissionalSaudeId, profissionalSaudeContatoId, ChatApi }) => {
  const response = await ChatApi.post('', {
    query: `
      query ($profissionalSaudeId: Long, $profissionalSaudeContatoId: Long){
        findContatoByProfissionalSaudeId(profissionalSaudeId: $profissionalSaudeId, profissionalSaudeContatoId: $profissionalSaudeContatoId){
          id
          fixado
          profissionalSaudeContato {
            id
            ativo
            fotoPerfil
            nome
            online
            tipo
          }
          ultimaMensagem {
            id
            mensagem
            dataHoraCriacao
          }
        }
      }
    `,
    variables: {
      profissionalSaudeId,
      profissionalSaudeContatoId,
    }
  });

  if (response.data.errors) {
    throw Error('Falha ao carregar contato do profissional de saúde');  
  }

  return response.data.data.findContatoByProfissionalSaudeId;
}

export const fixarContato = async ({ contatoId, ordem, ChatApi }) => {
  const response = await ChatApi.post('', {
    query: `
      mutation ($contatoId: UUID, $ordem: Long){
        fixarContato(contatoId: $contatoId, ordem: $ordem) {
          id
          fixado
        }
      }
    `,
    variables: {
      contatoId,
      ordem
    }
  });

  if (response.data.errors) {
    throw Error('Falha ao fixar contato');
  }

  return response.data.data.fixarContato;
};

export const chamarPaciente = async ({ profissionalSaudeId, pacienteNome, ChatApi }) => {
  const response = await ChatApi.post('', {
    query: `
      mutation ($profissionalSaudeId: Long, $pacienteNome: String) {
        chamarPaciente(profissionalSaudeId: $profissionalSaudeId, pacienteNome: $pacienteNome)
      }  
    `,
    variables: {
      profissionalSaudeId,
      pacienteNome
    },
  });

  if (response.data.errors) {
    throw Error('Falha ao chamar paciente');
  }
};

export const atualizarMensagensNaoLidas = async ({ lastMessageId, ChatApi }) => {
  const response = await ChatApi.post('', {
    query: `
      mutation ($mensagemId: UUID) {
        atualizarMensagensNaoLidas(mensagemId: $mensagemId)
      }  
    `,
    variables: {
      mensagemId: lastMessageId,
    },
  });

  if (response.data.errors) {
    throw Error('Falha ao atualizar mensagens');
  }
  return response.data.data.atualizarMensagensNaoLidas;
};
