import React from "react";
import {
  ContainerTab,
  InputCEPFormStyled,
  InputPhoneFormStyled,
  Row,
  TextFieldSearchStyled,
} from "../../styles";
import Field from "../../components/Field";
import {
  InputDateForm,
  InputForm,
} from "../../../../../components/Modal/Input";
import {
  findAllCbo,
  findAllConvenio,
  findAllEstadoCivil,
  findAllMunicipio,
  findAllSexo,
} from "../../../../../services/InternacaoService";
import { consultaDeCep } from "../../../../../services/EnderecoService";

const DadosCadastrais = ({ internacao, handleField }) => {
  const { sujeitoAtencao } = internacao;
  const {
    nome,
    endereco,
    contato,
    rg,
    documento,
    sexo,
    dataNascimento,
    idade,
    dadosConvenio,
    ocupacao,
    nomeConjuge,
    nomeMae,
    nomePai,
    estadoCivil,
    origem,
    quemIndicou,
  } = sujeitoAtencao || {};

  const { cep, nomeLogradouro, bairro, municipio, numero } = endereco || {};

  const { telefonePrincipal, telefoneSecundario, email } = contato || {};

  const { convenio, numeroCarteira, validadeCarteira } = dadosConvenio || {};

  const { municipio: municipioOrigem } = origem || {};

  const handleFieldSujeitoAtencao = (value, field) => {
    const dadosSujeitoAtencao = internacao.sujeitoAtencao;
    dadosSujeitoAtencao[field] = value;

    handleField("sujeitoAtencao", dadosSujeitoAtencao);
  };

  const loadAllMunicipio = async (search, loadedOptions, { page }) => {
    const searchDTO = {
      search,
      pageNumber: page,
      pageSize: 20,
      sortDir: "ASC",
      sortField: "nome",
    };

    const response = await findAllMunicipio({ searchDTO });
    const { content, last } = response;

    return {
      options: content,
      hasMore: !last,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadAllSexo = async (search, loadedOptions, { page }) => {
    const searchDTO = {
      search,
      pageNumber: page,
      pageSize: 20,
      sortDir: "ASC",
      sortField: "descricao",
    };

    const response = await findAllSexo({ searchDTO });
    const { content, last } = response;

    return {
      options: content,
      hasMore: !last,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadAllConvenio = async (search, loadedOptions, { page }) => {
    const searchDTO = {
      search,
      pageNumber: page,
      pageSize: 20,
      sortDir: "ASC",
      sortField: "descricao",
    };

    const response = await findAllConvenio({ searchDTO });
    const { content, last } = response;

    return {
      options: content,
      hasMore: !last,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadAllOcupacao = async (search, loadedOptions, { page }) => {
    const searchDTO = {
      search,
      pageNumber: page,
      pageSize: 20,
      sortDir: "ASC",
      sortField: "descricao",
    };

    const response = await findAllCbo({ searchDTO });
    const { content, last } = response;

    return {
      options: content,
      hasMore: !last,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadAllEstadoCivil = async (search, loadedOptions, { page }) => {
    const searchDTO = {
      search,
      pageNumber: page,
      pageSize: 20,
      sortDir: "ASC",
      sortField: "nome",
    };

    const response = await findAllEstadoCivil({ searchDTO });
    const { content, last } = response;

    return {
      options: content,
      hasMore: !last,
      additional: {
        page: page + 1,
      },
    };
  };

  const onCEPChange = async (e) => {
    const { value } = e.target;

    const cepSemMascara = value.replace(/\D/g, "");
    if (cepSemMascara.length === 8) {
      const response = await consultaDeCep(cepSemMascara);
      const responseConsultaDeCep = response?.data?.data?.consultaDeCep || {};
      if (responseConsultaDeCep) {
        const { logradouro, bairro, municipio } = responseConsultaDeCep;

        const dadosSujeitoAtencao = {
          ...internacao.sujeitoAtencao,
          endereco: {
            nomeLogradouro: logradouro,
            bairro,
            municipio,
            uf: municipio?.estado?.uf,
          },
        };

        handleField("sujeitoAtencao", dadosSujeitoAtencao);
      }
    }

    handleFieldSujeitoAtencao(value, "cep");
  };

  const handleFieldUpdate = (value, type, field) => {
    const dadosAcompanhante = {
      ...internacao.responsavel,
      [type]: {
        ...internacao.responsavel[type],
        [field]: value
      }
    };
  
    handleField("sujeitoAtencao", dadosAcompanhante);
  };

  return (
    <ContainerTab>
      <Row>
        <Field label="Nome" width="80%">
          <InputForm
            value={nome}
            onChange={(e) => handleFieldSujeitoAtencao(e.target.value, "nome")}
            disabled
          />
        </Field>
        <Field label="Sexo" width="20%">
          {/* <TextFieldSearchStyled
            placeholder="Selecione"
            loadOptions={loadAllSexo}
            getOptionLabel={(option) => option.descricao}
            getOptionValue={(option) => option.id}
            value={sexo}
            onChange={(e) => handleFieldSujeitoAtencao(e, "sexo")}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          /> */}
          <InputForm
            value={sexo?.descricao || ""}
            disabled
          />
        </Field>
      </Row>
      <Row>
        <Field label="Data nascimento">
          <InputDateForm
            value={dataNascimento}
            onChange={(date) =>
              this.handleFieldSujeitoAtencao(date, "dataNascimento")
            }
            iconposition="end"
            disabled
          />
        </Field>
        <Field label="Idade">
          <InputForm value={idade} disabled />
        </Field>
        <Field label="RG">
          <InputForm
            value={rg}
            onChange={(e) => handleFieldSujeitoAtencao(e.target.value, "rg")}
            disabled
          />
        </Field>
        <Field label="CPF">
          <InputForm
            value={documento}
            onChange={(e) =>
              handleFieldSujeitoAtencao(e.target.value, "documento")
            }
            disabled
          />
        </Field>
      </Row>
      <Row>
        <Field label="Telefone principal">
          <InputPhoneFormStyled
            value={telefonePrincipal}
            onChange={(e) =>
              handleFieldUpdate(e.target.value, "contato", "telefonePrincipal")
            }
            disabled
          />
        </Field>
        <Field label="Telefone secundário">
          <InputPhoneFormStyled
            value={telefoneSecundario}
            onChange={(e) =>
              handleFieldUpdate(e.target.value, "contato", "telefoneSecundario")
            }
            disabled
          />
        </Field>
        <Field label="E-mail">
          <InputForm
            value={email}
            onChange={(e) => handleFieldUpdate(e.target.value, "contato", "email")}
            disabled
          />
        </Field>
      </Row>
      <Row>
        <Field label="Cônjuge">
          <InputForm
            value={nomeConjuge}
            onChange={(e) =>
              handleFieldSujeitoAtencao(e.target.value, "nomeConjuge")
            }
            disabled
          />
        </Field>
        <Field label="Nome mãe">
          <InputForm
            value={nomeMae}
            onChange={(e) =>
              handleFieldSujeitoAtencao(e.target.value, "nomeMae")
            }
            disabled
          />
        </Field>
        <Field label="Nome pai">
          <InputForm
            value={nomePai}
            onChange={(e) =>
              handleFieldSujeitoAtencao(e.target.value, "nomePai")
            }
            disabled
          />
        </Field>
        <Field label="Indicado">
          <InputForm
            value={quemIndicou}
            onChange={(e) =>
              handleFieldSujeitoAtencao(e.target.value, "quemIndicou")
            }
            disabled
          />
        </Field>
      </Row>
      <Row>
        <Field label="Profissão">
          {/* <TextFieldSearchStyled
            placeholder="Selecione"
            loadOptions={loadAllOcupacao}
            getOptionLabel={(option) => option.descricao}
            getOptionValue={(option) => option.id}
            value={ocupacao}
            onChange={(e) => handleFieldSujeitoAtencao(e, "ocupacao")}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          /> */}
          <InputForm
            value={ocupacao?.descricao || ""}
            disabled
          />
        </Field>
        <Field label="Estado Civil">
          {/* <TextFieldSearchStyled
            placeholder="Selecione"
            loadOptions={loadAllEstadoCivil}
            getOptionLabel={(option) => option.descricao}
            getOptionValue={(option) => option.id}
            value={estadoCivil}
            onChange={(e) => handleFieldSujeitoAtencao(e, "estadoCivil")}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          /> */}
          <InputForm
            value={estadoCivil?.descricao || ""}
            disabled
          />
        </Field>
        <Field label="Naturalidade">
          {/* <TextFieldSearchStyled
            placeholder="Selecione"
            loadOptions={loadAllMunicipio}
            getOptionLabel={(option) => option.descricao}
            getOptionValue={(option) => option.id}
            value={municipioOrigem}
            onChange={(e) => handleFieldUpdate(e, "origem", "municipio")}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          /> */}
          <InputForm
            value={municipioOrigem?.descricao || ""}
            disabled
          />
        </Field>
      </Row>
      <Row>
        <Field label="Convênio">
          {/* <TextFieldSearchStyled
            placeholder="Selecione"
            loadOptions={loadAllConvenio}
            getOptionLabel={(option) => option.descricao}
            getOptionValue={(option) => option.id}
            value={convenio}
            onChange={(e) => handleFieldUpdate(e, "dadosConvenio", "convenio")}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          /> */}
          <InputForm
            value={convenio?.descricao || ""}
            disabled
          />
        </Field>
        <Field label="N cartão">
          <InputForm
            value={numeroCarteira}
            onChange={(e) =>
              handleFieldUpdate(e.target.value, "dadosConvenio", "numeroCarteira")
            }
            disabled
          />
        </Field>
        <Field label="Validade">
          <InputDateForm
            value={validadeCarteira}
            onChange={(date) =>
              this.handleFieldUpdate(date, "dadosConvenio", "validadeCarteira")
            }
            iconposition="end"
            disabled
          />
        </Field>
      </Row>

      <Row>
        <Field label="CEP">
          <InputCEPFormStyled
            type="text"
            onChange={(e) => onCEPChange(e)}
            value={cep}
            disabled
          />
        </Field>
        <Field label="Endereço">
          <InputForm
            value={nomeLogradouro}
            onChange={(e) =>
              handleFieldUpdate(e.target.value, "endereco", "nomeLogradouro")
            }
            disabled
          />
        </Field>
        <Field label="Número">
          <InputForm
            value={numero}
            onChange={(e) => handleFieldUpdate(e.target.value, "endereco", "numero")}
            disabled
          />
        </Field>
      </Row>
      <Row>
        <Field label="Bairro">
          <InputForm
            value={bairro}
            onChange={(e) => handleFieldUpdate(e.target.value, "endereco", "bairro")}
            disabled
          />
        </Field>
        <Field label="Cidade">
          {/* <TextFieldSearchStyled
            placeholder="Selecione"
            loadOptions={loadAllMunicipio}
            getOptionLabel={(option) => `${option.nome} - ${option.uf}`}
            getOptionValue={(option) => option.id}
            value={municipio}
            onChange={(e) => handleFieldUpdate(e, "endereco", "municipio")}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
            menuInTop
          /> */}
          <InputForm
            value={municipio ? `${municipio.nome} - ${municipio.uf}` : ""}
            disabled
          />
        </Field>
      </Row>
    </ContainerTab>
  );
};

export default DadosCadastrais;
