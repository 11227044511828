import React from "react";
import { Button } from "../../../components/ui/Buttons";
import Acompanhante from "./Tabs/Acompanhante/Acompanhante";
import DadosCadastrais from "./Tabs/DadosCadastrais/DadosCadastrais";
import DadosComplementares from "./Tabs/DadosComplementares/DadosComplementares";
import Responsavel from "./Tabs/Responsavel/Responsavel";
import DeleteIcon from "../../../components/Icon/DeleteIcon";
import { applyCurrencyMask } from "../../../utils/CurrencyMask";
import ProcedimentosSolicitados from "./Tabs/ProcedimentosSolicitados/ProcedimentosSolicitados";
import ProcedimentosExecutados from "./Tabs/ProcedimentosExecutados/ProcedimentosExecutados";
import Cids from "./Tabs/Cids/Cids";
import EditIcon from "../../../components/Icon/EditIcon";
import { ContentButtonsAcao } from "./styles";
import string from "../../../utils/string";
import LeitosOcupados from "./Tabs/LeitosOcupados/LeitosOcupados";
import moment from "moment";

export const optionsTab = [
  "Dados cadast",
  "Acomp",
  "Responsável",
  "Dados Compl",
  "Leitos",
  "Proc Solic",
  "Proc Realizado",
  "CIDs",
];

export const tabs = {
  DADOS_CADASTRAIS: 0,
  ACOMPANHANTE: 1,
  RESPONSAVEL: 2,
  DADOS_COMPLEMENTARES: 3,
  PROCEDIMENTOS_SOLICITADO: 4,
  PROCEDIMENTOS_REALIZADO: 5,
  CID: 6,
};

export const componentTab = {
  0: DadosCadastrais,
  1: Acompanhante,
  2: Responsavel,
  3: DadosComplementares,
  4: LeitosOcupados,
  5: ProcedimentosSolicitados,
  6: ProcedimentosExecutados,
  7: Cids,
};

export const sujeitoAtencaoDefault = {
  id: null,
  nome: "",
  endereco: {
    cep: "",
    bairro: "",
    municipio: {
      id: null,
      nome: "",
      uf: "",
    },
    nomeLogradouro: "",
    numero: "",
  },
  contato: {
    telefonePrincipal: "",
    telefoneSecundario: "",
    email: "",
  },
  documento: "",
  rg: "",
  nomeConjuge: "",
  nomeMae: "",
  nomePai: "",
  sexo: {
    id: null,
    descricao: "",
  },
  dataNascimento: "",
  dadosConvenio: {
    convenio: {
      id: null,
      descricao: "",
    },
    numeroCarteira: "",
    validadeCarteira: "",
  },
  ocupacao: {
    id: null,
    descricao: "",
  },
  estadoCivil: {
    id: null,
    descricao: "",
  },
};

export const acompanhanteDefault = {
  nome: "",
  endereco: {
    cep: "",
    bairro: "",
    municipio: {
      id: null,
      nome: "",
      uf: "",
    },
    nomeLogradouro: "",
    numero: "",
  },
  contato: {
    telefonePrincipal: "",
    telefoneSecundario: "",
  },
  documento: "",
  rg: "",
  vinculo: "",
};

export const responsavelDefault = {
  nome: "",
  endereco: {
    cep: "",
    bairro: "",
    municipio: {
      id: null,
      nome: "",
      uf: "",
    },
    nomeLogradouro: "",
    numero: "",
  },
  contato: {
    telefonePrincipal: "",
    telefoneSecundario: "",
  },
  documento: "",
  rg: "",
  vinculo: "",
};

export const dadosComplementaresDefault = {
  ambulante: false,
  convenio: null,
  dataGuia: "",
  encaminhamento: {
    value: "INTERNACAO",
    label: "Internação",
  },
  numeroGuia: "",
  observacoesGerais: "",
  observacoesMedico: "",
  profissionalAtendente: null,
  profissionalResponsavel: null,
  profissionalSolicitante: null,
  transferido: "",
};

export const leitoOcupadoDefault = {
  id: null,
  leito: null,
  tipoLeito: {
    value: "ENFERMARIA",
    label: "Enfermaria",
  },
  convenio: null,
  dataInicioOcupacao: "",
  dataFimOcupacao: "",
};

export const procedimentosSolicitadosDefault = {
  id: null,
  procedimento: null,
  codigoAMB: "",
  profissionalSaude: null,
  sequencia: 1,
  valor: "",
};

export const procedimentosExecutadosDefault = {
  id: null,
  sequencia: 1,
  codigoAMB: "",
  procedimento: null,
  profissionalSaude: null,
  quantidade: "",
  valor: "",
  dataRealizacao: "",
  prestacaoServico: "",
  resultado: "",
};

export const cidDefault = {
  id: null,
  sequencia: 1,
  cid: "",
  descricao: "",
  diagnostico: "",
};

export const internacaoDefault = {
  sujeitoAtencao: sujeitoAtencaoDefault,
  acompanhante: acompanhanteDefault,
  responsavel: responsavelDefault,
  dadosComplementares: dadosComplementaresDefault,
  leitosOcupados: [],
  procedimentosSolicitados: [],
  procedimentosExecutados: [],
  cids: [],
};

export const tiposLeitos = [
  {
    value: "APARTAMENTO",
    label: "Apartamento",
  },
  {
    value: "ENFERMARIA",
    label: "Enfermaria",
  },
];

export const tiposEncaminhamentos = [
  {
    value: "INTERNACAO",
    label: "Internação",
  },
  {
    value: "CENTRO_CIRURGICO",
    label: "Centro cirúrgico",
  },
  {
    value: "PRONTO_SOCORRO",
    label: "Pronto socorro",
  },
  {
    value: "UTI",
    label: "UTI",
  },
];

export const columnsProcedimentosSolicitados = ({
  handleDelete,
  handleEdit,
}) => {
  return [
    {
      Header: "Seq",
      align: "start",
      getValue: (data) => {
        return data.sequencia;
      },
    },
    {
      Header: "Procedimento",
      align: "start",
      getValue: (data) => {
        return data.procedimento?.nome || "";
      },
    },
    {
      Header: "Profissional",
      align: "start",
      getValue: (data) => {
        return data.profissionalSaude?.nome || "";
      },
    },
    {
      Header: "Cód amb",
      align: "start",
      getValue: (data) => {
        return data.codigoAMB || "";
      },
    },
    {
      Header: "Valor",
      align: "start",
      getValue: (data) => {
        return typeof data.valor === "string" ? data.valor : applyCurrencyMask(data.valor || 0);
      },
    },
    {
      Header: "Ação",
      align: "start",
      getValue: (data) => {
        return (
          <ContentButtonsAcao>
            <Button
              shape="circle"
              bgColor="#FB7676"
              onClick={() => handleDelete(data)}
              style={{ height: "30px", width: "30px" }}
            >
              <DeleteIcon size={14} />
            </Button>
            <Button
              shape="circle"
              bgColor="#F9BE73"
              onClick={() => handleEdit(data)}
              style={{ height: "30px", width: "30px" }}
            >
              <EditIcon />
            </Button>
          </ContentButtonsAcao>
        );
      },
    },
  ];
};

export const columnsCids = ({ handleDelete, handleEdit }) => {
  return [
    {
      Header: "Seq",
      align: "start",
      getValue: (data) => {
        return data.sequencia;
      },
    },
    {
      Header: "Código CID",
      align: "start",
      getValue: (data) => {
        return data.cid?.codigo || "";
      },
    },
    {
      Header: "Descrição",
      align: "start",
      getValue: (data) => {
        return data.descricao || "";
      },
    },
    {
      Header: "Diagnóstico",
      align: "start",
      getValue: (data) => {
        return data.diagnostico || "";
      },
    },
    {
      Header: "Ação",
      align: "start",
      getValue: (data) => {
        return (
          <ContentButtonsAcao>
            <Button
              shape="circle"
              bgColor="#FB7676"
              onClick={() => handleDelete(data)}
              style={{ height: "30px", width: "30px" }}
            >
              <DeleteIcon size={14} />
            </Button>
            <Button
              shape="circle"
              bgColor="#F9BE73"
              onClick={() => handleEdit(data)}
              style={{ height: "30px", width: "30px" }}
            >
              <EditIcon />
            </Button>
          </ContentButtonsAcao>
        );
      },
    },
  ];
};

export const getErrorMessage = ({
  sujeitoAtencao,
  acompanhante,
  responsavel,
  procedimentosSolicitados,
  leitosOcupados,
}) => {
  let messageError = "Favor preencher os campos: ";
  let error = false;

  const campos = [
    { entidade: sujeitoAtencao, prefixo: "paciente" },
    { entidade: acompanhante, prefixo: "acompanhante" },
    { entidade: responsavel, prefixo: "responsável" },
  ];

  const validaCampo = (campo, prefixo, label) => {
    if (!campo) {
      messageError += `${label} do ${prefixo}, `;
      error = true;
    }
  };

  campos.forEach(({ entidade, prefixo }) => {
    validaCampo(entidade?.nome, prefixo, "nome");
    validaCampo(string.validaCPF(entidade?.documento), prefixo, "cpf");
    validaCampo(
      string.validaTelefone(entidade?.contato?.telefonePrincipal),
      prefixo,
      "telefone"
    );
  });

  if (leitosOcupados?.length === 0) {
    messageError += "leitos, ";
    error = true;
  }

  if (procedimentosSolicitados?.length === 0) {
    messageError += "procedimentos solicitados";
    error = true;
  }

  messageError = messageError.trim().replace(/,$/, "");

  return { messageError, error };
};

export const columnsLeitos = ({
  handleDelete,
  handleEdit,
}) => {
  return [
    {
      Header: "Leito",
      align: "start",
      getValue: (data) => {
        return data.leito?.nome || "";
      },
    },
    {
      Header: "Tipo leito",
      align: "start",
      getValue: (data) => {
        return data.tipoLeito?.label || "";
      },
    },
    {
      Header: "Convênio",
      align: "start",
      getValue: (data) => {
        return data.convenio?.descricao || "";
      },
    },
    {
      Header: "Data início",
      align: "start",
      getValue: (data) => {
        return moment(data?.dataInicioOcupacao || "").format("DD/MM/YYYY HH:mm");
      },
    },
    {
      Header: "Data fim",
      align: "start",
      getValue: (data) => {
        return moment(data?.dataFimOcupacao || "").format("DD/MM/YYYY HH:mm");
      },
    },
    {
      Header: "Ação",
      align: "start",
      getValue: (data) => {
        return (
          <ContentButtonsAcao>
            <Button
              shape="circle"
              bgColor="#FB7676"
              onClick={() => handleDelete(data)}
              style={{ height: "30px", width: "30px" }}
            >
              <DeleteIcon size={14} />
            </Button>
            <Button
              shape="circle"
              bgColor="#F9BE73"
              onClick={() => handleEdit(data)}
              style={{ height: "30px", width: "30px" }}
            >
              <EditIcon />
            </Button>
          </ContentButtonsAcao>
        );
      },
    },
  ];
};