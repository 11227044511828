import React, { useEffect, useState } from "react";
import classNames from "classnames";
import moment from "moment";
import { Checkbox, CircularProgress, Grid, withStyles } from "@material-ui/core";
import PageTitle from "../../../../components/PageTitle/PageTitle";
import HeaderRelatorio from "../../HeaderRelatorio";
import Table from "../../../../components/Table/Table";
import { relatorioAgendamentoProducaoPorProfissional, relatorioAgendamentoProducaoPorProfissionalCsv } from "../../../../services/RelatorioService";
import { columns, filtersDefault } from "./constants";
import { TextFieldSearch } from "../../../../components/TextField";
import { findAllProfissionalSaude } from "../../../../services/ProfissionalSaudeService";
import PrintIcon from "../../../../components/Icon/Print";
import { Button } from "../../../../components/ui/Buttons";
import ImpressaoHtml from "../../../../components/Impressao/ImpressaoHtml";
import Notification from "../../../../components/Notification";
import MultipleSelectCheckbox from "../../../../components/Select/MultipleSelectCheckbox";
import { situacoesOptionsDefault } from "./constants";
import AgendamentosPorUsuario from "../../../../template/pdf/relatorio/AgendamentosPorUsuario";
import ArrowDownloadIcon from "../../../../components/Icon/ArrowDownload";
import { base64Convert } from "../../../../utils/base64ToCsv";
import { InputDateForm } from "../../../../components/Modal/Input";
import { inject } from "mobx-react";
import Scroll from "../../../../components/InfiniteScroll/Scroll";
import MensagemListaVazia from "../../../../components/Mensagem/MensagemListaVazia";

const RelatorioAgendamentosUsuario = ({ classes, unidadeStore, relatorioStore }) => {
    const [agendamentosUsuario, setAgendamentosUsuario] = useState([]);
    const [agendamentosUsuarioPrint, setAgendamentosUsuarioPrint] = useState([]);
    const [filters, setFilters] = useState(filtersDefault);
    const [isLoading, setIsLoading] = useState(false)
    const [ordenarTabela, setOrdenarTabela] = useState({
        sortDir: 'ASC',
        sortField: 'profissionalSaude',
    })
    const [situacoesOptions, setSituacoesOptions] = useState(situacoesOptionsDefault)
    const [isPrintMustache, setIsPrintMustache] = useState(false);
    const [notification, setNotification] = useState({
        isOpen: false,
        message: "",
    });
    const [pageNumber, setPageNumber] = useState(0);
    const [lastScroll, setLastScroll] = useState(false);
    const [totalElements, setTotalElements] = useState(0);

    const { profissionalSaude, criacao, situacoes, dataInicio, dataFim, } = filters || {};
    const { sortField, sortDir } = ordenarTabela

    const possuiDatas = dataInicio && dataFim;

    useEffect(() => {
        loadAgendamentosUsuario();
    }, []);

    const loadAgendamentosUsuario = async (options) => {

        try {
            const { dataInicio, dataFim } = filters;

            if(!relatorioStore.verificaLimiteTrintaDias(dataInicio, dataFim)) {
                showAlertMessage({
                    isOpen: true,
                    message: "O limite de sessenta dias foi excedido",
                });
                return;
            }

            setIsLoading(true)
            const response = await relatorioAgendamentoProducaoPorProfissional({
                pageableDto: {
                    pageNumber: options?.isClearable ? 0 : pageNumber,
                    pageSize: 30,
                    sortField,
                    sortDir
                },
                profissionalSaudeId: profissionalSaude?.id || null,
                criacao,
                dataInicio: moment(dataInicio).format("YYYY-MM-DD"),
                dataFim: moment(dataFim).format("YYYY-MM-DD"),
                situacoes
            });

            const { content, last, totalElements } = response;

            setAgendamentosUsuario(options?.isClearable ? content : [...agendamentosUsuario, ...content]);
            setPageNumber(options?.isClearable ? 1 : pageNumber + 1);
            setLastScroll(last);
            setTotalElements(totalElements);

        } catch (error) {
            showAlertMessage({
                isOpen: true,
                variant: "error",
                message: "Erro ao carregar o relatório de agendamentos por usuario",
            });
        }
        finally {
            setIsLoading(false)
        }
    };

    const handleFilters = (value, field) => {
        setFilters({ ...filters, [field]: value });
    };

    const loadProfissionaisSaude = async (search, loadedOptions, { page }) => {
        try {
            const { mostrarApenasProfissionaisAtivo } = relatorioStore.configuracaoUnidade || {};
            const { content, last } = (
                await findAllProfissionalSaude({
                    pageNumber: page,
                    search,
                    ...(mostrarApenasProfissionaisAtivo && {ativo: mostrarApenasProfissionaisAtivo}),
                })
            ).data.data.findAllProfissionalSaude;
            return {
                options: content,
                hasMore: !last,
                additional: {
                    page: page + 1,
                },
            };
        } catch (error) {
            console.error(error);
        }
    };

    const handleDownloadCSV = async () => {
        const { dataInicio, dataFim } = filters;

        if(!relatorioStore.verificaLimiteTrintaDias(dataInicio, dataFim)) {
            showAlertMessage({
                isOpen: true,
                message: "O limite de sessenta dias foi excedido",
            });
            return;
        };

        const base64 = await relatorioAgendamentoProducaoPorProfissionalCsv({
            pageableDto: {
                sortField,
                pageNumber: 0,
                pageSize: totalElements,
                sortDir
            },
            profissionalSaudeId: profissionalSaude?.id || null,
            criacao,
            dataInicio: moment(dataInicio).format("YYYY-MM-DD"),
            dataFim: moment(dataFim).format("YYYY-MM-DD"),
            situacoes
        })
        if (base64 === 'error') return
        const blob = base64Convert(base64)
        const url = window.URL.createObjectURL(blob)
        let link = document.createElement('a')
        link.setAttribute('href', url)
        link.setAttribute('download', 'relatorioAgendamentosUsuario.csv')
        document.body.appendChild(link)
        link.click()
    }

    const handlePrint = async() => {
        try {
            const { dataInicio, dataFim } = filters;
    
            if(!relatorioStore.verificaLimiteTrintaDias(dataInicio, dataFim)) {
                showAlertMessage({
                    isOpen: true,
                    message: "O limite de sessenta dias foi excedido",
                });
                return;
            };

            setIsLoading(true)
            const response = await relatorioAgendamentoProducaoPorProfissional({
                pageableDto: {
                    pageNumber: 0,
                    pageSize: totalElements,
                    sortField,
                    sortDir
                },
                profissionalSaudeId: profissionalSaude?.id || null,
                criacao,
                dataInicio: moment(dataInicio).format("YYYY-MM-DD"),
                dataFim: moment(dataFim).format("YYYY-MM-DD"),
                situacoes
            });

            setAgendamentosUsuarioPrint(response.content)

            setIsPrintMustache(true);
        } catch(e) {
            showAlertMessage({
                isOpen: true,
                message: "Erro ao imprimir o relatório",
            });
        } finally {
            setIsLoading(false)
        }
    };

    const showAlertMessage = (notification) => {
        setNotification(notification);

        const timeoutId = setTimeout(() => {
            closeAlertMessage();
            clearTimeout(timeoutId);
        }, 3000);
    };

    const closeAlertMessage = () => {
        const notification = {
            isOpen: false,
            message: "",
        };
        setNotification(notification);
    };

    const toggleAllSituacoes = (valueToToggle) => {
        const situacoes = situacoesOptions.map(item => {
            return {
                ...item,
                checked: valueToToggle
            };
        });
        let situacoesChecked = situacoes.filter((situacao) =>
            situacao.value !== "TODAS_SITUACOES" && situacao.checked
        )
        let arraySituacoesCheckedValue = situacoesChecked.map((situacao) => situacao.value
        )

        setSituacoesOptions(situacoes);
        setFilters((filter) => ({ ...filter, situacoes: arraySituacoesCheckedValue }))
    };

    const checkAllSituacoesChecked = (checkedSituacoes, isChecked, situacoes) => {

        let todasSituacoesIndex = situacoesOptions.findIndex(element =>
            element.value === "TODAS_SITUACOES");

        const todasSituacoesObject = situacoes[todasSituacoesIndex]
        if (isChecked && todasSituacoesObject.checked) {
            situacoes[todasSituacoesIndex] = { ...todasSituacoesObject, checked: false }
        }
        if (checkedSituacoes.length === 5) {
            situacoes[todasSituacoesIndex] = { ...todasSituacoesObject, checked: true }
        }
    }

    const getSituacaoInputPlaceholder = () => {
        let situacoesChecked = situacoesOptions.filter((situacao) =>
            situacao.value !== "TODAS_SITUACOES" && situacao.checked
        )
        if (situacoesChecked.length === 0) {
            return 'Selecione';
        }

        if (situacoesChecked.length > 0 && situacoesChecked.length <= 4) {
            return situacoesChecked.length === 1 ? '1 situação' : `${situacoesChecked.length} situações`
        }

        return 'Todas as situações';
    }

    const handleClickOrdenar = async value => {
        const sortDir = ordenarTabela.sortDir === 'ASC' ? 'DESC' : 'ASC'
        setOrdenarTabela({
            sortDir,
            sortField: value,
        })

        await loadAgendamentosUsuario({ isClearable: true })
    }

    const handleChangeSelectCheckbox = (item) => {

        if (item.value === "TODAS_SITUACOES") {
            return toggleAllSituacoes(!item.checked);
        }

        const situacaoIndex = situacoesOptions.findIndex(element => element.value === item.value);

        let situacoes = [...situacoesOptions];
        situacoes[situacaoIndex] = { ...item, checked: !item.checked };

        let situacoesChecked = situacoes.filter((situacao) =>
            situacao.value !== "TODAS_SITUACOES" && situacao.checked
        )

        const arraySituacoesValue = situacoesChecked.map((situacao) => {
            return situacao.value
        })
        checkAllSituacoesChecked(situacoesChecked, item.checked, situacoes)
        setFilters((filter) => ({ ...filter, situacoes: arraySituacoesValue }))
        setSituacoesOptions(situacoes);
    }

    const handleChangeCheckbox = (e) => {
        const { checked } = e.target
        setFilters((filter) => ({ ...filter, criacao: checked }))
    }

    return (
        <div className={classes.content}>
            <PageTitle title="Relatório - Agendamentos por usuário" />
            <HeaderRelatorio
                title="Agendamentos por usuário"
                hiddenSearch
                hiddenFilter
                hiddenButtons
                hiddenTotal
            />
            <div className={classes.contentFiltros}>
                <div className={classes.filtros}>
                    <div className={classes.campoFiltro}>
                        <span className={classes.tituloFiltros}> Data início: </span>
                        <InputDateForm
                            iconposition="end"
                            openTo="day"
                            views={["year", "month"]}
                            value={filters.dataInicio || ""}
                            onChange={(e) => handleFilters(e, "dataInicio")}
                            classes={{
                                input: classes.inputData,
                            }}
                        />
                    </div>
                    <div className={classes.campoFiltro}>
                        <span className={classes.tituloFiltros}> Data Fim: </span>
                        <InputDateForm
                            iconposition="end"
                            openTo="day"
                            views={["year", "month"]}
                            value={filters.dataFim || ""}
                            onChange={(e) => handleFilters(e, "dataFim")}
                            classes={{
                                input: classes.inputData,
                            }}
                        />
                    </div>
                    <div className={classes.campoFiltro}>
                        <span className={classes.tituloFiltros}> Usuário </span>
                        <TextFieldSearch
                            placeholder="Selecione"
                            classNotched={classes.notchedOutline}
                            loadOptions={loadProfissionaisSaude}
                            getOptionLabel={(option) => option?.nome}
                            getOptionValue={(option) => option?.id}
                            value={profissionalSaude}
                            onChange={(e) => handleFilters(e, "profissionalSaude")}
                            withPaginate
                            debounceTimeout={300}
                            additional={{
                                page: 0,
                            }}
                            menuPosition="fixed"
                        />
                    </div>
                    <div className={classes.campoFiltro}>
                        <span className={classes.tituloFiltros}> Situação: </span>
                        <MultipleSelectCheckbox
                            listaOpcoes={situacoesOptions}
                            changeCheckbox={handleChangeSelectCheckbox}
                            withCustomText
                            customText={getSituacaoInputPlaceholder()}
                            classes={{
                                select: classNames(classes.inputContainer, classes.select),
                                popper: classes.selectMenu
                            }}
                        />
                    </div>
                    <div className={classes.wrapperCheckbox} >
                        <Checkbox
                            color={'primary'}
                            onChange={handleChangeCheckbox}
                        />
                        <span className={classes.tituloFiltros}> Criação </span>
                    </div>
                </div>
                <Button
                    bgColor="#707C97"
                    onClick={() => loadAgendamentosUsuario({ isClearable: true })}
                    disabled={isLoading || !possuiDatas}
                >
                    Filtrar
                </Button>
            </div>
            <div className={classes.tableContainer}>
                {agendamentosUsuario.length === 0 && !isLoading && (
                    <MensagemListaVazia/>
                )}
                {agendamentosUsuario?.length > 0 && (
                    <Scroll
                        loadMore={loadAgendamentosUsuario}
                        hasMore={!lastScroll}
                        pageStart={0}
                        initialLoad={false}
                    >
                        <Table
                            dados={agendamentosUsuario}
                            columns={columns}
                            comOrdenacao
                            ordenarTabela={ordenarTabela}
                            handleClickOrdenar={handleClickOrdenar} />
                    </Scroll>)
                }
                {isLoading && (
                    <Grid
                        container
                        justify='center'
                        alignItems='center'
                        style={{ height: '100%', marginTop: 20 }}
                    >
                        <CircularProgress size={30} />
                    </Grid>
                )}
            </div>
            <div className={classes.buttonsDownloadPrint}>
                <Button
                    id='exportCsv'
                    shape='circle'
                    bgColor="#F9BE73"
                    onClick={handleDownloadCSV}
                    disabled={agendamentosUsuario.length === 0}
                >
                    <ArrowDownloadIcon />
                </Button>
                <Button
                    shape="circle"
                    bgColor="#F9BE73"
                    onClick={handlePrint}
                    disabled={agendamentosUsuario.length === 0}
                >
                    <PrintIcon />
                </Button>
            </div>
            {isPrintMustache && (
                <ImpressaoHtml
                    isPrintMustache={isPrintMustache}
                    handlePrintMustache={() => setIsPrintMustache(false)}
                    htmlStringComponent={
                        <AgendamentosPorUsuario
                            dadosRelatorio={agendamentosUsuarioPrint || []}
                            filters={filters}
                        />
                    }
                />
            )}

            <Notification
                close={closeAlertMessage}
                reset={closeAlertMessage}
                isOpen={notification.isOpen}
                variant={"error"}
                message={notification.message}
            />
        </div>
    );
};

const styles = {
    content: {
        display: 'grid',
        gridTemplateRows: '80px 71px 1fr',
        position: 'relative',
        overflow: 'hidden',
        background: '#f5f5f5',

        '& thead th': {
            textAlign: 'left',
            position: 'sticky',
            top: 0,
        },
    },
    contentFiltros: {
        display: "flex",
        flexDirection: "row",
        alignItems: "flex-end",
        padding: "13px 16px 8px 16px",
        boxShadow:
        "10px 10px 25px rgb(112 124 151 / 5%), 15px 15px 35px rgb(112 124 151 / 5%)",
        background: "#fff",
        marginTop: 1,
    },
    filtros: {
        gap: "8px",
        display: "flex",
        flexDirection: "row",
        width: "100%",
    },
    tableContainer: {
        overflow: 'auto',
        margin: '16px',
        borderRadius: '16px',
        border: '1px solid rgba(0, 0, 0, 0.1)',
        background: '#fff',
        '&> div': {
            height: '100%',
            '&> div': {
                height: '100%',
            },
        },
    },
    tituloFiltros: {
        color: "#868686",
        fontSize: "12px !important",
    },
    campoFiltro: {
        display: "flex",
        flexDirection: "column",
        width: "100%",
        maxWidth: "188px",
    },
    notchedOutline: {
        border: "0",
    },
    buttonsDownloadPrint: {
        display: 'flex',
        position: 'absolute',
        bottom: '20px',
        right: '14px',
        width: '88px',
        justifyContent: 'space-between',
    },
    inputContainer: {
        display: 'flex',
        background: '#F2F2F2',
        color: '#505050',
        borderRadius: '8px',
        border: 'none',
        '& > div': {
            '& > p': {
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
            },
        },

    },
    select: {
        width: '165px',
    },
    selectMenu: {
        marginTop: '4px',
        width: '165px',
        zIndex: 1000
    },
    wrapperCheckbox: {
        display: 'flex',
        alignItems: 'center',
        flex: 2,
    },
};

const stores = ["unidadeStore", "relatorioStore"];
const RelatorioAgendamentosUsuarioWithStyles = withStyles(styles)(RelatorioAgendamentosUsuario)
export default inject(...stores)(RelatorioAgendamentosUsuarioWithStyles);
