import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { withStyles } from '@material-ui/core'

import Dialog from '../../../../../../../../components/Dialog/Dialog'
import CloseIcon from '../../../../../../../../components/Icon/CloseIcon'
import ItemGrid from '../../../../../../../../components/Modal/ItemGrid'
import InputForm from '../../../../../../../../components/Input/InputForm'
import { TextFieldSearch } from '../../../../../../../../components/TextField'
import { Button } from '../../../../../../../../components/ui/Buttons'

import { findAllProcedimentosAtendimento } from '../../../../../../../../services/ProcedimentoService'
import {
  findControleSessaoById,
  updateControleSessao,
  findAllAgendamentoTipo,
  calculaQuantidadeAgendamentoPeridoControleSessao,
} from '../../../../../../../../services/ControleSessaoService'

import colors from '../../../../../../../../template/Colors'
import string from '../../../../../../../../utils/string'
import { InputDateForm } from '../../../../../../../../components/Modal/Input'
import Notification from '../../../../../../../../components/Notification'
import moment from 'moment'
import { ReactSelect } from '../../../../../../../../components/Select/SelectSearch'
import { periodos } from '../../utils/constants'

const ModalEditSession = ({ open, onClose, classes, idPacote, loadAllPacotesSessoes }) => {
  const [pacoteSessao, setPacoteSessao] = useState(null)
  const [loading, setLoading] = useState(false)
  const [quantidadeSessoes, setQuantidadeSessoes] = useState(0)
  const [notification, setNotification] = useState({
    isOpen: false,
    message: '',
    variant: '',
  })
  const [periodo, setPeriodo] = useState(null);
  const isProcedure = pacoteSessao?.controleSessaoAgendamentoTipos?.length === 0

  const handleLoadMore = async ({ search, data }) => {
    let pageableDTO = {
      sortDir: 'ASC',
      sortField: isProcedure ? 'nome' : 'descricao',
      pageNumber: data.page,
      pageSize: 20,
    }
    const procedimento =
      isProcedure &&
      (await findAllProcedimentosAtendimento({
        nome: search,
        ativo: true,
        salaId: null,
        pageableDTO,
      }))

    const consulta =
      !isProcedure &&
      (await findAllAgendamentoTipo({
        pageableDTO,
      }))

    const { content, last } = procedimento

    if (content || consulta) {
      return {
        options: isProcedure ? content : consulta,
        hasMore: isProcedure ? !last : true,
        additional: {
          page: data.page + 1,
        },
      }
    }
  }

  const loadProcedimentosConsultas = async (search, loadedOptions, { page }) => {
    return handleLoadMore({
      search,
      data: { page },
    })
  }

  const getPacoteSelected = async () => {
    try {
      const response = await findControleSessaoById(idPacote)
      setPacoteSessao(response)
      const field = string.currentFieldControleSessao(response)?.field
      const quantidade = response?.[field]?.filter(item => {
        return item?.ativo
      }).length
      setQuantidadeSessoes(quantidade)
    } catch (error) {
      console.error(error)
    }
  }
  const field = string.currentFieldControleSessao(pacoteSessao)?.field
  const findProcedimentoSelected = () => {
    if (!pacoteSessao) return
    return pacoteSessao?.[field]?.map(item => {
      return item.procedimento || item.agendamentoTipo
    })[0]
  }

  const handleChangeProcedimento = value => {
    const subField = string.currentFieldControleSessao(pacoteSessao)?.subField
    setPacoteSessao(prevState => {
      return {
        ...prevState,
        [field]: [
          {
            ...prevState?.[field][0],
            [subField]: value,
          },
        ],
      }
    })
  }

  const handleChangeQuantidadeSessoes = value => {
    const newValue = parseInt(value)
    if (newValue > 365) return;
    setQuantidadeSessoes(newValue)
  }

  const validateQuantidadeSessoes = value => {
    const newValue = parseInt(value)
    const minQuantity = pacoteSessao?.[field].filter(item => {
      return item?.agendamento?.id && item?.ativo
    }).length
    if (newValue < minQuantity) {
      setQuantidadeSessoes(minQuantity)
      return
    }
  }

  const handleChange = (value, field) => {
    if (field === 'dataVencimento') {
      const dataVencimento = new Date(value)
      const dataAgendamento = new Date(pacoteSessao?.ultimaDataAgendada)
      if (dataVencimento < dataAgendamento) {
        showAlertMessage({
          isOpen: true,
          message: 'A data de vencimento não pode ser menor que a data do último agendamento.',
          variant: 'error',
        })
        return
      }
    }
    setPacoteSessao(prevState => {
      return {
        ...prevState,
        [field]: value,
      }
    })
  }

  const removeProcedimentoPacote = pacote => {
    if (!pacote?.[field]) return
    const procedimentosToKeep = pacote?.[field].filter(item => item?.agendamento?.id)
    const procedimentosToRemove = pacote?.[field].filter(item => !item?.agendamento?.id)
    const newProcedimentos = procedimentosToKeep.concat(
      procedimentosToRemove.slice(0, quantidadeSessoes - procedimentosToKeep.length)
    )
    return newProcedimentos
  }

  const handleSubmit = async () => {
    try {
      setLoading(true)

      const existingProcedimentos = pacoteSessao?.[field]
      const removeProcedimentos = quantidadeSessoes < existingProcedimentos.length
      const repeatedProcedimentos = existingProcedimentos.map(procedimento => ({ ...procedimento }))

      let controleSessaoProcedimentos

      if (removeProcedimentos) {
        controleSessaoProcedimentos = removeProcedimentoPacote(pacoteSessao)
      } else {
        const newProcedimentos = Array.from(
          { length: quantidadeSessoes - existingProcedimentos.length },
          () => ({ ...repeatedProcedimentos[0] })
        ).map(procedimento => {
          const { id, ...procedimentoWithoutId } = procedimento
          delete procedimentoWithoutId?.ordem
          return {
            ...procedimentoWithoutId,
            titulo: null,
            agendamento: null,
            ativo: true,
          }
        })
        controleSessaoProcedimentos = [...repeatedProcedimentos, ...newProcedimentos]
      }

      delete pacoteSessao.titulo;
      delete pacoteSessao.saldoDisponivel;
      delete pacoteSessao.agendados;
      delete pacoteSessao.ultimaDataAgendada;

      const variables = {
        ...pacoteSessao,
        ...(pacoteSessao.dataVencimento && {
          dataVencimento: moment(pacoteSessao.dataVencimento).format('YYYY-MM-DD'),
        }),
        [field]: controleSessaoProcedimentos,
        sujeitoAtencao: {
          id: pacoteSessao?.sujeitoAtencao?.id,
        },
      }
      await updateControleSessao({
        controleSessao: variables,
      })

      await loadAllPacotesSessoes()
      onClose()
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const checkIfAgendado = useCallback(() => {
    if (!pacoteSessao) return false
    return pacoteSessao?.[field]?.some(item => {
      return item?.agendamento?.id
    })
  }, [pacoteSessao])

  const checkIfFaturado = useCallback(() => {
    if (!pacoteSessao) return false
    return pacoteSessao?.[field]?.some(item => {
      return !!item?.titulo?.dataPagamento
    })
  }, [pacoteSessao])

  const showAlertMessage = notification => {
    setNotification(notification)

    const timeoutId = setTimeout(() => {
      closeAlertMessage()
      clearTimeout(timeoutId)
    }, 3000)
  }

  const closeAlertMessage = () => {
    const notification = {
      isOpen: false,
      message: '',
      variant: '',
    }
    setNotification(notification)
  }

  const calculaQuantidadeSessoes = async() => {
    try {
      const quantidade = await calculaQuantidadeAgendamentoPeridoControleSessao({
        dataLimite: moment(pacoteSessao.dataVencimento).format('YYYY-MM-DD'),
        periodo: periodo.value,
      });
      setQuantidadeSessoes(quantidade);

    } catch (error) {
      showAlertMessage({
        isOpen: true,
        message: 'Erro ao calcular a quantidade de sessões.',
        variant: 'error',
      })
    }
  };

  const disableButton = useMemo(() => {
    const { field, subField } = string.currentFieldControleSessao(pacoteSessao)
    const procedimento = pacoteSessao?.[field]?.[0]?.[subField]
    return !procedimento || quantidadeSessoes === 0
  }, [pacoteSessao, quantidadeSessoes])

  useEffect(() => {
    if (idPacote) {
      getPacoteSelected()
    }
  }, [idPacote])

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth={'sm'}
      fullWidth={true}
      classes={{ paper: classes.paper }}
    >
      <div className={classes.content}>
        <div className={classes.header}>
          <h1>Editar sessão</h1>
          <CloseIcon onClick={onClose} size='20' />
        </div>
        <div>
          <div className={classes.inlineFields}>
            <ItemGrid size={12} label={'Nome da sessão:'} bold>
              <InputForm
                className={classes.inputContainer}
                placeholder={'Digite o nome'}
                value={pacoteSessao?.nomeSessao || ''}
                onChange={e => handleChange(e.target.value, 'nomeSessao')}
              />
            </ItemGrid>
          </div>
          <div className={classes.inlineFields}>
            <ItemGrid classes={{ item: classes.removePadding }} size={4} label={'Período:'} bold>
             <ReactSelect
              className={classes.reactSelect}
              placeholder={'Selecione'}
              value={periodo}
              options={periodos}
              onChange={(e) => setPeriodo(e)}
              menuPlacement={'auto'}
             />
            </ItemGrid>
            <ItemGrid classes={{ item: classes.removePadding }} size={4} label={'Vencimento:'} bold>
              <InputDateForm
                name={'dataVencimento'}
                iconposition="end"
                classes={{
                  date: classes.inputDate,
                  input: classes.colorInput,
                }}
                invalidDateMessage=""
                value={pacoteSessao?.dataVencimento || null}
                onChange={e => handleChange(e?.target?.value || e, 'dataVencimento')}
              />
            </ItemGrid>
            <ItemGrid classes={{ item: classes.removePadding }} size={4} label={''} bold>
              <Button
                onClick={calculaQuantidadeSessoes}
                kind='primary'
                bgColor="rgb(112, 124, 151)"
                disabled={!periodo || !pacoteSessao.dataVencimento}
              >
                Calcular quantidade
              </Button>
            </ItemGrid>
          </div>
          <div className={classes.inlineFields}>
            <ItemGrid classes={{ item: classes.removePadding }} size={8} label={isProcedure ? 'Procedimento*:' : 'Consulta*:'} bold>
              <TextFieldSearch
                isDisabled={checkIfAgendado()}
                classNotched={classes.notchedOutline}
                classInput={classes.inputTextField}
                classIcons={classes.classIcons}
                placeholder={'Selecione'}
                onChange={e => handleChangeProcedimento(e)}
                value={findProcedimentoSelected()}
                menuPlacement={'auto'}
                isClearable
                getOptionLabel={option => option.nome || option.descricao}
                getOptionValue={option => option.id}
                withPaginate
                loadOptions={loadProcedimentosConsultas}
                debounceTimeout={300}
                additional={{
                  page: 0,
                }}
              />
            </ItemGrid>
            <ItemGrid size={4} label={'QTD. de sessões:'} bold>
              <InputForm
                value={quantidadeSessoes}
                disabled={checkIfFaturado()}
                onChange={e => handleChangeQuantidadeSessoes(e.target.value)}
                onBlur={e => validateQuantidadeSessoes(e.target.value)}
                placeholder={'Digite a quantidade'}
                type={'number'}
                className={classes.inputContainer}
              />
            </ItemGrid>
          </div>
          <ItemGrid size={12} label={'Observações:'} bold>
            <InputForm
              className={classes.inputObservacao}
              rowsMax={20}
              rows={5}
              multiline
              value={pacoteSessao?.observacao}
              onChange={e => handleChange(e.target.value, 'observacao')}
            />
          </ItemGrid>
        </div>
        <div className={classes.footerButtons}>
          <Button
            onClick={handleSubmit}
            kind='primary'
            isLoading={loading}
            disabled={disableButton || loading}
          >
            Salvar
          </Button>
        </div>
        <Notification
          close={closeAlertMessage}
          reset={closeAlertMessage}
          isOpen={notification.isOpen}
          variant={notification.variant}
          message={notification.message}
        />
      </div>
    </Dialog>
  )
}

const styles = {
  paper: {
    height: 'fit-content',
    padding: '24px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& h1': {
      fontSize: '1.125rem',
      fontWeight: 'bold',
    },

    '& svg': {
      cursor: 'pointer',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  inputContainer: {
    width: '100%',
    background: '#F2F2F2',
    color: '#505050',
    borderRadius: '8px',
    height: '32px',
    padding: '4px 8px',

    '& > div': {
      '& > input': {
        border: 'none',
        '&::placeholder': {
          fontSize: '14px',
        },
      },
      '& > p': {
        overflow: 'hidden',
        fontSize: '14px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
      },
    },
  },
  inputTextField: {
    fontSize: '14px',
    border: '0',
    height: '32px',
    padding: '4px 8px',
    color: colors.commons.gray9,
    backgroundColor: colors.commons.gray2,
    margin: '0 0 0 0',
    borderRadius: '8px',
    fontFamily: 'Poppins',
  },
  notchedOutline: {
    border: '0',
  },
  inlineFields: {
    display: 'flex',
    gap: '16px',
    alignItems: 'center',
  },
  inputObservacao: {
    width: '100%',
    maxHeight: '100px',
    background: '#F2F2F2',
    border: ' 1px solid rgba(220, 220, 220, 0.2)',
    borderRadius: 10,
    padding: '8px',

    '& textarea': {
      maxHeight: '85px',
      border: 'none',
    },
  },
  footerButtons: {
    display: 'flex',
    justifyContent: 'flex-end',

    '& button': {
      minWidth: '150px',
    },
  },
  removePadding: {
    padding: '0 !important',
  },
  inputDate: {
    borderRadius: '8px',
    height: '40px',
  },
  colorInput: {
    color: '#333333',
  },
  reactSelect: {
    width: '100%',
    "& > div > div": {
      height: '40px',
    },
  }
};

export default withStyles(styles)(ModalEditSession)
