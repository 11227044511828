import React from "react";
import { inject, observer } from 'mobx-react';
import {
    Grid, CircularProgress, ClickAwayListener,
    Paper,
    Popper
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles/index";
import styles from "../RelatorioStyle";

import ErrorCollector from '../../../utils/ErrorCollector';
import moment from 'moment';
import { InputDateForm } from "../../../components/Modal/Input";
import Table from '../../../components/Table/Table'
import Api from '../../../config/api'
import HeaderRelatorio, { periodos } from "../HeaderRelatorio";
import PageTitle from "../../../components/PageTitle/PageTitle";
import ButtonYellow from "../../../components/Button/ButtonYellow";
import { Print, ArrowDropDown as ArrowDropDownIcon } from "@material-ui/icons";
import ImpressaoHtml from "../../../components/Impressao/ImpressaoHtml";
import AgendamentosPorConvenio from "../../../template/pdf/relatorio/AgendamentosPorConvenio";
import Checkbox from "../../../components/Checkbox/Checkbox";
import FormControlLabel from "@material-ui/core/es/FormControlLabel/FormControlLabel";
import classNames from "classnames";
import { TextFieldSearch } from "../../../components/TextField";
import { getUnidadeLogado } from "../../../services/UsuarioService";
import { findAllConvenio } from "../../../services/RelatorioService";
import { Button } from "../../../components/ui/Buttons";
import Notification from "../../../components/Notification";

const columns = [{
    Header: 'Convênio',
    getValue: (agendamento) => {
        return agendamento.descricao
    },
}, {
    Header: 'Tipo',
    getValue: (agendamento) => {
        return agendamento.tipo
    },
}, {
    Header: 'Quantidade',
    getValue: (agendamento) => {
        return agendamento.quantidade
    },
}];

const firstDayOfMonth = moment().startOf('month').format('YYYY-MM-DD');
const lastDayOfMonth = moment().endOf('month').format('YYYY-MM-DD');
@inject('atendimentoStore', 'googleAnalyticsStore', 'relatorioStore')
@observer
class RelatorioAgendamento extends React.Component {

    constructor() {
        super();
        this.state = {
            query: [],
            convenio: null,
            dataInicial: firstDayOfMonth,
            dataFinal: lastDayOfMonth,
            periodoSelecionado: periodos[2],
            loading: false,
            isPrintMustache: false,
            dadosRelatorio: null,
            situacoes: [
                { value: 'AGENDADO', name: "Agendado", cor: "#73C8F9", checked: false },
                { value: 'CONFIRMADO', name: "Confirmado", cor: "#26ACAA", checked: false },
                { value: 'AGUARDANDO', name: "Aguardando", cor: "#F9BE73", checked: false },
                { value: 'ATENDENDO', name: "Atendendo", cor: "#717FFC", checked: false },
                { value: 'ATENDIDO', name: "Atendido", cor: "#9871FC", checked: false },
                { value: 'CANCELADO', name: "Cancelado", cor: "#FB7676", checked: false },
                { value: 'EXCLUIDO', name: "Excluido", cor: "#FF6230", checked: false },
                { value: 'BLOQUEADO', name: "Bloqueado", cor: "#566280", checked: false },
                { value: 'FALTOU', name: "Faltou", cor: "#FC71B4", checked: false }
            ],
            anchorEl: null,
            totalAgendamentos: 0,
        };
    }

    status = [];

    async componentDidMount() {
        try {
            const query = await this.consultaAgendamento();
            this.setState({ query: query })
        } catch (error) {
            throw error;
        }

        const paginaVisualizada = {
            page: window.location.hash,
            title: 'Relatórios - Agendamentos por convênio'
        }
        this.props.googleAnalyticsStore.pageView(paginaVisualizada);
    }

    handleConvenioChange = e => {
        this.setState({ convenio: e });
    }

    consultaAgendamento = async () => {
        const { relatorioStore } = this.props;
        const { dataInicial, dataFinal } = this.state;
        try {
            if(!relatorioStore.verificaLimiteTrintaDias(dataInicial, dataFinal)) {
                relatorioStore.openNotification(
                    "O limite de sessenta dias foi excedido",
                    "error"
                );
                return;
            }

            this.setState({ loading: true });

            const situacoes = this.status?.length > 0 ? this?.status : null;
            const convenioId = this.state?.convenio?.id && this?.state?.convenio?.id;

            const response = await Api.post('',
                {
                    query: `
                        query ($page: SearchAgendamentoDTOInput){

                            relatorioAgendamentos(search: $page){
                                descricao
                                tipo
                                quantidade
                            }
                        }`,
                    variables: {
                        page: {
                            convenioId,
                            dataInicial,
                            dataFinal,
                            situacoes
                        }
                    }
                }
            )

            const { relatorioAgendamentos } = response.data.data;
            this.setState({ 
                totalAgendamentos: relatorioAgendamentos?.length,
                query: relatorioAgendamentos
            });
            return relatorioAgendamentos;
        } catch (error) {
            relatorioStore.openNotification('Erro ao carregar o relatório', 'error');
            throw new Error(error);
        } finally {
            this.setState({ loading: false })
        }
    }

    handlePeriodoSelecionado = (value) => {
        const { dataInicial, dataFinal } = value;

        this.setState({
            periodoSelecionado: value,
            dataInicial: dataInicial,
            dataFinal: dataFinal,
        });
    };

    handleDateChange = (e, field) => {
        const dataFormatada = e && moment(e).format("YYYY-MM-DD");

        if (field === "inicio") {
            this.setState({ dataInicial: dataFormatada });
        } else {
            this.setState({
                dataFinal: dataFormatada,
            })
        }
    };

    printRelatorio = async () => {
        const { relatorioStore } = this.props;
        try {
            if(!relatorioStore.verificaLimiteTrintaDias(dataInicial, dataFinal)) {
                relatorioStore.openNotification(
                    "O limite de sessenta dias foi excedido",
                    "error"
                );
                return;
            }

            const dadosRelatorio = await this.consultaAgendamento();
            this.setState({ dadosRelatorio }, () => {
                this.handlePrintRelatorio();
            });
        } catch (error) {
            relatorioStore.openNotification('Erro ao carregar o relatório', 'error')
        }
    }

    handlePrintRelatorio = () => {
        this.setState({ isPrintMustache: true });
    }

    changeCheckbox = (e, index) => {
        let situacoes = this.state.situacoes
        let actualState = situacoes[index].checked
        situacoes[index].checked = !actualState
        this.setState({ situacoes })
        this.status = this.state.situacoes.filter(value => !value.checked).map(({ value }) => value);
    };

    quantidadeChecked = () => {
        const { situacoes } = this.state;
        const situacoesChecked = situacoes.filter(item => item.checked);
        const quantidadeChecked = situacoesChecked.length;
        return quantidadeChecked === 0 ? "Selecione" : `${quantidadeChecked} selecionados`
    }

    handleClickSituacao = event => {
        const { currentTarget } = event;
        this.setState(state => ({
            anchorEl: state.anchorEl ? null : currentTarget,
        }));
    };

    loadAllConvenios = async (search, loadedOptions, { page }) => {
        const unidadeAtual = await getUnidadeLogado();
        const pageableDTO = {
          pageNumber: page,
          pageSize: 10,
          sortDir: 'ASC',
          sortField: 'descricao',
        }
        const variables = {
          searchDTO: {
            ...pageableDTO,
            search,
            unidadeId: unidadeAtual.id,
            ativo: true,
          }
        }
    
        try {
          const { content, last } = (
            await findAllConvenio(variables)
          )
          return {
            options: content,
            hasMore: !last,
            additional: {
              page: page + 1,
            },
          }
        } catch (error) {
          console.error(error)
        }
      }

    render() {
        const { classes, relatorioStore } = this.props;
        const { dataInicial, dataFinal, anchorEl, periodoSelecionado, convenio, query, loading, situacoes, totalAgendamentos } = this.state;

        const open = Boolean(anchorEl);
        const id = open ? 'no-transition-popper' : null;
        const possuiDatas = dataInicial && dataFinal;

        return (
            <div className={classes.content}>
                <PageTitle title="Relatórios - Agendamentos por convênios" />
                <HeaderRelatorio
                    handlePeriodo={this.handlePeriodoSelecionado}
                    periodoSelecionado={periodoSelecionado}
                    hiddenSearch
                    totalAgendamentos={totalAgendamentos}
                    totalTitle="agendamentos"
                />

                <div className={classes.contentFiltros}>
                    <div className={classes.filtros}>
                        <Grid item xs={3} className={classes.spacingConvenio}>
                            <span className={classes.tituloFiltros}> Data início: </span>
                            <InputDateForm
                                iconposition="end"
                                openTo="day"
                                views={["year", "month"]}
                                value={dataInicial}
                                classes={{
                                    input: classes.inputData,
                                }}
                                onChange={(e) => this.handleDateChange(e, "inicio")}
                            />
                        </Grid>
                        <Grid item xs={3} className={classes.spacingConvenio}>
                            <span className={classes.tituloFiltros}> Data fim: </span>
                            <InputDateForm
                                iconposition="end"
                                openTo="day"
                                views={["year", "month"]}
                                value={dataFinal}
                                classes={{
                                    input: classes.inputData,
                                }}
                                onChange={(e) => this.handleDateChange(e, "fim")}
                            />
                        </Grid>
                        <Grid item xs={3} className={classes.spacingConvenio}>
                            <span className={classes.tituloFiltros}> Convênio: </span>
                            <TextFieldSearch
                                placeholder='Selecione'
                                classNotched={classes.notchedOutline}
                                classInput={classes.inputContainer}
                                classIcons={classes.classIcons}
                                loadOptions={this.loadAllConvenios}
                                getOptionLabel={(option) => option.descricao}
                                getOptionValue={(option) => option.id}
                                value={convenio}
                                onChange={this.handleConvenioChange}
                                withPaginate
                                debounceTimeout={300}
                                additional={{
                                page: 0,
                                }}
                                menuPosition='fixed'
                            />
                        </Grid>
                        <Grid item xs={2} className={classes.spacingConvenio}>
                            <span className={classes.tituloFiltros}> Situação: </span>
                            <div
                                onClick={this.handleClickSituacao}
                                className={classes.selectSituacao}
                            >
                                <span> {this.quantidadeChecked()} </span>
                                <ArrowDropDownIcon className={classes.colorArrow} />
                            </div>
                            <Popper
                                id={id}
                                open={open}
                                anchorEl={anchorEl}
                                placement={"bottom"}
                                className={classes.popper}
                            >
                                <ClickAwayListener
                                    onClickAway={() => this.setState({ open: false })}
                                >
                                    <Paper className={classes.paperSituacao}>
                                        <div className={classes.checkboxOptions}>
                                            {situacoes.map((item, index) => (
                                                <div className={classes.wrapperCheckbox}>
                                                    <FormControlLabel
                                                        className={classNames(item.name.toLowerCase())}
                                                        control={
                                                            <Checkbox
                                                                checked={item.checked}
                                                                color={'#505050'}
                                                                value={item.value}
                                                            />
                                                        }
                                                        label={<span className={classNames(item.name.toLowerCase())}> {item.name} </span>}
                                                        key={index}
                                                        onChange={e => this.changeCheckbox(e, index)} />
                                                    <div className={classes.wrapperColorBox}>
                                                        <div className={classes.colorBox} style={{ background: item.cor }} />
                                                    </div>
                                                </div>
                                            )
                                            )}
                                        </div>
                                    </Paper>
                                </ClickAwayListener>
                            </Popper>
                        </Grid>
                    </div>
                    <Button
                        bgColor="#707C97"
                        onClick={this.consultaAgendamento}
                        disabled={loading || !possuiDatas}
                    >
                        Filtrar
                    </Button>
                </div>
                <Notification
                    close={relatorioStore.closeNotification}
                    reset={relatorioStore.closeNotification}
                    isOpen={relatorioStore.notification.isOpen}
                    variant={relatorioStore.notification.variant}
                    message={relatorioStore.notification.message}
                />
                <ErrorCollector mensagem={'Não foi possível carregar o relatório!'}>
                    <Grid item xs={12} container className={classes.contentTable}>
                        {
                            query?.length > 0 &&
                            <Table
                                className={classes.table}
                                dados={query || []}
                                columns={columns}
                                clickable={false}
                            />
                        }
                        {loading && (
                            <Grid
                                container
                                justify="center"
                                alignItems="center"
                                style={{ height: "100%", marginTop: 20 }}
                            >
                                <CircularProgress size={30} />
                            </Grid>
                        )}
                    </Grid>
                </ErrorCollector>
                <div className={classes.buttonsDownloadPrint}>
                    <ButtonYellow
                        id="exportCsv"
                        className={classes.buttonImprimir}
                        onClick={this.printRelatorio}
                    >
                        <Print />
                    </ButtonYellow>
                </div>
                {this.state.isPrintMustache && <ImpressaoHtml
                    isPrintMustache={this.state.isPrintMustache}
                    handlePrintMustache={() => { this.setState({ isPrintMustache: false }) }}
                    htmlStringComponent={
                        <AgendamentosPorConvenio
                            dadosRelatorio={this.state.dadosRelatorio}
                            dataInicial={this.state.dataInicial}
                            dataFinal={this.state.dataFinal}
                        />
                    }
                />}
            </div>
        );
    }
}


export default withStyles(styles)(RelatorioAgendamento);
