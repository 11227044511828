import React from "react";
import styled from "styled-components";

const Field = ({ children, label, width }) => {
  return (
    <Container width={width}>
      <Label>{label}</Label>
      {children}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: ${(props) => props.width || "100%"};
`;

const Label = styled.label`
  font-size: 10px;
  color: #868686;
`;

export default Field;
