import { withStyles, CircularProgress } from "@material-ui/core";
import React, { useEffect, useState, useRef } from "react";
import MultiToggleButtons from "../../../../../components/Button/MultiToggleButtons";
import LineChartGraficoPercentis from "./LineChartGraficoPercentis";
import LineChartGraficoZScore from "./LineChartGraficoZScore";
import { inject } from "mobx-react";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { Button } from "../../../../../components/ui/Buttons";
import PrintIcon from "../../../../../components/Icon/Print";
import ImpressaoHtml from "../../../../../components/Impressao/ImpressaoHtml";
import Impressao from "./Impressao";

const tabs = {
  ESTATURA: 0,
  PERIMETROCEFALICO: 1,
  PESO: 2,
};

const options = ["Estatura", "Perímetro cefálico", "Peso"];

const GraficosAntropometricos = (props) => {
  const {
    classes,
    sujeitoAtencao,
    avaliacaoAntropometricaStore,
    selectedTipoGrafico,
    isLoading,
    configuracaoImpressaoStore,
  } = props;

  const [tabSelected, setTabSelected] = useState(tabs.ESTATURA);
  const [printing, setPrinting] = useState(false);

  let refEstatura = useRef(null);
  let refEstatura1 = useRef(null);
  let refPerimetroCefalico = useRef(null);
  let refPeso = useRef(null);

  useEffect(() => {
    avaliacaoAntropometricaStore.buscaDadosGrafico({
      sujeitoAtencao,
      tipo: selectedTipoGrafico,
    });
  }, []);

  const changeTabSelected = (tabSelected) => {
    setTabSelected(tabSelected);
  };

  const scroll = (scrollOffset) => {
    switch (tabSelected) {
      case 0:
        selectedTipoGrafico === "ZSCORE"
          ? (refEstatura.current.scrollLeft += scrollOffset)
          : (refEstatura1.current.scrollLeft += scrollOffset);
        break;
      case 1:
        return (refPerimetroCefalico.current.scrollLeft += scrollOffset);
      case 2:
      default:
        return (refPeso.current.scrollLeft += scrollOffset);
    }
  };

  const renderGraficoEstatura = () => {
    return selectedTipoGrafico === "ZSCORE" ? (
      <div className={classes.contentGrafico} ref={refEstatura}>
        <LineChartGraficoZScore
          labelY="cm"
          campo="estatura"
          dados={
            avaliacaoAntropometricaStore.graficoCrescimentoInfantilOMSEstatura
          }
          valorMinimoY={avaliacaoAntropometricaStore.verificaMenorValorEstatura()}
          isTipoSemanal={avaliacaoAntropometricaStore.isTipoGraficoSemanal}
        />
      </div>
    ) : (
      <div className={classes.contentGrafico} ref={refEstatura1}>
        <LineChartGraficoPercentis
          labelY="cm"
          campo="estatura"
          dados={
            avaliacaoAntropometricaStore.graficoCrescimentoInfantilOMSEstatura
          }
          valorMinimoY={avaliacaoAntropometricaStore.verificaMenorValorEstatura()}
          isTipoSemanal={avaliacaoAntropometricaStore.isTipoGraficoSemanal}
        />
      </div>
    );
  };

  const renderGraficoPerimetroCefalico = () => {
    return selectedTipoGrafico === "ZSCORE" ? (
      <div className={classes.contentGrafico} ref={refPerimetroCefalico}>
        <LineChartGraficoZScore
          labelY="cm"
          campo="perimetroCefalico"
          dados={avaliacaoAntropometricaStore.graficoCrescimentoInfantilOMSPerimetroCefalico}
          valorMinimoY={avaliacaoAntropometricaStore.verificaMenorValorPerimetroCefalico()}
          isTipoSemanal={avaliacaoAntropometricaStore.isTipoGraficoSemanal}
        />
      </div>
    ) : (
      <div className={classes.contentGrafico} ref={refPerimetroCefalico}>
        <LineChartGraficoPercentis
          labelY="cm"
          campo="perimetroCefalico"
          dados={avaliacaoAntropometricaStore.graficoCrescimentoInfantilOMSPerimetroCefalico}
          valorMinimoY={avaliacaoAntropometricaStore.verificaMenorValorPerimetroCefalico()}
          isTipoSemanal={avaliacaoAntropometricaStore.isTipoGraficoSemanal}
        />
      </div>
    )
  };

  const renderGraficoPeso = () => {
    return selectedTipoGrafico === "ZSCORE" ? (
      <div className={classes.contentGrafico} ref={refPeso}>
        <LineChartGraficoZScore
          labelY="kg"
          campo="peso"
          dados={avaliacaoAntropometricaStore.graficoCrescimentoInfantilOMSPeso}
          valorMinimoY={avaliacaoAntropometricaStore.verificaMenorValorPeso()}
          isTipoSemanal={avaliacaoAntropometricaStore.isTipoGraficoSemanal}
        />
      </div>
    ) : (
      <div className={classes.contentGrafico} ref={refPeso}>
        <LineChartGraficoPercentis
          labelY="kg"
          campo="peso"
          dados={avaliacaoAntropometricaStore.graficoCrescimentoInfantilOMSPeso}
          valorMinimoY={avaliacaoAntropometricaStore.verificaMenorValorPeso()}
          isTipoSemanal={avaliacaoAntropometricaStore.isTipoGraficoSemanal}
        />
      </div>
    );
  }

  const renderListaVazio = () => {
    return <div className={classes.mensagemDeListaVazia}> Nenhum item encontrado </div> 
  }

  const renderTab = () => {
    switch (tabSelected) {
      case 0:
        return avaliacaoAntropometricaStore.graficoCrescimentoInfantilOMSEstatura[0]?.length === 0 ?
          renderListaVazio()
          :
          renderGraficoEstatura();
        
      case 1:
        return avaliacaoAntropometricaStore.graficoCrescimentoInfantilOMSPerimetroCefalico[0]?.length === 0 ?
          renderListaVazio()
          :
          renderGraficoPerimetroCefalico()
      case 2:
      default:
        return avaliacaoAntropometricaStore.graficoCrescimentoInfantilOMSPeso[0]?.length === 0 ?
          renderListaVazio()
          :
          renderGraficoPeso();
    }
  };

  const handleClickPrint = async() => {
    await configuracaoImpressaoStore.getConfig('OUTROS');

    setPrinting(true);
  };

  return (
    <div className={classes.content}>
      <MultiToggleButtons
        options={options}
        tabSelected={tabSelected}
        changeTabSelected={changeTabSelected}
      />
      <div className={classes.titleGrafico}>
        <div className={classes.buttonArrow} onClick={() => scroll(-20)}>
          <KeyboardArrowLeft />
        </div>
        {options[tabSelected]}
        <div className={classes.buttonArrow} onClick={() => scroll(20)}>
          <KeyboardArrowRight />
        </div>
      </div>
      {
        isLoading ? 
        <div className={classes.notFoundContainer}>
          <CircularProgress/> 
        </div>
        :
        <div className={classes.boxTab}>{renderTab()}</div>
      }
      <div className={classes.contentButton}>
        <Button
          shape='circle'
          onClick={handleClickPrint}
          bgColor={"#F9BE73"}
        >
          <PrintIcon/>
        </Button>
      </div>
        { printing &&
          <ImpressaoHtml
            isPrintMustache={printing}
            handlePrintMustache={() => setPrinting(false)}
            htmlStringComponent={<Impressao
              renderTab={renderTab}
              sujeitoAtencao={sujeitoAtencao}
              avaliacaoAntropometricaStore={avaliacaoAntropometricaStore}
              renderListaVazio={renderListaVazio}
              renderGraficoEstatura={renderGraficoEstatura}
              renderGraficoPerimetroCefalico={renderGraficoPerimetroCefalico}
              renderGraficoPeso={renderGraficoPeso}
            />}
          />
        }
    </div>
  );
};

const style = {
  titleGrafico: {
    color: "#868686",
    fontSize: 18,
    fontWeight: 600,
    textAlign: "center",
    margin: "7px 0",
    display: "flex",
    justifyContent: "space-between",
  },
  buttonArrow: {
    cursor: "pointer",
    background: "#26ACA9",
    borderRadius: "100px",
    color: "#F9F9F9",
    height: "25px",
  },
  contentGrafico: {
    overflow: "hidden",
  },
  boxTab: {
    display: "flex",
    justifyContent: 'center',
    marginTop: '20px',    
  },
  notFoundContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '80%',
    marginTop: 100,
  },
  mensagemDeListaVazia: {
    color: "#707C97",
  },
  contentButton: {
    position: "absolute",
    bottom: 11,
    right: 378,
  }
};

const GraficosAntropometricosWithStyle = withStyles(style)(GraficosAntropometricos);
export default inject("avaliacaoAntropometricaStore", "configuracaoImpressaoStore")(GraficosAntropometricosWithStyle);
