import { withStyles } from "@material-ui/core";
import React from "react";
import dates from "../../../../../utils/dates";
import string from "../../../../../utils/string";

const Impressao = ({
  classes,
  avaliacaoAntropometricaStore,
  sujeitoAtencao,
  renderListaVazio,
  renderGraficoEstatura,
  renderGraficoPerimetroCefalico,
  renderGraficoPeso,
}) => {
  const { nome, dataNascimento, sexo } = sujeitoAtencao || {};
  const idade = dataNascimento ? dates.calculaIdade(dataNascimento) : " - ";
  const sexoPaciente = sexo?.descricao
    ? string.capitalize(sexo.descricao)
    : " - ";

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div>
          <span>Nome:{' '}</span>
          {nome}
        </div>
        <div>
          <span>Idade:{' '}</span>
          {idade}
        </div>
        <div>
          <span>Sexo:{' '}</span>
          {sexoPaciente}
        </div>
      </div>
      <span> Estatura </span>
      <div>
        {avaliacaoAntropometricaStore.graficoCrescimentoInfantilOMSEstatura[0]
          ?.length === 0
          ? renderListaVazio()
          : renderGraficoEstatura()}
      </div>
      <span> Perímetro cefálico </span>
      <div>
        {avaliacaoAntropometricaStore
          .graficoCrescimentoInfantilOMSPerimetroCefalico[0]?.length === 0
          ? renderListaVazio()
          : renderGraficoPerimetroCefalico()}
      </div>
      <span> Peso </span>
      <div>
        {avaliacaoAntropometricaStore.graficoCrescimentoInfantilOMSPeso[0]
          ?.length === 0
          ? renderListaVazio()
          : renderGraficoPeso()}
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    "& span": {
      fontWeight: "bold",
    },
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    gap: "8px",
  },
};

export default withStyles(styles)(Impressao);
