
export default theme => ({
    root: {
        fontFamily: "Poppins !important",
        padding: 0,
        position: 'relative',

        height: '100%',
        flex: 1,

        '& .rbc-calendar': {
            height: "100%"
        },

        '& .rbc-timeslot-group': {
            height: '85px',
            minHeight: '85px',
            maxHeight: '85px',
        },

        '& .rbc-event': {
            border: "1px solid rgba(0, 0, 0, 0.1)"
        },

        '& .rbc-time-content': {
            overflow: 'auto'
        },

        '& .rbc-current-time-indicator, .rbc-time-header, .rbc-allday-cell, .rbc-event-label': {
            display: 'none'
        },

        '& .rbc-time-view, .rbc-events-container, .rbc-day-slot .rbc-time-content': {
            border: 'none',
            background: '#fff',
        },

        '& .rbc-label': {
            marginLeft: '10px',
            position: 'relative',
        },

        '& .rbc-time-content > .rbc-day-slot ': {
            marginTop: '10px',
        },

        '& .rbc-day-slot .rbc-events-container': {
            margin: '0 1px 0 3px',
        },

        '&  .rbc-time-gutter .rbc-time-column': {
            width: '100px',
        },

        '& .rbc-day-slot .rbc-time-slot': {
            border: '2px',
        },

        '& .rbc-time-gutter > .rbc-timeslot-group': {
            border: 'none',
            color: '#B6B6B6',
        },

        '& .rbc-event-content': {
            fontSize: '11.45px',
            flex: 1,
            fontWeight: 500,
        },

        '& .rbc-time-view .rbc-row': {
            boxSizing: 'border-box',
        },

        '& .rbc-today': {
            backgroundColor: '#fff'
        },

        '& .Resizer': {
            position: 'relative',
            background: '#505050',
        },

        '& .Resizer.vertical': {
            margin: '0',
            borderLeft: '5px solid rgba(255, 255, 255, 0)',
            borderRight: '5px solid rgba(255, 255, 255, 0)',
            cursor: 'col-resize',
            zIndex: 5
        },

    },

    tela: {
        overflow: 'hidden',
        fontFamily: "Poppins !important",
        gridArea: 'content',
    },

    container: {
        margin: '0 3% 1.9% 2.3%',
    },

    content: {
        gridArea: "content",
        display: "grid",
        gridTemplateRows: 'auto 1fr',
        position: 'relative',
    },
    header: {
        height: '64px !important',
    },

    gridHeader: {
        zIndex: 4,
    },

    headerContent: {
        display: 'grid !important',
        gridTemplateColumns: '436px 1fr 1fr 1fr 1fr',
        gridGap: '10px',
    },

    headerSelecaoAgendas: {
        height: '42px',
        minHeight: '42px',
        display: 'flex',
        alignContent: 'center',
        alignItems: 'start',
        background: '#fff',
        '& svg': {
            color: '#505050',
            cursor: 'pointer',
            marginLeft: '5px'
        },
    },
    headerSelecaoAgendasProfissional: {
        minHeight: '42px',
        display: 'flex',
        alignItems: 'start',
        position: 'relative',
        justifyContent: 'space-between',
    },
    selecaoAgendasTitle: {
        margin: '6px 20px 0 20px',
        color: '#868686',
        fontSize: '16px',
    },


    wrapper: {
        width: '100%',
        height: '100vh',
        display: 'grid',
        gridTemplateColumns: ' 1fr 16px 1fr',
    },

    iconButtonSearch: {
        width: 30,
        height: 30,
    },

    inputRootSearch: {
        border: 'none',
    },

    inputSearch: {
        fontFamily: "Poppins!important",
    },

    tutorial: {
        cursor: 'pointer',
    },

    popper: {
        position: 'absolute',
        zIndex: 10,
        left: '-17% !important',
        top: '1% !important',
    },

    typography: {
        padding: '2px 0 10px 0',
    },

    fade: {
        width: 436,
        height: 348,
        padding: 24,

    },

    filtrar: {
        fontSize: 16,
        fontWeight: 700,
        color: '#5F6368',
    },

    redefinirFiltro: {
        fontSize: 14,
        fontWeight: 400,
    },
    filtrarRedefinirFiltro: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },

    dataTipoConsulta: {
        display: 'flex',
        flexDirection: 'row',
    },

    selectPorMedico: {
        width: '100%',
    },

    filtroHeader: {
        display: 'flex',
        flexDirection: 'row',
        borderRadius: 10,
    },

    titleHeader: {
        fontFamily: "Poppins !important",
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: theme.palette.primary.main
    },

    dividerRoot: {
        backgroundColor: theme.palette.commons.gray3
    },

    panelLeft: {
        padding: '0 5px',
        maxWidth: '270px',
        height: '100%',
        overflow: 'hidden',
        fontFamily: "Poppins !important",
    },

    rootPanelLeft: {
        marginBottom: '10px',
        maxWidth: '270px',
    },

    rootPanelLeftFiltro: {
        maxWidth: '270px',
        "@media(max-height: 585px)": {
            overflow: 'auto',
        }
    },

    centralizaFiltro: {
        alignItems: 'center',
    },

    tableVigencesRoot: {
        border: `solid 1px ${theme.palette.commons.gray3}`,
        borderRadius: 27,
        borderCollapse: 'separate',
        overflow: 'hidden',
        width: '98%'
    },

    tableVigencesHeader: {
        backgroundColor: theme.palette.commons.gray3,
    },

    tableVigencesHeadRow: {
        height: 36

    },

    checkboxOptions: {
        display: "grid",
        gridRowGap: 0,
        gridTemplateColumns: '1fr 1fr',
        padding: '0 15px',
    },

    headCell: {
        color: theme.palette.commons.white,
        fontSize: '0.8rem',
        padding: '0',
        textTransform: 'none',
        '&:last-child': {
            paddingRight: 0
        }
    },

    bodyRowRoot: {
        height: 32
    },

    bodyCell: {
        borderRight: `1px solid ${theme.palette.commons.gray3}`,
        padding: '12px 0 5px',
        width: '50%',
        '&:last-child': {
            borderRight: 0,
            paddingRight: 0
        }
    },

    searchBlock: {
        paddingLeft: 30,
        paddingRight: 30,
        marginBottom: 25
    },

    openModal: {
        position: 'absolute',
        top: '20px',
        right: '10px',
        width: '400px'
    },

    contentLoadingContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%"
    },

    notFoundTitle: {
        fontSize: '1.2rem',
        color: theme.palette.primary.main,
        fontWeight: 'normal',
    },

    profile: {
        position: 'absolute',
        right: '16px',
    },

    searchPacient: {
        minWidth: 340,
        height: 48,
        borderRadius: "8px !important",
        border: 'none !important',
        background: "#F2F2F2",
        justifyContent: 'center',
        fontWeight: 400,
    },

    tituloFiltro: {
        fontWeight: 600,
        color: '#868686',
        fontSize: 14,
        margin: "0 0 5px 15px"
    },
    drawerMinimizar: {
        width: '20%',
        flexShrink: 0,
        display: 'grid',
    },
    menuButton: {
        justifyContent: 'space-between',
        borderRadius: 0,
        zIndex: 10,
        fontWeight: 'bold',
    },
    menuButtonMinimizado: {
        justifyContent: 'center',
        borderRadius: 0,
        zIndex: 10,
        fontWeight: 'bold',
    },
    drawerPaper: {
        width: '20%',
    },
    drawerOpen: {
        zIndex: 1,
        left: 72,
        maxWidth: 270,
        minWidth: 270,
        width: '20%',
        maxHeight: 'calc(100% - 64px)',
        overflowY: 'hidden',
        background: '#F9F9F9',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        overflowX: 'hidden',
        boxShadow: "0px 10px 15px rgb(0 0 0 / 0.05), 0px 7px 25px rgb(38 172 169 / 0.05), 0px 5px 25px rgb(38 172 169 / 0.05)",
    },
    drawerClose: {
        left: 72,
        maxHeight: 'calc(100% - 64px)',
        background: '#F9F9F9',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden !important',
        overflowY: 'hidden',
        width: '79px',
        zIndex: 0,
    },
    gridContainer: {
        height: "100dvh",
        display: 'grid',
        gridTemplateColumns: `270px 1fr`,
        gridTemplateRows: '1fr 64px',
        gridTemplateAreas: `
            "sidebar-left content"
            "footer footer"
            `,
        flex: 1,
        boxShadow: '0px 10px 15px rgba(0, 0, 0, 0.03), 0px 7px 25px rgba(38, 172, 169, 0.04), 0px 5px 25px rgba(38, 172, 169, 0.04)',
    },

    gridContainerBarraMinimizado: {
        display: 'grid',
        height: "100dvh",
        gridTemplateColumns: `80px 1fr`,
        gridTemplateRows: '1fr 64px',
        gridTemplateAreas: `
            "sidebar-left content"
            "footer footer"
            `,
        flex: 1
    },
    labelMinimizar: {
        marginRight: 30,
    },
    radioButtons: {
        textAlign: 'center',
        margin: '5px 0 10px',
    },
    radioButtonsMinimizado: {
        textAlign: 'center',
        marginTop: 15,
    },
    dataAtual: {
        color: '#009A97',
        cursor: 'pointer',
        padding: '0px 0px 3px 0px',
        fontSize: 16,
        fontWeight: 'bold',
        textAlign: 'center',
    },
    divDataAtualMinimizado: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center',
        marginBottom: 15,
    },
    botoesMudarDia: {
        cursor: 'pointer',
        color: 'rgba(0, 0, 0, 0.54)',
        marginRight: '-5px'
    },
    flexColumn: {
        background: '#fff',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        zIndex: 1,
        position: 'relative',
    },
    notFoundContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '80%',
        margin: 10,
    },
    selectTipoAgenda: {
        margin: '0 7px',
        "& > div": {
            borderRadius: 8,
            minHeight: '40px',
            background: '#F2F2F2',
            border: '1px solid rgba(0, 0, 0, 0.1)',
        }
    },
    contentButtonsDiaAnteriorPosterior: {
        display: 'flex',
        columnGap: '8px',
        marginRight: '24px',
        height: '42px',
    },
    buttonDiaAnteriorPosterior: {
        height: 30,
        minWidth: 30,
        borderRadius: 100,
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#F4F4F4',
        border: '1px solid rgba(0, 0, 0, 0.1)',
    },
    iconButtonDiaAnteriorPosterior: {
        height: '12px',
    },
    contentButtonTelaCheia: {
        width: '160px',
    },
    contentButtonSemTelaCheia: {
        width: '40px',
        height:'40px',
        display:'flex',
        border:'2px solid rgba(220,220,220,0.2) ' ,
        borderRadius:'50%',

    },
    contentButtonBloquear: {
        width: '156px',
    },
    buttonTelaCheia: {
        color: '#fff',
        background: '#F9BE73',
        width: '40px',
        height: '40px',
        '&:hover': {
            background: '#E3922B',
        }
    },
    buttonBloquearAgenda: {
        color: '#fff',
        background: '#707C97',
        border: '1px solid rgba(0, 0, 0, 0.1)',
    },
    buttonHeader: {
        height: 32,
        width: '40px',
        height:'40px',
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        cursor: 'pointer',
        borderRadius: '100px',
        alignItems: 'center',
        justifyContent: 'center',
        // paddingLeft:'2px'
        margin:'0 auto'
    },

    telaCheia: {
        position: 'fixed',
        width: '100vw',
        height: '100%',
        zIndex: 10,
        left: 0,
        top: 0,
        background: '#fff',
    },
    telaCheiaThreeDaysView: {
        height: 'calc(100vh - 42px)',
    },
    semTelaCheia: {
        width: '100%',
        height: 'calc(100% - 104px)',
    },
    contentMultipleDays: {
        height: '100%',
    },
    headerSelecaoAgendasProfissionalTelaCheia: {
        alignItems: 'center',
        height: '48px',
    },
    splitPane: {
        "& .Pane2": {
            overflow: 'hidden',
        },
    },

    lockButtonsContainer: {
        display: 'flex',
        gap: '8px'
    },
});
