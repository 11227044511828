import React, { useState } from "react";
import {
  ContainerProcedimentos,
  ContentButtons,
  InputMaskCurrencyStyled,
  Row,
  TextFieldSearchStyled,
} from "../../styles";
import Field from "../../components/Field";
import { InputForm } from "../../../../../components/Modal/Input";
import {
  findAllProcedimentosValor,
  findAllProfissionalSaudeComAgenda,
} from "../../../../../services/InternacaoService";
import { Button } from "../../../../../components/ui/Buttons";
import Table from "../../../../../components/Table/Table";
import {
  columnsProcedimentosSolicitados,
  procedimentosSolicitadosDefault,
} from "../../constants";

const ProcedimentosSolicitados = ({ internacao, handleField }) => {
  const { procedimentosSolicitados, sujeitoAtencao } = internacao;

  const [procedimentoNovo, setProcedimentoNovo] = useState({
    ...procedimentosSolicitadosDefault,
    sequencia: procedimentosSolicitados.length + 1,
  });
  const [isEdit, setIsEdit] = useState(false);

  const { procedimento, codigoAMB, profissionalSaude, sequencia, valor } =
    procedimentoNovo || {};

  const unidadeLogada = JSON.parse(localStorage["_immortal|unidadeLogada"]);

  const handleProcedimentoSelected = (value) => {
    const idConvenio = sujeitoAtencao?.dadosConvenio?.convenio?.id;
    let valor = 0;

    if (idConvenio && value?.convenioProcedimentos?.length > 0) {
      const convenioProcedimento = value?.convenioProcedimentos.find(
        (convenioProcedimento) => {
          return convenioProcedimento.convenio.id === idConvenio;
        }
      );

      valor = convenioProcedimento?.valor || 0;
    }

    setProcedimentoNovo((prevState) => ({
      ...prevState,
      procedimento: value,
      valor,
    }));
  };

  const handleProcedimento = (value, field) => {
    setProcedimentoNovo((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleAdicionaProcedimento = () => {
    const dadosProcedimentos = internacao.procedimentosSolicitados;
    dadosProcedimentos.push(procedimentoNovo);

    setProcedimentoNovo({
      procedimento: null,
      codigoAMB: "",
      profissionalSaude: null,
      sequencia: procedimentosSolicitados.length + 1,
      valor: "",
    });

    handleField("procedimentosSolicitados",dadosProcedimentos);
  };

  const handleDelete = (data) => {
    const dadosProcedimentos = internacao.procedimentosSolicitados;
    const index = dadosProcedimentos.findIndex(
      (procedimento) => procedimento.id === data.id
    );

    dadosProcedimentos.splice(index, 1);
    const procedimentosSolicitadosSequencial = alteraSequencia(dadosProcedimentos);
    
    handleField("procedimentosSolicitados", procedimentosSolicitadosSequencial);
  };

  const alteraSequencia = (procedimentos) => {
    setProcedimentoNovo((prevState) => ({
      ...prevState,
      sequencia: procedimentosSolicitados.length + 1,
    }));

    const procedimentosSolicitadosSequencial = procedimentos.map((procedimento, index) => {
      return {
        ...procedimento,
        sequencia: index + 1
      }
    });

    return procedimentosSolicitadosSequencial;
  };

  const handleEdit = (data) => {
    setProcedimentoNovo(data);
    setIsEdit(true);
  };

  const resetProcedimentoNovo = () => {
    setProcedimentoNovo({
      ...procedimentosSolicitadosDefault,
      sequencia: procedimentosSolicitados.length + 1,
    });
  };

  const handleCancelarProcedimentoEdit = () => {
    resetProcedimentoNovo();
    setIsEdit(false);
  };

  const handleSalvarProcedimentoEdit = () => {
    const dadosProcedimentos = internacao.procedimentosSolicitados;
    const index = dadosProcedimentos.findIndex(
      (procedimento) => procedimento.id === procedimentoNovo.id
    );

    dadosProcedimentos[index] = procedimentoNovo;

    handleField("procedimentosSolicitados", dadosProcedimentos);
    setIsEdit(false);
    resetProcedimentoNovo();
  };

  const loadAllProcedimentos = async (search, loadedOptions, { page }) => {
    const variables = {
      nome: search,
      pageableDTO: {
        pageNumber: page,
        pageSize: 20,
        sortDir: "ASC",
        sortField: "nome",
      },
      ativo: true,
    };

    const response = await findAllProcedimentosValor(variables);
    const { content, last } = response;

    return {
      options: content,
      hasMore: !last,
      additional: {
        page: page + 1,
      },
    };
  };

  const loadAllProfissional = async (search, loadedOptions, { page }) => {
    const variables = {
      search,
      pageableDTO: {
        pageNumber: page,
        pageSize: 20,
        sortDir: "ASC",
        sortField: "nome",
      },
      ativo: true,
      unidadeId: unidadeLogada.id,
      externos: false,
    };

    const response = await findAllProfissionalSaudeComAgenda(variables);
    const { content, last } = response;

    return {
      options: content,
      hasMore: !last,
      additional: {
        page: page + 1,
      },
    };
  };

  return (
    <ContainerProcedimentos>
      <Row>
        <Field label="Sequencia" width="10%">
          <InputForm
            value={sequencia}
            onChange={(e) => handleProcedimento(e.target.value, "sequencia")}
            disabled={true}
          />
        </Field>
        <Field label="Procedimento" width="25%">
          <TextFieldSearchStyled
            placeholder="Selecione"
            loadOptions={loadAllProcedimentos}
            getOptionLabel={(option) => option.nome}
            getOptionValue={(option) => option.id}
            value={procedimento}
            onChange={handleProcedimentoSelected}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          />
        </Field>
        <Field label="Profissional" width="25%">
          <TextFieldSearchStyled
            placeholder="Selecione"
            loadOptions={loadAllProfissional}
            getOptionLabel={(option) => option.nome}
            getOptionValue={(option) => option.id}
            value={profissionalSaude}
            onChange={(e) => handleProcedimento(e, "profissionalSaude")}
            withPaginate
            debounceTimeout={300}
            additional={{
              page: 0,
            }}
          />
        </Field>
        <Field label="Código ambulatorial" width="15%">
          <InputForm
            value={codigoAMB}
            onChange={(e) => handleProcedimento(e.target.value, "codigoAMB")}
          />
        </Field>
        <Field label="Valor" width="15%">
          <InputMaskCurrencyStyled
            value={valor}
            onChange={(e) => handleProcedimento(e.target.value, "valor")}
          />
        </Field>
        {isEdit ? (
          <ContentButtons>
            <Button
              bgColor="#FB7676"
              style={{ height: 40 }}
              padding={0}
              onClick={handleCancelarProcedimentoEdit}
            >
              Cancelar
            </Button>
            <Button
              bgColor={"#00B0AE"}
              style={{ height: 40 }}
              padding={0}
              onClick={handleSalvarProcedimentoEdit}
              disabled={!procedimento?.id || !profissionalSaude?.id}
            >
              Salvar
            </Button>
          </ContentButtons>
        ) : (
          <Button
            bgColor={"#707C97"}
            style={{ height: 40 }}
            padding={0}
            onClick={handleAdicionaProcedimento}
            disabled={!procedimento?.id || !profissionalSaude?.id}
          >
            Adicionar
          </Button>
        )}
      </Row>
      <Table
        columns={columnsProcedimentosSolicitados({ handleDelete, handleEdit })}
        dados={procedimentosSolicitados}
      />
    </ContainerProcedimentos>
  );
};

export default ProcedimentosSolicitados;
