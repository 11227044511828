import React, { useEffect, useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Dialog from "../../../components/Dialog/Dialog";
import { CircularProgress, Fab } from "@material-ui/core";
import style from "./AvaliacaoAntropometricaStyle";
import GracefulImage from "../../../components/Image/GracefulImage";
import MultiToggleButtons from "../../../components/Button/MultiToggleButtons";
import NovoLancamento from "./NovoLancamento";
import { buildUrlFotoPerfil } from "../../../config/config";
import HistoricoLancamentos from "./Tabs/HistoricoLancamentos/HistoricoLancamentos";
import { findAllAvaliacaoAntropometrica } from "../../../services/AvaliacaoAntropometricaService";
import GraficosAntropometricos from "./Tabs/Graficos/GraficosAntropometricos";
import imgFilter from "../../../assets/img/svg/img-filter.svg";
import { inject } from "mobx-react";
import Scroll from "../../../components/InfiniteScroll/Scroll";
import FiltroAvaliacaoAntropometrica from "./FiltroAvaliacaoAntropometrica";
import classNames from "classnames";
import moment from "moment";
import string from "../../../utils/string";
import PageTitle from "../../../components/PageTitle/PageTitle";
import { Button } from "../../../components/ui/Buttons";

const profileDefault = require("../../../assets/img/svg/profile-default.svg");

const tabs = {
  GRAFICOS: 0,
  HISTORIOLANCAMENTOS: 1,
};

const tiposGrafico = {
  ZSCORE: 0,
  PERCENTILES: 1,
};

const tiposVisualizacao = {
  MENSAL: 0,
  SEMANAL: 1,
};

const periodoVisualizacao = {
  ZEROASEISMESES: 0,
  ZEROADOISANOS: 1,
  DOISACINCOANOS: 2,
  PADRAO: 3,
};

const AvaliacaoAntropometricaModal = (props) => {
  const {
    classes,
    openModal,
    handleClose,
    sujeitoAtencao,
    idadeSujeitoAtencao,
    avaliacaoAntropometricaSelecionado,
    isNovoLancamento,
    avaliacaoAntropometricaStore,
    screen,
  } = props;
  const [tabSelected, setTabSelected] = useState(tabs.GRAFICOS);
  const [
    historicoLancamentoSelecionado,
    setHistoricoLancamentoSelecionado,
  ] = useState(null);
  const [
    listaAvaliacaoAntropometrica,
    setListaAvaliacaoAntropometrica,
  ] = useState([]);
  const [
    ultimoLancamentoAvaliacaoAntropometrica,
    setUltimoLancamentoAvaliacaoAntropometrica,
  ] = useState(null);
  const [isVisualizarLancamento, setIsVisualizarLancamento] = useState(false);
  const [openModalFiltrar, setOpenModalFiltrar] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [placement, setPlacement] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [lastScroll, setLastScroll] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tabSelectedTipoGrafico, setTabSelectedTipoGrafico] = useState(tiposGrafico.ZSCORE);
  const [tabSelectedTipoVisualizacao, setTabSelectedTipoVisualizacao] = useState(tiposVisualizacao.MENSAL);
  const [tabSelectedPeriodoVisualizacao, setTabSelectedPeriodoVisualizacao] = useState(periodoVisualizacao.PADRAO);
  const [idadeSujeitoAtencaoNumber, setIdadeSujeitoAtencaoNumber] = useState(Number(idadeSujeitoAtencao?.split(' ')[0]) || null);

  useEffect(() => {
    listarAvaliacoesAntropometricas();
    setIsVisualizarLancamento(!isNovoLancamento);
    inicializarDatas();
  }, []);

  useEffect(() => {
    pageNumber > 0 && listarAvaliacoesAntropometricas();
  }, [pageNumber]);

  useEffect(() => {
    const listaLancamentoAtivos = listaAvaliacaoAntropometrica.filter(item => item.ativo);
    setUltimoLancamentoAvaliacaoAntropometrica({...listaLancamentoAtivos[0], data: moment() });
  }, [listaAvaliacaoAntropometrica])

  const inicializarDatas = () => {
    const dataNascimento = sujeitoAtencao.dataNascimento;
    avaliacaoAntropometricaStore.dataInicioFiltroGrafico = moment(avaliacaoAntropometricaStore.dataInicioFiltroGrafico).isBefore(dataNascimento) ? 
      dataNascimento :
      moment().startOf('month').subtract(5, "years");
    avaliacaoAntropometricaStore.dataFimFiltroGrafico = moment().endOf('month');
    avaliacaoAntropometricaStore.dataFimFiltroGraficoSemanal = moment().isoWeekday(5);
    avaliacaoAntropometricaStore.dataInicioFiltroGraficoSemanal = moment().isoWeekday(1);

  };

  const listarAvaliacoesAntropometricas = async () => {
    try {
      setIsLoading(true);

      const listaAvaliacao = await findAllAvaliacaoAntropometrica({
        sujeitoAtencaoId: sujeitoAtencao.id,
        pageableDTO: {
          pageNumber: pageNumber,
        },
      });
      setLastScroll(listaAvaliacao.last);
      setListaAvaliacaoAntropometrica([
        ...listaAvaliacaoAntropometrica,
        ...listaAvaliacao.content,
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  const listarAvaliacoesAntropometricasAposSalvarLancamento = async () => {
    try {
      setIsLoading(true);
      setPageNumber(0);

      const listaAvaliacao = await findAllAvaliacaoAntropometrica({
        sujeitoAtencaoId: sujeitoAtencao.id,
        pageableDTO: {
          pageNumber: 0,
        },
      });
      setLastScroll(listaAvaliacao.last);
      setUltimoLancamentoAvaliacaoAntropometrica(listaAvaliacao.content[0]);
      setListaAvaliacaoAntropometrica(listaAvaliacao.content);
    } finally {
      setIsLoading(false);
    }
  };

  const changeTabSelected = (tabSelected) => {
    setTabSelected(tabSelected);
  };

  const changeTabSelectedTipoGrafico = (tabSelected) => {
    setTabSelectedTipoGrafico(tabSelected);

    avaliacaoAntropometricaStore.buscaDadosGrafico({
      sujeitoAtencao: sujeitoAtencao,
      tipo: tabSelected === 0 ? "ZSCORE" : "PERCENTILES",
    });
  };

  const changeTabSelectedTipoVisualizacao = (tabSelected) => {
    setTabSelectedTipoVisualizacao(tabSelected);

    if (tabSelected === 1) {
      avaliacaoAntropometricaStore.findAllAvaliacaoAntropometricaMediaDiaria({
        ativo: true,
        sujeitoAtencaoId: sujeitoAtencao.id,
      });
    } else {
      avaliacaoAntropometricaStore.buscaDadosGrafico({
        sujeitoAtencao,
        tipo: tabSelectedTipoGrafico === 0 ? "ZSCORE" : "PERCENTILES",
      });
    }

    avaliacaoAntropometricaStore.isTipoGraficoSemanal = tabSelected === 1;
  };

  const handleVisualizar = (item) => {
    setHistoricoLancamentoSelecionado(item);
    setIsVisualizarLancamento(true);
  };

  const calculaPesoAltura = (peso, altura) => {
    const alturaEmMetro = altura * 0.01;

    const imc = peso / (alturaEmMetro * alturaEmMetro);
    return imc.toFixed(1);
  };

  const loadMore = () => {
    if (isLoading) {
      return;
    }
    setPageNumber(listaAvaliacaoAntropometrica.length > 0 ? pageNumber + 1 : 0);
  };

  const inativarAvaliacaoAntropometrica = (avaliacao, index) => {
    const listaComItemInativado = listaAvaliacaoAntropometrica;
    listaComItemInativado[index] = {...avaliacao, ativo: false};

    setListaAvaliacaoAntropometrica(listaComItemInativado);
  };

  const renderTab = () => {
    switch (tabSelected) {
      case 0:
        return (
          <GraficosAntropometricos
            isLoading={isLoading}
            sujeitoAtencao={sujeitoAtencao}
            selectedTipoGrafico={
              tabSelectedTipoGrafico === 0 ? "ZSCORE" : "PERCENTILES"
            }
          />
        );
      case 1:
      default:
        openModalFiltrar && setOpenModalFiltrar(false);
        return (
          <>
          {
          !isLoading && listaAvaliacaoAntropometrica.length === 0 ?
          <div className={classes.mensagemDeListaVazia}>
            Nenhum item encontrado
          </div>
          :  
            <Scroll
            loadMore={loadMore}
            hasMore={!lastScroll}
            pageStart={0}
            initialLoad={false}
            className={classes.scrollContainerModelos}
          >
            {listaAvaliacaoAntropometrica.map((item, index) => {
              const imc =
                item.peso &&
                item.estatura &&
                calculaPesoAltura(item.peso, item.estatura);
              const sexoId = sujeitoAtencao?.sexo?.id;
              return (
                <HistoricoLancamentos
                  key={index}
                  avaliacaoAntropometrica={{ ...item, imc: imc, idade: idadeSujeitoAtencaoNumber, sexoId, dataNascimento: sujeitoAtencao?.dataNascimento }}
                  handleVisualizar={() => handleVisualizar(item)}
                  carregaListaHistoricoLancamento={
                    listarAvaliacoesAntropometricas
                  }
                  inativarAvaliacaoAntropometrica={() => inativarAvaliacaoAntropometrica(item, index)}
                  avaliacaoAntropometricaSelecionado={avaliacaoAntropometricaSelecionado}
                />
              );
            })}
            {isLoading && (
              <div className={classes.notFoundContainer}>
                <CircularProgress />
              </div>
            )}
          </Scroll>}
          </>
        );
    }
  };

  const handleClickNovoLancamento = () => {
    setHistoricoLancamentoSelecionado(ultimoLancamentoAvaliacaoAntropometrica);
    setIsVisualizarLancamento(false);
  };

  const handleClick = (newPlacement) => (event) => {
    setAnchorEl(event.currentTarget);
    setOpenModalFiltrar((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  const handleDateInicioChange = (e) => {
    avaliacaoAntropometricaStore.dataInicioFiltroGrafico = e;
    avaliacaoAntropometricaStore.buscaDadosGrafico({ 
      sujeitoAtencao,
      tipo: tabSelectedTipoGrafico === 0 ? "ZSCORE" : "PERCENTILES",
    });
    setTabSelectedPeriodoVisualizacao(3)
  };

  const handleDateFinalChange = (e) => {
    avaliacaoAntropometricaStore.dataFimFiltroGrafico = e;
    avaliacaoAntropometricaStore.buscaDadosGrafico({ 
      sujeitoAtencao,
      tipo: tabSelectedTipoGrafico === 0 ? "ZSCORE" : "PERCENTILES",
    });
    setTabSelectedPeriodoVisualizacao(3)
  };

  const handleDateInicioSemanaChange = (e) => {
    avaliacaoAntropometricaStore.dataInicioFiltroGraficoSemanal = e;
    avaliacaoAntropometricaStore.findAllAvaliacaoAntropometricaMediaDiaria({
      ativo: true,
      sujeitoAtencaoId: sujeitoAtencao.id,
    });
  };

  const handleDateFinalSemanaChange = (e) => {
    avaliacaoAntropometricaStore.dataFimFiltroGraficoSemanal = e;
    avaliacaoAntropometricaStore.findAllAvaliacaoAntropometricaMediaDiaria({
      ativo: true,
      sujeitoAtencaoId: sujeitoAtencao.id,
    });
  };
 
  const changeTabSelectedPeriodoVisualizacao = (e) => {
    const dataNascimento = moment(sujeitoAtencao.dataNascimento);
    setTabSelectedPeriodoVisualizacao(e);
    switch(e) {
      case 0:
        const dataFimZeroASeisMeses = moment(dataNascimento);
        dataFimZeroASeisMeses.add(6, "months");
        
        avaliacaoAntropometricaStore.alteraDataInicioFinal(dataNascimento, dataFimZeroASeisMeses);
        break;
        case 1:
          const dataFimZeroaDoisAnos = moment(dataNascimento);
          dataFimZeroaDoisAnos.add(2, "years");
          
          avaliacaoAntropometricaStore.alteraDataInicioFinal(dataNascimento, dataFimZeroaDoisAnos);
        break;
      case 2:
        default:
        const dataInicio = moment(dataNascimento);
        dataInicio.add(2, "years")

        const dataFim = moment(dataNascimento);
        dataFim.add(5, "years");   
        
        avaliacaoAntropometricaStore.alteraDataInicioFinal(dataInicio, dataFim);
        break;
    }

    avaliacaoAntropometricaStore.buscaDadosGrafico({
      sujeitoAtencao,
      tipo: tabSelectedTipoGrafico === 0 ? "ZSCORE" : "PERCENTILES",
    });
  };

  const getSexoDescricao = () => {
    if (sujeitoAtencao?.sexo?.descricao) {
      return string.capitalize(sujeitoAtencao.sexo.descricao);
    } else {
      switch(sujeitoAtencao.sexoId) {
        case 1:
          return 'Masculino'
        case 2:
          return 'Femenino'
        default: 
          return '-'
      }
    }
  }

  const titleTab = () => {
    const tab = tabSelected === 0 ? "Gráficos" : "Histórico de lançamentos"
    const acao = isVisualizarLancamento ? "Visualizar" : "Novo"

    return `${screen} - ${acao} dados antropométricos - ${tab}`
  };

  return (
    <>
    <PageTitle title={titleTab()}/>
    <Dialog
      classes={{
        paper: classes.fullSizePaper,
      }}
      maxWidth="md"
      open={openModal}
      fullWidth
    >
      <div className={classes.contentModal}>
        <div className={classes.header}>
          <div className={classes.headerTitleButtons}>
            <div className={classes.tituloHeader}>Avaliação antropométrica</div>
          </div>
          <div className={classes.dadoPacienteButton}>
            <div className={classes.dadosPacienteFoto}>
              <GracefulImage
                src={
                  sujeitoAtencao?.fotoPerfil
                    ? buildUrlFotoPerfil(sujeitoAtencao?.fotoPerfil)
                    : profileDefault
                }
                className={classes.imageMini}
                alt={"Imagem Perfil"}
                height="60"
                placeholderColor={"#00ACA9"}
              />
              <div className={classes.boxDadosPaciente}>
                <div className={classes.nomePaciente}>
                  {sujeitoAtencao.nome}
                </div>
                <div className={classes.dadosPaciente}>
                  <div className={classes.dadosPacienteItem}>
                    Idade: <span> {idadeSujeitoAtencao} </span>
                  </div>
                  <div className={classes.dadosPacienteItem}>
                    Sexo: <span>{getSexoDescricao()}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.divButtonNovoLancamento}>
              {isVisualizarLancamento && isNovoLancamento && (
                <Button
                  onClick={handleClickNovoLancamento}
                  disabled={false}
                >
                  Novo lançamento
                </Button>
              )}
            </div>
          </div>
        </div>
        <div className={classes.content}>
          <MultiToggleButtons
            options={["Gráficos", "Histórico de lançamentos"]}
            tabSelected={tabSelected}
            changeTabSelected={changeTabSelected}
          />
          <div className={classes.boxTab}>{renderTab()}</div>
        </div>
        {tabSelected === 0 && (
          <Fab
            className={classNames(
              classes.buttonGray,
              openModalFiltrar && classes.buttonGraySelecionado
            )}
            onClick={handleClick("left-end")}
          >
            <img src={imgFilter} alt="Filtro" />
          </Fab>
        )}
        <FiltroAvaliacaoAntropometrica
          openModalFiltrar={openModalFiltrar}
          anchorEl={anchorEl}
          placement={placement}
          handleDateInicioChange={handleDateInicioChange}
          handleDateFinalChange={handleDateFinalChange}
          tabSelectedTipoGrafico={tabSelectedTipoGrafico}
          tabSelectedTipoVisualizacao={tabSelectedTipoVisualizacao}
          changeTabSelectedTipoGrafico={changeTabSelectedTipoGrafico}
          changeTabSelectedTipoVisualizacao={changeTabSelectedTipoVisualizacao}
          handleDateFinalSemanaChange={handleDateFinalSemanaChange}
          handleDateInicioSemanaChange={handleDateInicioSemanaChange}
          tabSelectedPeriodoVisualizacao={tabSelectedPeriodoVisualizacao}
          changeTabSelectedPeriodoVisualizacao={changeTabSelectedPeriodoVisualizacao}
        />
      </div>
      <div className={classes.contentNovoLancamento}>
        <NovoLancamento
          actionClose={handleClose}
          sujeitoAtencao={sujeitoAtencao}
          historicoLancamentoSelecionado={historicoLancamentoSelecionado}
          carregaListaHistoricoLancamento={listarAvaliacoesAntropometricasAposSalvarLancamento}
          ultimoLancamentoAvaliacaoAntropometrica={
            ultimoLancamentoAvaliacaoAntropometrica
          }
          isVisualizarLancamento={isVisualizarLancamento}
          avaliacaoAntropometricaSelecionado={
            avaliacaoAntropometricaSelecionado
          }
          tipoGrafico={tabSelectedTipoGrafico === 0 ? "ZSCORE" : "PERCENTILES"}
        />
      </div>
    </Dialog>
    </>
  );
};

const AvaliacaoAntropometricaModalWithStyle = withStyles(style)(AvaliacaoAntropometricaModal);
export default inject("avaliacaoAntropometricaStore")(AvaliacaoAntropometricaModalWithStyle);
