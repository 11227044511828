import { withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styles from './LeitosStyle';
import PageTitle from '../../../components/PageTitle/PageTitle';
import { pageConfiguracoes } from '../../../utils/getPageTitle';
import HeaderConfiguracoesButtonNovo from '../Components/HeaderConfiguracoesButtonNovo';
import Scroll from '../../../components/InfiniteScroll/Scroll';
import Table from '../../../components/Table/Table';
import LeitoModal from './LeitoModal';
import Notification from '../../../components/Notification';
import { notificationDefault, pageableDTODefault, columns, leitoDefault, tiposLeito } from './Constants';
import { ativarLeito, inativarLeito, findAllLeito, findByIdLeito } from '../../../services/LeitoService';
import MensagemListaVazia from '../../../components/Mensagem/MensagemListaVazia';

const Leitos = ({ classes, }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [last, setLast] = useState(true);
  const [pageableDTO, setPageableDTO] = useState(pageableDTODefault);
  const [search, setSearch] = useState('');
  const [leitos, setLeitos] = useState([]);
  const [notification, setNotification] = useState(notificationDefault);
  const [leitoSelecionado, setLeitoSelecionado] = useState(leitoDefault);

  useEffect(() => {
    loadLeitos({ isClear: true });

  }, [pageableDTO.sortDir, pageableDTO.sortField])

  const handleCreate = () => {
    setLeitoSelecionado(leitoDefault);
    setOpen(true);
  };

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearch(value);
  };

  const onKeypressSearch = (e) => {
    if (e.key === "Enter") {
      handleClickSearch();
    }
  };

  const handleClickSearch = () => {
    setPageableDTO({ ...pageableDTO, pageNumber: 0 });
    loadLeitos({ isClear: true });
  };

  const handleClickClear = () => {
    setSearch('');
    setPageableDTO({ ...pageableDTO, pageNumber: 0 });
    loadLeitos({ isClear: true, isClearSearching: true });

  };

  const openNotification = ({ message, type }) => {
    setNotification({
      open: true,
      type,
      message,
    });

    const timeoutId = setTimeout(() => {
      resetNotification();
      clearTimeout(timeoutId);
    }, 4000);
  };

  const resetNotification = () => {
    setNotification(notificationDefault);
  };

  const closeAlertMessage = () => {
    setNotification(notificationDefault);
  };

  const loadLeitos = async(options) => {
    try {
      setLoading(true);

      const pageable = {
        ...pageableDTO,
        pageNumber: options?.isClear ? 0 : pageableDTO.pageNumber,
      };

      const textSearch = options?.isClearSearching ? "" : search;

      const response = await findAllLeito({
        search: textSearch,
        pageableDTO: pageable,
      });

      const { content, last } = response;

      setLeitos(options?.isClear ? content : [...leitos, ...content]);
      setLast(last);
      setPageableDTO({ ...pageableDTO, pageNumber: pageable.pageNumber + 1 });


    } catch(e) {
      openNotification({
        message: "Erro ao carregar os leitos",
        type: "error",
      })
    } finally {
      setLoading(false);
    }
  }

  const handleClickOrdenar = value => {
    const sortDir =
      pageableDTO.sortField !== value ? 'ASC' : pageableDTO.sortDir === 'ASC' ? 'DESC' : 'ASC'
    setPageableDTO({
      ...pageableDTO,
      pageNumber: 0,
      sortDir: sortDir,
      sortField: value,
    });
  };

  const handleSelectLeito = async(leitoId) => {
    const response = await findByIdLeito({ id: leitoId });
    const tipoLeito = tiposLeito.find(item => item.value === response.tipoLeito);

    setLeitoSelecionado({
      ...response,
      tipoLeito,
    });
    setOpen(true);
  };

  const handleDuplicar = async(leitoId) => {
    const response = await findByIdLeito({ id: leitoId });

    const tipoLeito = tiposLeito.find(item => item.value === response.tipoLeito);

    setLeitoSelecionado({
      ...response,
      tipoLeito,
      id: null
    });
    setOpen(true);
  };

  const handleDesativar = async(id) => {
    try {
      await inativarLeito(id);
      loadLeitos({ isClear: true });
    } catch(e) {
      openNotification({
        message: "Erro ao inativar o leito",
        type: "error",
      })
    }
  };

  const handleAtivar = async(id) => {
    try {
      await ativarLeito(id);
      loadLeitos({ isClear: true });
    } catch(e) {
      openNotification({
        message: "Erro ao inativar o leito",
        type: "error",
      })
    }
  };

  return (
    <div>
       <PageTitle title={pageConfiguracoes("Leitos")} />
       <HeaderConfiguracoesButtonNovo
          title="Leitos"
          labelButton="Novo leito"
          handleClickNovo={handleCreate}
          search={{
            handleChange: handleSearchChange,
            value: search,
            onKeypress: onKeypressSearch,
            handleClickClear: handleClickClear,
            onClickButtonSearch: handleClickSearch
          }}
        />
        <div className={classes.content}>
          <div className={classes.tableContainer}>
            <Scroll
              loadMore={() => loadLeitos()}
              hasMore={last}
              pageStart={0}
              initialLoad={false}
              className={classes.scrollContainer}
              isLoading={loading}
            >
              <Table
                dados={leitos}
                columns={columns({
                  functions: {
                    handleEditar: handleSelectLeito,
                    handleDuplicar,
                    handleDesativar,
                    handleAtivar,
                  }
                })}
                clickable={true}
                handleClick={handleSelectLeito}
                ordenarTabela={{
                  sortField: pageableDTO.sortField,
                  sortDir: pageableDTO.sortDir
                }}
                comOrdenacao
                handleClickOrdenar={handleClickOrdenar}
              />
              {
                leitos?.length === 0 && !loading &&
                <div className={classes.notFoundContainer}>
                  <MensagemListaVazia/>
                </div>
              }
            </Scroll>
          </div>
        </div>
        {open && <LeitoModal 
          open={open} 
          onClose={() => setOpen(false)} 
          leitoSelecionado={leitoSelecionado} 
          loadLeitos={loadLeitos}
          openNotification={openNotification}
        />}
        <Notification
          close={closeAlertMessage}
          reset={closeAlertMessage}
          isOpen={notification.open}
          variant={notification.type}
          message={notification.message}
        />
    </div>
  );
};

export default withStyles(styles)(Leitos);
